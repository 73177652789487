import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EapModal from '../../../components/EapModal';
import ProductSelector from './ProductSelector';
import EapButton from '../../../components/EapButton';

import './ProductAssignmentModal.css';

const ProductAssignmentModal = ({
  assignProduct,
  selectedExaminee,
  closeModal,
}) => {
  const [selectedProduct, setSelectedProduct] = useState();
  return (
    <EapModal
      modalTitle="Assign Product"
      showModal
    >
      <form
        onSubmit={() => {
          if (selectedProduct) {
            assignProduct(selectedExaminee, selectedProduct);
          }
        }}
      >
        <div className="super-assign-product-form">
          <ProductSelector
            name="product"
            value={selectedProduct}
            onChange={(product) => setSelectedProduct(product)}
          />
        </div>
        <div className="pure-u-1 product-assign-button-bar">
          <EapButton
            text="Assign"
            disabled={selectedProduct === undefined}
            onClick={() => {
              assignProduct(selectedExaminee, selectedProduct);
            }}
          />
          <EapButton
            text="CLOSE"
            isSecondary
            onClick={() => closeModal()}
          />
        </div>
      </form>
    </EapModal>
  );
};

export default ProductAssignmentModal;

ProductAssignmentModal.propTypes = {
  assignProduct: PropTypes.func.isRequired,
  selectedExaminee: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};
