import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import SelfActivationForm from './SelfActivationForm';
import FormHeader from './components/FormHeader';
import SensibleContainer from './components/SensibleContainer';
import EapThrone from './components/EapThrone';

const Activator = ({
  activateUser,
  user,
  error
}) => (
  <div className="content pure-g">
    <SensibleContainer>
      <EapThrone>
        <FormHeader
          headerText="Account Activation"
          renderParagraph={() => (
            <>
              Please enter your information below to activate
              your sign-in. Fields marked with (<span className="required">*</span>) are required.
            </>
          )}
        />
        <div className="pure-u-3-4">
          <SelfActivationForm
            initialValues={user}
            onSubmit={activateUser}
            error={error}
          />
        </div>
      </EapThrone>
    </SensibleContainer>
  </div>
);

Activator.propTypes = {
  activateUser: PropTypes.func.isRequired,
  error: PropTypes.string,
  user: PropTypes.shape({
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
    dob: PropTypes.string,
    grade: PropTypes.number
  }).isRequired
};

Activator.defaultProps = {
  error: undefined
};

export default withRouter(Activator);
