import { connect } from 'react-redux';

//import { getOrganizationGroups } from '../../actions/group';
import { transferProductsAsSuper } from '../../actions/availableProducts';

import AdminTransferProductModal from './components/AdminTransferProductModal';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = dispatch => ({
  getGroups: (organizationId) => {
    //dispatch(getOrganizationGroups(organizationId));
  },
  transferProducts: (availableProductId, sourceGroupId, amount, destinationGroupId) => {
    dispatch(transferProductsAsSuper(
      availableProductId,
      sourceGroupId,
      amount,
      destinationGroupId
    ));
  }
});

const AdminTransferProductModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTransferProductModal);

export default AdminTransferProductModalContainer;
