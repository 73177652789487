import {
  GET_ALL_PRODUCTS_SUCCESS,
} from '../actions/products';

const productsReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS_SUCCESS: {
      return {
        list: action.payload.products
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default productsReducer;
