import React from 'react';

import './EditsLogo.css';

const EditsLogo = () => (
  <div className="pure-u-1-6">
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 297.03 284.3"
    >
      <polygon className="cls-1" points="131.41 184.5 148.09 184.5 161.33 101.48 144.65 101.48 131.41 184.5" />
      <path className="cls-1" d="M149.14,15.16h-.46c-34.13.9-118.52,94.58-118.32,126.31.23,34.61,84.12,127.66,118.19,127.66,33.3,0,117.88-92.12,118.12-127.19C266.87,109.93,182.52,15.16,149.14,15.16ZM149,256c-20.49,0-61.26-37.87-85.56-71.9H92.58a32.77,32.77,0,0,0,8.38-.75,35.55,35.55,0,0,0,4.31-1.21c7.83-2.75,17-9.26,20.58-24.46,0-.1.06-.2.08-.3l2.15-13.63,6.8-42.66H118.2l-5.15,32.29h-.15a10.18,10.18,0,0,0-2.88-5.79q-2.3-2.22-6.75-2.22A13.67,13.67,0,0,0,93.83,129a31,31,0,0,0-6.9,9.46,53.91,53.91,0,0,0-4.27,12.76c-.31,1.52-.55,3-.77,4.51a65.72,65.72,0,0,0-.67,9.07c0,1,0,2.06.12,3H53.29l3-19H74l2.37-14.79H58.57l2.8-17.25h40.39L104.23,101H64.35c24.43-33.11,64.51-70.31,84.75-70.84,19.75-.52,60.37,37.6,84.87,71.37H169.63l-2.48,15.36h17.22L173.5,184.49h16.36l10.88-67.64h13a3.27,3.27,0,0,0-.14.33,30,30,0,0,0-1.87,10.7,23.46,23.46,0,0,0,1,7.32,21.73,21.73,0,0,0,2.74,5.64,20.39,20.39,0,0,0,3.93,4.28,43.85,43.85,0,0,0,4.61,3.27,20.81,20.81,0,0,1,5.94,5.13,9.7,9.7,0,0,1,1.81,5.8,10.14,10.14,0,0,1-.77,4.06,9.09,9.09,0,0,1-5,5,8.53,8.53,0,0,1-3.31.68,13.26,13.26,0,0,1-6.36-1.63,16.15,16.15,0,0,1-5.12-4.23l-9.42,13a24.36,24.36,0,0,0,8.69,6.09,30.08,30.08,0,0,0,12.31,2.47,26.78,26.78,0,0,0,9.42-1.69,22.83,22.83,0,0,0,5.19-2.75C213.45,215.19,169.87,256,149,256ZM95.62,162.09a46.26,46.26,0,0,1,.65-7.4,40.11,40.11,0,0,1,1.93-7.57,21.08,21.08,0,0,1,3.18-5.9,6,6,0,0,1,3.2-2.22,4.86,4.86,0,0,1,1.27-.16h.21a4.18,4.18,0,0,1,4,2.71,16.94,16.94,0,0,1,1.24,7,46.15,46.15,0,0,1-2.68,14.71,20.45,20.45,0,0,1-3.18,5.78,5.46,5.46,0,0,1-4.27,2.33c-.24,0-.47,0-.7,0a4.21,4.21,0,0,1-3.63-2.57A15.58,15.58,0,0,1,95.62,162.09Zm151.26-12.52a23.09,23.09,0,0,0-3.16-5.86,24.25,24.25,0,0,0-4.24-4.39c-1.55-1.24-3-2.31-4.39-3.21a23.22,23.22,0,0,1-5.44-4.4,8.11,8.11,0,0,1-1.81-5.41,10.09,10.09,0,0,1,3-7.55,9,9,0,0,1,2.9-1.86,8.55,8.55,0,0,1,3.05-.62,12.33,12.33,0,0,1,9.87,5.12c4.62,8.54,7.34,16,7.3,21.47s-2.36,12.43-6.33,20.27a37.24,37.24,0,0,0,.44-5.84A22,22,0,0,0,246.88,149.57Z" />
    </svg>
  </div>
);

export default EditsLogo;
