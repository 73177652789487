import React from 'react';
import PropTypes from 'prop-types';

// NOTE: This is a wrapper class that helps smooth the inconsistencies
// between a Stripe Element input and a regular vanilla element. It requires
// pure grid declaration for each instance
const CheckoutInputElement = ({ children, gridClass }) => (
  <div className={gridClass}>
    <div style={{ padding: '1rem .5rem 0rem .5rem' }}>
      {children}
    </div>
  </div>
);

CheckoutInputElement.propTypes = {
  gridClass: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
};

export default CheckoutInputElement;

