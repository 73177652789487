import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  PasswordResetNotFoundMessage,
  PasswordResetMultipleAccountMessage,
  PasswordResetSuccessMessage
} from './PasswordResetFeedback';
import EapStaticAlert from '../../components/EapStaticAlert';
import EapButton from '../../components/EapButton';
import './PasswordReset.css';

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.state = {
      success: false,
      emailAddress: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    const resetData = nextProps.password[this.state.emailAddress];
    if (resetData) {
      this.setState({
        success: resetData.success,
        notFound: resetData.notFound,
        multipleAccountsFound: resetData.multipleAccounts
      });
    }
  }

  onInputChange(e) {
    this.setState({
      emailAddress: e.target.value,
      success: false,
      multipleAccountsFound: false,
      notFound: false,
    });
  }

  render() {
    const hasError = this.state.notFound || this.state.multipleAccountsFound;
    return (
      <div className="password-reset-section">
        <EapStaticAlert
          positive={this.state.success}
        >
          {(this.state.success || hasError) &&
            <div>
              {this.state.success &&
                <PasswordResetSuccessMessage emailAddress={this.state.emailAddress} />
              }
              {this.state.notFound &&
                <PasswordResetNotFoundMessage emailAddress={this.state.emailAddress} />
              }
              {this.state.multipleAccountsFound &&
                <PasswordResetMultipleAccountMessage emailAddress={this.state.emailAddress} />
              }
            </div>
          }
        </EapStaticAlert>
        <p>
          Please enter your email address to recover your password.
        </p>
        <div className="pure-form pure-form-stacked eap-grey-form">
          <form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              this.props.resetPassword(this.state.emailAddress);
            }}
          >
            <label htmlFor="username-input" className="">
              EMAIL
            </label>
            <input
              type="text"
              className="pure-input pure-input-1"
              id="email-input"
              onChange={this.onInputChange}
            />
            <EapButton
              optionalClass="recover-password-btn"
              text="SEND RECOVERY EMAIL"
              isSubmit
            />
          </form>
          <div className="pure-u-2-5" style={{ paddingTop: '1.5em' }}>
            <Link to="/signin">
              Go back to Sign In
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

PasswordReset.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  password: PropTypes.object,
};

PasswordReset.defaultProps = {
  password: undefined
};

export default PasswordReset;
