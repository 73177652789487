import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import EapButton from '../../components/EapButton';

import './FailedPaymentDisplay.css';

const FailedPaymentDisplay = ({ message, groupId }) => (
  <div className="pure-u-1 failed-payment-display">
    <p>
      The credit card could not be processed.
    </p>
    <p>
      Reason: {message}
    </p>
    <div className="pure-u-2-3" />
    <div className="pure-u-1-3">
      <div style={{ float: 'right' }}>
        <Link to={`/group/${groupId}/products`}>
          <EapButton text="DONE" onClick={() => {}} />
        </Link>
      </div>
    </div>
  </div>
);

FailedPaymentDisplay.propTypes = {
  message: PropTypes.string.isRequired,
  groupId: PropTypes.number.isRequired
};

FailedPaymentDisplay.defaultProps = {
};

export default FailedPaymentDisplay;
