import { connect } from 'react-redux';
import { loadStripePublicKey } from '../actions/stripe';
import { refill } from '../actions/purchase';

import Checkout from './components/Checkout';

const mapStateToProps = (state, ownProps) => {
  const group = state.group && state.group.groupsById[ownProps.groupId];
  const { availableProductId, groupId } = ownProps;

  const currentProduct = group.availableProducts
    .find(availableProduct => availableProduct.id.toString() === availableProductId);
  return {
    email: state.user.email,
    product: state.group.groupsById[groupId].availableProducts,
    availableProduct: currentProduct,
    stripePublicKey: state.stripe.publicKey
  };
};
const mapDispatchToProps = (dispatch) => ({
  loadStripePublicKey: () => dispatch(loadStripePublicKey()),
  refill: (params) => (dispatch(refill(params)))
});

const CheckoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);

export default CheckoutContainer;
