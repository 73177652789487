import React from 'react';
import PropTypes from 'prop-types';
import Selector from 'react-select/async';
import _ from 'lodash';

import { httpClient } from '../../../utils/httpClient';
import './ProductSelector.css';

class AllProductsSelector extends React.Component {
  constructor() {
    super();
    this.loadProducts = this.loadProducts.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      value: undefined,
      products: []
    };
  }

  onChange(selected) {
    if (!selected) {
      this.props.onChange();
      this.setState({ value: undefined });
    } else {
      this.props.onChange(selected.value);
      this.setState({ value: selected });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.value) {
      return {
        value: null
      };
    }
    if (!_.isEqual(props.value, state.value)) {
      return {
        value: _.find(state.products, prod => (prod.id === props.value.id))
      };
    }
    return null;
  }

  loadProducts(input) {
    return httpClient.get('/super/products', { params: { input } }).then((response) => {
      const mapped = _.map(response.data, prod => ({
        value: prod,
        label: prod.adminName
      }));
      this.setState({
        products: mapped
      });
      return Promise.resolve(mapped);
    });
  }

  render() {
    return (
      <Selector
        className={this.props.className}
        value={this.state.value}
        isSearchable
        isClearable
        loadOptions={this.loadProducts}
        onChange={this.onChange}
        placeholder="Product"
        defaultOptions
      />
    );
  }
}

AllProductsSelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.number,
    adminName: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
};

AllProductsSelector.defaultProps = {
  className: '',
  value: undefined,
};

export default AllProductsSelector;
