import React from 'react';
import PropTypes from 'prop-types';

import './FormInputGroup.css';

// Initial expectation here is Formik field (or input),
// and optionally a validation message block
const FormInputGroup = ({ children, required }) => {
  const classes = `form-input-group ${required === true ? 'form-input-required' : ''}`;
  return (
    <div className={classes}>
      {children}
    </div>
  );
};

FormInputGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  required: PropTypes.bool
};

FormInputGroup.defaultProps = {
  required: false
};

export default FormInputGroup;
