import React from 'react';
import PropTypes from 'prop-types';

const DisplayPrice = ({ amount }) => (
  <span>
    ${amount.toFixed(2)} USD
  </span>
);

DisplayPrice.propTypes = {
  amount: PropTypes.number.isRequired
};

export default DisplayPrice;
