import React from 'react';
import PropTypes from 'prop-types';

const FormInputComponent = ({
  required,
  formGroupStyle,
  keyName,
  displayLabel,
  inputType,
  handleChange,
  inputValue,
  disableInput,
  errorMessage
}) => {
  let asterisk = '';
  if (required) {
    asterisk = (<span className="required"> *</span>);
  }
  return (
    <div className={formGroupStyle}>
      <label
        htmlFor={keyName}
      >
        {displayLabel}
        {asterisk}
      </label>
      <input
        type={inputType}
        className="pure-u-11-12"
        id={keyName}
        autoComplete={keyName || undefined}
        name={keyName}
        onChange={handleChange}
        onBlur={handleChange}
        value={inputValue}
        disabled={disableInput}
        aria-invalid={errorMessage !== undefined}
        aira-required={required.toString()}
      />
      {
        (!errorMessage) ? '' :
        <div className="validation-message" role="alert">
          {errorMessage}
        </div>
      }
    </div>
  );
};

export default FormInputComponent;

FormInputComponent.propTypes = {
  required: PropTypes.bool,
  formGroupStyle: PropTypes.string,
  keyName: PropTypes.string.isRequired,
  displayLabel: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  disableInput: PropTypes.bool,
  inputValue: PropTypes.string.isRequired
};

FormInputComponent.defaultProps = {
  required: true,
  formGroupStyle: 'pure-u-lg-1-2 pure-u-md-1-2 pure-u-1',
  inputType: 'text',
  errorMessage: undefined,
  disableInput: false
};
