import React from 'react';
import PropTypes from 'prop-types';
import EAPModal from '../../../components/EapModal';
import EapButton from '../../../components/EapButton';
import UserSelector from '../../components/UserSelector';

const AddExamineeModal = ({ showModal, closeModal, addExaminee, }) => (
  <EAPModal
    showModal={showModal}
    modalTitle="Add Examinee"
  >
    <UserSelector
      onSelected={(user) => { addExaminee(user); closeModal(); }}
    />
    <EapButton
      text="Close"
      isSecondary
      onClick={() => closeModal()}
    />
  </EAPModal>
);

AddExamineeModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  addExaminee: PropTypes.func.isRequired
};

AddExamineeModal.defaultProps = {
  showModal: false
};

export default AddExamineeModal;
