import { connect } from 'react-redux';

import { addGroup } from '../actions/group';

import Dashboard from './components/Dashboard';

const mapStateToProps = (state) => {
  if (!state.user) {
    return {};
  }
  return {
    user: state.user
  };
};


const mapDispatchToProps = dispatch => ({
  // TODO: clean this up.
  loadUserData: () => {
  },
  onUserDataReceived: () => {
  },
  addNewGroup: (value) => {
    dispatch(addGroup(value.sourceGroup.groupId, value.name, value.description));
  }
});

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default DashboardContainer;
