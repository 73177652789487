import React from 'react';

import { httpClient } from '../utils/httpClient';
import history from '../utils/history';
import { dispatchAlert } from './alert';
import SERVER_ERROR from './serverErrorMessage';
import { initClientUser } from './user';

export const MOVE_EXAMINEE_REQUEST = 'MOVE_EXAMINEE_REQUEST';
export const MOVE_EXAMINEE_SUCCESS = 'MOVE_EXAMINEE_SUCCESS';
export const MOVE_EXAMINEE_FAILURE = 'MOVE_EXAMINEE_FAILURE';

export const ASSIGN_PRODUCT_SUCCESS = 'ASSIGN_PRODUCT_SUCCESS';
export const ASSIGN_PRODUCT_FAILURE = 'ASSIGN_PRODUCT_FAILURE';

export const ADD_EXAMINEE_SUCCESS = 'ADD_EXAMINEE_SUCCESS';
export const ADD_EXAMINEE_FAILURE = 'ADD_EXAMINEE_FAILURE';

export const GET_SUPER_GROUP_EXAMINEES_SUCCESS = 'GET_SUPER_GROUP_EXAMINEES_SUCCESS';
export const GET_SUPER_GROUP_EXAMINEES_FAILURE = 'GET_SUPER_GROUP_EXAMINEES_FAILURE';

export const SUPER_DELETE_EXAMINEE_SUCCESS = 'SUPER_DELETE_EXAMINEE_SUCCESS';
export const SUPER_DELETE_EXAMINEE_FAILURE = 'SUPER_DELETE_EXAMINEE_FAILURE';

export const SUPER_ASSIGN_PRODUCT_SUCCESS = 'SUPER_ASSIGN_PRODUCT_SUCCESS';
export const SUPER_ASSIGN_PRODUCT_FAILURE = 'SUPER_ASSIGN_PRODUCT_FAILURE';

export const SUPER_ADD_EXAMINEE_SUCCESS = 'SUPER_ADD_EXAMINEE_SUCCESS';
export const SUPER_ADD_EXAMINEE_ERROR = 'SUPER_ADD_EXAMINEE_ERRROR';

export const EXAMINEES_FILE_VALIDATION_REQUEST = 'examinees-file-validation-request';
export const EXAMINEES_FILE_VALIDATION_FAILED = 'EXAMINEES_FILE_VALIDATION_FAILED';
export const EXAMINEES_FILE_VALIDATION_SUCCEEDED = 'EXAMINEES_FILE_VALIDATION_SUCCEEDED';

export const EXAMINEES_FILE_UPLOAD_REQUEST = 'examinees-file-upload-request';
export const EXAMINEES_FILE_UPLOAD_SUCCESS = 'examinees-file-upload-success';
export const EXAMINEES_FILE_UPLOAD_FAILURE = 'examinees-file-upload-failure';

export const FILTER_ALL_EXAMINEES_REQUEST = 'examinees-filter-all-request';
export const FILTER_ALL_EXAMINEES_SUCCESS = 'examinees-filter-all-success';

export const EXAMINEE_LOAD_RESULTS_REQUEST = 'examinees-load-results-request';
export const EXAMINEE_LOAD_RESULTS_SUCCESS = 'examinees-load-results-success';
export const EXAMINEE_LOAD_RESULTS_CLEAR = 'examinees-load-results-clear';

function assignProductSuccessMessage(examinee, productName) {
  return (
    <span> Product
      <b> {productName} </b>
      has been assigned to
      <b> {examinee.firstName} {examinee.lastName}</b>.
    </span>
  );
}

function assignProductFailureMessage(examinee) {
  return (
    <span> Unable to assign product
      to <b> {examinee.firstName} {examinee.lastName}</b>.&nbsp;
      {SERVER_ERROR}
    </span>
  );
}

export const moveExamineeFailure = (error) => ({
  type: MOVE_EXAMINEE_FAILURE,
  payload: { error }
});

export function overrideExamineePassword(userId, newPassword, username) {
  return (dispatch) => {
    httpClient.put(`/user/${userId}/password`, { newPassword })
      .then(() => dispatch(dispatchAlert({
        type: 'eap-alert-success',
        message: (<span>Password updated for {username}</span>),
        time: 5000
      }))).catch((e) => {
        dispatch(dispatchAlert({
          message: (<span>Error overriding examinee password {e}</span>),
          type: 'eap-alert-success',
          time: 8000
        }));
      });
  };
}

export function moveExaminee(
  adminGroupId,
  examinee,
  sourceGroupId,
  newGroup,
  opts = { remove: false }
) {
  return (dispatch) => {
    dispatch({
      type: MOVE_EXAMINEE_REQUEST,
      payload: {
        examineeId: examinee.id,
        groupId: sourceGroupId,
        sourceUserGroupId: examinee.userGroupId,
        destUserGroupId: newGroup.userGroupId,
        destGroup: newGroup,
      }
    });
    return httpClient.put(
      `/group/${adminGroupId}/examinee/move`,
      {
        examineeId: examinee.id,
        sourceUserGroupId: examinee.userGroupId,
        destinationGroupId: newGroup.groupId
      }
    ).then(({ data }) => {
      dispatch({
        type: 'MOVE_EXAMINEE_SUCCESS',
        payload: {
          examineeId: examinee.id,
          groupId: sourceGroupId,
          sourceUserGroupId: examinee.userGroupId,
          destUserGroupId: data.userGroupId,
          destGroup: newGroup,
          opts,
        }
      });
      dispatch(dispatchAlert({
        message: (
          <span> Examinee
            <b> {examinee.firstName} {examinee.lastName} </b>
            moved to
            <b> {newGroup.name} </b>.
          </span>
        ),
        type: 'eap-alert-success',
        time: 5000
      }));
    }).catch((err) => {
      console.error('ERROR MOVING');
      console.error(err);
      dispatch(moveExamineeFailure(err.message));
      dispatch(dispatchAlert({
        message: (
          <span> Unable to move Examinee
            <b> {examinee.firstName} {examinee.lastName} </b>
            to <b> {newGroup.name}</b>.&nbsp;
            {SERVER_ERROR}
          </span>
        ),
        type: 'eap-alert-error',
      }));
    });
  };
}

export const assignProductSuccess = (assignedProduct, userId, groupId, availableProductId) => ({
  type: ASSIGN_PRODUCT_SUCCESS,
  payload: {
    assignedProduct, userId, groupId, availableProductId
  }
});

export const assignProductFailure = (error) => ({
  type: ASSIGN_PRODUCT_FAILURE,
  payload: { error }
});

export function assignProduct(adminGroupId, actorId, subject, groupId, availProductId) {
  return (dispatch) => {
    httpClient.post(
      `/group/${adminGroupId}/examinee/assign`,
      { userId: subject.id, availProductId }
    ).then((response) => {
      dispatch(assignProductSuccess(response.data, subject.id, groupId, availProductId));
      if (actorId === subject.id) {
        dispatch(initClientUser());
      }
      dispatch(dispatchAlert({
        message: assignProductSuccessMessage(subject, response.data.product.name),
        type: 'eap-alert-success',
        time: 10000
      }));
    }).catch((err) => {
      dispatch(assignProductFailure(err.message));
      dispatch(dispatchAlert({
        message: assignProductFailureMessage(subject),
        type: 'eap-alert-error'
      }));
    });
  };
}

export const addExamineeSuccess = (newExaminee, availableProductId, groupId) => ({
  type: ADD_EXAMINEE_SUCCESS,
  payload: { newExaminee, availableProductId, groupId }
});

export const addExamineeFailure = (error) => ({
  type: ADD_EXAMINEE_FAILURE,
  payload: { error }
});

export const parseBulkExamineesFile = (groupId, file, availableProductId) => (
  (dispatch) => {
    const formData = new FormData();
    dispatch({
      type: EXAMINEES_FILE_VALIDATION_REQUEST,
      payload: {}
    });
    formData.append('file', file);
    formData.append('availableProductId', availableProductId);
    return httpClient.post(`/super/group/${groupId}/examinees/validate`, formData).then((response) => {
      dispatch({
        type: EXAMINEES_FILE_VALIDATION_SUCCEEDED,
        payload: response.data
      });
    }).catch((err) => {
      if (err.response && err.response.status === 400) {
        dispatch({
          type: EXAMINEES_FILE_VALIDATION_FAILED,
          payload: err.response.data
        });
      }
    });
  }
);

export function bulkCreateExaminees(groupId, users, availableProductId) {
  return (dispatch) => {
    const u = users.map((usr) => ({
      ...usr,
      ...{
        dob: usr.dob ? usr.dob.format('MM/DD/YYYY') : undefined
      }
    }));
    dispatch({
      type: EXAMINEES_FILE_UPLOAD_REQUEST,
      payload: {}
    });
    httpClient.post(
      `/super/group/${groupId}/examinees`,
      {
        users: u,
        availableProductId
      }
    ).then((response) => {
      dispatch({
        type: EXAMINEES_FILE_UPLOAD_SUCCESS,
        payload: { ...response.data }
      });
    }).catch((err) => {
      dispatch({
        type: EXAMINEES_FILE_UPLOAD_FAILURE,
        payload: { err }
      });
      dispatch(dispatchAlert({
        message: (
          <span>Something went wrong during bulk examinee creation:
            {JSON.stringify(err)}
          </span>
        ),
        type: 'eap-alert-success',
        time: 10000
      }));
    });
  };
}

// TODO: Rename this to be CreateExaminee. It's only use on the admin side.
export function addExaminee(adminGroupId, signUpInfo, availProductInfo) {
  return (dispatch) => {
    httpClient.post(
      `/group/${adminGroupId}/examinee`,
      { ...signUpInfo, availProductId: availProductInfo.id }
    ).then((response) => {
      dispatch(addExamineeSuccess(response.data, availProductInfo.id, availProductInfo.groupId));
      dispatch(dispatchAlert({
        message: (
          <span> Examinee
            <b> {signUpInfo.username} </b>
            has successfully been added.
          </span>
        ),
        type: 'eap-alert-success',
        time: 10000
      }));
      history.push(`/group/${availProductInfo.groupId}/examinees/active`);
    }).catch((err) => {
      dispatch(addExamineeFailure(err.message));
      dispatch(dispatchAlert({
        message: (
          <span> Problem adding examinee. {err.message}</span>
        ),
        type: 'eap-alert-error',
      }));
    });
  };
}

// TODO: when we rename the function above, this should be addExaminee
export function superAddExaminee(user, groupId) {
  return (dispatch) => {
    httpClient.post(
      `/super/group/${groupId}/examinee`,
      { userId: user.id }
    ).then((res) => {
      dispatch({
        type: SUPER_ADD_EXAMINEE_SUCCESS,
        payload: {
          user,
          userGroupId: res.data.id,
          createdAt: res.data.createdAt,
          groupId
        }
      });
      dispatch(dispatchAlert({
        message: (
          <span>
            <b>{user.firstName} {user.lastName} </b>
            added as an examinee into the group.
          </span>
        ),
        time: 10000,
        type: 'eap-alert-success'
      }));
    }).catch((err) => {
      if (err.response && err.response.status === 400) {
        dispatch(dispatchAlert({
          message: (
            <span>
              Cannot add <b>{user.firstName} {user.lastName}</b>.
              Examinee exsists in the group.
            </span>
          ),
          type: 'eap-alert-error'
        }));
      }
      dispatch({
        type: SUPER_ADD_EXAMINEE_ERROR,
        payload: {
          err,
          user,
          groupId
        }
      });
    });
  };
}

export const getSuperGroupExamineesSuccess = (examinees, groupId) => ({
  type: GET_SUPER_GROUP_EXAMINEES_SUCCESS,
  payload: { examinees, groupId }
});

export const getSuperGroupExamineesFailure = (error) => ({
  type: GET_SUPER_GROUP_EXAMINEES_FAILURE,
  payload: { error }
});

export const getSuperGroupExaminees = (groupId) => (
  (dispatch) => (
    httpClient.get(`/super/group/${groupId}/examinees`)
      .then((response) => {
        dispatch(getSuperGroupExamineesSuccess(response.data, groupId));
      }).catch((err) => {
        dispatch(getSuperGroupExamineesFailure(err.message));
      })
  )
);

export const superDeleteExamineeFailure = (error) => ({
  type: SUPER_DELETE_EXAMINEE_FAILURE,
  payload: { error }
});

export function superDeleteExaminee(examinee, groupId) {
  return (dispatch) => {
    const { userGroupId } = examinee;
    dispatch({
      type: SUPER_DELETE_EXAMINEE_SUCCESS,
      payload: {
        examinee,
        groupId
      }
    });
    return httpClient.delete(`/super/examinee/${userGroupId}`)
      .then(() => {
        dispatch(dispatchAlert({
          message: (
            <span>
              Successfully removed&nbsp;
              <b>{examinee.firstName} {examinee.lastName}</b>&nbsp;
              from group as examinee.
            </span>
          ),
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('Unable to delete examinee', err.message);
        dispatch(superDeleteExamineeFailure(err.message));
        dispatch(dispatchAlert({
          message: (
            <span>
              Unable to remove&nbsp;
              <b>{examinee.firstName} {examinee.lastName}</b>&nbps;
              from group as examinee. {err.message}
            </span>
          ),
          type: 'eap-alert-error',
        }));
      });
  };
}

export const superAssignProductSuccess = (newAssignedProduct, userId, groupId) => ({
  type: SUPER_ASSIGN_PRODUCT_SUCCESS,
  payload: { assignedProduct: newAssignedProduct, userId, groupId }
});

export const superAssignProductFailure = (error) => ({
  type: SUPER_ASSIGN_PRODUCT_FAILURE,
  payload: { error }
});

export const superAssignProduct = (examinee, groupId, product) => (
  (dispatch) => (
    httpClient.post(`/super/examinee/${examinee.userGroupId}/assign`, { productId: product.id })
      .then((response) => {
        dispatch(superAssignProductSuccess(response.data, examinee.userId, groupId));
        dispatch(dispatchAlert({
          message: assignProductSuccessMessage(examinee, product.name),
          type: 'eap-alert-success',
          time: 10000
        }));
      })
      .catch((err) => {
        console.error('ERROR ASSIGNING PRODUCT', err.message);
        dispatch(superAssignProductFailure(err.message));
        dispatch(dispatchAlert({
          message: assignProductFailureMessage(examinee),
          type: 'eap-alert-error'
        }));
      })
  )
);

export const filterExaminees = (params = {}) => (
  (dispatch) => {
    dispatch({
      type: FILTER_ALL_EXAMINEES_REQUEST,
      payload: {},
    });
    return httpClient.get('/examinees', { params }).then((res) => {
      dispatch({
        type: FILTER_ALL_EXAMINEES_SUCCESS,
        payload: res.data,
      });
    });
  }
);

export const loadResults = ({ adminGroupId, userId }) => (
  (dispatch) => {
    dispatch({
      type: EXAMINEE_LOAD_RESULTS_REQUEST,
      payload: {
        userId,
        adminGroupId,
      }
    });
    return httpClient.get(
      `/group/${adminGroupId}/examinee`,
      {
        params: {
          userId,
        },
      }
    ).then(({ data }) => {
      dispatch({
        type: EXAMINEE_LOAD_RESULTS_SUCCESS,
        payload: data,
      });
    });
  }
);

export const clearResults = () => (
  (dispatch) => {
    dispatch({
      type: EXAMINEE_LOAD_RESULTS_CLEAR,
      payload: {},
    });
  }
);
