import { PRODUCT_LOG_RECEIVED, } from '../actions/productLog';

const productLogReducer = (
  state = {
    list: []
  },
  action
) => {
  switch (action.type) {
    case PRODUCT_LOG_RECEIVED: {
      return { ...state, list: action.payload.productLogEntries };
    }
    default: {
      return { ...state };
    }
  }
};

export default productLogReducer;
