/* eslint jsx-a11y/label-has-associated-control:0 */
import React from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { FilePicker } from 'react-file-picker';
import PropTypes from 'prop-types';

import AvailableProductSelector from './AvailableProductSelector';
import EapButton from '../../components/EapButton';
import InlineValidationMessage from '../../components/InlineValidationMessage';

import examineeTemplateExcelFile from './examinee_template.xlsx';

const ExamineeUploadStep1 = ({
  availableProducts,
  next
}) => (
  <div>
    <div className="download-link">
      <span className="fa fa-info-circle info-icon" />
      Examinees can only be uploaded in bulk using a specially formatted Excel
      file. Certain fields are required (first and last name, username, password).
      Any time you need it, you can click
      to <a href={examineeTemplateExcelFile}>download empty template</a>.
    </div>
    <Formik
      enableReinitialize
      validateOnChange
      validateOnBlur
      initialValues={{
        availableProduct: availableProducts[0] || { id: -1, amount: -1 },
        file: {}
      }}
      validationSchema={yup.object().shape({
        availableProduct: yup.object().shape({
          amount: yup.number().min(1, 'You are out of this product')
        }),
        file: yup.mixed().test('name', 'Please select a file.', (value) => (value.name !== undefined))
      })}
      onSubmit={(values) => {
        next({
          selectedProduct: values.availableProduct,
          file: values.file
        });
      }}
    >
      {({ touched, errors, values }) => (
        <Form className="pure-form pure-form-aligned">
          <label htmlFor="availableProduct" className="screen-reader">Select a Product</label>
          <Field
            name="availableProduct"
          >
            {({ field, form }) => (
              <AvailableProductSelector
                selectedProduct={field.value}
                availableProducts={availableProducts}
                onSelectProduct={(p) => form.setFieldValue(field.name, p)}
              />
            )}
          </Field>
          {
            (touched.availableProduct
              && errors.availableProduct
              && errors.availableProduct.amount
              && <InlineValidationMessage message={errors.availableProduct.amount} />
            ) || (
              <div style={{ marginTop: '.5em' }} />
            )
          }
          <Field
            name="file"
          >
            {({ field, form }) => (
              <FilePicker
                style={{ display: 'inline-block' }}
                extensions={['xlsx', 'xls']}
                onChange={(file) => {
                  form.setFieldValue(field.name, file);
                }}
                onError={(err) => {
                  form.setFieldError('file.name', err);
                }}
              >
                <div
                  style={{ display: 'inline-block' }}
                >
                  <i style={{ paddingRight: '.5em' }}>{values.file.name || 'Please select a file'}</i>
                  <EapButton
                    text="Select File"
                    onClick={() => {
                      form.setFieldValue(field.name, {});
                      form.setFieldTouched(field.name, true);
                    }}
                    isSecondary
                  />
                </div>
              </FilePicker>
            )}
          </Field>
          {
            (touched.file
              && errors.file
              && errors.file.name
              && <InlineValidationMessage message={errors.file.name} />
            ) || (
              <div style={{ marginTop: '.5em' }} />
            )
          }
          <EapButton
            isSubmit
            text="Next"
          />
        </Form>
      )}
    </Formik>
  </div>
);

ExamineeUploadStep1.propTypes = {
  availableProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    amount: PropTypes.number,
  })),
  next: PropTypes.func.isRequired
};

ExamineeUploadStep1.defaultProps = {
  availableProducts: []
};

export default ExamineeUploadStep1;
