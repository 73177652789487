import React from 'react';
import PropTypes from 'prop-types';

// $light-gray: #EAEAEA;
const GroupContentTab = ({ children }) =>
  (
    <div style={{
      backgroundColor: '#EAEAEA',
      paddingLeft: '.5em',
      paddingRight: '.5em',
      paddingTop: '.5em',
      paddingBottom: '.5em',
      }}
    >
      {children}
    </div>
  );

GroupContentTab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

export default GroupContentTab;
