/* In the shell building the scripts you must run `source ./config/env.sh`
 * to get the hash in the process.env
 */
import React from 'react';

const VersionComponent = () => (
  <small>Rev: {process.env.REACT_APP_GIT_HASH || '0.0.0'}</small>
);

export default VersionComponent;
