import _ from 'lodash';

export function sortPermissions(adminPermissions) {
  return {
    id: adminPermissions.id,
    create: adminPermissions.per_create,
    admin: {
      edit: adminPermissions.per_admin_edit,
      invite: adminPermissions.per_admin_invite,
      remove: adminPermissions.per_admin_remove,
      view: adminPermissions.per_admin_view
    },
    users: {
      invite: adminPermissions.per_users_invite,
      move: adminPermissions.per_users_move,
      view: adminPermissions.per_users_view
    },
    products: {
      assign: adminPermissions.per_products_assign,
      transfer: adminPermissions.per_products_transfer,
      view: adminPermissions.per_products_view
    }
  };
}

export default function extractAndSortPermissions(adminGroups, groupId) {
  return sortPermissions(adminGroups[
    _.findIndex(adminGroups, adminGroup => adminGroup.groupId === groupId)
  ]);
}
