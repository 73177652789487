import React from 'react';
import _ from 'lodash';

import { httpClient } from '../utils/httpClient';
import { dispatchAlert } from './alert';
import history from '../utils/history';

export const LOAD_GROUP_SUPER_SUCCESS = 'LOAD_GROUP_SUPER_SUCCESS';
export const EDIT_GROUP = 'EDIT_GROUP';
export const CANCEL_EDIT = 'CANCEL_EDIT';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_FAILURE = 'GET_GROUP_FAILURE';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE';
export const SUPER_ADD_GROUP_SUCCESS = 'SUPER_ADD_GROUP_SUCCESS';
export const GROUP_NAME_EXISTS_REQUEST = 'GROUP_NAME_EXISTS_REQUEST';
export const GROUP_NAME_EXISTS_RESPONSE = 'GROUP_NAME_EXISTS_RESPONSE';
export const RESET_GROUP_DATA = 'RESET_GROUP_DATA';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';
export const GROUP_CLEAR_CACHE = 'GROUP_CLEAR_CACHE';

export const editGroup = () => ({
  type: EDIT_GROUP
});

export const cancelEdit = () => ({
  type: CANCEL_EDIT
});

export const getGroupSuccess = (groupInfo) => ({
  type: GET_GROUP_SUCCESS,
  payload: { groupInfo }
});

export const getGroupFailure = (error) => ({
  type: GET_GROUP_FAILURE,
  payload: { error }
});

export function getGroupInfo(id) {
  return (dispatch) => httpClient.get(`/group/${id}`)
    .then((groupInfo) => {
      dispatch(getGroupSuccess(groupInfo.data));
    }).catch((err) => {
      if (err.response && err.response.status !== 401) {
        console.error('ERROR RETRIEVING GROUP INFO', err.message);
        dispatch(getGroupFailure(err.message));
      }
    });
}

export const loadGroupViaSuper = (id) => ((dispatch) => (
  httpClient
    .get(`/super/group/${id}`)
    .then((response) => {
      dispatch({
        type: LOAD_GROUP_SUPER_SUCCESS,
        payload: response.data
      });
      return response.data;
    })
    .catch((err) => console.error('Error loading super group info', err))
));

export const beginCheckGroupNameExists = () => ({
  type: GROUP_NAME_EXISTS_REQUEST
});

export const groupNameExists = (exists) => ({
  type: GROUP_NAME_EXISTS_RESPONSE,
  payload: { exists }
});

function groupNameIsUnique(name) {
  return new Promise((resolve, reject) => httpClient
    .get(`/group/exists/${name}`)
    .then((response) => {
      resolve(response.data.exists);
    }).catch((err) => {
      reject(err);
    }));
}

export function checkUniqueGroupName(name) {
  return (dispatch) => {
    groupNameIsUnique(name)
      .then((isUnique) => dispatch(groupNameExists(isUnique)))
      .catch((err) => console.error('ERR checking unique groupname: ', name, err));
  };
}

export const addGroupSuccess = (newAdminGroup) => ({
  type: ADD_GROUP_SUCCESS,
  payload: { newAdminGroup }
});

export const addGroupFailure = (message) => ({
  type: ADD_GROUP_FAILURE,
  payload: { message }
});

export function addGroup(sourceGroupId, name, description) {
  return (dispatch) => {
    httpClient.post('/group', { name, sourceGroupId, description })
      .then((response) => {
        dispatch(addGroupSuccess(response.data));
        dispatch(dispatchAlert({
          message: <span> Group successfully added </span>,
          type: 'eap-alert-success',
          time: 5000
        }));
        history.push('/dashboard/groups');
      }).catch((err) => {
        console.error('ERROR', err.message);
        dispatch(dispatchAlert({
          message: <span> {err.message} </span>,
          type: 'eap-alert-error',
        }));
        dispatch(addGroupFailure(err.message));
      });
  };
}

export const superAddGroupSuccess = (newGroup) => ({
  type: SUPER_ADD_GROUP_SUCCESS,
  payload: { newGroup }
});

export function superAddGroup(orgId, val) {
  return (dispatch) => {
    const sourceGroupId = val.sourceGroup ? val.sourceGroup.groupId : undefined;
    httpClient.post('/super/group', {
      orgId, name: val.name, description: val.description, sourceGroupId
    })
      .then((response) => {
        dispatch(dispatchAlert({
          message: <span> Group {val.name} successfully added </span>,
          type: 'eap-alert-success',
          time: 5000
        }));
        const id = response.data.groupId || response.data.id;
        dispatch(superAddGroupSuccess({
          id,
          organizationId: orgId,
          name: val.name,
          description: val.description,
          availableProducts: []
        }));
      }).catch((err) => {
        console.error('ERROR ADDING GROUP', err.message);
        dispatch(addGroupFailure(err.message));
      });
  };
}

export const updateGroupSuccess = (group) => ({
  type: UPDATE_GROUP_SUCCESS,
  payload: { ...group }
});

export const updateGroupFailure = (error) => ({
  type: UPDATE_GROUP_FAILURE,
  payload: { error }
});

export function updateGroup(group) {
  return (dispatch) => {
    httpClient.put(`/group/${group.id}`, _.pick(group, ['name', 'description']))
      .then(() => {
        dispatch(updateGroupSuccess(group));
        dispatch(dispatchAlert({
          message: <span> Group {group.name} successfully updated </span>,
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('ERR updating group', err.message);
        dispatch(updateGroupFailure(err.message));
        dispatch(dispatchAlert({
          message: <span> Group could not be updated. {err.message} </span>,
          type: 'eap-alert-error',
        }));
      });
  };
}

export const clearCache = () => ((dispatch) => dispatch({
  type: GROUP_CLEAR_CACHE,
  payload: {}
}));

export const loadGroups = () => undefined;
