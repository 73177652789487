import React from 'react';
import PropTypes from 'prop-types';
import EapModal from './EapModal';
import EAPButton from './EapButton';
import { validate, PermissionSelector } from './PermissionSelector';

const UpdatePermissionModal = ({
  showModal, update, onChange, closeModal, title, permissions
}) => (
  <EapModal
    modalTitle={title}
    showModal={showModal}
  >
    <React.Fragment>
      <PermissionSelector
        permissions={permissions}
        onChange={onChange}
      />
      <div style={{ marginTop: '1em' }}>
        <EAPButton
          onClick={() => update()}
          text="Update"
          type="button"
          disabled={!validate(permissions)}
        />
        &nbsp;
        <EAPButton
          isSecondary
          onClick={() => closeModal()}
          text="Close"
          type="button"
        />
      </div>
    </React.Fragment>
  </EapModal>
);

UpdatePermissionModal.propTypes = {
  showModal: PropTypes.bool,
  update: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    per_users_view: PropTypes.bool,
    per_users_invite: PropTypes.bool,
    per_users_move: PropTypes.bool,
    per_products_view: PropTypes.bool,
    per_products_assign: PropTypes.bool,
    per_products_transfer: PropTypes.bool,
    per_admin_view: PropTypes.bool,
    per_admin_invite: PropTypes.bool,
    per_admin_remove: PropTypes.bool,
    per_admin_edit: PropTypes.bool,
    per_create: PropTypes.bool
  }).isRequired,
  title: PropTypes.string
};

UpdatePermissionModal.defaultProps = {
  title: 'Information',
  showModal: false
};

export default UpdatePermissionModal;
