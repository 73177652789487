import { httpClient } from '../utils/httpClient';
import redirect from '../utils/redirectUrl';

const eapUrl = require('../utils/url-parser');

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SIGNUP_SUCCEEDED = 'SIGNUP_SUCCEEDED';
export const USERNAME_EXISTS_REQUEST = 'USERNAME_EXISTS_REQUEST';
export const USERNAME_EXISTS_RESPONSE = 'USERNAME_EXISTS_RESPONSE';

export const signupSucceeded = () => ({
  type: SIGNUP_SUCCEEDED, payload: {}
});

export const signupRejected = () => ({
  type: SIGNUP_FAILED,
  payload: { success: false, message: 'Signup Error Occurred' }
});

export const signupFailed = (err) => ({
  type: SIGNUP_FAILED,
  payload: { err }
});

export const signupRequest = () => ({
  type: SIGNUP_REQUEST
});

export const signUpNewUser = (userInfo, redirectUrl) => function signUpNewUserAsync(dispatch) {
  dispatch(signupRequest());
  let transitionToUrl;
  if (redirectUrl && eapUrl.isWhitelistedRedirectUrl(redirectUrl)) {
    transitionToUrl = redirectUrl;
  }

  const newSignUp = {
    ...userInfo,
    isActive: true
  };
  return httpClient.post('/signup', newSignUp)
    .then((response) => response.data)
    .then((data) => {
      if (data.success) {
        dispatch(signupSucceeded(data));
        redirect(transitionToUrl, redirectUrl);
      } else {
        dispatch(signupFailed(new Error(data.message)));
      }
    })
    .catch((err) => {
      console.error('ERROR during signup: ', err);
      dispatch(signupFailed({
        err: err.response.data,
        status: err.response.status
      }));
    });
};
