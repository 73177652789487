import { connect } from 'react-redux';
import history from './utils/history';
import { clearState } from './actions/root';

import GeneralError from './components/GeneralError';

const mapStateToProps = (state) => {
  if (!state.error.fatalError) {
    history.push('/');
  }
  return {};
};

const mapDispatchToProps = () => ({
  reloadAllTheThings() {
    clearState();
  }
});

const GeneralErrorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralError);

export default GeneralErrorContainer;
