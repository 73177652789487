import { httpClient } from '../utils/httpClient';
import redirect from '../utils/redirectUrl';

const eapUrl = require('../utils/url-parser');

export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_REQUEST_FAILED = 'LOGIN_REQUEST_FAILED';
export const LOGIN_AUTHENTICATED = 'LOGIN_AUTHENTICATED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';

export const loginAuthenticated = (user) => ({
  type: LOGIN_AUTHENTICATED,
  payload: {
    username: user.username,
    firstName: user.firstName,
    authenticated: true
  }
});

export const loginRejected = () => ({
  type: LOGIN_REJECTED,
  payload: { authenticated: false, message: 'Login no bueno' }
});

export const loginFailed = (err) => ({
  type: LOGIN_REQUEST_FAILED,
  payload: { err }
});

export const loginRequest = () => ({
  type: LOGIN_REQUESTED
});

export const login = (username, password, redirectUrl) => function xLogin(dispatch) {
  dispatch(loginRequest());
  const loginBody = { username, password };

  let urlToPassOn;
  if (redirectUrl && eapUrl.isWhitelistedRedirectUrl(redirectUrl)) {
    urlToPassOn = redirectUrl;
  }

  return httpClient.post('/login', loginBody)
    .then((response) => response.data)
    .then((data) => {
      dispatch(loginAuthenticated(data));
      redirect(urlToPassOn, redirectUrl);
    })
    .catch((err) => {
      if (err.response.status === 400) {
        dispatch(loginRejected());
      } else {
        dispatch(loginFailed({
          err: err.response.data,
          status: err.response.status
        }));
      }
    });
};
