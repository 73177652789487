import React from 'react';
import PropTypes from 'prop-types';

import WizardStepIndicator from './WizardStepIndicator';

import './WizardProgressBar.css';

const WizardProgressBar = ({ currentStep, paymentFailed }) => (
  <div className="wizard-progress-bar">
    <WizardStepIndicator
      number={1}
      text="ORDER DETAILS"
      current={currentStep === 1}
      completed={currentStep > 1}
    />
    <hr />
    <WizardStepIndicator
      number={2}
      text="CONTACT INFO"
      current={currentStep === 2}
      completed={currentStep > 2}
    />
    <hr />
    <WizardStepIndicator
      number={3}
      text="CONFIRMATION"
      current={currentStep === 3}
      completed={currentStep > 3}
      paymentFailed={paymentFailed}
    />
  </div>
);

WizardProgressBar.propTypes = {
  paymentFailed: PropTypes.bool,
  currentStep: PropTypes.number.isRequired
};

WizardProgressBar.defaultProps = {
  paymentFailed: false
};


export default WizardProgressBar;
