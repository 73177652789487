import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../components/ConfirmationModal';
import AdminIcon from '../../components/AdminIcon';

class ExamineeInviteResendModal extends Component {
  constructor() {
    super();
    this.state = {
      openModal: false
    };
  }
  render() {
    return (
      <span>
        <AdminIcon
          onClick={() => this.setState({ openModal: true })}
          icon="fa-envelope"
          toolTip="Resend"
        />
        <ConfirmationModal
          modalTitle="RESEND ADMIN INVITE"
          showModal={this.state.openModal}
          messagePrompt={
            <p>
              Are you sure you want to resend this examinee invite to
              <b> {this.props.selectedExamineeInvite.email}</b>?
            </p>
          }
          closeModal={() => this.setState({ openModal: false })}
          confirmAction={
            () => this.props.resendInvite(this.props.selectedExamineeInvite.id)
          }
        />
      </span>
    );
  }
}

export default ExamineeInviteResendModal;

ExamineeInviteResendModal.propTypes = {
  selectedExamineeInvite: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired,
  resendInvite: PropTypes.func.isRequired
};
