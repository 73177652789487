import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { enterMSD } from '../actions/assignedProducts';
import EapModal from './EapModal';
import AddMSD from '../group/components/AddMSD';

const EnterMSDModal = ({
  showModal,
  closeModal,
  testId,
  groupId,
  adminGroupId,
  firstName,
  lastName,
  gender,
  grade,
  sendMSD
}) => (
  <EapModal
    showModal={showModal}
    modalTitle="Enter MSD"
  >
    <AddMSD
      closeModal={closeModal}
      sendMSD={(vals) => {
        sendMSD({
          testId,
          groupId,
          adminGroupId,
          score: vals.score,
          gender: vals.gender,
          grade: vals.grade
        });
        closeModal();
      }}
      firstName={firstName}
      lastName={lastName}
      gender={gender}
      grade={grade}
    />
  </EapModal>
);

EnterMSDModal.propTypes = {
  showModal: PropTypes.bool,
  testId: PropTypes.number,
  groupId: PropTypes.number,
  adminGroupId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  gender: PropTypes.string,
  grade: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
  // REDUX
  sendMSD: PropTypes.func.isRequired
};


EnterMSDModal.defaultProps = {
  showModal: false,
  testId: undefined,
  groupId: undefined,
  adminGroupId: undefined,
  firstName: undefined,
  lastName: undefined,
  gender: undefined,
  grade: undefined
};

export default connect(
  () => ({}),
  (dispatch) => ({
    sendMSD: (vals) => {
      dispatch(enterMSD(vals));
    },
  })
)(EnterMSDModal);
