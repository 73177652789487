import { connect } from 'react-redux';
import SigninsTable from './components/SigninsTable';
import {
  filter,
  superUpdateUser
} from '../../actions/user';

const mapStateToProps = (state) => {
  if (!state.user.permissions.super) {
    return {
      unauthorized: true
    };
  }
  return {
    unauthorized: false,
    users: state.user ? (state.user.serverFiltered) : undefined
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (sourceUserId, updatedUserInfo) => {
    dispatch(superUpdateUser(sourceUserId, updatedUserInfo));
  },
  filterUsers: (filters, sorted, limit) => {
    dispatch(filter(filters, sorted, limit));
  }
});

const SigninsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SigninsTable);

export default SigninsTableContainer;
