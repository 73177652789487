import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import UserTableSelector from './UserTableSelector';
import EapButton from '../../../components/EapButton';
import InlineValidationMessage from '../../../components/InlineValidationMessage';
import Email from '../../../formComponents/Email';
import {
  RECOMMENDED,
  validate as validatePermissions,
  PermissionSelector
} from '../../../components/PermissionSelector';

import './AddGroupAdministratorForm.scss';

class AddGroupAdministratorForm extends Component {
  constructor() {
    super();
    this.state = {
      user: undefined,
      permissions: { ...RECOMMENDED }
    };
    this.validate = this.validate.bind(this);
    this.onUserSelected = this.onUserSelected.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
  }

  onUserSelected(user) {
    this.setState({ user });
  }

  onSubmit(e) {
    e.preventDefault();
    if (!this.validate()) {
      return false;
    }
    this.props.addAdmin(this.state.user, this.state.permissions);
    return true;
  }

  onEmailChange(emailData) {
    if (emailData.isValid) {
      const { user } = this.state;
      user.email = emailData.value;
      this.setState({ user });
    }
  }

  validate() {
    return this.state.user && this.state.user.email && validatePermissions(this.state.permissions);
  }

  render() {
    let selectedUserDisplay = '';
    if (this.state.user) {
      selectedUserDisplay = ` ${this.state.user.firstName} ${this.state.user.lastName} (${this.state.user.username})`;
    }
    return (
      <div className="add-administrator-form">
        <form
          className="pure-form eap-grey-form"
          onSubmit={this.onSubmit}
        >
          <div className="pure-u-2-3">
            <div className="selected-user-display">
              { this.state.user ?
                <React.Fragment>
                  <span className="selected-user-label pure-u-1-">Selected User:</span>
                  <span className="pure-u-2-3"> {selectedUserDisplay}</span>
                </React.Fragment> :
                <InlineValidationMessage message="Please select a user" />
              }
              <UserTableSelector
                users={this.props.users}
                filterUsers={this.props.filterUsers}
                onSelected={user => this.onUserSelected(user)}
              />
            </div>
            { (this.state.user && !this.state.user.email) ?
              <div>
                <p> Please enter an email for the user. </p>
                <Email
                  require
                  update={this.onEmailChange}
                />
              </div> : <span />
            }
          </div>
          <div className="pure-u-1-4">
            <PermissionSelector
              permissions={this.state.permissions}
              onChange={permissions => this.setState({ permissions })}
            />
            <EapButton
              text="CREATE ADMINISTRATOR"
              isSubmit
              disabled={!this.validate()}
            />
          </div>
          <div className="pure-u-1">
            <Link to={`/super/group/${this.props.groupId}/info`}>
            Go back to the group screen
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

AddGroupAdministratorForm.propTypes = {
  groupId: PropTypes.string.isRequired,
  addAdmin: PropTypes.func.isRequired,
};

export default AddGroupAdministratorForm;
