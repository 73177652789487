import React from 'react';

import logo from '../../images/partners/pscc.png';
import secondary from '../../images/partners/upep.png';

import './PartnershipBanner.css';

const PartnershipBanner = () => (
  <div className="partner-banner-block pure-g">
    <div className="pure-u-1-3" />
    <div className="pure-u-2-3">
      <span className="pure-u-1">In partnership with:</span>
      <img
        alt="Pellissisppi"
        src={logo}
      />
      { secondary &&
      <img
        alt="UPEP"
        src={secondary}
      />
      }
    </div>
  </div>
);

export default PartnershipBanner;
