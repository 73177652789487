import { connect } from 'react-redux';
import AdministratorsTable from './components/AdministratorsTable';
import * as AdminsActions from '../actions/admins';
import { getAdminGroup } from '../utils/AdminGroupHelper';
import { getPermissionString } from '../components/PermissionSelector';

const mapStateToProps = (state, ownProps) => {
  if (!state.user.permissions.administrator) {
    return {
      unauthorized: true
    };
  } else if (state.group.loading) {
    return {
      unauthorized: false,
      group: { loading: true }
    };
  } else if (state.group.message) {
    return {
      unauthorized: false,
      group: {
        loading: false,
        message: state.group.message
      }
    };
  }

  const group = state.group.groupsById[ownProps.groupId];
  const actorAdmin = getAdminGroup(ownProps.groupId, state);
  const administrators = group.admins.map(administrator => ({
    ...administrator,
    permissionLabel: getPermissionString(administrator)
  }));

  return {
    unauthorized: false,
    groupId: ownProps.groupId,
    admins: administrators,
    actorAdmin
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateAdminPermissions: (actorAdmin, subjectAdmin, newPermissions) => {
    dispatch(AdminsActions.updateAdminPermissions(
      ownProps.groupId,
      actorAdmin,
      subjectAdmin,
      newPermissions
    ));
  },
  deleteAdmin: (actorAdmin, subjectAdmin) => {
    dispatch(AdminsActions.deleteAdmin(ownProps.groupId, actorAdmin, subjectAdmin));
  }
});

const AdministratorsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdministratorsTable);

export default AdministratorsContainer;
