import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import AdminMenu from '../../components/AdminMenu';
import InformationContainer from '../InformationContainer';
import AvailableProductsContainer from '../AvailableProductsContainer';
import CheckoutContainer from '../../checkout/CheckoutContainer';
import GroupResultsContainer from '../GroupResultsContainer';
import GroupWrapper from './GroupWrapper';
import GroupContentTab from './GroupContentTab';
import Examinees from './Examinees';
import AdministratorsView from './AdministratorsView';
import ProgressSpinner from '../../components/ProgressSpinner';
import SensibleContainer from '../../components/SensibleContainer';

import './Group.css';

const NotFound = () => (
  <div>Page not found.</div>
);

const Group = ({
  match: {
    params: {
      id: rawGroupId,
    },
    url,
  },
  unauthorized,
  group,
  loadGroupData,
  location,
}) => {
  const groupId = parseInt(rawGroupId, 10);

  // inital load
  React.useEffect(() => {
    loadGroupData(groupId);
  }, [groupId]);

  // block
  if (unauthorized) {
    return (
      <div className="pure-u-1">
        <h1>Forbidden</h1>
        <p>You do not have permission to view this page.</p>
      </div>
    );
  }
  if (!group) {
    return (
      <div className="pure-u-1">
        <ProgressSpinner />
      </div>
    );
  }
  if (group.message !== undefined) {
    return (
      <div className="pure-u-1">
        <b> {group.message} </b>
      </div>
    );
  }

  // dynamically create the left menu items
  let routes = [{ path: 'information', icon: 'fa-info-circle' }];
  if (group && group.permissions) {
    if (group.permissions.products.view) {
      routes = routes.concat({ path: 'products', icon: 'fa-cubes' });
    }
    if (group.permissions.users.view) {
      routes = routes.concat({ path: 'results', icon: 'fa-bar-chart' });
      routes = routes.concat({ path: 'examinees', icon: 'fa-users' });
    }
    if (group.permissions.admin.view) {
      routes = routes.concat({ path: 'administrators', icon: 'fa-bank' });
    }
  }
  // render the routes
  return (
    <div className="group-content-container flex-column">
      <div className="pure-u-1-1">
        <SensibleContainer>
          <div className="pure-u-1 pure-u-md-1-6 group-menu-container">
            <div className="group-admin-menu-wrapper">
              <AdminMenu
                routes={routes}
                base={`group/${groupId}`}
                transparent
              />
            </div>
          </div>
          <div className="pure-u-1 pure-u-md-5-6">
            <Switch>
              <Route
                path={`${url}/information`}
                render={
                  () => (
                    <GroupWrapper
                      groupName={group.name || 'no group found'}
                      headingText="information"
                    >
                      <GroupContentTab>
                        <InformationContainer
                          baseUrl={url}
                          groupId={groupId}
                        />
                      </GroupContentTab>
                    </GroupWrapper>
                  )
                }
              />
              <Route
                path={`${url}/available-product/:availableProductId/purchase`}
                render={
                  (purchaseRouteProps) => (
                    <GroupWrapper
                      groupName={group.name || 'no group found'}
                      headingText="NEW PURCHASES"
                    >
                      <CheckoutContainer
                        availableProductId={purchaseRouteProps.match.params.availableProductId}
                        groupId={groupId}
                      />
                    </GroupWrapper>
                  )
                }
              />
              <Route
                path={`${url}/products`}
                render={
                  () => (
                    <GroupWrapper
                      groupName={group.name || 'no group found'}
                      headingText="products"
                    >
                      <GroupContentTab>
                        <AvailableProductsContainer
                          groupId={groupId}
                        />
                      </GroupContentTab>
                    </GroupWrapper>
                  )
                }
              />
              <Route
                path={`${url}/results`}
                render={() => (
                  <GroupWrapper
                    groupName={group.name || 'no group found'}
                    headingText="results"
                  >
                    <GroupContentTab>
                      <GroupResultsContainer
                        groupId={groupId}
                      />
                    </GroupContentTab>
                  </GroupWrapper>
                )}
              />
              <Route
                path={`${url}/examinees/`}
                render={() => (
                  <GroupWrapper
                    groupName={group.name || 'no group found'}
                    headingText="examinees"
                  >
                    <Examinees
                      baseUrl={url}
                      group={group}
                      location={location}
                    />
                  </GroupWrapper>
                )}
              />
              <Route
                path={`${url}/administrators`}
                render={() => (
                  <GroupWrapper
                    groupName={group.name || 'no group found'}
                    headingText="administrators"
                  >
                    <AdministratorsView
                      baseUrl={url}
                      location={location}
                      group={group}
                    />
                  </GroupWrapper>
                )}
              />
              <Route
                path={`${url}/*`}
                component={NotFound}
              />
            </Switch>
          </div>
        </SensibleContainer>
      </div>
    </div>
  );
};

Group.propTypes = {
  unauthorized: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  loadGroupData: PropTypes.func.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    permissions: PropTypes.shape({
      per_create: PropTypes.number,
      admin: PropTypes.shape({
        view: PropTypes.bool
      }),
      users: PropTypes.shape({
        view: PropTypes.bool
      }),
      products: PropTypes.shape({
        view: PropTypes.bool
      })
    }),
    message: PropTypes.string,
  })
};

Group.defaultProps = {
  group: undefined
};

export default Group;
