import _ from 'lodash';
import moment from 'moment';

import {
  GET_SUPER_GROUP_EXAMINEES_SUCCESS,
  MOVE_EXAMINEE_REQUEST,
  MOVE_EXAMINEE_SUCCESS,
  MOVE_EXAMINEE_FAILURE,
  ADD_EXAMINEE_SUCCESS,
  ADD_EXAMINEE_FAILURE,
  EXAMINEES_FILE_VALIDATION_REQUEST,
  EXAMINEES_FILE_VALIDATION_SUCCEEDED,
  EXAMINEES_FILE_VALIDATION_FAILED,
  EXAMINEES_FILE_UPLOAD_REQUEST,
  EXAMINEES_FILE_UPLOAD_SUCCESS,
  EXAMINEES_FILE_UPLOAD_FAILURE,
  SUPER_ADD_EXAMINEE_SUCCESS,
  SUPER_DELETE_EXAMINEE_SUCCESS,
  FILTER_ALL_EXAMINEES_REQUEST,
  FILTER_ALL_EXAMINEES_SUCCESS,
  EXAMINEE_LOAD_RESULTS_REQUEST,
  EXAMINEE_LOAD_RESULTS_SUCCESS,
  EXAMINEE_LOAD_RESULTS_CLEAR,
} from '../actions/examinees';

import {
  SUPER_LOAD_USER_SUCCESS
} from '../actions/user';

import {
  ENTER_MSD_SUCCESS,
  RELEASE_RESULTS_SUCCESS,
} from '../actions/assignedProducts';

const examineesReducer = (
  state = {
    list: [],
    upload: {
      examinees: [],
      errors: {}
    },
    serverFiltered: [],
    serverTotal: 0,
    results: {
      list: undefined,
      loading: false,
    },
    moving: false,
  },
  action
) => {
  switch (action.type) {
    case GET_SUPER_GROUP_EXAMINEES_SUCCESS: {
      const list = _.cloneDeep(state.list);
      _.each(action.payload.examinees, (ex) => {
        list.push({
          id: ex.userGroupId,
          userId: ex.userId,
          examineeGroupId: ex.userGroupId,
          userGroupId: ex.userGroupId,
          groupId: _.parseInt(action.payload.groupId, 10)
        });
      });

      return {
        ...state,
        list: _.uniqBy(list, 'examineeGroupId')
      };
    }
    case MOVE_EXAMINEE_REQUEST: {
      return { ...state, moving: true };
    }
    case MOVE_EXAMINEE_SUCCESS: {
      const {
        opts,
        destGroup,
      } = action.payload;
      let newServer = state.serverFiltered;
      let total = state.serverTotal;
      if (state.serverFiltered && state.serverFiltered.length > 0) {
        if (opts.remove) {
          newServer = _.filter(state.serverFiltered, (ex) => (
            ex.userGroupId !== action.payload.sourceUserGroupId
          ));
          if (total - 1 >= 0) {
            total -= 1;
          }
        } else {
          newServer = newServer.map((ex) => {
            if (ex.userGroupId === action.payload.sourceUserGroupId) {
              return {
                ...ex,
                groupName: destGroup.name,
                adminGroupId: destGroup.id,
                userGroupId: action.payload.destUserGroupId,
              };
            }
            return ex;
          });
        }
      }
      return {
        ...state,
        moveError: undefined,
        serverFiltered: newServer,
        serverTotal: total,
        moving: false,
      };
    }
    case MOVE_EXAMINEE_FAILURE: {
      return { ...state, moveError: action.payload.error, moving: false };
    }
    case ADD_EXAMINEE_SUCCESS: {
      return { ...state, addExamineeError: undefined };
    }
    case ADD_EXAMINEE_FAILURE: {
      return { ...state, addExamineeError: action.payload.error };
    }
    case EXAMINEES_FILE_UPLOAD_REQUEST: {
      return { ...state, upload: { inProgress: true } };
    }
    case EXAMINEES_FILE_UPLOAD_SUCCESS: {
      return {
        ...state,
        upload: {
          success: true,
          inProgress: false,
          examinees: [],
          errors: {}
        }
      };
    }
    case EXAMINEES_FILE_UPLOAD_FAILURE: {
      return {
        ...state,
        upload: {
          success: false,
          inProgress: true,
          examinees: [],
          errors: action.payload,
        }
      };
    }
    case EXAMINEES_FILE_VALIDATION_REQUEST: {
      return {
        ...state,
        upload: {
          inProgress: true,
          examinees: [],
          errors: {},
        }
      };
    }
    case EXAMINEES_FILE_VALIDATION_SUCCEEDED: {
      const examinees = _.map(action.payload, (val) => ({
        ...val,
        ...{
          dob: val.dob ? moment(val.dob) : ''
        }
      }));
      return {
        ...state,
        upload: {
          inProgress: false,
          examinees,
          errors: {}
        }
      };
    }
    case EXAMINEES_FILE_VALIDATION_FAILED: {
      return {
        ...state,
        upload: {
          inProgress: false,
          examinees: [],
          errors: action.payload
        }
      };
    }
    case SUPER_ADD_EXAMINEE_SUCCESS: {
      const list = _.cloneDeep(state.list);
      list.push({
        id: action.payload.userGroupId,
        userId: action.payload.user.userId,
        examineeGroupId: action.payload.userGroupId,
        userGroupId: action.payload.userGroupId,
        groupId: _.parseInt(action.payload.groupId, 10)
      });
      return { ...state, list: _.uniqBy(list, 'examineeGroupId') };
    }
    case SUPER_DELETE_EXAMINEE_SUCCESS: {
      const list = _.cloneDeep(state.list);
      _.remove(list, { userGroupId: action.payload.examinee.userGroupId });
      return { ...state, list };
    }
    case SUPER_LOAD_USER_SUCCESS: {
      const list = _.cloneDeep(state.list);
      _.each(action.payload.userData.examineeGroups, (g) => {
        list.push({
          id: g.id,
          userId: action.payload.userData.id,
          userGroupId: g.id,
          examineeGroupId: g.id,
          groupId: g.groupId
        });
      });
      return {
        ...state,
        list: _.uniqBy(list, 'id')
      };
    }
    case FILTER_ALL_EXAMINEES_REQUEST: {
      return {
        ...state,
        filtering: true,
      };
    }
    case FILTER_ALL_EXAMINEES_SUCCESS: {
      return {
        ...state,
        filtering: false,
        serverFiltered: action.payload.payload, // payload is the users
        serverTotal: action.payload.total
      };
    }
    case EXAMINEE_LOAD_RESULTS_REQUEST: {
      return {
        ...state,
        results: {
          loading: true,
        },
      };
    }
    case EXAMINEE_LOAD_RESULTS_SUCCESS: {
      return {
        ...state,
        results: {
          list: action.payload,
          loading: false,
        },
      };
    }
    case EXAMINEE_LOAD_RESULTS_CLEAR: {
      return {
        ...state,
        results: {
          loading: false,
        }
      };
    }
    case ENTER_MSD_SUCCESS: {
      const serverFiltered = _.cloneDeep(state.serverFiltered);
      const pUpdate = _.find(
        serverFiltered,
        (ex) => (ex.assignedProductId === action.payload.testId)
      );
      if (!pUpdate) {
        return { ...state };
      }
      if (!action.payload.incomplete && pUpdate.configs.suppressed) {
        pUpdate.status = 'Pending';
      } else if (!action.payload.incomplete) {
        pUpdate.status = 'Results';
      } else {
        pUpdate.status = 'Resume';
      }
      return {
        ...state,
        serverFiltered,
      };
    }
    case RELEASE_RESULTS_SUCCESS: {
      const serverFiltered = _.cloneDeep(state.serverFiltered);
      const pUpdate = _.find(
        serverFiltered,
        (ex) => (ex.assignedProductId === action.payload.assignedProductId)
      );
      if (pUpdate && pUpdate.configs) {
        pUpdate.configs.suppressed = false;
      }
      if (pUpdate && pUpdate.status === 'Pending') {
        pUpdate.status = 'Results';
      }
      return {
        ...state,
        serverFiltered,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default examineesReducer;
