import {
  PASSWORD_RESET_REQUEST_FAILED,
  PASSWORD_RESET_REQUEST_SUCCESS,
  PASSWORD_RESET_MULTIPLE_ACCOUNTS,
  PASSWORD_RESET_NO_ACCOUNT_FOUND
} from '../actions/password';

const passwordReducer = (state = {}, action) => {
  switch (action.type) {
    case PASSWORD_RESET_REQUEST_FAILED: {
      return {
        ...state,
        [action.payload.emailAddress]: {
          generalError: true,
          // errorMessage:
        }
      };
    }
    case PASSWORD_RESET_NO_ACCOUNT_FOUND: {
      return {
        ...state,
        [action.payload.emailAddress]: {
          notFound: true,
        }
      };
    }
    case PASSWORD_RESET_MULTIPLE_ACCOUNTS: {
      return {
        ...state,
        [action.payload.emailAddress]: {
          multipleAccounts: true
        }
      };
    }
    case PASSWORD_RESET_REQUEST_SUCCESS: {
      return {
        ...state,
        [action.payload.emailAddress]: {
          success: true,
        }
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default passwordReducer;
