import React, { Component } from 'react'; import PropTypes from 'prop-types';
import _ from 'lodash';
import InputMask from 'react-input-mask';

import EapButton from '../../components/EapButton';
import GenderDropdown from '../../formComponents/GenderDropdown';
import GradeDropdown from '../../formComponents/GradeDropdown';
import { checkFormValidity } from '../../utils/FormsHelper';

const MIN_MSD_SCORE = 1;
const MAX_MSD_SCORE = 532;

export default class AddMSD extends Component {
  constructor() {
    super();
    this.state = {
      form: {
        score: {
          value: undefined,
          isValid: false
        },
        grade: {
          value: undefined,
          isValid: false
        },
        gender: {
          value: undefined,
          isValid: false
        }
      },
      isValid: false
    };
    this.checkScore = this.checkScore.bind(this);
    this.updateTestInfo = this.updateTestInfo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.updateTestInfo({ isValid: this.props.gender !== undefined, keyName: 'gender', value: this.props.gender });
    this.updateTestInfo({ isValid: this.props.grade !== undefined, keyName: 'grade', value: this.props.grade });
  }

  componentWillReceiveProps(nextProps) {
    this.updateTestInfo({ isValid: nextProps.gender !== undefined, keyName: 'gender', value: nextProps.gender });
    this.updateTestInfo({ isValid: nextProps.grade !== undefined, keyName: 'grade', value: nextProps.grade });
  }

  checkScore(e) {
    const value = _.toSafeInteger(e.target.value);
    if (value < MIN_MSD_SCORE || value > MAX_MSD_SCORE) {
      this.updateTestInfo({ isValid: false, keyName: 'score', value: undefined });
    } else {
      this.updateTestInfo({ isValid: true, keyName: 'score', value });
    }
  }

  updateTestInfo({ isValid, keyName, value }) {
    const updateObj = { ...this.state.form };
    updateObj[keyName].value = value;
    updateObj[keyName].isValid = isValid;
    const isFormValid = checkFormValidity(updateObj);
    this.setState({
      form: updateObj,
      isValid: isFormValid
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.isValid) {
      this.props.sendMSD({
        score: this.state.form.score.value,
        gender: this.state.form.gender.value,
        grade: this.state.form.grade.value
      });
    }
  }

  render() {
    return (
      <div>
        <form
          className="pure-form pure-form-aligned eap-grey-form"
        >
          <div className="pure-control-group">
            <label htmlFor="user"><b>User</b></label>
            <span name="user">
              {this.props.firstName} {this.props.lastName}
            </span>
          </div>
          <div className="pure-control-group">
            <label htmlFor="msdScore"><b>MSD SCORE ({MIN_MSD_SCORE}-{MAX_MSD_SCORE})</b></label>
            <InputMask
              id="score"
              onChange={this.checkScore}
              type="text"
              alwaysShowMask={false}
              mask="999"
              maskChar=""
              placeholder={`${MIN_MSD_SCORE}-${MAX_MSD_SCORE}`}
            />
          </div>
          { !this.state.form.score.isValid ?
            <div className="pure-control-group">
              <label htmlFor="scoreError">
                <span className="screen-reader">Error</span>
              </label>
              <span className="validation-message">Please enter a valid score.</span>
            </div>
            : ''
          }
          <div className="pure-control-group">
            <GenderDropdown
              selectStyle="pure-u-1-2"
              update={this.updateTestInfo}
              value={this.state.form.gender.value}
              boldLabel
              formGroupStyle=""
              addLabel
              required
            />
          </div>
          <div className="pure-control-group">
            <GradeDropdown
              selectStyle="pure-u-1-2"
              update={this.updateTestInfo}
              value={this.state.form.grade.value}
              boldLabel
              formGroupStyle=""
              addLabel
              required
            />
          </div>
          <div className="pure-controls">
            <EapButton
              onClick={this.handleSubmit}
              optionalClass="confirmation-button"
              disabled={!this.state.isValid}
              text="Submit"
            />
            &nbsp;
            <EapButton
              isSecondary
              onClick={this.props.closeModal}
              text="Cancel"
            />
          </div>
        </form>
      </div>
    );
  }
}

AddMSD.propTypes = {
  closeModal: PropTypes.func.isRequired,
  sendMSD: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  gender: PropTypes.string,
  grade: PropTypes.number
};

AddMSD.defaultProps = {
  firstName: '',
  lastName: '',
  gender: undefined,
  grade: undefined
};
