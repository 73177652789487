import React from 'react';
import './Footer.css';
import VersionComponent from './components/Version';

const Footer = () => (
  <footer className="footer" role="contentinfo">
    <div className="pure-g pure-u-1-1">
      <div className="pure-u-sm-1-4 pure-u-md-1-6 pure-u-lg-1-8 pure-u-1-1 footer-tos font-serif">
        <a className="" href="https://www.edits.net/legal/terms-of-use/" target="__blank">
          TERMS OF USE
        </a>
      </div>
      <div className="pure-u-sm-1-4 pure-u-md-1-6 pure-u-lg-1-8 pure-u-1-1 font-serif">
        <a className="" href="https://www.edits.net/legal/privacy/" target="__blank">
          PRIVACY
        </a>
      </div>
      <div className="pure-u-sm-1-4 pure-u-md-1-6 pure-u-lg-1-8 pure-u-1-1 font-serif">
        <a className="" href="https://www.edits.net/legal/trademarks/" target="__blank">
          TRADEMARKS
        </a>
      </div>
    </div>
    <div className="footer-copyright pure-u-1-1">
      &#169; EDITS LLC ALL RIGHTS RESERVED
      <br />
      <VersionComponent />
    </div>
  </footer>
);

export default Footer;
