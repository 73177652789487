import { cloneDeep } from 'lodash';
import * as Actions from '../actions/quickbooks';

function quickbooksReducer(
  state = {
    display: 'unloaded',
    updatingProducts: false,
  },
  action
) {
  const newState = cloneDeep(state);
  switch (action.type) {
    case Actions.QBO_ERROR:
      newState.display = 'error';
      return newState;
    case Actions.QBO_INIT_START:
      newState.display = 'loading';
      return newState;
    case Actions.QBO_INIT_INSYNC:
      newState.display = 'insync';
      return newState;
    case Actions.QBO_INIT_OAUTH:
      newState.display = 'oauth';
      newState.uri = action.payload.uri;
      return newState;
    case Actions.QBO_OAUTH_START:
      newState.display = 'loading';
      return newState;
    case Actions.QBO_OAUTH_SUCCESS:
      newState.display = 'insync';
      return newState;
    case Actions.QBO_REFRESH_REQUEST:
      newState.updatingProducts = true;
      return newState;
    case Actions.QBO_REFRESH_SUCCESS:
      newState.updatingProducts = false;
      return newState;
    default:
      return state;
  }
}

export default quickbooksReducer;
