import _ from 'lodash';

import {
  SUPER_FILTER_GROUPS_SUCCESS,
} from '../actions/groups';

import {
  LOAD_GROUP_SUPER_SUCCESS,
  SUPER_ADD_GROUP_SUCCESS
} from '../actions/group';

import {
  ORG_LOAD_GROUPS_SUCCESS
} from '../actions/organization';

import {
  SUPER_LOAD_USER_SUCCESS
} from '../actions/user';

const groupsReducer = (
  state = {
    list: [],
    serverFiltered: []
  },
  action
) => {
  switch (action.type) {
    case LOAD_GROUP_SUPER_SUCCESS: {
      const list = _.cloneDeep(state.list);
      list.push(_.omit(action.payload, ['organization']));
      return {
        ...state,
        list: _.uniqBy(list, 'id')
      };
    }
    case ORG_LOAD_GROUPS_SUCCESS: {
      const list = [
        ..._.cloneDeep(state.list),
        ...action.payload
      ];
      return { ...state, list: _.uniqBy(list, 'id') };
    }
    case SUPER_ADD_GROUP_SUCCESS: {
      const list = _.cloneDeep(state.list);
      list.push(action.payload.newGroup);
      return { ...state, list };
    }
    case SUPER_FILTER_GROUPS_SUCCESS: {
      return { ...state, serverFiltered: action.payload };
    }
    case SUPER_LOAD_USER_SUCCESS: {
      const list = _.cloneDeep(state.list);
      _.each(action.payload.userData.examineeGroups, (g) => {
        list.push({
          ...g,
          ...{ id: g.groupId }
        });
      });
      _.each(action.payload.userData.adminGroups, (g) => {
        list.push({
          ...g.group,
        });
      });
      return { ...state, list: _.uniqBy(list, 'id') };
    }
    default: {
      return { ...state };
    }
  }
};

export default groupsReducer;
