import { httpClient } from '../utils/httpClient';

export const STRIPE_PUBLIC_KEY_LOADED = 'STRIPE_PUBLIC_KEY_LOADED';

export const stripePublicKeyLoaded = (key) => ({
  type: STRIPE_PUBLIC_KEY_LOADED,
  payload: { key }
});

export const loadStripePublicKey = () => (dispatch) => httpClient
  .get('/stripe-key')
  .then((response) => {
    dispatch(stripePublicKeyLoaded(response.data.key));
  })
  .catch((err) => {
    console.error(err);
  });
