import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditsLogo from './EditsLogo';
import StagingLabel from './StagingLabel';

import SensibleContainer from './SensibleContainer';
import './Banner.css';

const Banner = ({ username, isStagingEnv }) => (
  <div className="eap-banner-background" role="banner" label="main banner">
    <SensibleContainer>
      <div className="pure-u-md-3-5 pure-u-1 tagLine">
        <EditsLogo />
        <h1 className=""><span className="screen-reader">EDITS</span> Assessment Platform</h1>
        { isStagingEnv && <StagingLabel /> }
      </div>
      <div className="eap-banner-menu-container pure-u-md-2-5 pure-u-1">
        <div className="pure-menu pure-menu-horizontal">
          <ul className="pure-menu-list">
            <li className="pure-menu-item">
              <Link className="pure-menu-link" to="/edit/profile">
                <span className="fa fa-user" />
                <span className="screen-reader">Edit sign-in information for,</span>
                <span className="eap-banner-text">{username}</span>
              </Link>
            </li>
            |
            <li className="pure-menu-item">
              <Link className="pure-menu-link" to="/logout">
                <span>LOG OUT</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </SensibleContainer>
  </div>
);

Banner.propTypes = {
  isStagingEnv: PropTypes.bool,
  username: PropTypes.string.isRequired,
};

Banner.defaultProps = {
  isStagingEnv: false
};

const mapStateToProps = state => ({
  username: state.user.username || '',
});

const mapDispatchToProps = () => ({ });

const BannerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Banner);

export default BannerContainer;
