import React from 'react';
import { Switch, Route } from 'react-router';
import EditSigninContainer from './EditSigninContainer';
import UpdatePasswordContainer from './UpdatePasswordContainer';
import NavigationBar from '../components/NavigationBar';
import GroupContentTab from '../group/components/GroupContentTab';

const UserUpdateRoutes = () => (
  <React.Fragment>
    <NavigationBar
      routes={[
          { path: 'profile', text: 'Edit Profile', icon: 'fa-user' },
          { path: 'password', text: 'Update Password', icon: 'fa-envelope' },
          ]}
      base="edit"
    />
    <Switch>
      <Route
        path="/edit/profile"
        render={() => <GroupContentTab><EditSigninContainer /></GroupContentTab>}
      />
      <Route
        path="/edit/password"
        render={() => <GroupContentTab><UpdatePasswordContainer /></GroupContentTab>}
      />
      <Route
        path="/edit"
        render={() => <GroupContentTab><EditSigninContainer /></GroupContentTab>}
        exact
      />
    </Switch>
  </React.Fragment>
);

export default UserUpdateRoutes;
