import { connect } from 'react-redux';
import OrderDetailsForm from './components/OrderDetailsForm';

const mapStateToProps = state => ({
  stripeScriptError: state.stripe.scriptError
});

const mapDispatchToProps = () => ({
});

const OrderDetailsFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetailsForm);

export default OrderDetailsFormContainer;

