/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { StaticEapTable } from '../../../components/StaticEapTable';
import ResourceLink from '../../../components/ResourceLink';
import AdminIcon from '../../../components/AdminIcon';
import AdminIconList from '../../../components/AdminIconList';
import UpdatePermissionsModal from '../../../components/UpdatePermissionModal';
import AdminDeleteModal from '../../../components/AdminDeleteModal';
import { EMPTY, getPermissionString } from '../../../components/PermissionSelector';

class GroupAdministratorsTable extends Component {
  constructor() {
    super();
    this.state = {
      deleteModal: undefined,
      adminUpdateModal: undefined,
      tempPermissions: { ...EMPTY }
    };
    this.update = this.update.bind(this);
    this.deleteAdmin = this.deleteAdmin.bind(this);
  }

  update() {
    this.props.updatePermissions(
      this.state.adminUpdateModal,
      this.state.tempPermissions
    );
    this.setState({
      adminUpdateModal: undefined,
      tempPermissions: { ...EMPTY }
    });
  }

  deleteAdmin() {
    this.props.superDeleteAdmin(this.state.deleteModal);
    this.setState({ deleteModal: undefined });
  }

  render() {
    const columns = [
      {
        Header: 'User ID',
        accessor: 'id',
        Cell: ({ row }) => {
          const userId = row.original.id.toString();
          return (<ResourceLink toRoute={`/super/user/${userId}/info`} displayValue={userId} />);
        }
      },
      {
        Header: 'User Name',
        accessor: 'username'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'First',
        accessor: 'firstName',
      },
      {
        Header: 'Last',
        accessor: 'lastName',
      },
      {
        Header: 'Permissions',
        accessor: 'permissions',
        Cell: ({ row }) => (getPermissionString(row.original)),
        disableFilters: true
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => (
          <AdminIconList>
            <AdminIcon
              url={`/super/user/${row.original.id}/info`}
              icon="fa-info-circle"
              toolTip="Information"
            />
            <AdminIcon
              onClick={() => this.setState({
                adminUpdateModal: row.original,
                tempPermissions: row.original
              })}
              icon="fa-pencil-square-o"
              toolTip="Edit"
            />
            <AdminIcon
              onClick={() => this.setState({ deleteModal: row.original })}
              icon="fa-trash"
              toolTip="Delete"
            />
          </AdminIconList>
        )
      }
    ];
    let permissionsTitle = 'Update ';
    if (this.state.adminUpdateModal) {
      permissionsTitle += `${this.state.adminUpdateModal.firstName} ${this.state.adminUpdateModal.lastName}`;
    }

    return (
      <div>
        <UpdatePermissionsModal
          title={permissionsTitle}
          showModal={this.state.adminUpdateModal !== undefined}
          permissions={this.state.tempPermissions}
          onChange={(permissions) => this.setState({ tempPermissions: permissions })}
          update={this.update}
          closeModal={() => this.setState({ adminUpdateModal: undefined })}
        />
        <AdminDeleteModal
          showModal={this.state.deleteModal !== undefined}
          admin={this.state.deleteModal}
          remove={this.deleteAdmin}
          closeModal={() => this.setState({ deleteModal: undefined })}
        />
        <StaticEapTable
          columns={columns}
          data={this.props.admins}
        />
        <div>
          <Link to={`/super/group/${this.props.groupId}/administrator/new`}>
            ADD NEW ADMINISTRATOR
          </Link>
        </div>
      </div>
    );
  }
}

GroupAdministratorsTable.propTypes = {
  updatePermissions: PropTypes.func.isRequired,
  superDeleteAdmin: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  admins: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    adminGroupId: PropTypes.number.isRequired
    // You also need the per_ but I'm WAYYY to lazy to type that out.
  })).isRequired,
};

export default GroupAdministratorsTable;
