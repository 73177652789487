import React from 'react';
import PropTypes from 'prop-types';
import EapButton from '../../components/EapButton';
import './AutoStart.css';

export default class AutoStart extends React.Component {
  constructor() {
    super();
    this.state = {
      time: 30,
      showTimer: true
    };
    this.tick = this.tick.bind(this);
    this.stopClock = this.stopClock.bind(this);
  }

  componentDidMount() {
    this.timer = setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  tick() {
    if (this.state.time <= 0) {
      this.stopClock();
      window.location.href = `/api/user/assigned-product/${this.props.assignedProduct.id}`;
      return;
    }
    this.setState(prevState => ({
      time: prevState.time - 1
    }));
  }

  stopClock() {
    clearInterval(this.timer);
    this.setState({
      showTimer: false
    });
  }

  render() {
    if (!this.state.showTimer) {
      return <div id="emptyAutoStart" />;
    }
    if (this.state.time <= 0) {
      return (
        <div id="autoStart" className="autoStart pure-g">
          <div className="pure-u-2-3 left-column">
            <p>Launching your product.</p>
          </div>
        </div>
      );
    }
    return (
      <div id="autoStart" className="autoStart pure-g">
        <div className="pure-u-2-3 left-column">
          <p>
            <span className="fa fa-clock-o" /> Your product will auto start
            in {this.state.time} seconds.
          </p>
        </div>
        <div className="pure-u-1-3">
          <p className="center-btn">
            <EapButton
              text="Cancel"
              onClick={this.stopClock}
            />
          </p>
        </div>
      </div>
    );
  }
}

AutoStart.propTypes = {
  assignedProduct: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};
