import * as yup from 'yup';
import { httpClient } from '../../utils/httpClient';

export const NewPasswordSchema = yup
  .string()
  .required('Please enter a password')
  .test(
    'spaces',
    'Passwords may not contain spaces',
    (password) => {
      if (password) {
        return !password.match(/ /);
      }
      return false;
    }
  )
  .min(4, 'Please enter at least 4 characters');

export const ConfirmPasswordSchema = yup
  .mixed()
  .test(
    'match', 'Please match your passwords',
    function comparePasswords(confirmPassword) {
      return confirmPassword === this.parent.password;
    }
  );

export const CoreUserProfileSchema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),

  dateOfBirth: yup.date('Please enter a valid date').notRequired(),

  gradeLevel: yup.string().notRequired(),
  gender: yup.string().notRequired(),
});

export const TermsAndConditionsSchema = yup.boolean()
  .oneOf([true], 'Please agree to terms and conditions');

export const UniqueEmailSchema = (initialEmail) => yup.string()
  .email('Please enter a valid email address')
  .test(
    'unique-email',
    'This email is already associated to an account',
    (value, testContext) => new Promise((resolve) => {
      // FYI:  true === valid in the yup sense
      if (value === initialEmail) {
        return resolve(true);
      }
      return httpClient.get(`/user/exists/email/${value}`)
        .then((response) => resolve(response.data.exists === false))
        .catch((err) => {
          const isStorybook = window.location.href.includes('localhost:9009');
          console.log(isStorybook ? 'Email lookup can NOT work in storybook' : err.message);
          resolve(testContext.createError({ message: 'Connection Error. Please check your internet connection' }));
        });
    })
  )
  .required('Please enter your email');

export const UsernameSchema = yup.string()
  .required('Please enter a username')
  .test(
    'illegal-chars',
    'Usernames may not contain spaces, commas, or semi-colons',
    (username) => username && username.match(/.*( |,|;).*/) === null
  );

export const UniqueUsernameSchema = (initialUsername) => yup.string()
  .test(
    'unique-username',
    'This username already exists',
    (value, testContext) => new Promise((resolve) => {
      // do not test the (optional) initial username for uniqueness
      if (value === initialUsername) {
        return resolve(true);
      }
      return httpClient.get(`/user/exists/${value}`)
        .then((response) => resolve(response.data.exists === false))
        .catch((err) => {
          const isStorybook = window.location.href.includes('localhost:9009');
          console.log(isStorybook ? 'Username lookup can NOT work in storybook' : err.message);
          resolve(testContext.createError({ message: 'Connection Error. Please check your internet connection' }));
        });
    })
  );

export const RequiredEmailSchema = yup.string().required('Please enter an email').email('Please enter a valid email');
export const OptionalEmailSchema = yup.string().email('Please enter a valid email');

export default CoreUserProfileSchema;
