import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from './ConfirmationModal';

const ReleaseResultsModal = ({
  firstName,
  lastName,
  productName,
  releaseResults,
  showModal,
  closeModal,
}) => (
  <ConfirmationModal
    modalTitle="RELEASE RESULTS"
    showModal={showModal}
    messagePrompt={(
      <div>
        <p>
          Are you sure you want to release results to&nbsp;
          <b>
            {firstName}&nbsp;
            {lastName}
          </b> for
          <b> {productName}</b>?
        </p>
        <p>
          <strong>NOTE:</strong> This cannot be undone!
        </p>
      </div>
    )}
    closeModal={closeModal}
    confirmAction={releaseResults}
  />
);

ReleaseResultsModal.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  releaseResults: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

ReleaseResultsModal.defaultProps = {
  showModal: true,
};

export default ReleaseResultsModal;
