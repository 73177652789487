import { connect } from 'react-redux';
import EditSignin from './EditSignin';
import { updateSignin } from '../actions/user';

const mapStateToProps = (state) => {
  if (!state.user) {
    return {};
  }
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => ({
  updateSignin: (updatedUserInfo) => {
    dispatch(updateSignin(updatedUserInfo));
  }
});

const EditSigninContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSignin);

export default EditSigninContainer;
