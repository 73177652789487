import history from '../utils/history';
import { httpClient } from '../utils/httpClient';
import { clearState } from './root';

export const LOGOUT_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_COMPLETED = 'LOGOUT_COMPLETED';

export const logoutCompleted = () => ({
  type: LOGOUT_COMPLETED,
});

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST
});

export const logout = (location) => (dispatch) => {
  dispatch(logoutRequest());
  return httpClient.post('/logout', {
    value: 'value'
  }).then(() => {
    history.push(location);
    dispatch(logoutCompleted());
    dispatch(clearState());
  }).catch((err) => {
    if (err.response) {
      console.error('logout err data', err.response.data);
    } else {
      console.error('logout err message:', err.message);
    }
  });
};
