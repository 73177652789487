import React from 'react';
import PropTypes from 'prop-types';

import EapButton from '../../../components/EapButton';

const DeleteExamineeNoProducts = ({
  closeModal,
  selectedExaminee,
  deleteExaminee
}) => (
  <div className="modal-center">
    <p>
      Are you sure you want to remove&nbsp;
      <b>{selectedExaminee.firstName} {selectedExaminee.lastName}</b> as an examinee?
    </p>
    <EapButton
      text="Yes"
      onClick={() => { closeModal(); deleteExaminee(selectedExaminee); }}
    />
    &nbsp;
    <EapButton
      text="No"
      isSecondary
      onClick={closeModal}
    />
  </div>
);

export default DeleteExamineeNoProducts;

DeleteExamineeNoProducts.propTypes = {
  closeModal: PropTypes.func.isRequired,
  selectedExaminee: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }).isRequired,
  deleteExaminee: PropTypes.func.isRequired
};
