import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getAllProducts } from '../../../actions/products';
import { StaticEapTable } from '../../../components/StaticEapTable';

const columns = [
  {
    Header: 'Code',
    accessor: 'code'
  },
  {
    Header: 'Product Name',
    accessor: 'name'
  },
  {
    Header: 'Admin Name',
    accessor: 'adminName'
  },
  {
    Header: 'Product URL',
    accessor: 'url',
  },
  {
    Header: 'Price',
    accessor: 'price'
  },
  {
    Header: 'QBO ID',
    accessor: 'qboId'
  },
  {
    Header: 'Configs',
    accessor: 'configs',
    filterable: false,
    sortable: false,
    Cell: ({ row }) => {
      const configs = _.map(Object.keys(row.original.configs), (key) => (
        <div key={`${key}${row.original.id}`}> {key}: {row.original.configs[key].toString()} </div>
      ));
      return <div> {configs} </div>;
    }
  }
];

const Products = ({ products, loadProducts }) => {
  if (_.isEmpty(products)) {
    loadProducts();
  }
  return (
    <div>
      <StaticEapTable
        columns={columns}
        data={products}
      />
    </div>
  );
};

Products.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadProducts: PropTypes.func.isRequired
};


const mapStateToProps = (state) => ({
  products: state.products.list
});

const mapDispatchToProps = (dispatch) => ({
  loadProducts: () => { dispatch(getAllProducts()); }
});

const ProductsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Products);

export default ProductsContainer;
