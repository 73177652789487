import React from 'react';
import PropTypes from 'prop-types';

import PendingInvitesTable from './PendingInvitesTable';
import ExamineeInviteForm from './ExamineeInviteForm';

const ExamineeInvites = ({
  availableProducts,
  sendInvites,
  invites,
  revokeInvite,
  resendInvite,
  adminGroupId,
  sending
}) => (
  <div className="pure-u-1">
    <ExamineeInviteForm
      availableProducts={availableProducts}
      sending={sending}
      sendInvites={(validEmailList, availableProductId) => {
        sendInvites(adminGroupId, validEmailList, availableProductId);
      }}
    />
    <PendingInvitesTable
      pendingInvites={invites}
      revokeInvite={revokeInvite}
      resendInvite={resendInvite}
      adminGroupId={adminGroupId}
    />
  </div>
);

ExamineeInvites.propTypes = {
  adminGroupId: PropTypes.number.isRequired,
  invites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    createdAt: PropTypes.string
  })).isRequired,
  revokeInvite: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
  sendInvites: PropTypes.func.isRequired,
  availableProducts: PropTypes.arrayOf(PropTypes.shape({
    adminName: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired
  })),
};

ExamineeInvites.defaultProps = {
  availableProducts: undefined
};

export default ExamineeInvites;
