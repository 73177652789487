import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AdminIconList from '../../../components/AdminIconList';
import { StaticEapTable } from '../../../components/StaticEapTable';
import EapButton from '../../../components/EapButton';
import EapModal from '../../../components/EapModal';
import ResourceLink from '../../../components/ResourceLink';
import AdminIcon from '../../../components/AdminIcon';
import AdminInvitesForm from '../../../components/AdminInvitesForm';
import AdminInfoModal from '../../../components/AdminInfoModal';
import AdminInviteRevokeModal from '../../../group/components/AdminInviteRevokeModal';
import AdminInviteResendModal from '../../../group/components/AdminInviteResendModal';
import './GroupAdminInvitesTable.css';

class GroupAdminInvitesTable extends Component {
  constructor() {
    super();
    this.state = {
      showInvitesModal: false
    };
    this.sendInvites = this.sendInvites.bind(this);
  }

  sendInvites(emailList, permissions) {
    this.props.sendInvites(emailList, permissions);
    this.setState({
      showInvitesModal: false
    });
  }

  render() {
    const columns = [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Sender ID',
        accessor: 'senderUserId',
        Cell: ({ row }) => (
          <ResourceLink
            displayValue={row.original.senderUserId.toString()}
            toRoute={`/super/user/${row.original.senderUserId}/info`}
          />
        )
      },
      {
        Header: 'Permissions',
        accessor: 'permissionString',
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        disableFitlers: true,
        Cell: ({ row }) => (
          <AdminIconList>
            <AdminIcon
              onClick={() => this.setState({ adminInfoModal: row.original })}
              icon="fa-info-circle"
              toolTip="Information"
            />
            <AdminInviteResendModal
              selectedAdminInvite={row.original}
              resendInvite={this.props.superResendAdminInvite}
            />
            <AdminInviteRevokeModal
              selectedAdminInvite={row.original}
              revokeInvite={
                selectedInvite =>
                  this.props.superRevokeAdminInvite(selectedInvite)
              }
            />
          </AdminIconList>
        )
      }
    ];
    return (
      <div>
        <EapButton
          text="Invite Admins"
          onClick={() => this.setState({ showInvitesModal: true })}
        />
        <EapModal
          modalTitle="Admin Invites"
          showModal={this.state.showInvitesModal}
        >
          <div className="admin-invite-form-container">
            <AdminInvitesForm
              submitInvites={this.sendInvites}
              closeModal={() => this.setState({ showInvitesModal: false })}
              isModal
            />
          </div>
        </EapModal>
        <AdminInfoModal
          showModal={this.state.adminInfoModal !== undefined}
          permissions={this.state.adminInfoModal || {}}
          closeModal={() => this.setState({ adminInfoModal: undefined })}
        />
        <StaticEapTable
          columns={columns}
          data={this.props.adminInvites || []}
        />
      </div>
    );
  }
}

GroupAdminInvitesTable.propTypes = {
  sendInvites: PropTypes.func.isRequired,
  adminInvites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    senderUserId: PropTypes.number,
    email: PropTypes.string,
    per_users_view: PropTypes.bool,
    per_users_invite: PropTypes.bool,
    per_users_move: PropTypes.bool,
    per_products_view: PropTypes.bool,
    per_products_transfer: PropTypes.bool,
    per_products_assign: PropTypes.bool,
    per_admin_view: PropTypes.bool,
    per_admin_invite: PropTypes.bool,
    per_admin_remove: PropTypes.bool,
    per_admin_edit: PropTypes.bool,
    per_create: PropTypes.bool,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string
  })),
  superRevokeAdminInvite: PropTypes.func.isRequired,
  superResendAdminInvite: PropTypes.func.isRequired
};

GroupAdminInvitesTable.defaultProps = {
  adminInvites: undefined
};

export default GroupAdminInvitesTable;
