import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EapButton from './EapButton';
import { RECOMMENDED, validate, PermissionSelector } from './PermissionSelector';
import EmailListTextArea, { getErrorString } from './EmailListTextArea';
import InlineValidationMessage from './InlineValidationMessage';

const initState = () => ({
  dirty: false,
  rawEmail: '',
  emails: [],
  emailError: undefined,
  permissions: { ...RECOMMENDED },
});

class AdminInvitesForm extends Component {
  constructor() {
    super();
    this.state = initState();
    this.onSettingsChange = this.onSettingsChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.submitInvites = this.submitInvites.bind(this);
  }

  onSettingsChange(permissions) {
    this.setState({
      permissions,
    });
  }

  onEmailChange(emails, isValid, opts) {
    const nextState = {
      emails,
      dirty: true,
      rawEmail: opts.raw
    };
    if (!isValid) {
      if (this.state.dirty && emails.length === 0) {
        nextState.emailError = 'Please enter an email address.';
      } else {
        nextState.emailError = getErrorString(opts);
      }
    } else {
      nextState.emailError = undefined;
    }
    this.setState(nextState);
  }

  submitInvites(e) {
    e.preventDefault();
    if (
      this.state.emailError !== undefined ||
      !validate(this.state.permissions) ||
      this.state.emails.length === 0
    ) {
      this.setState({ dirty: true });
      return;
    }
    this.props.submitInvites(
      this.state.emails,
      this.state.permissions
    );
    this.setState(initState());
    if (this.props.isModal) {
      this.props.closeModal();
    }
  }

  render() {
    return (
      <form
        className="pure-form eap-grey-form"
        onSubmit={this.submitInvites}
      >
        <div>
            Please enter information below to send admin invites.
        </div>
        <div className="pure-u-3-5">
          <EmailListTextArea
            value={this.state.rawEmail}
            onChange={this.onEmailChange}
            message={this.state.emailError}
            rows={22}
          />
        </div>
        <div
          className="pure-u-sm-9-24 pure-u-1"
          style={{ marginBottom: '1.5em', paddingLeft: '1em' }}
        >
          <PermissionSelector
            onChange={this.onSettingsChange}
            permissions={this.state.permissions}
          />
          <div className="pure-u-1" style={{ paddingTop: '.5em' }}>
            <EapButton
              isSubmit
              className="pure-button pure-button-primary"
              disabled={
                this.state.emailError !== undefined ||
                !validate(this.state.permissions) ||
                this.state.emails.length === 0
              }
              faIcon="fa-envelope"
              text="SEND"
            />
          </div>
        </div>
        { this.props.isModal ? (
          <EapButton
            isSecondary
            onClick={this.props.closeModal}
            text="CLOSE"
          />
            ) : <span />
          }
        {this.state.emaiLError !== undefined || !validate(this.state.permissions) ? (
          <InlineValidationMessage
            message="Please fix the errors in red before sending invites."
          />
          ) : ''}
      </form>
    );
  }
}

export default AdminInvitesForm;

AdminInvitesForm.propTypes = {
  isModal: PropTypes.bool,
  closeModal: PropTypes.func,
  submitInvites: PropTypes.func.isRequired
};

AdminInvitesForm.defaultProps = {
  isModal: false,
  closeModal: undefined
};
