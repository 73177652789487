import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';
import PublicBanner from './components/PublicBanner';
import Footer from './Footer';

class Public extends React.Component {
  constructor(props) {
    super(props);
    this.firstDivRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.firstDivRef.current.focus();
    }
  }

  render() {
    return (
      <div className="flex-column" ref={this.firstDivRef}>
        <div className="wrapper">
          <PublicBanner />
          {this.props.children}
          <Footer />
        </div>
      </div>
    );
  }
}

Public.propTypes = {
  children: PropTypes.element.isRequired,
  // eslint-disable-next-line react/no-typos
  location: ReactRouterPropTypes.location.isRequired
};

export default withRouter(Public);
