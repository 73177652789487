import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import EnterMSD from '../../components/EnterMSDModal';
import AdminIcon from '../../components/AdminIcon';
import GenericProductLink from '../../components/GenericProductLink';
import ReleaseResultsModal from '../../components/ReleaseResultsModal';

import './ExamineeResults.css';

const ExamineeResults = ({
  adminGroupId,
  clearResults,
  groupId,
  loadResults,
  loadingResults,
  releaseResults,
  results,
  user,
}) => {
  const [showModal, setShowModal] = useState({
    type: '',
    data: {},
  });

  useEffect(() => {
    loadResults({
      adminGroupId,
      userId: user.userId, // it's set weird somewhere up the chain
    });
    return () => {
      clearResults();
    };
  }, []);

  if (results === undefined || loadingResults) {
    return (
      <div className="pure-grid">
        <h4 className="examinee-results-header">
          {user.firstName} {user.lastName} ({user.username})
        </h4>
        <div className="examinee-results-list">
          Loading
        </div>
      </div>
    );
  }

  const productList = results.map((assignedProduct) => (
    <div
      className="examinee-results-item"
      key={`assignedProduct_${assignedProduct.id}`}
    >
      <span className="pure-u-13-24">{assignedProduct.product.name}</span>
      <span className="pure-u-1-5">
        <GenericProductLink
          text={assignedProduct.status}
          assignedProductId={assignedProduct.id}
          baseUrl="/api/user/assigned-product/"
          isAdmin
        />
      </span>
      <span className="pure-u-1-5">
        <AdminIcon
          onClick={() => setShowModal({ type: 'msd', data: assignedProduct.id })}
          icon="fa-plus"
          toolTip="Add MSD"
          permission="caps"
          permissions={assignedProduct.configs}
        />
        {
          assignedProduct.configs && assignedProduct.configs.suppressed
            ? (
              <AdminIcon
                onClick={() => {
                  setShowModal({ type: 'release', data: assignedProduct });
                }}
                icon="fa-share-square-o"
                toolTip="Release Results"
              />
            ) : ''
        }
      </span>
    </div>
  ));
  return (
    <div className="pure-grid">
      <h4 className="examinee-results-header">
        {user.firstName} {user.lastName} ({user.username})
      </h4>
      <div className="examinee-results-list">
        { productList.length > 0 ? productList : 'This examinee has no products assigned.' }
      </div>
      <ReleaseResultsModal
        showModal={showModal.type === 'release'}
        firstName={user.firstName || ''}
        lastName={user.lastName || ''}
        productName={showModal.data.product ? showModal.data.product.name : 'Loading'}
        releaseResults={() => {
          releaseResults({
            adminGroupId,
            groupId,
            assignedProductId: showModal.data.id,
            firstName: user.firstName,
            lastName: user.lastName,
          });
          setShowModal({ type: '', data: {} });
        }}
        closeModal={() => setShowModal({ type: '', data: {} })}
      />
      <EnterMSD
        showModal={showModal.type === 'msd'}
        closeModal={() => setShowModal({ type: '', data: {} })}
        testId={showModal.type === 'msd' ? showModal.data : null}
        groupId={groupId}
        adminGroupId={adminGroupId}
        firstName={user.firstName}
        lastName={user.lastName}
        gender={user.gender}
        grade={user.grade}
      />
    </div>
  );
};

ExamineeResults.propTypes = {
  groupId: PropTypes.number.isRequired,
  user: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    username: PropTypes.string,
    gender: PropTypes.string,
    grade: PropTypes.number
  }).isRequired,
  results: PropTypes.arrayOf(PropTypes.shape({
    product: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string
  })),
  releaseResults: PropTypes.func.isRequired
};

ExamineeResults.defaultProps = {
  results: []
};

export default ExamineeResults;
