import { connect } from 'react-redux';

import { fulfill } from '../../actions/purchase';

import Fulfillment from '../components/FulfillmentComponent';

const mapStateToProps = ({
  user: {
    adminGroups: groups,
  },
}, props) => ({
  groups,
  ...props,
});

const mapDispatchToProps = (dispatch) => ({
  fulfill: ({ purchase, group }) => {
    dispatch(fulfill({ purchase, group }));
  }
});

const FulfillmentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Fulfillment);

export default FulfillmentContainer;
