import React from 'react';
import { Link } from 'react-router-dom';

import EapGeneralHeader from '../../components/EapGeneralHeader';
import './NoProductsBox.css';

const NoProductsBox = () => (
  <div className="pure-u-1 no-products-box">
    <EapGeneralHeader text="No Products" />
    <p>You do not have any products assigned to
      you. <Link to="/passcode">Click here</Link> to
      enter a passcode.
    </p>
  </div>
);

export default NoProductsBox;
