import _ from 'lodash';

import {
  EDIT_GROUP,
  CANCEL_EDIT,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAILURE,
  SUPER_ADD_GROUP_SUCCESS,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  LOAD_GROUP_SUPER_SUCCESS,
  GROUP_CLEAR_CACHE
} from '../actions/group';

import {
  NEW_PASSCODE_SUCCESS,
  TRANSFER_PRODUCTS_SUCCESS,
  LOAD_AVAILABLE_PRODUCTS_SUCCESS,
  INCREMENT_PRODUCTS_SUCCESS,
  DELETE_AVAILABLE_PRODUCTS_SUCCESS,
  ADD_NEW_PRODUCT_SUCCESS
} from '../actions/availableProducts';

import {
  RELEASE_RESULTS_SUCCESS,
  ENTER_MSD_SUCCESS
} from '../actions/assignedProducts';

import {
  ASSIGN_PRODUCT_SUCCESS,
  MOVE_EXAMINEE_SUCCESS,
  ADD_EXAMINEE_SUCCESS,
  GET_SUPER_GROUP_EXAMINEES_SUCCESS,
  SUPER_ASSIGN_PRODUCT_SUCCESS,
  SUPER_ADD_EXAMINEE_SUCCESS
} from '../actions/examinees';

import {
  SEND_EXAMINEE_INVITES_SUCCESS,
  REVOKE_EXAMINEE_INVITE_SUCCESS,
  REIMBURSE_AVAILABLE_PRODUCT,
  SUPER_GET_EXAMINEE_INVITES_SUCCESS
} from '../actions/examineeInvites';

import {
  SUPER_LOAD_GROUP_ADMINS_SUCCESS,
  UPDATE_ADMIN_PERMISSIONS_SUCCESS,
  ADD_ADMIN_SUCCESS,
  DELETE_ADMIN_SUCCESS
} from '../actions/admins';

import {
  SEND_ADMIN_INVITES_SUCCESS,
  REVOKE_ADMIN_INVITE_SUCCESS,
} from '../actions/adminInvites';

function incrementProductAmount(group, productId, amount) {
  const updatedGroup = { ...group };
  const productIndex = _.findIndex(
    group.availableProducts,
    ['id', productId]
  );
  if (productIndex !== -1) {
    updatedGroup.availableProducts[productIndex].amount += amount;
  }
  return updatedGroup;
}

function updateAvailableProductsAmount(state, groupId, productId, amount) {
  const updatedGroupsById = _.cloneDeep(state.groupsById);
  const sourceGroup = updatedGroupsById[groupId];
  updatedGroupsById[groupId] =
    incrementProductAmount(sourceGroup, productId, amount);
  return {
    ...state,
    groupsById: updatedGroupsById
  };
}

function assignProduct(state, groupId, userId, assignedProduct) {
  const updatedGroupsById = { ...state.groupsById };
  const updatedGroup = updatedGroupsById[groupId];
  updatedGroupsById[groupId] = {
    ...updatedGroup,
    examinees: _.map(updatedGroup.examinees, examinee => ({
      ...examinee,
      assigned: (examinee.id === userId) ?
        (examinee.assigned || []).concat(assignedProduct) :
        _.cloneDeep(examinee.assigned)
    }))
  };
  return updatedGroupsById;
}

function suppressResults(state, groupId, productId, suppress) {
  if (groupId) {
    const updatedGroupsById = { ...state.groupsById };
    const updatedGroup = updatedGroupsById[groupId];
    if (!updatedGroup) {
      return {
        ...state,
      };
    }
    updatedGroupsById[groupId] = {
      ...updatedGroup,
      examinees: _.map(updatedGroup.examinees, (examinee) => ({
        ...examinee,
        assigned: _.map(examinee.assigned, (product) => {
          if (product.id === productId) {
            let { status } = product;
            if (status === 'Results' && suppress) {
              status = 'Pending';
            }
            if (status === 'Pending' && !suppress) {
              status = 'Results';
            }
            return {
              ...product,
              status,
              configs: {
                ...product.configs,
                suppressed: suppress
              }
            };
          } return product;
        })
      }))
    };
    return {
      ...state,
      groupsById: updatedGroupsById
    };
  } return { ...state };
}

const groupReducer = (state = ({ groupsById: {} }), action) => {
  switch (action.type) {
    case EDIT_GROUP: {
      return { ...state };
    }
    // TODO: Send error message differently possibly
    case CANCEL_EDIT: {
      return { ...state, nameMessage: undefined };
    }
    case GET_GROUP_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      updatedGroupsById[action.payload.groupInfo.id] = action.payload.groupInfo;
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case GET_GROUP_FAILURE: {
      return { ...state, message: action.payload.error };
    }
    case SUPER_ADD_GROUP_SUCCESS: {
      const newGroupArray = _.concat(state.sisterGroups, action.payload.newGroup);
      return {
        ...state,
        sisterGroups: newGroupArray
      };
    }
    case UPDATE_GROUP_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      // TODO: Remove if condition once structure is implemented in super
      if (updatedGroupsById[action.payload.id]) {
        updatedGroupsById[action.payload.id].name = action.payload.name;
        updatedGroupsById[action.payload.id].description = action.payload.description;
        return {
          ...state,
          info: {
            ...state.info, 
            ...action.payload
          },
          groupsById: updatedGroupsById,
          nameMessage: undefined
        };
      }
      return { ...state };
    }
    case UPDATE_GROUP_FAILURE: {
      return {
        ...state,
        nameMessage: action.payload.error
      };
    }
    case NEW_PASSCODE_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      let updatedGroup = { ...updatedGroupsById[action.payload.groupId] };
      updatedGroup = {
        ...updatedGroup,
        availableProducts: _.map(updatedGroup.availableProducts, (product) => {
          if (product.id === action.payload.availableProductId) {
            return { ...product, passcode: action.payload.newPasscode };
          } return product;
        })
      };
      updatedGroupsById[action.payload.groupId] = updatedGroup;
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case TRANSFER_PRODUCTS_SUCCESS: {
      return updateAvailableProductsAmount(
        state,
        action.payload.sourceGroupId,
        action.payload.availableProductId,
        -action.payload.selectedAmount
      );
    }
    case INCREMENT_PRODUCTS_SUCCESS: {
      return updateAvailableProductsAmount(
        state,
        action.payload.sourceGroupId,
        action.payload.availableProductId,
        action.payload.selectedAmount
      );
    }
    case ADD_NEW_PRODUCT_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      const updatedGroup = updatedGroupsById[action.payload.groupId];
      updatedGroup.availableProducts =
        updatedGroup.availableProducts.concat(action.payload.product);
      updatedGroupsById[action.payload.groupId] = updatedGroup;
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case RELEASE_RESULTS_SUCCESS: {
      return suppressResults(
        state,
        action.payload.groupId,
        action.payload.assignedProductId,
        false
      );
    }
    case ENTER_MSD_SUCCESS: {
      const newState = { ...state };
      const group = newState.groupsById[action.payload.groupId];
      if (!group) {
        return newState;
      }
      // This is not efficient. Please forgive me. RLEE
      _.each(group.examinees, (examinee) => {
        const pUpdate = _.find(
          examinee.assigned,
          (prod) => (prod.id === action.payload.testId)
        );
        if (!pUpdate) {
          // no-op. It's not the test we're looking for.
        } else if (!action.payload.incomplete && pUpdate.configs.suppressed) {
          pUpdate.status = 'Pending';
        } else if (!action.payload.incomplete) {
          pUpdate.status = 'Results';
        } else {
          pUpdate.status = 'Resume';
        }
      });
      return newState;
    }
    case ASSIGN_PRODUCT_SUCCESS: {
      const updatedGroupsById = assignProduct(
        state,
        action.payload.groupId,
        action.payload.userId,
        action.payload.assignedProduct
      );
      updatedGroupsById[action.payload.groupId] =
        incrementProductAmount(
          updatedGroupsById[action.payload.groupId],
          action.payload.availableProductId,
          -1
        );
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case ADD_EXAMINEE_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      const updatedGroup = updatedGroupsById[action.payload.groupId];
      updatedGroup.examinees = _.concat(updatedGroup.examinees, action.payload.newExaminee);
      updatedGroupsById[action.payload.groupId] =
        incrementProductAmount(updatedGroup, action.payload.availableProductId, -1);
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case SUPER_ADD_EXAMINEE_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      const updatedGroup = updatedGroupsById[action.payload.groupId];
      const newExaminee = _.cloneDeep(action.payload.user);
      newExaminee.userGroupId = action.payload.userGroupId;
      newExaminee.assignedProducts = [];
      newExaminee.createdAt = action.payload.createdAt;
      updatedGroup.examinees = _.concat(updatedGroup.examinees, newExaminee);
      updatedGroupsById[action.payload.groupId] = updatedGroup;
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case SEND_EXAMINEE_INVITES_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      let updatedGroup = updatedGroupsById[action.payload.groupId];
      updatedGroup = {
        ...updatedGroup,
        userInvites: _.concat(updatedGroup.userInvites, action.payload.newInvites)
      };
      updatedGroupsById[action.payload.groupId] =
        incrementProductAmount(
          updatedGroup,
          action.payload.availableProductId,
          -action.payload.newInvites.length
        );
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case REVOKE_EXAMINEE_INVITE_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      const updatedUserInvites = updatedGroupsById[action.payload.groupId].userInvites;
      _.remove(updatedUserInvites, invite => invite.id === action.payload.selectedRowId);
      updatedGroupsById[action.payload.groupId] = {
        ...updatedGroupsById[action.payload.groupId],
        userInvites: _.cloneDeep(updatedUserInvites)
      };
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case REIMBURSE_AVAILABLE_PRODUCT: {
      const updatedGroupsById = { ...state.groupsById };
      const updatedGroup = updatedGroupsById[action.payload.groupId];
      const productIndex = _.findIndex(
        updatedGroup.availableProducts,
        ['id', action.payload.product.id]
      );
      if (productIndex !== -1) {
        updatedGroupsById[action.payload.groupId] =
          incrementProductAmount(updatedGroup, action.payload.product.id, 1);
      } else {
        updatedGroup.availableProducts =
          _.concat(updatedGroup.availableProducts, action.payload.product);
        updatedGroupsById[action.payload.groupId] = updatedGroup;
      }
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case UPDATE_ADMIN_PERMISSIONS_SUCCESS: {
      const newState = _.cloneDeep(state);
      const group = newState.groupsById[action.payload.groupId];
      const adminIndex = _.findIndex(group.admins, {
        adminGroupId: action.payload.admin.adminGroupId
      });
      group.admins[adminIndex] = {
        ...group.admins[adminIndex],
        ...action.payload.newPermissions
      };

      return newState;
    }
    case ADD_ADMIN_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      const updatedGroup = updatedGroupsById[action.payload.groupId];
      updatedGroup.admins = updatedGroup.admins.concat(action.payload.newAdmin);
      updatedGroupsById[action.payload.groupId] = updatedGroup;
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case DELETE_ADMIN_SUCCESS: {
      const newState = _.cloneDeep(state);
      const g = newState.groupsById[action.payload.groupId];
      _.remove(g.admins, admin => admin.adminGroupId === action.payload.subjectAdmin.adminGroupId);
      return newState;
    }
    case SEND_ADMIN_INVITES_SUCCESS: {
      const updatedGroupsById = _.cloneDeep(state.groupsById);
      const updatedGroup = updatedGroupsById[action.payload.groupId];
      const adminInvites = updatedGroup.adminInvites || [];
      updatedGroupsById[action.payload.groupId] = {
        ...updatedGroup,
        adminInvites: [
          ...adminInvites,
          ...action.payload.newInvites
        ]
      };
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case REVOKE_ADMIN_INVITE_SUCCESS: {
      const updatedGroupsById = _.cloneDeep(state.groupsById);
      const updatedAdminInvites = updatedGroupsById[action.payload.groupId].adminInvites;
      _.remove(updatedAdminInvites, invite => invite.id === action.payload.adminInviteId);
      updatedGroupsById[action.payload.groupId] = {
        ...updatedGroupsById[action.payload.groupId],
        adminInvites: _.cloneDeep(updatedAdminInvites)
      };
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case LOAD_GROUP_SUPER_SUCCESS: {
      const info = { ...action.payload };
      delete info.organization;
      return {
        ...state,
        info
      };
    }
    case SUPER_GET_EXAMINEE_INVITES_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      updatedGroupsById[action.payload.groupId] = {
        ...updatedGroupsById[action.payload.groupId],
        userInvites: action.payload.invites
      };
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case LOAD_AVAILABLE_PRODUCTS_SUCCESS: {
      // id payload refers to groupId
      // products refer to availableProducts
      const updatedGroupsById = { ...state.groupsById };
      updatedGroupsById[action.payload.id] = {
        ...updatedGroupsById[action.payload.id],
        availableProducts: _.cloneDeep(action.payload.products)
      };
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case SUPER_LOAD_GROUP_ADMINS_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      updatedGroupsById[action.payload.groupId] = {
        ...updatedGroupsById[action.payload.groupId],
        admins: _.cloneDeep(action.payload.admins)
      };
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case GET_SUPER_GROUP_EXAMINEES_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      updatedGroupsById[action.payload.groupId] = {
        ...updatedGroupsById[action.payload.groupId],
        examinees: _.cloneDeep(action.payload.examinees)
      };
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case DELETE_AVAILABLE_PRODUCTS_SUCCESS: {
      const updatedGroupsById = { ...state.groupsById };
      const currentGroup = updatedGroupsById[action.payload.groupId];
      updatedGroupsById[action.payload.groupId] = {
        ...currentGroup,
        availableProducts:
          _.remove(
            currentGroup.availableProducts,
            product => product.id !== action.payload.availableProductId
          )
      };
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case SUPER_ASSIGN_PRODUCT_SUCCESS: {
      const updatedGroupsById = assignProduct(
        state,
        action.payload.groupId,
        action.payload.userId,
        action.payload.assignedProduct
      );
      return {
        ...state,
        groupsById: updatedGroupsById
      };
    }
    case GROUP_CLEAR_CACHE: {
      return {
        ...state,
        info: undefined
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default groupReducer;
