import { connect } from 'react-redux';
import Examinees from './components/ExamineesView';
import {
  moveExaminee,
  assignProduct,
  overrideExamineePassword,
  filterExaminees,
} from '../actions/examinees';
import { requestPasswordReset } from '../actions/password';
import extractAndSortPermissions from '../utils/sortPermissions';

const mapStateToProps = (state, ownProps) => {
  if (!state.user.permissions.administrator) {
    return {
      unauthorized: true
    };
  }
  if (state.group.loading) {
    return {
      unauthorized: false,
      group: { loading: true }
    };
  }
  if (state.group.message) {
    return {
      unauthorized: false,
      group: {
        loading: false,
        message: state.group.message,
      }
    };
  }

  const adminPermissions = extractAndSortPermissions(state.user.adminGroups, ownProps.groupId);
  const selectedGroup = state.group.groupsById[ownProps.groupId];

  return {
    unauthorized: false,
    adminId: adminPermissions.id,
    user: state.user,
    adminGroups: state.user.adminGroups,
    examinees: state.examinees.serverFiltered,
    examineesCount: state.examinees.serverTotal,
    moving: state.examinees.moving,
    availableProducts: selectedGroup.availableProducts,
    productsPermission: adminPermissions.products,
    usersPermission: adminPermissions.users
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  assignProduct: (adminId, actorId, subject, availProductId) => {
    console.log(adminId, actorId, subject, ownProps.groupId, availProductId);
    dispatch(assignProduct(
      adminId,
      actorId,
      {
        ...subject,
        id: subject.userId,
      },
      ownProps.groupId,
      availProductId,
    ));
  },
  moveExaminee: (adminId, examinee, newGroup) => {
    dispatch(moveExaminee(
      adminId,
      {
        ...examinee,
        id: examinee.userId || examinee.id,
      },
      ownProps.groupId,
      newGroup,
      {
        remove: true,
      }
    ));
  },
  resetPassword: (email) => {
    dispatch(requestPasswordReset(email, true));
  },
  overrideExamineePassword: (examineeId, newPassword, username) => {
    dispatch(overrideExamineePassword(examineeId, newPassword, username));
  },
  loadExaminees: (groupId, params) => {
    params.filtered.push({
      id: 'groupId',
      value: groupId,
    });
    dispatch(filterExaminees(params));
  },
});

const ExamineesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Examinees);

export default ExamineesContainer;
