import { httpClient } from '../utils/httpClient';

export const FILTER_PURCHASES_REQUEST = 'FILTER_PURCHASES_REQUEST';
export const FILTER_PURCHASES_SUCCESS = 'FILTER_PURCHASES_SUCCESS';
export const FILTER_PURCHASES_FAILURE = 'FILTER_PURCHASES_FAILURE';

export const FULFILLMENT_REQUEST = 'FULFILLMENT_REQUEST';
export const FULFILLMENT_SUCCESS = 'FULFILLMENT_SUCCESS';
export const FULFILLMENT_FAILURE = 'FULFILLMENT_FAILURE';

export const REFILL_REQUEST = 'REFILL_REQUEST';
export const REFILL_SUCCESS = 'REFILL_SUCCESS';
export const REFILL_ERROR = 'REFILL_ERROR';

export class RefillError extends Error {}

export const fulfill = ({
  purchase,
  group
}) => (
  (dispatch) => {
    dispatch({
      type: FULFILLMENT_REQUEST,
      payload: { purchase, group },
    });
    return httpClient
      .post('/order', { orderId: purchase.id, groupId: group.groupId })
      .then(({ data }) => {
        dispatch({
          type: FULFILLMENT_SUCCESS,
          payload: { purchase, group, response: data },
        });
      });
  }
);

export const filterPurchases = ({
  type = 'purchases',
  filtered,
  sorted,
  limit,
}) => (
  (dispatch) => {
    dispatch({
      type: FILTER_PURCHASES_REQUEST,
      payload: {}
    });
    return httpClient
      .get(`/super/${type}`, {
        params: {
          type,
          sorted,
          limit,
          filter: filtered,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: FILTER_PURCHASES_SUCCESS,
          payload: data
        });
      })
      .catch((error) => {
        dispatch({
          type: FILTER_PURCHASES_FAILURE,
          payload: error
        });
      });
  }
);

export const refill = ({
  product: {
    id: productId,
  },
  groupId,
  quantity,
  amount,
  stripeToken,
  optionalEmail,
}) => (
  (dispatch) => {
    dispatch({
      type: REFILL_REQUEST,
      payload: {},
    });
    return httpClient
      .post(
        `/group/${groupId}/available-products/${productId}/purchase`,
        {
          quantity,
          purchaseAmount: amount,
          stripeToken,
          optionalEmail,
        }
      ).then((result) => {
        if (result.data.success && result.data.refill) {
          dispatch({
            type: REFILL_SUCCESS,
            payload: {
              refill: result.data.refill,
              availableProductId: productId,
            },
          });
          return result.data.refill;
        }
        if (result.data.message) {
          dispatch({
            type: REFILL_ERROR,
            payload: new RefillError(result.data.message),
          });
          return Promise.reject(new RefillError(result.data.message));
        }
        dispatch({
          type: REFILL_ERROR,
          payload: new RefillError('Your payment could not be processed at this time.'),
        });
        return Promise.reject(new RefillError('Your payment could not be processed at this time.'));
      })
      .catch((err) => {
        dispatch({
          type: REFILL_ERROR,
          payload: err
        });
        return Promise.reject(err);
      });
  }
);
