import { connect } from 'react-redux';
import _ from 'lodash';

import {
  getAllAvailableProductsByGroup
} from '../../actions/availableProducts';

import {
  loadGroupViaSuper,
} from '../../actions/group';

import {
  loadGroups
} from '../../actions/organization';

import {
  loadSuperGroupAdmins
} from '../../actions/admins';

import {
  getSuperGroupExaminees as loadSuperGroupExaminees
} from '../../actions/examinees';

import {
  superGetAdminInvites,
} from '../../actions/adminInvites';

import {
  superGetExamineeInvites
} from '../../actions/examineeInvites';

import {
  superRevokeAssignedProduct,
  superReleaseResults,
  superSuppressResults
} from '../../actions/assignedProducts';

import {
  loadProductLog
} from '../../actions/productLog';

import GroupView from './components/GroupView';

const mapStateToProps = (state, ownProps) => {
  const groupId = parseInt(ownProps.match.params.groupId, 10);
  const {
    group,
    organization,
    products,
    assignedProducts
  } = state;

  if (group.info === undefined) {
    return {
      group: {},
      organization: {},
    };
  }

  if (group.info.id !== groupId) {
    return {
      group: {},
      organization: {}
    };
  }

  let org = {};
  if (group.info !== undefined) {
    org = organization.list.find((elm) => (elm.id === group.info.organizationId));
  }

  const assProd = _
    .chain(assignedProducts.list)
    .filter({ groupId })
    .map((prod) => {
      const productDef = _.find(products.list, { id: prod.productId });
      let productName = '';
      if (productDef !== undefined) {
        productName = productDef.adminName;
      }
      return {
        ..._.find(state.user.list, { userId: prod.userId }),
        ...{ productName },
        ...prod,
      };
    })
    .value();

  const productLog = state.productLog.list.map((p) => {
    const prodDef = _.find(state.products.list, { id: p.productId });
    return {
      ...p,
      ...{
        productName: prodDef ? prodDef.name : ''
      }
    };
  });

  return {
    group: { ...group.info },
    organization: org,
    assignedProducts: assProd,
    productLog
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  load: () => {
    const id = parseInt(ownProps.match.params.groupId, 10);
    dispatch(loadGroupViaSuper(id))
      .then((data) => {
        dispatch(loadGroups(data.organizationId));
      });
    dispatch(getAllAvailableProductsByGroup(id));
    dispatch(loadSuperGroupAdmins(id));
    dispatch(loadSuperGroupExaminees(id));
    dispatch(superGetAdminInvites(id));
    dispatch(superGetExamineeInvites(id));
    dispatch(loadProductLog(id));
  },
  revokeAssignedProduct: (product) => {
    dispatch(superRevokeAssignedProduct(product.id, product.groupId));
  },
  releaseResults: (product) => {
    dispatch(superReleaseResults(product, product.groupId));
  },
  suppressResults: (product) => {
    dispatch(superSuppressResults(product, product.groupId));
  }
});

const GroupViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupView);

export default GroupViewContainer;
