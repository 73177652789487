import React from 'react';
import PropTypes from 'prop-types';

import './CheckoutPanel.css';

const CheckoutPanel = ({ title, children }) => (
  <div className="checkout-panel">
    <div className="panel-header">{title}</div>
    <div className="panel-content">
      {children}
    </div>
  </div>
);

CheckoutPanel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
};

export default CheckoutPanel;

