import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import { filter } from '../../actions/user';

import './UserSelector.css';

const splitName = (value) => {
  const names = value.split(' ');
  const filterNames = [
    { id: 'firstName', value: names.shift() }
  ];
  if (names.length > 0) {
    filterNames.push({
      id: 'lastName',
      value: names.join(' ')
    });
  }
  return filterNames;
};

class UserSelector extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.state = {
      value: '',
      filter: 'name'
    };
  }

  componentWillMount() {
    this.props.filterUsers();
  }

  onChange(event, { newValue, method }) {
    this.setState({ value: newValue });
    if (method === 'enter') {
      event.preventDefault();
    }
    /*
    if (newValue === '') {
      this.props.onProductSelected(undefined);
    }
    */
  }

  onSuggestionsFetchRequested(value) {
    if (this.state.filter === 'name') {
      this.props.filterUsers(splitName(value.value));
    } else {
      this.props.filterUsers([{
        id: this.state.filter,
        value: value.value
      }]);
    }
  }

  getSuggestionValue(suggestion) {
    if (this.state.filter === 'name') {
      return `${suggestion.firstName} ${suggestion.lastName}`;
    }
    return suggestion[this.state.filter];
  }

  changeFilter(e) {
    this.setState({
      filter: e.target.value
    });
  }

  selectUser(user) {
    this.props.onSelected(user);
  }

  render() {
    const { value } = this.state;
    return (
      <Autosuggest
        id="SuggestUser"
        suggestions={this.props.users}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => ({})}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={suggestion => (
          <div>
            <span>{suggestion.firstName}</span>
            &nbsp;
            <span>{suggestion.lastName}</span>
            <span>&nbsp; ({suggestion.username})</span>
            <br />
            <span>{suggestion.email} &nbsp;</span>
          </div>
          )}
        alwaysRenderSuggestions
        renderInputComponent={inputProps => (
          <div className="userSelector-inputs pure-form">

            <input {...inputProps} />
            &nbsp;
            <div className="user-selector-inputs">
              <label>
                <input
                  type="radio"
                  name="user"
                  value="name"
                  checked={this.state.filter === 'name'}
                  onChange={this.changeFilter}
                />
            Name
              </label>
              <label>
                <input
                  type="radio"
                  name="user"
                  value="email"
                  checked={this.state.filter === 'email'}
                  onChange={this.changeFilter}
                />Email
              </label>
              <label>
                <input
                  type="radio"
                  name="user"
                  value="username"
                  checked={this.state.filter === 'username'}
                  onChange={this.changeFilter}
                />
            Username
              </label>
            </div>
          </div>

        )}
        onSuggestionSelected={(e, { suggestion }) => {
          e.preventDefault();
          this.selectUser(suggestion);
        }}
        highlightFirstSuggestion
        theme={{
          suggestionsContainerOpen: 'userSelector__suggestions-container',
          suggestionHighlighted: 'userSelector__suggestion--highlighted',
          suggestion: 'userSelector__suggestion'
        }}
        inputProps={{
          placeholder: 'Enter Text Here',
          value,
          onChange: this.onChange
        }}
      />
    );
  }
}

UserSelector.propTypes = {
  filterUsers: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string
  })),
  onSelected: PropTypes.func.isRequired
};

UserSelector.defaultProps = {
  users: []
};

const mapStateToProps = (state) => ({
  users: state.user.serverFiltered
});

const mapDispatchToProps = (dispatch) => ({
  filterUsers: (filterParams) => {
    dispatch(filter(filterParams));
  }
});

const UserSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSelector);

export default UserSelectorContainer;
