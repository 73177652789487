import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Formik,
  Field,
  Form,
  ErrorMessage
} from 'formik';
import InlineValidationMessage from '../../../components/InlineValidationMessage';

import EapButton from '../../../components/EapButton';
import ProductSelector from './ProductSelector';

import './AddProductForm.css';

const AddProductForm = ({ onSubmit, excludedProducts }) => {
  const validate = (values) => {
    const errors = {};
    if (!values.amount || values.amount < 1) {
      errors.amount = 'Please enter an amount.';
    }
    if (_.isEmpty(values.product)) {
      errors.product = 'Please select a product.';
    } else if (_.find(excludedProducts, (prod) => (prod.id === values.product.id))) {
      errors.product = 'This product exsits in the group';
    }
    return errors;
  };

  const submit = (values, { setSubmitting, resetForm }) => {
    onSubmit(values);
    setSubmitting(false);
    resetForm();
  };
  return (
    <Formik
      initialValues={{ amount: '', product: undefined }}
      validate={validate}
      onSubmit={submit}
      validateOnChange
    >
      {({
        values, setFieldValue, isSubmitting
      }) => (
        <Form
          className="pure-form"
        >
          <div className="pure-u-1-6 add-product-element amount-wrapper">
            <Field
              type="number"
              name="amount"
              placeholder="Amount"
            />
          </div>
          <div className="pure-u-1-2 add-product-element">
            <ProductSelector
              name="product"
              value={values.product}
              onChange={(prod) => setFieldValue('product', prod)}
            />
          </div>
          <div className="pure-u-1-5 add-product-element">
            <EapButton
              isSubmit
              text="Add"
              disabled={isSubmitting}
            />
          </div>
          <ErrorMessage
            name="amount"
            render={(msg) => <InlineValidationMessage message={msg} />}
          />
          <ErrorMessage
            name="product"
            render={(msg) => <InlineValidationMessage message={msg} />}
          />
        </Form>
      )}
    </Formik>
  );
};

AddProductForm.propTypes = {
  excludedProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })),
  onSubmit: PropTypes.func.isRequired,
};

AddProductForm.defaultProps = {
  excludedProducts: [],
};

export default AddProductForm;
