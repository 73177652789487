import React from 'react';
import PropTypes from 'prop-types';
import MainMenuItem from './MainMenuItem';
import SensibleContainer from './components/SensibleContainer';

import './MainMenuBar.css';

const PipeSeparator = () => (<span className="pipe-separator">|</span>);

const MainMenuBar = ({ isSuper }) => (
  <div className="eap-menu-bar-container">
    <SensibleContainer>
      <div className="pure-u-1">
        <div className="pure-menu pure-menu-horizontal">
          <ul className="pure-menu-list">
            <MainMenuItem to="/dashboard" text="DASHBOARD" />
            <PipeSeparator />
            { isSuper ? <MainMenuItem text="SUPER" to="/super/home" /> : '' }
            { isSuper ? <PipeSeparator /> : '' }
            <MainMenuItem to="/passcode" text="ENTER PASSCODE" />
            <PipeSeparator />
            <MainMenuItem
              to="https://www.edits.net/support/eap/welcome/"
              external
              text="HELP"
            />
          </ul>
        </div>
      </div>
    </SensibleContainer>
  </div>
);

MainMenuBar.propTypes = {
  isSuper: PropTypes.bool
};

MainMenuBar.defaultProps = {
  isSuper: false
};

export default MainMenuBar;
