import { connect } from 'react-redux';

import { getGroupInfo } from '../actions/group';
import AvailableProducts from './components/AvailableProducts';
import extractAndSortPermissions from '../utils/sortPermissions';

import {
  transferProducts,
  generateNewPasscode,
}
  from '../actions/availableProducts';

const mapStateToProps = (state, ownProps) => {
  if (!state.user.permissions.administrator) {
    return {
      unauthorized: true
    };
  } else if (state.group.loading) {
    return {
      unauthorized: false,
      group: { loading: true }
    };
  } else if (state.group.message) {
    return {
      unauthorized: false,
      group: {
        loading: false,
        message: state.group.message
      }
    };
  }

  const selectedGroup = state.group.groupsById[ownProps.groupId];

  // NOTE: The following is required to get the adminGroupId
  // const permissions = extractAndSortPermissions(state.user.adminGroups, ownProps.groupId);
  // const adminGroupId = permissions.id;

  return {
    unauthorized: false,
    user: state.user,
    group: {
      ...selectedGroup,
      permissions: extractAndSortPermissions(state.user.adminGroups, ownProps.groupId)
    },
    availableProducts: selectedGroup.availableProducts
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  // TODO: Combine group id/name, and product id/name
  transferProducts: (
    sourceAdminGroup,
    targetAdminGroup,
    availableProduct,
    amountAdded
  ) => {
    dispatch(transferProducts(
      sourceAdminGroup,
      targetAdminGroup,
      availableProduct,
      amountAdded
    ));
  },
  generateNewPasscode: (adminId, productId) => {
    dispatch(generateNewPasscode(adminId, ownProps.groupId, productId));
  },
  loadGroupInfo: () => dispatch(getGroupInfo(ownProps.groupId))
});

const AvailableProductsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailableProducts);

export default AvailableProductsContainer;
