import { connect } from 'react-redux';
import _ from 'lodash';

import OrdersTable from './OrdersTable';
import {
  filterPurchases
} from '../../actions/purchase';

const mapStateToProps = (state, inProps) => {
  const data = state.purchases.serverFiltered.map((row) => ({
    id: row.id,
    userId: row.userId,
    firstName: row.user.firstName,
    lastName: row.user.lastName,
    email: row.user.email,
    groupName: row.group ? row.group.name : '',
    groupId: row.groupId ? row.groupId : '',
    fulfillmentStatus: row.fulfillmentStatus,
    grandTotal: _.toNumber(row.grandTotal),
    qboId: row.qboId,
    createdAt: row.createdAt,
    updatedAt: row.updatedAt,
  }));
  return {
    data,
    baseUrl: inProps.baseUrl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchData: ({ filtered: unparsed, ...params }) => {
    const filtered = {
      purchases: _.filter(unparsed, ({ id }) => _.includes(['id', 'stripeChargeId', 'chargeAmount'], id)),
      users: _.filter(unparsed, ({ id }) => _.includes(['email', 'firstName', 'lastName'], id)),
      groups: _(unparsed)
        .filter(({ id }) => _.includes(['groupName'], id))
        .map(({ id, value }) => {
          if (id === 'groupName') {
            return {
              id: 'name',
              value,
            };
          }
          return {
            id,
            value,
          };
        })
        .value(),
    };
    dispatch(filterPurchases({ type: 'orders', filtered, ...params }));
  }
});

const OrdersTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersTable);

export default OrdersTableContainer;
