/* eslint react/destructuring-assignment:0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PaginationEapTable } from '../../components/PaginationEapTable';
import EapModal from '../../components/EapModal';
import EapButton from '../../components/EapButton';
import AdminIcon from '../../components/AdminIcon';
import AdminIconList from '../../components/AdminIconList';
import AssignProductModal from './AssignProductModal';
import ExamineeResultsContainer from '../ExamineeResultsContainer';
import MoveExaminee from './MoveExaminee';
import ConfirmationModal from '../../components/ConfirmationModal';
import ExamineePasswordOverrideForm from './ExamineePasswordOverrideForm';
import './ExamineesView.css';

const ExamineesView = ({
  groupId,
  productsPermission,
  usersPermission,
  adminId,
  adminGroups,
  moveExaminee,
  moving,
  examinees,
  resetPassword,
  availableProducts,
  overrideExamineePassword,
  user,
  assignProduct,
  loadExaminees,
  examineesCount,
}) => {
  const [showModal, setShowModal] = useState({
    type: '',
    data: {},
  });

  const columns = [
    {
      Header: 'Username',
      accessor: 'username'
    },
    {
      Header: 'First Name',
      accessor: 'firstName'
    },
    {
      Header: 'Last Name',
      accessor: 'lastName'
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => (
        <AdminIconList>
          <AdminIcon
            onClick={() => setShowModal({ type: 'results', data: row.original })}
            icon="fa-bar-chart"
            toolTip="Results"
          />
          <AdminIcon
            onClick={() => setShowModal({ type: 'assign', data: row.original })}
            icon="fa-file"
            toolTip="Assign"
            permission="assign"
            permissions={productsPermission}
          />
          <AdminIcon
            onClick={() => setShowModal({ type: 'move', data: row.original })}
            icon="fa-random"
            toolTip="Move"
            permission="move"
            permissions={usersPermission}
          />
          <AdminIcon
            onClick={() => setShowModal({ type: 'setPassword', data: row.original })}
            icon="fa-key"
            toolTip="Set Password"
            permission="invite"
            permissions={usersPermission}
          />
          { (!row.original.email) ? <span /> : (
            <span>
              <AdminIcon
                onClick={() => setShowModal({ type: 'passwordReset', data: row.original })}
                icon="fa-rotate-left"
                toolTip="Reset Password"
              />
            </span>
          )}
        </AdminIconList>
      )
    }
  ];
  return (
    <>
      <PaginationEapTable
        data={examinees}
        columns={columns}
        total={examineesCount}
        fetchData={(params) => loadExaminees(groupId, params)}
      />
      {
        availableProducts && availableProducts.length > 0
          ? (
            <AssignProductModal
              showModal={showModal.type === 'assign'}
              availableProducts={availableProducts}
              assignProduct={(assignedProductId) => {
                assignProduct(
                  adminId,
                  user.id,
                  showModal.data,
                  assignedProductId,
                );
                setShowModal({ type: '', data: {} });
              }}
              closeModal={() => setShowModal({ type: '', data: {} })}
            />
          ) : (
            <EapModal
              modalTitle="ASSIGN PRODUCTS"
              showModal={showModal.type === 'assign'}
            >
              <div
                style={{
                  display: 'flex',
                  flex: '1 1 auto',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <p>You are out of produts.</p>
                <EapButton
                  text="CLOSE"
                  onClick={() => setShowModal({ type: '', data: {} })}
                />
              </div>
            </EapModal>
          )
      }
      <EapModal
        modalTitle="MOVE USER"
        showModal={showModal.type === 'move'}
      >
        <MoveExaminee
          adminId={adminId}
          adminGroups={adminGroups}
          examinee={showModal.data}
          moving={moving}
          moveExaminee={(...params) => {
            moveExaminee(...params);
            setShowModal({ type: '', data: {} });
          }}
          moveError={examinees.moveError}
          closeModal={() => setShowModal({ type: '', data: {} })}
        />
      </EapModal>
      <EapModal
        modalTitle={`Results for Examinee (${showModal.data.username})`}
        showModal={showModal.type === 'results'}
      >
        <div className="examinee-results-form">
          <ExamineeResultsContainer
            user={showModal.data}
            groupId={groupId}
          />
          <div>
            <EapButton
              text="CLOSE"
              onClick={() => setShowModal({ type: '', data: {} })}
            />
          </div>
        </div>
      </EapModal>
      <EapModal
        modalTitle="OVERRIDE PASSWORD"
        showModal={showModal.type === 'setPassword'}
      >
        <ExamineePasswordOverrideForm
          name={`${showModal.data.firstName} ${showModal.data.lastName}`}
          onCancel={() => setShowModal({ type: '', data: {} })}
          onOverride={(newPassword) => {
            const {
              data: {
                username,
                userId,
              },
            } = showModal;
            overrideExamineePassword(userId, newPassword, username);
            setShowModal({ type: '', data: {} });
          }}
        />
      </EapModal>
      <ConfirmationModal
        modalTitle="RESET PASSWORD"
        showModal={showModal.type === 'passwordReset'}
        messagePrompt={(
          <div>
            <span>
              Please confirm that you would like to reset
              the password for the following signin:
            </span>
            <br />
            <b> Name: {showModal.data.firstName} {showModal.data.lastName} </b>
            <br />
            <b> Username: {showModal.data.username} </b>
            <br />
            <span>
              Would you like to send a password reset request email to
              <b> {showModal.data.email}</b>?
            </span>
          </div>
        )}
        closeModal={() => setShowModal({ type: '', data: {} })}
        confirmAction={
          () => resetPassword(showModal.data.email)
        }
      />
    </>
  );
};

ExamineesView.propTypes = {
  adminId: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  examinees: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string
  })).isRequired,
  availableProducts: PropTypes.arrayOf(PropTypes.shape({
    adminName: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired
  })),
  assignProduct: PropTypes.func.isRequired,
  overrideExamineePassword: PropTypes.func.isRequired,
  moveError: PropTypes.string,
  moveExaminee: PropTypes.func.isRequired,
  adminGroups: PropTypes.arrayOf(PropTypes.shape({
    per_products_view: PropTypes.bool,
    per_products_transfer: PropTypes.bool,
    groupId: PropTypes.number
  })).isRequired,
  productsPermission: PropTypes.shape({
    assign: PropTypes.bool
  }).isRequired,
  usersPermission: PropTypes.shape({
    move: PropTypes.bool
  }).isRequired,
  resetPassword: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired
};

ExamineesView.defaultProps = {
  availableProducts: undefined,
  moveError: undefined
};

export default ExamineesView;
