import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import AdminIcon from '../../components/AdminIcon';
import AdminIconList from '../../components/AdminIconList';
import ConfirmationModal from '../../components/ConfirmationModal';
import { StaticEapTable } from '../../components/StaticEapTable';
import AssignedProductStatusModal from '../components/AssignedProductStatusModal';
import GenericProductLink from '../../components/GenericProductLink';
import EnterMSDModal from '../../components/EnterMSDModal';
import ReleaseResultsModal from '../../components/ReleaseResultsModal';
import SuppressResultsModal from '../../components/SuppressResultsModal';
import * as ProductStatus from '../../components/ProductStatusFilter';
import EapDisplayDate from '../../components/EapDisplayDate';

export default class AssignedProductsTable extends React.Component {
  constructor() {
    super();
    this.state = {
      showStatusModal: undefined,
      selectedProductToRevoke: undefined,
      showModal: {
        type: '',
        data: {},
      },
    };
  }

  render() {
    const {
      state: {
        showModal,
      },
      props: {
        releaseResults,
        data,
      },
    } = this;
    const columns = [
      {
        Header: 'Unique Test Id',
        accessor: 'id'
      },
      {
        Header: 'Product Name',
        accessor: 'productName'
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => (
          <GenericProductLink
            text={row.original.status}
            assignedProductId={row.original.id}
            baseUrl="/api/user/assigned-product/"
            isAdmin
          />
        ),
        Filter: ProductStatus.Filter,
        filterMethod: ProductStatus.Method

      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        disableFilters: true,
        Cell: ({ row }) => (<EapDisplayDate date={row.original.createdAt} />),
        sortMethod: (a, b) => (moment.utc(a).diff(moment.utc(b)))
      },
      {
        Header: 'Updated',
        accessor: 'updatedAt',
        disableFilters: true,
        Cell: ({ row }) => (<EapDisplayDate date={row.original.updatedAt} />),
        sortMethod: (a, b) => (moment.utc(a).diff(moment.utc(b)))
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => {
          let hasCaps = false;
          if (row && row.original && row.original.configs) {
            hasCaps = (
              row.original.configs.caps
              || row.original.configs.capsp
              || row.original.configs.spac
            );
          }
          return (
            <AdminIconList>
              <AdminIcon
                onClick={() => this.setState({ showStatusModal: row.original.id })}
                icon="fa-info-circle"
                toolTip="Show Status"
              />
              <AdminIcon
                onClick={() => this.setState({ selectedProductToRevoke: row.original.id })}
                icon="fa-times"
                toolTip="Revoke"
              />
              <ConfirmationModal
                modalTitle="REVOKE ASSIGNED PRODUCT"
                showModal={this.state.selectedProductToRevoke === row.original.id}
                messagePrompt={
                  <span>
                    <p>
                    Are you sure you want to revoke assigned product&nbsp;
                      <b>{row.original.productName}</b> for user&nbsp;
                      <b>
                        {row.original.firstName} {row.original.lastName}
                      </b>?
                    </p>
                    <p><b>WARNING: This action cannot be undone.</b></p>
                  </span>
                }
                closeModal={() => this.setState({ selectedProductToRevoke: undefined })}
                confirmAction={() => this.props.revoke(row.original)}
              />
              {hasCaps ?
                <AdminIcon
                  onClick={() => this.setState({ showMSDModal: row.original.id })}
                  icon="fa-plus"
                  toolTip="Add MSD"
                /> : <span />}
              <EnterMSDModal
                showModal={this.state.showMSDModal === row.original.id}
                closeModal={() => this.setState({ showMSDModal: undefined })}
                testId={row.original.id}
                firstName={row.original.firstName}
                lastName={row.original.lastName}
                gender={row.original.gender}
                grade={row.original.grade}
              />
              {
                row && row.original && row.original.configs && row.original.configs.suppressed
                  ? (
                    <AdminIcon
                      onClick={() => {
                        this.setState({ showModal: { type: 'release', data: row.original } });
                      }}
                      icon="fa-share-square-o"
                      toolTip="Release Results"
                    />
                  ) : ''
              }
              { // Specific check for suppressed since not all products have suppressed config
                (row && row.original && row.original.configs && row.original.configs.suppressed === false) ?
                  <SuppressResultsModal
                    selectedProduct={row.original}
                    suppressResults={() => this.props.suppressResults(row.original)}
                  /> : <span />
              }
            </AdminIconList>
          );
        }
      }
    ];
    const additionalColumns = (this.props.forUser) ? [
      {
        Header: 'Group Id',
        accessor: 'groupId'
      },
      {
        Header: 'Group Name',
        accessor: 'groupName',
      },
      {
        Header: 'Organization Id',
        accessor: 'organizationId'
      },
    ] : [
      {
        Header: 'First Name',
        accessor: 'firstName'
      },
      {
        Header: 'Last Name',
        accessor: 'lastName'
      },
      {
        Header: 'Username',
        accessor: 'username'
      }
    ];
    columns.splice(_.findIndex(columns, column => column.accessor === 'productName'), 0, ...additionalColumns);
    let statusModal;
    if (this.state.showStatusModal !== undefined) {
      statusModal = (<AssignedProductStatusModal
        testId={this.state.showStatusModal}
        isOpen
        closeModal={() => { this.setState({ showStatusModal: undefined }); }}
      />);
    }
    return (
      <div className="pure-u-1-1">
        <StaticEapTable
          columns={columns}
          data={data}
        />
        {statusModal}
        {
          showModal.type === 'release'
            ? (
              <ReleaseResultsModal
                firstName={showModal.data.firstName}
                lastName={showModal.data.lastName}
                productName={showModal.data.productName}
                releaseResults={() => releaseResults(showModal.data)}
                closeModal={() => this.setState({ showModal: { type: '', data: {} } })}
              />
            ) : ''
        }
      </div>
    );
  }
}

AssignedProductsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    groupId: PropTypes.number,
    organizationId: PropTypes.string,
    groupName: PropTypes.string,
    productName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    status: PropTypes.string
  })).isRequired,
  revoke: PropTypes.func.isRequired,
  releaseResults: PropTypes.func.isRequired,
  suppressResults: PropTypes.func.isRequired,
};

AssignedProductsTable.defaultProps = {
  groupId: undefined
};
