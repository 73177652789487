import React from 'react';
import PropTypes from 'prop-types';

import ProductLink from '../../components/ProductLink';
import { StaticEapTable } from '../../components/StaticEapTable';
import AutoStart from './AutoStart';
import NoProductsBox from './NoProductsBox';
import * as ProductStatus from '../../components/ProductStatusFilter';

const Products = ({ user }) => {
  const columns = [{
    Header: 'Name',
    accessor: 'product.name'
  }, {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }) => (
      <ProductLink text={row.original.status} assignedProductId={row.original.id} />
    ),
    Filter: ProductStatus.Filter,
    filterMethod: ProductStatus.Method
  }];
  let pageSize = 5;
  if (user.assignedProducts.length > 5) {
    pageSize = 10;
  }

  if (user.assignedProducts.length === 0) {
    return (<NoProductsBox />);
  }
  return (
    <div className="pure-u-1">
      <StaticEapTable
        columns={columns}
        data={user.assignedProducts}
        sorted={[{
          id: 'product.name',
          desc: false
        }]}
        defaultPageSize={pageSize}
      />
      {!user.permissions.administrator && user.assignedProducts.length === 1
        ? <AutoStart assignedProduct={user.assignedProducts[0]} /> : '' }
    </div>
  );
};

Products.propTypes = {
  user: PropTypes.shape({
    assignedProduts: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string
    }))
  }).isRequired
};

Products.defaultProps = {
};

export default Products;
