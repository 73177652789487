import { connect } from 'react-redux';
import _ from 'lodash';

import GroupAdministratorsTable from './components/GroupAdministratorsTable';
import * as AdminsActions from '../../actions/admins';

const mapStateToProps = (state, ownProps) => {
  const groupId = _.parseInt(ownProps.match.params.groupId);
  const admins = _.chain(_.cloneDeep(state.admins.list))
    .filter({ groupId })
    .map((a) => (
      _.merge(
        a,
        _.cloneDeep(_.find(state.user.list, { userId: a.userId }))
      )
    ))
    .value();
  return {
    admins,
    groupId
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { groupId } = ownProps.match.params;
  return {
    updatePermissions: (administrator, newPermissions) => {
      dispatch(AdminsActions.superUpdateAdminPermissions(
        ownProps.match.params.groupId,
        administrator,
        newPermissions
      ));
    },
    superDeleteAdmin: (administrator) => {
      dispatch(AdminsActions.deleteAdmin(groupId, undefined, administrator));
    }
  };
};

const GroupAdministratorsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupAdministratorsTable);

export default GroupAdministratorsTableContainer;
