import React from 'react';
import { httpClient } from '../utils/httpClient';
import { dispatchAlert } from './alert';
import history from '../utils/history';

export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';

export const LOAD_ORGANIZATION_SUPER_SUCCESS = 'LOAD_ORGANIZATION_SUPER_SUCCESS';
export const LOAD_ORGANIZATION_SUPER_FAILURE = 'LOAD_ORGANIZATION_SUPER_FAILURE';
export const UPDATE_ORGANIZATION_SUPER_SUCCESS = 'UPDATE_ORGANIZATION_SUPER_SUCCESS';
export const UPDATE_ORGANIZATION_SUPER_FAILURE = 'UPDATE_ORGANIZATION_SUPER_FAILURE';

export const ORG_FILTER_SUCCESS = 'ORG_FILTER_SUCCESS';

export const ORG_LOAD_GROUPS_SUCCESS = 'ORG_LOAD_GROUPS_SUCCESS';

export const ORG_CLEAR_CACHE = 'ORG_CLEAR_CACHE';

export const createOrganizationFailure = (error) => ({
  type: CREATE_ORGANIZATION_FAILURE,
  payload: { error }
});

export const createOrganization = (newOrgInfo) => (dispatch) => {
  dispatch({ type: CREATE_ORGANIZATION_REQUEST });
  httpClient
    .post('/super/organization', newOrgInfo)
    .then((res) => {
      dispatch({
        type: CREATE_ORGANIZATION_SUCCESS,
        payload: {
          ...newOrgInfo,
          id: res.data.id
        }
      });
      history.push(`/super/organization/${res.data.id}/info`);
    });
};

export const loadOrganizationSuccess = (organizationData) => ({
  type: LOAD_ORGANIZATION_SUPER_SUCCESS,
  payload: { organizationData }
});

export const loadOrganizationFailure = (error) => ({
  type: LOAD_ORGANIZATION_SUPER_FAILURE,
  payload: { error }
});

export function loadOrganizationInfo(sourceOrganizationId) {
  return (dispatch) => {
    httpClient.get(`/super/organization/${sourceOrganizationId}`)
      .then((response) => {
        dispatch(loadOrganizationSuccess(response.data));
      }).catch((err) => {
        dispatch(loadOrganizationFailure(err.response.data));
      });
  };
}

export const updateOrganizationSuperSuccess = (updatedOrganizationInfo) => ({
  type: UPDATE_ORGANIZATION_SUPER_SUCCESS,
  payload: { updatedOrganizationInfo }
});

export const updateOrganizationSuperFailure = (error) => ({
  type: UPDATE_ORGANIZATION_SUPER_FAILURE,
  payload: { error }
});


export function updateOrganizationInfo(sourceOrganizationId, updatedOrganizationInfo) {
  return (dispatch) => {
    dispatch(updateOrganizationSuperSuccess({ id: sourceOrganizationId, ...updatedOrganizationInfo }));
    // swagger doesn't like null for a string???
    const updateVals = { ...updatedOrganizationInfo };
    if (!updateVals.customId) {
      updateVals.customId = '';
    }
    if (!updateVals.notes) {
      delete updateVals.notes;
    }
    if (!updateVals.qboId) {
      delete updateVals.qboId;
    }
    httpClient.put(`/super/organization/${sourceOrganizationId}`, { ...updateVals })
      .then(() => {
        dispatch(dispatchAlert({
          message: <span> Update successful. </span>,
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('ERROR UPDATING ORGANIZATION', err);
        let errorMessage = 'Error updating organization';
        if (err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        dispatch(updateOrganizationSuperFailure(errorMessage));
        dispatch(dispatchAlert({
          message: <span> {errorMessage}. Please refresh page </span>,
          type: 'eap-alert-error'
        }));
      });
  };
}

export const filter = ({ filtered, sorted, limit }) => ((dispatch) => (
  httpClient
    .get('/super/organizations', { params: { filter: filtered, sorted, limit } })
    .then((response) => {
      dispatch({
        type: ORG_FILTER_SUCCESS,
        payload: response.data
      });
    })
    .catch((error) => {
      console.error('ERROR filtering orgizations', error.response.data.message);
    })
));

export const loadGroups = (id) => (dispatch) => {
  httpClient.get(`/super/organization/${id}/groups`)
    .then((response) => {
      dispatch({
        type: ORG_LOAD_GROUPS_SUCCESS,
        payload: response.data.groups
      });
    }).catch((err) => {
      console.error(err);
    });
};
