import { connect } from 'react-redux';

import { deleteAvailableProducts } from '../../actions/availableProducts';

import DeleteProductForm from './components/DeleteProductForm';

const mapStateToProps = (state, ownProps) => ({ ...ownProps });

const mapDispatchToProps = dispatch => ({
  deleteProducts: (availableProductId, groupId) =>
    dispatch(deleteAvailableProducts(availableProductId, groupId))
});

const DeleteProductsFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteProductForm);

export default DeleteProductsFormContainer;
