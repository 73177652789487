/* eslint react/destructuring-assignment:0 jsx-a11y/label-has-associated-control:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import moment from 'moment';

import EapThrone from '../../components/EapThrone';
import FormHeader from '../../components/FormHeader';
import EapButton from '../../components/EapButton';
import StyledForm from '../../components/StyledForm';
import FormInputGroup from '../../components/FormInputGroup';
import EapDatePicker from '../../components/EapDatePicker';
import GenderSelectList from '../../components/GenderSelectList';
import GradeLevelSelectList from '../../components/GradeLevelSelectList';
import AvailableProductSelector from './AvailableProductSelector';
import InlineValidationMessage from '../../components/InlineValidationMessage';
import {
  CoreUserProfileSchema,
  NewPasswordSchema,
  ConfirmPasswordSchema,
  UniqueEmailSchema,
  OptionalEmailSchema,
  UsernameSchema,
  UniqueUsernameSchema
} from '../../signup/components/UserSchema';

export default class AddExamineeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDoesNotHaveEmail: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    this.props.addExaminee(
      this.props.adminGroupId,
      {
        ...values,
        dob: values.dob ? moment(values.dob).format('YYYY-MM-DD') : undefined,
        availableProduct: undefined
      },
      values.availableProduct
    );
  }

  render() {
    const { userDoesNotHaveEmail } = this.state;
    return (
      <EapThrone>
        <FormHeader
          headerText="Add Examinee"
          renderParagraph={() => (
            <>
              Please enter your information below for the examinee. In order to take
              an assessment, the examinee must sign-in using their username and
              password. Fields marked with (<span className="required">*</span>) are
              required.
            </>
          )}
        />
        <div className="pure-u-3-4 form-container">
          <Formik
            validateOnChange
            validateOnBlur
            initialValues={{
              username: '',
              email: '',
              password: '',
              confirmPassword: '',
              firstName: '',
              lastName: '',
              dob: '',
              gender: '',
              grade: '',
              availableProduct: this.props.availableProducts[0] || { id: -1, amount: -1 }
            }}
            validationSchema={CoreUserProfileSchema.concat(yup.object().shape({
              username: UsernameSchema.concat(UniqueUsernameSchema()),
              email: !userDoesNotHaveEmail ? UniqueEmailSchema() : OptionalEmailSchema,
              password: NewPasswordSchema,
              confirmPassword: ConfirmPasswordSchema,
              availableProduct: yup.object().shape({
                amount: yup.number().min(1, 'You are out of this product')
              })
            }))}
            onSubmit={(values) => this.onSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldTouched
            }) => (
              <StyledForm>
                <FormInputGroup required>
                  <label htmlFor="username">USERNAME</label>
                  <Field
                    type="text"
                    name="username"
                  />
                  {touched.username && errors.username && (
                    <InlineValidationMessage message={errors.username} />
                  )}
                </FormInputGroup>
                <FormInputGroup required>
                  <label htmlFor="email">EMAIL</label>
                  <Field
                    disabled={userDoesNotHaveEmail}
                    type="text"
                    name="email"
                  />
                  {touched.email && errors.email && (
                    <InlineValidationMessage message={errors.email} />
                  )}
                  <div>
                    <input
                      type="checkbox"
                      checked={userDoesNotHaveEmail}
                      onChange={() => {
                        this.setState({ userDoesNotHaveEmail: !userDoesNotHaveEmail });
                        setFieldValue('email', '');
                        setFieldTouched('email', false);
                      }}
                    />
                    User does not have email
                  </div>
                </FormInputGroup>
                <FormInputGroup required>
                  <label htmlFor="password">PASSWORD</label>
                  <Field
                    type="password"
                    name="password"
                  />
                  {touched.password && errors.password && (
                    <InlineValidationMessage message={errors.password} />
                  )}
                </FormInputGroup>
                <FormInputGroup required>
                  <label htmlFor="confirmPassword">CONFIRM PASSWORD</label>
                  <Field
                    type="password"
                    name="confirmPassword"
                  />
                  {touched.confirmPassword && errors.confirmPassword && (
                    <InlineValidationMessage message={errors.confirmPassword} />
                  )}
                </FormInputGroup>
                <FormInputGroup required>
                  <label htmlFor="firstName">FIRST NAME</label>
                  <Field
                    type="text"
                    name="firstName"
                  />
                  {touched.firstName && errors.firstName && (
                    <InlineValidationMessage message={errors.firstName} />
                  )}
                </FormInputGroup>
                <FormInputGroup required>
                  <label htmlFor="lastName">LAST NAME</label>
                  <Field
                    type="text"
                    name="lastName"
                  />
                  {touched.lastName && errors.lastName && (
                    <InlineValidationMessage message={errors.lastName} />
                  )}
                </FormInputGroup>
                <FormInputGroup>
                  <label htmlFor="dob">DATE OF BIRTH (MM/DD/YYYY)</label>
                  <EapDatePicker
                    initialDate={values.dob}
                    onDateSelected={(dateVal) => setFieldValue('dob', dateVal)}
                  />
                </FormInputGroup>
                <FormInputGroup>
                  <label htmlFor="gender">GENDER</label>
                  <GenderSelectList />
                </FormInputGroup>
                <FormInputGroup>
                  <label htmlFor="grade">GRADE LEVEL</label>
                  <GradeLevelSelectList />
                </FormInputGroup>
                <FormInputGroup>
                  <label htmlFor="availableProduct">ASSIGN PRODUCT *</label>
                  <Field
                    name="availableProduct"
                  >
                    {({ field, form }) => (
                      <AvailableProductSelector
                        selectedProduct={field.value}
                        availableProducts={this.props.availableProducts}
                        onSelectProduct={(p) => form.setFieldValue(field.name, p)}
                      />
                    )}
                  </Field>
                  {
                    touched.availableProduct
                      && errors.availableProduct
                      && errors.availableProduct.amount
                      && <InlineValidationMessage message={errors.availableProduct.amount} />
                  }
                </FormInputGroup>
                <FormInputGroup>
                  <EapButton
                    isSubmit
                    text="ADD EXAMINEE"
                  />
                  {this.props.addExamineeError && (
                    <InlineValidationMessage text={this.props.addExamineeError} />
                  )}
                </FormInputGroup>
              </StyledForm>
            )}
          </Formik>
        </div>
      </EapThrone>
    );
  }
}

AddExamineeForm.propTypes = {
  adminGroupId: PropTypes.number.isRequired,
  addExaminee: PropTypes.func.isRequired,
  availableProducts: PropTypes.arrayOf(PropTypes.shape({
    adminName: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired
  })),
  addExamineeError: PropTypes.string
};

AddExamineeForm.defaultProps = {
  availableProducts: [],
  addExamineeError: undefined
};
