import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements';

import CheckoutInputElement from './CheckoutInputElement';

import './InjectedStripeForm.css';

class InjectedStripeForm extends React.Component {
  constructor() {
    super();
    this.onCardElementChange = this.onCardElementChange.bind(this);
    this.isCardFormReady = this.isCardFormReady.bind(this);
    this.state = {
      errors: {},
      cardName: '',
      validations: {
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false
      }
    };
  }

  onCardNameChange(e) {
    const name = e.target.value;
    if (name === undefined || name === '') {
      this.props.onStripeTokenDestroyed();
    }
    this.setState({ cardName: e.target.value }, () => {
      const ready = this.isCardFormReady();
      this.props.onValidation(ready);
      if (ready) {
        this.tryCreateStripeToken();
      }
    });
  }

  onCardElementChange(changeObj) {
    const { errors, validations } = this.state;
    const newValidations = Object.assign(
      validations,
      { [changeObj.elementType]: changeObj.complete }
    );
    this.setState({ validations: newValidations });
    if (changeObj.error) {
      this.props.onStripeTokenDestroyed();
      const newErrors = Object.assign(errors, { [changeObj.elementType]: changeObj.error.message });
      this.setState({ errors: newErrors });
    } else {
      const newErrors = Object.assign(errors, { [changeObj.elementType]: undefined });
      this.setState({ errors: newErrors });
    }

    this.tryCreateStripeToken();
  }

  tryCreateStripeToken() {
    const ready = this.isCardFormReady();
    if (ready && !this.state.stripeToken) {
      this.props.stripe
        .createToken()
        .then((payload) => {
          this.props.onCardTokenCreated(payload.token);
          this.props.onValidation(ready);
        }).catch(err => console.error('TODO: Feedback to user: ', err));
    }
  }

  isCardFormReady() {
    const { cardName } = this.state;
    const { cardNumber, cardExpiry, cardCvc } = this.state.validations;
    return cardNumber && cardExpiry && cardCvc && !isEmpty(cardName);
  }

  render() {
    return (
      <div className="pure-u-1">
        <CheckoutInputElement gridClass="pure-u-1">
          <input
            type="text"
            placeholder="NAME ON CARD"
            className="custom-checkout-input"
            onChange={(e) => {
            this.onCardNameChange(e);
            }}
            value={this.state.cardName}
          />
        </CheckoutInputElement>
        <CheckoutInputElement gridClass="pure-u-1-2">
          <CardNumberElement
            placeholder="5555 5555 5555 5555"
            className="custom-checkout-input"
            style={{ complete: { color: 'green' } }}
            onChange={this.onCardElementChange}
            onReady={(stripeElement) => {
              // console.log('ready', stripeElement);
              // TODO: We could give more user more granular
              // feedback for when inputs are truly "ready"
            }}
          />
        </CheckoutInputElement>
        <CheckoutInputElement gridClass="pure-u-1-4">
          <CardExpiryElement
            placeholder="MM/YY"
            style={{ complete: { color: 'green' } }}
            className="custom-checkout-input"
            onChange={this.onCardElementChange}
          />
        </CheckoutInputElement>
        <CheckoutInputElement gridClass="pure-u-1-4">
          <CardCVCElement
            placeholder="CVC"
            style={{ complete: { color: 'green' } }}
            className="custom-checkout-input"
            onChange={this.onCardElementChange}
          />
        </CheckoutInputElement>
        <CheckoutInputElement gridClass="pure-u-1">
          { Object.keys(this.state.errors).map((key) => {
          const message = this.state.errors[key];
          return (message) ? (<div key={`err_${key}`} style={{ color: 'red' }}>{ message }</div>) : '';
        })
        }
        </CheckoutInputElement>
      </div>
    );
  }
}

InjectedStripeForm.propTypes = {
  onValidation: PropTypes.func.isRequired,
  stripe: PropTypes.shape({
    createToken: PropTypes.func.isRequired
  }).isRequired,
  onCardTokenCreated: PropTypes.func.isRequired,
  onStripeTokenDestroyed: PropTypes.func.isRequired
};

export default injectStripe(InjectedStripeForm);
