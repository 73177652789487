import React from 'react';
import _ from 'lodash';
import P from 'bluebird';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';

// import OrganizationFormContent from '../../../components/OrganizationFormContent';
import EapButton from '../../../components/EapButton';
import http from '../../../utils/httpClient';
import ValidationMessage from '../../../components/InlineValidationMessage';

const validate = ({ name, customId }) => ((values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Please enter a name.';
    return errors;
  }
  const proms = [
    http.get(`/organization/exists?name=${encodeURIComponent(values.name)}`)
  ];
  if (values.customId) {
    proms.push(http.get(`/organization/exists?customId=${encodeURI(values.customId)}`));
  }
  return P.all(proms).then((vals) => {
    const takenName = vals[0].data.exists;
    let takenId;
    if (vals[1]) {
      takenId = vals[1].data.exists;
    }
    if (values.name !== name && takenName) {
      errors.name = 'This name is taken.';
    }
    if (values.customId !== customId && takenId) {
      errors.customId = 'This ID is taken.';
    }
    if (!_.isEmpty(errors)) {
      return errors;
    }
    return {};
  }).catch((e) => (e));
});

const OrganizationEditor = ({
  buttonText,
  onSubmit,
  onCancel,
  name,
  customId = '',
  qboId,
  notes
}) => (
  <Formik
    initialValues={{
      name,
      customId,
      qboId,
      notes
    }}
    validate={validate({ name, customId })}
    onSubmit={onSubmit}
  >
    {({ errors, isValid }) => (
      <Form className="pure-form pure-form-aligned eap-grey-form">

        <div className="pure-control-group">
          <label htmlFor="name">Name:</label>
          <Field id="name" type="text" name="name" placeholder="Name" className="" />
          { errors.name ? <ValidationMessage message={errors.name} /> : '' }
        </div>
        <div className="pure-control-group">
          <label htmlFor="customId">Custom ID:</label>
          <Field id="customId" type="text" name="customId" placeholder="Custom ID" />
          { errors.customId ? <ValidationMessage message={errors.customId} /> : '' }
        </div>
        <div className="pure-control-group">
          <label htmlFor="qboId">Quickbooks Id:</label>
          <Field id="qboId" type="number" name="qboId" placeholder="QBO ID" />
        </div>
        <div className="pure-control-group">
          <label htmlFor="notes">Notes:</label>
          <Field id="notes" component="textarea" name="notes" placeholder="Notes" className="pure-u-1-2" />
        </div>
        <div className="pure-controls">
          <EapButton text={buttonText} isSubmit disabled={!isValid} />
          { onCancel ? <EapButton text="Cancel" isSecondary onClick={onCancel} /> : '' }
        </div>
      </Form>
    )}
  </Formik>
);

OrganizationEditor.propTypes = {
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  name: PropTypes.string,
  customId: PropTypes.string,
  qboId: PropTypes.number,
  notes: PropTypes.string
};

OrganizationEditor.defaultProps = {
  buttonText: 'Update',
  onSubmit: () => {},
  onCancel: undefined,
  name: undefined,
  customId: undefined,
  qboId: undefined,
  notes: '',
};

export default OrganizationEditor;
