import {
  RESULTS_LOAD_SUCCESS,
  RESULTS_LOAD_REQUEST,
} from '../actions/results';

import {
  MOVE_EXAMINEE_REQUEST,
  MOVE_EXAMINEE_SUCCESS,
} from '../actions/examinees';

const resultsReducer = (
  state = {
    serverFiltered: [],
    serverTotal: 0,
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case MOVE_EXAMINEE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case MOVE_EXAMINEE_SUCCESS: {
      const {
        destGroup,
      } = action.payload;
      const newServerFiltered = state.serverFiltered.map((ex) => {
        if (ex.userGroupId === action.payload.sourceUserGroupId) {
          return {
            ...ex,
            groupName: destGroup.name,
            adminGroupId: destGroup.id,
            userGroupId: action.payload.destUserGroupId,
          };
        }
        return ex;
      });
      return {
        ...state,
        loading: false,
        serverFiltered: newServerFiltered,
      };
    }
    case RESULTS_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        serverFiltered: action.payload.payload,
        serverTotal: action.payload.total,
      };
    }
    case RESULTS_LOAD_REQUEST: {
      return {
        ...state,
        results: {
          loading: true,
        },
      };
    }
    default:
      return state;
  }
};

export default resultsReducer;
