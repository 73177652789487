import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import EapModal from '../../../components/EapModal';
import EapButton from '../../../components/EapButton';
import SectionHeader from '../../components/SectionHeader';
import OrganizationEditor from './OrganizationEditor';
import OrganizationGroups from '../../commonTables/OrganizationGroups';
import AddGroup from '../../../components/AddGroup';

export default class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrgToEdit: undefined
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.load();
  }

  render() {
    const {
      organization,
      updateOrganizationInfo: update,
      groups,
      superAddGroup
    } = this.props;

    const {
      selectedOrgToEdit
    } = this.state;

    return (
      <div>
        <SectionHeader title="Organization Information" />
        <EapModal
          modalTitle="Edit Organization Information"
          showModal={
            selectedOrgToEdit === organization.id
          }
        >
          <OrganizationEditor
            {...organization}
            onCancel={() => this.setState({ selectedOrgToEdit: undefined })}
            onSubmit={(values) => {
              this.setState({ selectedOrgToEdit: undefined });
              update(values);
            }}
          />
        </EapModal>
        <table className="pure-table pure-table-bordered">
          <tbody>
            <tr>
              <td><b>Name:</b></td>
              <td>
                <div> {organization.name} </div>
              </td>
            </tr>
            <tr>
              <td><b>Custom Id:</b></td>
              <td>
                <div> {organization.customId} </div>
              </td>
            </tr>
            <tr>
              <td><b>QBO ID:</b></td>
              <td>
                {organization.qboId ? <a href={`https://qbo.intuit.com/app/customerdetail?nameId=${organization.qboId}`} target="__blank">{organization.qboId}</a> : ''}
              </td>
            </tr>
            <tr>
              <td><b>Notes:</b></td>
              <td>
                <div> {organization.notes} </div>
              </td>
            </tr>
            <tr>
              <td><b>Id:</b></td>
              <td>
                <div> {organization.id} </div>
              </td>
            </tr>
            <tr>
              <td><b>Date Created:</b></td>
              <td>
                <div> {organization.createdAt} </div>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <EapButton
          text="Edit"
          onClick={() => this.setState({ selectedOrgToEdit: organization.id })}
        />
        <OrganizationGroups tableData={groups} />
        <h3> Add New Group </h3>
        <AddGroup
          groups={
            _.map(groups, (group) => ({ groupId: group.id, name: group.name })) || []
          }
          requireAdmins={false}
          submit={(val) => {
            superAddGroup(val);
          }}
        />
      </div>
    );
  }
}

Organization.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string
  })),
  updateOrganizationInfo: PropTypes.func.isRequired,
  unloaded: PropTypes.bool.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number,
    customId: PropTypes.string,
    qboId: PropTypes.number,
    name: PropTypes.string,
    notes: PropTypes.string,
    hasSubscription: PropTypes.bool,
    subscriptionStartDate: PropTypes.string,
    renewalDate: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  superAddGroup: PropTypes.func.isRequired
};

Organization.defaultProps = {
  organization: undefined,
  groups: undefined
};
