import { connect } from 'react-redux';
import Group from './components/Group';
import { getGroupInfo } from '../actions/group';
import extractAndSortPermissions from '../utils/sortPermissions';

const mapStateToProps = (state, ownProps) => {
  const groupId = parseInt(ownProps.match.params.id, 10);
  const selectedGroup = state.group.groupsById[groupId];
  if (!state.user.permissions.administrator) {
    return {
      unauthorized: true
    };
  }
  if (state.group.message) {
    return {
      unauthorized: true,
      group: {
        message: state.group.message
      }
    };
  }
  if (!selectedGroup) {
    return {
      unauthorized: false,
      user: state.user,
      group: undefined
    };
  }
  return {
    unauthorized: false,
    user: state.user,
    group: {
      ...selectedGroup,
      permissions: extractAndSortPermissions(state.user.adminGroups, groupId)
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadGroupData: (id) => {
    dispatch(getGroupInfo(id));
  }
});

const GroupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Group);

export default GroupContainer;
