import React from 'react';

const SuperHome = () => (
  <div>
    <h1>Welcome Super Admin</h1>
    <p>Some sweet looking graphs will go here.</p>
  </div>
);

export default SuperHome;
