import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AdminIcon from '../../../components/AdminIcon';
import ProductTransferDialog from '../../../components/ProductTransferDialog';

class AdminTransferProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
    };
  }

  render() {
    if (this.props.allowedGroups.length === 0) {
      return (<span />);
    }
    return (
      <span>
        <AdminIcon
          onClick={() => this.setState({ showDialog: true })}
          icon="fa-random"
          toolTip="Transfer Products"
        />
        <ProductTransferDialog
          dialogTitle="TRANSFER PRODUCTS"
          groups={this.props.allowedGroups}
          availableAmount={this.props.amount}
          showDialog={this.state.showDialog}
          handleClose={() => this.setState({ showDialog: false })}
          transferProducts={(amount, destinationGroupId) => {
            // special for super admin
            this.props.transferProducts(
              this.props.availableProductId,
              this.props.groupId,
              amount,
              destinationGroupId
            );
            this.setState({ showDialog: false });
            this.props.onProductsTransferred(amount);
          }}
        />
      </span>
    );
  }
}

AdminTransferProductModal.propTypes = {
  transferProducts: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  availableProductId: PropTypes.number.isRequired,
  onProductsTransferred: PropTypes.func,
  allowedGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
  groupId: PropTypes.number.isRequired
};

AdminTransferProductModal.defaultProps = {
  onProductsTransferred: () => {}
};

export default AdminTransferProductModal;
