import React from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useBlockLayout,
  useResizeColumns
} from 'react-table';
import { v4 as uuid } from 'uuid';

import EapSelectList from './EapSelectList';
import {
  FULL_STRING,
  VIEW_STRING,
  RECOMMENDED_STRING,
  CUSTOM_STRING
} from './PermissionSelector';
import './StaticEapTable.css';

export const DefaultFilter = ({
  column
}) => (
  <input
    placeholder="Search"
    value={column.filterValue || ''}
    onChange={(e) => column.setFilter(e.target.value || undefined)}
  />
);

DefaultFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.node,
    setFilter: PropTypes.func
  }).isRequired
};

export const PermissionFilter = ({ column }) => (
  <EapSelectList
    id={column.id}
    className="permission-selector"
    onChange={(e) => { column.setFilter(e.target.value); }}
    value={column.filterValue}
    choices={[
      { value: '', label: '' },
      { value: FULL_STRING, label: FULL_STRING },
      { value: RECOMMENDED_STRING, label: RECOMMENDED_STRING },
      { value: VIEW_STRING, label: VIEW_STRING },
      { value: CUSTOM_STRING, label: CUSTOM_STRING }
    ]}
  />
);

PermissionFilter.propTypes = {
  column: PropTypes.node.isRequired
};

const renderColumnSort = (column) => {
  if (!column.canSort) {
    return '';
  }
  if (!column.isSorted) {
    return (<span className="header-arrow fa fa-arrow-circle-right" />);
  }
  if (column.isSortedDesc) {
    return (<span className="header-arrow fa fa-arrow-circle-down" />);
  }
  return <span className="header-arrow fa fa-arrow-circle-up" />;
};

const renderColumnFilter = (column) => {
  if (!column.canFilter) {
    return '';
  }
  return (column.render('Filter'));
};

const renderPaddedPage = (page, pageSize, headerGroups, prepareRow) => {
  const renderedRows = page.map(
    (row) => {
      prepareRow(row);
      return (
        <tr {...row.getRowProps()}>
          {row.cells.map((cell) => (
            <td {...cell.getCellProps()}>
              {cell.value === null ? '' : cell.render('Cell')}
            </td>
          ))}
        </tr>
      );
    }
  );
  // TODO: Make this dynamic styles
  const paddedCells = headerGroups[0].headers.map((column) => {
    const sty = {
      display: 'inline-block',
      boxSizing: 'border-box',
      width: column.width
    };
    return (
      <td key={`emptyCol_${uuid()}`} style={sty} />
    );
  });
  const sty2 = {
    display: 'flex'
  };
  for (let i = page.length; i < pageSize; i += 1) {
    renderedRows.push(<tr key={`emptyRow_${uuid()}`} style={sty2}>{paddedCells}</tr>);
  }
  return renderedRows;
};

export const StaticEapTable = ({ columns = [], data }) => {
  const [showFilter, setShowFilter] = React.useState(false);
  const [width, setWidth] = React.useState(100);
  const ref = React.useRef(null);

  const defaultColumn = {
    Filter: DefaultFilter
  };

  // register window listener for resizing
  React.useEffect(() => {
    const handleResize = () => {
      setWidth(ref.current.getBoundingClientRect().width);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  // evenly compute the width of each column
  const cols = React.useMemo(() => {
    let w = width / columns.length;
    if (w < 100) { w = 100; }
    return columns.map((c) => ({
      width: w,
      ...c
    }));
  }, [ref.current, width, columns]);

  const instance = useTable({
    columns: cols,
    data,
    defaultColumn,
    autoResetFilters: false,
    autoResetSortBy: false,
  }, useFilters, useSortBy, usePagination, useBlockLayout, useResizeColumns);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    state: { pageIndex, pageSize },
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canNextPage,
    canPreviousPage,
    setAllFilters,
  } = instance;

  const [currPage, setCurrPage] = React.useState(pageIndex + 1);

  // Page Persist on props update
  React.useEffect(() => {
    if (pageCount === 0) {
      return;
    }
    if (currPage > pageCount) {
      setCurrPage(currPage - 1);
      gotoPage(currPage - 2);
    } else {
      gotoPage(currPage - 1);
    }
  }, [data]);

  React.useEffect(() => {
    if (!showFilter) {
      setAllFilters([]);
      setCurrPage(1);
    }
  }, [showFilter]);

  const pageOptions = [
    5,
    10,
    20,
    25,
    50,
    100
  ];

  return (
    <div className="eap-table">
      <button
        className="filter-button"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setShowFilter(!showFilter);
        }}
      >
        <span className={`fa ${showFilter ? 'fa-search-minus' : 'fa-search-plus'}`} />
        Filter/Search
        <span className="screen-reader"> the following table:</span>
      </button>

      <table {...getTableProps()} ref={ref}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="text-header" {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header').toUpperCase()}
                    &nbsp;
                    {renderColumnSort(column)}
                  </div>
                  <div {...column.getResizerProps()} className="resizer" />
                </th>
              ))}
            </tr>
          ))}
          {showFilter ? headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div className="filter-container">
                    {renderColumnFilter(column)}
                  </div>
                  <div {...column.getResizerProps()} className="resizer" />
                </th>
              ))}
            </tr>
          )) : undefined}
        </thead>
        <tbody {...getTableBodyProps()}>
          {renderPaddedPage(page, pageSize, headerGroups, prepareRow)}
        </tbody>
      </table>
      <div className="eap-table-footer">
        <div>
          <select
            value={pageSize}
            onChange={(e) => {
              setCurrPage(pageIndex + 1);
              setPageSize(Number(e.target.value));
            }}
          >
            {pageOptions.map((pZ) => (
              <option key={pZ} value={pZ}>
                {`Show ${pZ}`}
              </option>
            ))}
          </select>
          <div className="eap-table-page-display">
            <span>
              Page
              <span> </span>
              <input
                type="text"
                value={currPage}
                onChange={(e) => {
                  setCurrPage(e.target.value);
                  const pageNum = e.target.value ? Number(e.target.value) : '';
                  if (pageNum > -1) {
                    gotoPage(pageNum - 1);
                  }
                }}
              />
              {` of ${pageCount}`}
            </span>
            <button
              type="button"
              onClick={() => {
                setCurrPage(pageIndex);
                previousPage();
              }}
              disabled={!canPreviousPage}
            >
              <span className="fa fa-arrow-left" />
            </button>
            <button
              type="button"
              onClick={() => {
                setCurrPage(pageIndex + 2);
                nextPage();
              }}
              disabled={!canNextPage}
            >
              <span className="fa fa-arrow-right" />
            </button>
          </div>
        </div>
        <div className="totalRows">
          <small><em>{`(Total rows: ${rows.length})`}</em></small>
        </div>
      </div>
    </div>
  );
};

StaticEapTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    Header: PropTypes.string,
    accessor: PropTypes.string
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.object)
};

StaticEapTable.defaultProps = {
  data: []
};

export default StaticEapTable;
