/* eslint jsx-a11y/label-has-associated-control:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as yup from 'yup';
import { Formik, Field } from 'formik';

import InlineValidationMessage from '../../../components/InlineValidationMessage';
import EapButton from '../../../components/EapButton';
import EapDatePicker from '../../../components/EapDatePicker';
import EapThrone from '../../../components/EapThrone';
import SensibleContainer from '../../../components/SensibleContainer';
import FormInputGroup from '../../../components/FormInputGroup';
import StyledForm from '../../../components/StyledForm';
import GradeLevelSelectList from '../../../components/GradeLevelSelectList';
import GenderSelectList from '../../../components/GenderSelectList';
import { scrubUserData } from '../../../utils/FormsHelper';

import {
  CoreUserProfileSchema,
  ConfirmPasswordSchema,
  UsernameSchema,
  UniqueUsernameSchema,
  UniqueEmailSchema,
  RequiredEmailSchema
} from '../../../signup/components/UserSchema';

export default class UserEditor extends Component {
  constructor(props) {
    super(props);
    const initialEmail = props.userInfo.email;
    this.state = {
      userHasNoEmail: _.isEmpty(initialEmail)
    };
  }

  render() {
    const {
      props: {
        userInfo,
        closeModal,
      },
      state: {
        userHasNoEmail,
      },
    } = this;

    const user = _.pick(userInfo, [
      'username', 'email', 'firstName', 'lastName', 'gender', 'dob', 'grade', 'isActive'
    ]);

    const initialValues = {
      ...user,
      gender: _.isEmpty(user.gender) ? undefined : user.gender,
      // Need to handle date special here in editing forms where date already exists
    };

    const emailSchema = userHasNoEmail
      ? undefined
      : RequiredEmailSchema.concat(UniqueEmailSchema(initialValues.email));

    const formSchema = CoreUserProfileSchema.concat(yup.object().shape({
      username: UsernameSchema.concat(UniqueUsernameSchema(initialValues.username)),
      email: userHasNoEmail ? undefined : emailSchema,
      confirmPassword: ConfirmPasswordSchema,
    }));

    return (
      <SensibleContainer>
        <EapThrone className="pure-u-1-1">
          <h1>Signin Information</h1>
          <div className="">
            <Formik
              validateOnChange
              validateOnBlur
              initialValues={initialValues}
              validationSchema={formSchema}
              onSubmit={(updatedValues) => {
                const {
                  props: {
                    userId,
                    updateUserInfo,
                  },
                } = this;
                const scrubbed = scrubUserData(updatedValues);
                closeModal();
                updateUserInfo(userId, scrubbed);
              }}
            >
              {({
                values, errors, touched, isValid, setFieldValue, setFieldTouched
              }) => (
                <StyledForm>
                  <FormInputGroup required>
                    <label htmlFor="username">USERNAME</label>
                    <Field
                      type="text"
                      name="username"
                    />
                    {
                      touched.username
                        && errors.username
                        && <InlineValidationMessage message={errors.username} />
                    }
                  </FormInputGroup>
                  <FormInputGroup required={userHasNoEmail}>
                    <label htmlFor="email">EMAIL</label>
                    <Field
                      disabled={userHasNoEmail}
                      type="text"
                      name="email"
                    />
                    {
                      touched.email
                        && errors.email
                        && <InlineValidationMessage message={errors.email} />
                    }
                    <div>
                      <label htmlFor="userDoesNotHaveEmail">
                        <input
                          type="checkbox"
                          name="userDoesNotHaveEmail"
                          checked={userHasNoEmail}
                          onChange={() => {
                            this.setState({ userHasNoEmail: !userHasNoEmail });
                            setFieldValue('email', '');
                            setFieldTouched('email', false);
                          }}
                        />
                        User does not have email
                      </label>
                    </div>
                  </FormInputGroup>
                  <FormInputGroup required>
                    <label htmlFor="password">PASSWORD</label>
                    <Field
                      type="password"
                      name="password"
                    />
                    {touched.password && errors.password
      && <InlineValidationMessage message={errors.password} />}
                  </FormInputGroup>
                  <FormInputGroup required>
                    <label htmlFor="confirmPassword">CONFIRM PASSWORD</label>
                    <Field
                      type="password"
                      name="confirmPassword"
                    />
                    {touched.confirmPassword && errors.confirmPassword
      && <InlineValidationMessage message={errors.confirmPassword} />}
                  </FormInputGroup>
                  <FormInputGroup required>
                    <label htmlFor="firstName">FIRST NAME</label>
                    <Field
                      type="text"
                      name="firstName"
                    />
                    {touched.firstName && errors.firstName
      && <InlineValidationMessage message={errors.firstName} />}
                  </FormInputGroup>
                  <FormInputGroup required>
                    <label htmlFor="lastName">LAST NAME</label>
                    <Field
                      type="text"
                      name="lastName"
                    />
                    {touched.lastName && errors.lastName
      && <InlineValidationMessage message={errors.lastName} />}
                  </FormInputGroup>
                  <FormInputGroup>
                    <label htmlFor="dob">
                      DATE OF BIRTH (MM/DD/YYYY)
                    </label>
                    <EapDatePicker
                      initialDate={values.dob}
                      onDateSelected={(newDate) => setFieldValue('dob', newDate)}
                    />
                  </FormInputGroup>
                  <FormInputGroup>
                    <label htmlFor="gender">GENDER</label>
                    <GenderSelectList />
                  </FormInputGroup>
                  <FormInputGroup>
                    <label htmlFor="grade">
                      GRADE LEVEL
                    </label>
                    <GradeLevelSelectList />
                  </FormInputGroup>
                  <div />
                  <FormInputGroup>
                    <label htmlFor="isActive">ACTIVE USER</label>
                    <Field
                      name="isActive"
                      component="select"
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Field>
                  </FormInputGroup>
                  <div />
                  <FormInputGroup>
                    <EapButton
                      disabled={!isValid}
                      text="UPDATE"
                      isSubmit
                    />
                    <EapButton
                      isSecondary
                      onClick={closeModal}
                      text="CANCEL"
                    />
                  </FormInputGroup>
                </StyledForm>
              )}
            </Formik>
          </div>
        </EapThrone>
      </SensibleContainer>
    );
  }
}

UserEditor.propTypes = {
  closeModal: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dob: PropTypes.string,
    grade: PropTypes.number,
    gender: PropTypes.string,
    isActive: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
  }).isRequired,
  updateUserInfo: PropTypes.func.isRequired,
};
