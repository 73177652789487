/* eslint no-underscore-dangle: 0 */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { StaticEapTable, DefaultFilter, PermissionFilter } from '../../components/StaticEapTable';
import AdminIcon from '../../components/AdminIcon';
import AdminIconList from '../../components/AdminIconList';
import './GroupsTable.css';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({
      row: {
        original: {
          groupId,
          name
        }
      }
    }) => (
      <Link to={`/group/${groupId}/information`}>
        {name}
      </Link>
    ),
    Filter: DefaultFilter
  },
  {
    Header: 'Description',
    accessor: 'description',
    Filter: DefaultFilter
  },
  {
    Header: 'Product Count',
    Cell: ({ row }) => {
      const products = row.original.availableProducts;
      const { groupId } = row.original;
      const count = products.reduce((acc, product) => product.amount + acc, 0);
      if (products.length === 0) {
        return `${count}`;
      }
      const denominator = products.length > 1 ? 'many' : _.first(products).product.name;

      return (
        <Link to={`/group/${groupId}/products`}>
          {`${count} of ${denominator}`}
        </Link>
      );
    },
    accessor: 'availableProducts',
    disableFilters: true,
    sortType: (a, b) => {
      const aAmount = a.original.availableProducts.reduce((acc, a1) => a1.amount + acc, 0);
      const bAmount = b.original.availableProducts.reduce((acc2, b1) => b1.amount + acc2, 0);
      if (aAmount > bAmount) {
        return 1;
      }
      if (aAmount < bAmount) {
        return -1;
      }
      return 0;
    },
  },
  {
    Header: 'Permission',
    accessor: 'permissionLabel',
    Filter: PermissionFilter
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      const baseUrl = `/group/${row.original.groupId}`;
      return (
        <div className="groups-table-actions">
          <AdminIconList>
            <AdminIcon
              url={`${baseUrl}/information`}
              icon="fa-info-circle"
              toolTip="Information"
            />
            <AdminIcon
              url={`${baseUrl}/products`}
              icon="fa-cubes"
              toolTip="Products"
              permission="per_products_view"
              permissions={row.original}
            />
            <AdminIcon
              url={`${baseUrl}/results`}
              icon="fa-bar-chart"
              toolTip="Results"
              permission="per_users_view"
              permissions={row.original}
            />
            <AdminIcon
              url={`${baseUrl}/examinees`}
              icon="fa-users"
              toolTip="Examinees"
              permission="per_users_view"
              permissions={row.original}
            />
            <AdminIcon
              url={`${baseUrl}/administrators`}
              icon="fa-bank"
              toolTip="Administrators"
              permission="per_admin_view"
              permissions={row.original}
            />
            {/*
            <AdminIcon
              url={`${baseUrl}/notifications`}
              icon="fa-bell"
              toolTip="Notifications"
            />
            */}
          </AdminIconList>
        </div>
      );
    }
  }
];

const GroupsTable = ({ groups }) => (
  <StaticEapTable
    columns={columns}
    data={groups}
  />
);

GroupsTable.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default GroupsTable;
