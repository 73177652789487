import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Password from './Password';
import ConfirmPassword from './ConfirmPassword';
import './Form.css';

export default class PasswordAndConfirm extends Component {
  constructor() {
    super();
    this.state = {
      passwordMatch: true
    };
    this.checkAndUpdatePassword = this.checkAndUpdatePassword.bind(this);
  }

  checkAndUpdatePassword(passwordInfo) {
    const password = document.getElementById(this.props.passwordKeyName).value;
    const confirmPassword = document.getElementById(this.props.confirmKeyName).value;
    let { isValid } = passwordInfo;
    if (password === confirmPassword) {
      this.setState({
        passwordMatch: true
      });
    } else {
      isValid = false;
      this.setState({
        passwordMatch: false
      });
    }
    if (!this.props.required && passwordInfo.value === '') {
      this.props.update({
        isValid: password === confirmPassword,
        keyName: this.props.passwordKeyName,
        value: undefined
      });
    } else {
      this.props.update({
        isValid,
        keyName: this.props.passwordKeyName,
        value: passwordInfo.value
      });
    }
  }
  render() {
    return (
      <div className="pure-u-1-1">
        <Password
          update={this.checkAndUpdatePassword}
          keyName={this.props.passwordKeyName}
          formGroupStyle={this.props.formGroupStyle}
          confirmKeyName={this.props.confirmKeyName}
          required={this.props.required}
        />
        <ConfirmPassword
          update={this.checkAndUpdatePassword}
          keyName={this.props.confirmKeyName}
          passwordMatch={this.state.passwordMatch}
          formGroupStyle={this.props.formGroupStyle}
          required={this.props.required}
        />
      </div>
    );
  }
}

PasswordAndConfirm.propTypes = {
  update: PropTypes.func.isRequired,
  passwordKeyName: PropTypes.string,
  confirmKeyName: PropTypes.string,
  formGroupStyle: PropTypes.string,
  required: PropTypes.bool
};

PasswordAndConfirm.defaultProps = {
  passwordKeyName: 'password',
  confirmKeyName: 'confirm-password',
  formGroupStyle: 'pure-u-lg-1-2 pure-u-md-1-2 pure-u-1',
  required: undefined
};
