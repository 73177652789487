import React from 'react';
import PropTypes from 'prop-types';

const messagePropTypes = {
  emailAddress: PropTypes.string.isRequired
};
export const PasswordResetSuccessMessage = ({ emailAddress }) => (
  <span>Password reset request sent to <b>{emailAddress}</b></span>
);

PasswordResetSuccessMessage.propTypes = messagePropTypes;


export const PasswordResetNotFoundMessage = ({ emailAddress }) => (
  <span>No account could be found for that email: <b>{emailAddress}</b></span>
);

PasswordResetNotFoundMessage.propTypes = messagePropTypes;

export const PasswordResetMultipleAccountMessage = () => (
  <span>
    This email is associated to more than one account. Please contact
    EdITS support@edits.net for assistance.
  </span>
);
