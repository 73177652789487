import React from 'react';
import PropTypes from 'prop-types';

import SensibleContainer from '../../components/SensibleContainer';

import './ExamineeView.css';

const ExamineeView = ({ children }) => (
  <div className="pure-u-1">
    <SensibleContainer>
      <div className="examinee-container pure-u-1">
        <h2><span className="fa fa-cubes" /> Your Products</h2>
        { children }
      </div>
    </SensibleContainer>
  </div>
);

ExamineeView.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};


export default ExamineeView;
