import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as validator from '@edits/eap-signup-validator';

import EapStaticAlert from '../../components/EapStaticAlert';
import EapButton from '../../components/EapButton';

export default class PasswordUpdate extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.isValid = this.isValid.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      message: props.message
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.message !== nextProps.message) {
      this.setState({ message: nextProps.message });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const validationMessage = validator.validateSingleInput('password', this.state.newPassword);
    if (!_.isEmpty(validationMessage)) {
      this.setState({ message: validationMessage[0] });
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({ message: 'Passwords do not match.' });
    } else {
      const hash = _.last(this.props.location.pathname.split('/'));
      this.props.updatePassword(this.state.newPassword, hash);
    }
  }

  isValid() {
    if (!_.isEmpty(this.state.newPassword) && !_.isEmpty(this.state.confirmPassword)) {
      return this.state.newPassword === this.state.confirmPassword;
    }
    return false;
  }

  render() {
    const resetExpired = (
      <div>
        This reset link is no longer valid.
        Click <a href="/reset-password">here</a> to request another
        password reset.
      </div>
    );
    return (
      <div className="password-reset-section">
        <EapStaticAlert>
          {this.props.expired ? resetExpired : this.state.message}
        </EapStaticAlert>
        <p>
          Please enter your new password, and then retype it just to be sure.
        </p>
        <div className="pure-form pure-form-stacked eap-grey-form">
          <form className="">
            <label htmlFor="username-input" className="">
            NEW PASSWORD
            </label>
            <input
              type="password"
              className="pure-input pure-input-1"
              id="new-password-input"
              onChange={e => this.setState({ newPassword: e.target.value })}
            />
            <label htmlFor="username-input" className="">
            CONFIRM NEW PASSWORD
            </label>
            <input
              type="password"
              className="pure-input pure-input-1"
              id="confirm-new-password-input"
              onChange={e => this.setState({ confirmPassword: e.target.value })}
            />
            <EapButton
              isSubmit
              optionalClass="recover-password-btn"
              text="UPDATE PASSWORD"
              onClick={e => this.handleSubmit(e)}
            />
          </form>
        </div>
      </div>
    );
  }
}

PasswordUpdate.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  message: PropTypes.string,
  updatePassword: PropTypes.func.isRequired,
  expired: PropTypes.bool.isRequired
};

PasswordUpdate.defaultProps = {
  message: undefined
};

