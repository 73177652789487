import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import EapOption from './EapOption';
import './EapSelectList.css';

const EapSelectList = ({
  id,
  name,
  value,
  disabled,
  onChange,
  className,
  choices,
  style
}) => (
  <select
    id={id}
    name={name}
    value={value}
    disabled={disabled}
    style={style}
    onChange={(e) => { onChange(e); }}
    className={
        classnames(
          'pure-input',
          'eap-dropdown-component',
          className || 'pure-u-11-12',
          className
        )
      }
  >
    {choices && choices.map((choice) => (
      <EapOption
        key={`${id}_${choice.key || choice.value}`}
        choice={choice}
      />
    ))}
  </select>
);

EapSelectList.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }))
};

EapSelectList.defaultProps = {
  disabled: false,
  className: undefined,
  choices: []
};

export default EapSelectList;
