import { httpClient } from '../utils/httpClient';

export const SQUARE_SPACE_ERROR = 'SQUARE_SPACE_ERROR';

export const SQUARE_SPACE_INIT_START = 'SQUARE_SPACE_INIT';
export const SQUARE_SPACE_INIT_INSYNC = 'SQUARE_SPACE_INIT_INSYNC';
export const SQUARE_SPACE_INIT_OAUTH = 'SQUARE_SPACE_OAUTH';

export const SQUARE_SPACE_OAUTH_START = 'SQUARE_SPACE_OAUTH_START';
export const SQUARE_SPACE_OAUTH_SUCCESS = 'SQUARE_SPACE_OAUTH_SUCCESS';

export const SQUARE_SPACE_HOOKS_REQUEST = 'SQUARE_SPACE_HOOKS_REQUEST';
export const SQUARE_SPACE_HOOKS_SUCCESS = 'SQUARE_SPACE_HOOKS_SUCCESS';

export const refreshToken = () => ((dispatch) => {
  httpClient
    .delete('/squarespace')
    .then((res) => {
      dispatch({
        type: SQUARE_SPACE_INIT_OAUTH,
        payload: {
          uri: res.data.redirect
        }
      });
    }).catch((err) => console.error('Error on Squarespace token delete', err));
});

export const init = () => ((dispatch) => {
  dispatch({ type: SQUARE_SPACE_INIT_START });
  httpClient
    .get('/squarespace')
    .then((res) => {
      switch (res.data.state) {
        case 'insync':
          dispatch({ type: SQUARE_SPACE_INIT_INSYNC });
          break;
        case 'oauth':
          dispatch({
            type: SQUARE_SPACE_INIT_OAUTH,
            payload: {
              uri: res.data.redirect
            }
          });
          break;
        default:
          throw new Error('Error');
      }
    })
    .catch((e) => {
      dispatch({
        type: SQUARE_SPACE_ERROR,
      });
      console.log(e);
    });
});

export function saveToken(data) {
  return (dispatch) => {
    dispatch({ type: SQUARE_SPACE_OAUTH_START });
    httpClient
      .put('/squarespace', data)
      .then(() => {
        dispatch({
          type: SQUARE_SPACE_OAUTH_SUCCESS
        });
      }).catch(() => {
        console.error('ERROR on Squarespace save auth');
        dispatch({
          type: SQUARE_SPACE_ERROR
        });
      });
  };
}

export const getHooks = () => ((dispatch) => {
  dispatch({ type: SQUARE_SPACE_HOOKS_REQUEST });
  httpClient
    .get('/squarespace/hooks')
    .then(({ data }) => {
      dispatch({
        type: SQUARE_SPACE_HOOKS_SUCCESS,
        payload: {
          hooks: data.webhookSubscriptions
        },
      });
    })
    .catch((e) => {
      console.log(e);
    });
});

export const setHooks = ({ uri }) => ((dispatch) => {
  dispatch({ type: SQUARE_SPACE_HOOKS_REQUEST });
  httpClient
    .put('/squarespace/hooks', { uri })
    .then(({ data }) => {
      dispatch({
        type: SQUARE_SPACE_HOOKS_SUCCESS,
        payload: {
          hooks: data.webhookSubscriptions
        },
      });
    });
});

export const removeHook = ({ id }) => ((dispatch) => {
  dispatch({ type: SQUARE_SPACE_HOOKS_REQUEST });
  httpClient
    .delete('/squarespace/hooks', { data: { id } })
    .then(({ data }) => {
      dispatch({
        type: SQUARE_SPACE_HOOKS_SUCCESS,
        payload: {
          hooks: data.webhookSubscriptions
        },
      });
    });
});
