import { httpClient } from '../utils/httpClient';

export const RESULTS_LOAD_REQUEST = 'RESULTS_LOAD_REQUEST';
export const RESULTS_LOAD_SUCCESS = 'RESULTS_LOAD_SUCCESS';
export const RESULTS_LOAD_FAILURE = 'RESULTS_LOAD_FAILURE';

export const filterResults = (params = {}) => (
  (dispatch) => {
    dispatch({
      type: RESULTS_LOAD_REQUEST,
      payload: {},
    });
    return httpClient.get('/results', { params }).then((res) => {
      dispatch({
        type: RESULTS_LOAD_SUCCESS,
        payload: res.data,
      });
    });
  }
);
