import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../components/ConfirmationModal';
import AdminIcon from '../../components/AdminIcon';

class AdminInviteRevokeModal extends Component {
  constructor() {
    super();
    this.state = {
      openModal: false
    };
  }
  render() {
    return (
      <span>
        <AdminIcon
          onClick={() => this.setState({ openModal: true })}
          icon="fa-times"
          toolTip="Revoke"
        />
        <ConfirmationModal
          modalTitle="REVOKE ADMIN INVITE"
          showModal={this.state.openModal}
          messagePrompt={
            <p>
              Are you sure you want to revoke
              <b> {this.props.selectedAdminInvite.email} </b>
              admin invite?
            </p>
          }
          closeModal={() => this.setState({ openModal: false })}
          confirmAction={
            () => this.props.revokeInvite(this.props.selectedAdminInvite)
          }
        />
      </span>
    );
  }
}

export default AdminInviteRevokeModal;

AdminInviteRevokeModal.propTypes = {
  selectedAdminInvite: PropTypes.shape({
    id: PropTypes.number,
    senderUserId: PropTypes.number,
    email: PropTypes.string,
    per_users_view: PropTypes.bool,
    per_users_invite: PropTypes.bool,
    per_users_move: PropTypes.bool,
    per_products_view: PropTypes.bool,
    per_products_transfer: PropTypes.bool,
    per_products_assign: PropTypes.bool,
    per_admin_view: PropTypes.bool,
    per_admin_invite: PropTypes.bool,
    per_admin_remove: PropTypes.bool,
    per_admin_edit: PropTypes.bool,
    per_create: PropTypes.bool,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string
  }).isRequired,
  revokeInvite: PropTypes.func.isRequired
};
