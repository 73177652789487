import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SensibleContainer from './SensibleContainer';
import './NoRoute.css';

const NoRoute = ({ match }) => (
  <div className="flex-column">
    <div className="pure-g">
      <div className="pure-u-sm-1-5 pure-u-1" />
      <div className="pure-u-sm-3-5 pure-u-1">
        <SensibleContainer>
          <div className="pure-u-1 no-route-content">
            <p>
              No Route found for {match.url}.
            </p>
            <p>
              To log into EAP <Link to="/signin">click here</Link>.
            </p>
          </div>
        </SensibleContainer>
      </div>
    </div>
  </div>
);

NoRoute.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }).isRequired
};

export default NoRoute;
