import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { StaticEapTable } from '../../components/StaticEapTable';
import ResourceLink from '../../components/ResourceLink';

const SuperGroups = ({ type, tableData }) => {
  const columns = [
    {
      Header: 'Unique Id',
      accessor: 'id'
    },
    {
      Header: 'Group Id',
      accessor: 'groupId',
      Cell: ({ row }) => {
        const groupId = row.original.groupId.toString();
        return (<ResourceLink toRoute={`/super/group/${groupId}/info#`} displayValue={groupId} />);
      }

    },
    {
      Header: 'Organization Id',
      accessor: 'customId',
      Cell: ({ row }) => {
        const { organizationId, customId } = row.original;
        return (
          <ResourceLink
            toRoute={`/super/organization/${organizationId}/info`}
            displayValue={`${customId || ''} (${organizationId})`}
          />
        );
      }


    },
    {
      Header: 'Group Name',
      accessor: 'name',
    },
    {
      Header: 'Group Description',
      accessor: 'description',
    }
  ];
  return (
    <div className="pure-u-1-1">
      <h1>{_.upperFirst(type)} Groups</h1>
      <StaticEapTable
        columns={columns}
        data={tableData}
      />
    </div>
  );
};

export default SuperGroups;

SuperGroups.propTypes = {
  type: PropTypes.string.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    groupId: PropTypes.number,
    organizationId: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string
  })).isRequired
};
