import React from 'react';
import PropTypes from 'prop-types';
import './ProgressSpinner.css';

const ProgressSpinner = ({ size }) => (
  <div className="spinner-container">
    <span
      className={`fa fa-spinner ${size === 'small' ? 'fa-1x' : 'fa-5x'}`}
      style={{
        animation: 'spin 2.5s linear infinite'
      }}
    />
  </div>
);

ProgressSpinner.propTypes = {
  size: PropTypes.string
};

ProgressSpinner.defaultProps = {
  size: ''
};

export default ProgressSpinner;
