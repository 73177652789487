import * as Actions from '../actions/squarespace';

function getInitialState() {
  return {
    tokenState: '',
    hooks: null,
  };
}

function squarespaceReducer(state = getInitialState(), action) {
  switch (action.type) {
    case Actions.SQUARE_SPACE_ERROR:
      return { ...state, tokenState: 'error' };
    case Actions.SQUARE_SPACE_INIT_START:
      return { ...state, tokenState: 'loading' };
    case Actions.SQUARE_SPACE_INIT_INSYNC:
      return { ...state, tokenState: 'insync' };
    case Actions.SQUARE_SPACE_INIT_OAUTH:
      return {
        ...state,
        tokenState: 'oauth',
        uri: action.payload.uri
      };
    case Actions.SQUARE_SPACE_OAUTH_START:
      return { ...state, tokenState: 'loading' };
    case Actions.SQUARE_SPACE_OAUTH_SUCCESS:
      return { ...state, tokenState: 'insync' };
    case Actions.SQUARE_SPACE_HOOKS_REQUEST:
      return { ...state, hooks: null };
    case Actions.SQUARE_SPACE_HOOKS_SUCCESS:
      return { ...state, hooks: action.payload.hooks };
    default:
      return state;
  }
}

export default squarespaceReducer;
