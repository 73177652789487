import {
  // ACTIVATE_SUCCESS,
  ACTIVATE_FAILURE,
} from '../actions/activate';

const activateReducer = (state = {}, action) => {
  switch (action.type) {
    // For ACTIVATE_SUCCESS see activator actions. We reload the user on success.
    case ACTIVATE_FAILURE: {
      return { ...state, error: action.payload.message };
    }
    default: {
      return state;
    }
  }
};

export default activateReducer;
