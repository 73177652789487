import { connect } from 'react-redux';
import EnterPasscode from './components/EnterPasscode';

import { enterPasscode } from '../actions/user';

const mapStateToProps = (state) => {
  if (!state.user) {
    return {};
  }
  return {
    user: state.user
  };
};


const mapDispatchToProps = dispatch => ({
  enterPasscode: (passcode) => {
    dispatch(enterPasscode(passcode));
  }
});

const PasscodeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterPasscode);

export default PasscodeContainer;
