import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import EapModal from '../../components/EapModal';
import EapButton from '../../components/EapButton';
import GroupDetailItem from './GroupDetailItem';
import GroupDetailEditorContainer from '../GroupDetailEditorContainer';
import './Information.css';

const Information = ({
  group,
  adminCount,
  baseUrl,
  examineeCount,
  availableProductCount,
}) => {
  const [editing, setEditing] = React.useState(false);

  return (
    <div className="group-overview pure-u-1">
      <div className="pure-u-lg-1-2 pure-u-md-1-2 pure-u-1">
        <GroupDetailItem headerText="GROUP NAME">
          {group.name}
        </GroupDetailItem>
        <GroupDetailItem headerText="DESCRIPTION">
          {group.description || undefined}
        </GroupDetailItem>
      </div>
      <div className="pure-u-lg-1-2 pure-u-md-1-2 pure-u-1">
        {
          group.permissions.admin.view && (
            <GroupDetailItem
              faIconStr="fa fa-bank"
              headerText="ADMINISTRATORS"
            >
              <span>{adminCount}&nbsp;</span>
              <Link to={`${baseUrl}/administrators`}>
                VIEW ALL
              </Link>
            </GroupDetailItem>
          )
        }
        {
          group.permissions.users.view && (
            <GroupDetailItem
              faIconStr="fa fa-users"
              headerText="EXAMINEES"
            >
              <span>{examineeCount}&nbsp;</span>
              <Link to={`${baseUrl}/examinees`}>
                VIEW ALL
              </Link>
            </GroupDetailItem>
          )
        }
        {
          group.permissions.products.view && (
            <GroupDetailItem
              faIconStr="fa fa-cubes"
              headerText="PRODUCTS"
            >
              <span>{availableProductCount}&nbsp;</span>
              <Link to={`${baseUrl}/products`}>
                VIEW ALL
              </Link>
            </GroupDetailItem>
          )
        }
      </div>
      <div className="pure-u-1 group-info-button-bar">
        <EapButton
          optionalClass="edit-group-btn"
          onClick={() => setEditing(true)}
          text="EDIT"
        />
        <EapModal
          modalTitle="Edit Group"
          showModal={editing}
        >
          <GroupDetailEditorContainer
            group={group}
            closeModal={() => setEditing(false)}
          />
        </EapModal>
      </div>
    </div>
  );
};

Information.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    permissions: PropTypes.shape({
      admin: PropTypes.shape({
        view: PropTypes.bool
      }),
      users: PropTypes.shape({
        view: PropTypes.bool
      }),
      products: PropTypes.shape({
        view: PropTypes.bool
      })
    })
  }).isRequired,
  baseUrl: PropTypes.string.isRequired,
  adminCount: PropTypes.number.isRequired,
  examineeCount: PropTypes.number.isRequired,
  availableProductCount: PropTypes.number.isRequired,
};

export default Information;
