import React from 'react';
import {
  Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import customHistory from './utils/history';
import ScrollToTop from './components/ScrollToTop';
import LoginContainer from './auth/LoginContainer';
import DashboardContainer from './dashboard/DashboardContainer';
import LogoutContainer from './auth/LogoutContainer';
import NoRoute from './components/NoRoute';
import GeneralErrorContainer from './GeneralErrorContainer';
import PrintableReceipt from './checkout/components/PrintableReceipt';
import Authorized from './Authorized';
import SignupContainer from './signup/SignupContainer';
import PasswordResetRouter from './password-reset/PasswordResetRouter';
import PasscodeContainer from './dashboard/PasscodeContainer';
import GroupContainer from './group/GroupContainer';
import Public from './Public';
import SuperContainer from './super/SuperContainer';
import UserUpdateRoutes from './profile/UserUpdateRoutes';
import AdminInviteSpinnerContainer from './AdminInviteSpinnerContainer';
import ExamineeInviteSpinnerContainer from './ExamineeInviteSpinnerContainer';
import { Purchase } from './purchase';

const App = () => (
  <Router
    history={customHistory}
    onUpdate={() => { window.scrollTo(0, 0); }}
  >
    <ScrollToTop>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Authorized><Redirect to="/dashboard" /></Authorized>}
        />
        { false && (
          // TODO: Investigate old code
          <Route
            path="/purchase-receipt"
            render={(props) => <Authorized><PrintableReceipt {...props} /></Authorized>}
          />
        )}
        <Route
          path="/server-error"
          render={(props) => <Public><GeneralErrorContainer {...props} /></Public>}
        />
        <Route
          path="/dashboard"
          render={(props) => <Authorized><DashboardContainer {...props} /></Authorized>}
        />
        <Route
          path="/group/:id"
          render={(props) => <Authorized><GroupContainer {...props} /></Authorized>}
        />
        <Route
          path="/purchase/:uuid"
          render={(props) => <Authorized><Purchase {...props} /></Authorized>}
          exact
        />
        <Route
          path="/passcode"
          render={() => <Authorized><PasscodeContainer /></Authorized>}
        />
        <Route
          path="/super"
          render={(props) => <Authorized><SuperContainer {...props} /></Authorized>}
        />
        <Route
          path="/edit"
          render={(props) => <Authorized><UserUpdateRoutes {...props} /></Authorized>}
        />
        <Route
          path="/join-admin/:hash"
          render={(props) => <Authorized><AdminInviteSpinnerContainer {...props} /></Authorized>}
        />
        <Route
          path="/join-examinee/:hash"
          render={(props) => <Authorized><ExamineeInviteSpinnerContainer {...props} /></Authorized>}
        />
        <Route
          path="/signin"
          render={(props) => <Public><LoginContainer {...props} /></Public>}
        />
        <Route
          path="/signup"
          render={() => <Public><SignupContainer /></Public>}
        />
        <Route
          path="/reset-password"
          render={(props) => <Public><PasswordResetRouter {...props} /></Public>}
        />
        <Route
          path="/logout"
          render={() => <Public><LogoutContainer /></Public>}
        />
        <Route
          path="/signout"
          render={() => <Public><LogoutContainer /></Public>}
        />
        <Route path="*" render={(props) => <Public><NoRoute {...props} /></Public>} />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default App;
