import { connect } from 'react-redux';
import _ from 'lodash';

import GroupAdminInvitesTable from './components/GroupAdminInvitesTable';

import {
  superSendAdminInvites,
  superRevokeAdminInvite,
  superResendAdminInvite
} from '../../actions/adminInvites';

import { getPermissionString } from '../../components/PermissionSelector';

const mapStateToProps = (state) => {
  if (state.group.info === undefined) {
    return {
      adminInvites: []
    };
  }

  const groupId = state.group.info.id;

  const adminInvites = _.chain(state.adminInvites.list).filter({ groupId }).map((invite) => ({
    ...invite,
    ...{
      status: invite.status || 'Sent',
      permissionString: getPermissionString(invite)
    }
  })).value();

  return { adminInvites };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { groupId } = ownProps.match.params;
  return {
    sendInvites: (emailList, permissions) => {
      dispatch(superSendAdminInvites(groupId, emailList, permissions));
    },
    superRevokeAdminInvite: (selectedAdminInvite) => {
      dispatch(superRevokeAdminInvite(selectedAdminInvite, groupId));
    },
    superResendAdminInvite: (inviteId) => {
      dispatch(superResendAdminInvite(inviteId));
    }
  };
};

const GroupAdminInvitesTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupAdminInvitesTable);

export default GroupAdminInvitesTableContainer;
