import React from 'react';
import _ from 'lodash';

import { httpClient } from '../utils/httpClient';
import { dispatchAlert } from './alert';
import { loadProductLog } from './productLog';
import { initClientUser } from './user';
import SERVER_ERROR from './serverErrorMessage';
import ProductTransferAlert from '../components/ProductTransferAlert';

export const PASSCODE_EXISTS_REQUEST = 'PASSCODE_EXISTS_REQUEST';
export const PASSCODE_EXISTS_RESPONSE = 'PASSCODE_EXISTS_RESPONSE';
export const UPDATE_PASSCODE_SUCCESS = 'UPDATE_PASSCODE_SUCCESS';
export const UPDATE_PASSCODE_FAILURE = 'UPDATE_PASSCODE_FAILURE';
export const TRANSFER_PRODUCTS_SUCCESS = 'TRANSFER_PRODUCTS_SUCCESS';
export const TRANSFER_PRODUCTS_FAILURE = 'TRANSFER_PRODUCTS_FAILURE';

export const TRANSFER_AVAILABLE_PRODUCTS_SUCCESS = 'TRANSFER_AVAILABLE_PRODUCTS_SUCCESS';

export const ADD_NEW_PRODUCT_SUCCESS = 'ADD_NEW_PRODUCTS_SUCCESS';
export const NEW_PASSCODE_SUCCESS = 'NEW_PASSCODE_SUCCESS';
export const NEW_PASSCODE_FAILURE = 'NEW_PASSCODE_FAILURE';

export const LOAD_AVAILABLE_PRODUCTS_SUCCESS = 'LOAD_AVAILABLE_PRODUCTS_SUCCESS';
export const DELETE_AVAILABLE_PRODUCTS_SUCCESS = 'DELETE_AVAILABLE_PRODUCTS_SUCCESS';

export const INCREMENT_PRODUCTS_SUCCESS = 'INCREMENT_PRODUCTS_SUCCESS';

export const beginCheckPasscodeExists = () => ({
  type: PASSCODE_EXISTS_REQUEST
});

export const passcodeExists = (exists) => ({
  type: PASSCODE_EXISTS_RESPONSE,
  payload: { exists }
});

export function checkPasscode(passcode) {
  return (dispatch) => {
    if (_.isEmpty(passcode)) {
      return dispatch(passcodeExists(false));
    }
    return httpClient.get(`/passcode/exists/${passcode}`)
      .then((response) => {
        dispatch(passcodeExists(response.data.exists));
      }).catch((err) => {
        console.error(`Problem checking existing group groupName: [${passcode}]`, err);
      });
  };
}

export const updatePasscodeSuccess = (newPasscodeData) => ({
  type: UPDATE_PASSCODE_SUCCESS,
  payload: { newPasscodeData }
});

export const updatePasscodeFailure = (error) => ({
  type: UPDATE_PASSCODE_FAILURE,
  payload: { error }
});

export function updatePasscode(productId, passcode) {
  const data = { productId, passcode };
  return (dispatch) => {
    httpClient.put('/passcode', data)
      .then(() => {
        dispatch(updatePasscodeSuccess(data));
      }).catch((err) => {
        console.error('ERROR UPDATING PASSCODE');
        dispatch(updatePasscodeFailure(err.message));
      });
  };
}

export const getAllAvailableProductsByGroup = (id) => (dispatch) => (
  httpClient
    .get(`/super/group/${id}/products`)
    .then((response) => {
      dispatch({
        type: LOAD_AVAILABLE_PRODUCTS_SUCCESS,
        payload: { id, products: response.data }
      });
    })
    .catch((err) => console.error('Cannot load available Products:', err))
);

export const addNewProductSuccess = (groupId, product) => ({
  type: ADD_NEW_PRODUCT_SUCCESS,
  payload: { groupId, product }
});

export function addNewProduct(groupId, productId, amount) {
  return (dispatch) => {
    httpClient.put(
      `/super/group/${groupId}/product`,
      { productId, amount }
    )
      .then((response) => {
        dispatch(addNewProductSuccess(groupId, response.data.availableProduct));
        dispatch(loadProductLog(groupId));
      })
      .catch((err) => console.error('Problem creating new available products: ', err));
  };
}

export const deleteAvailableProductsSuccess = (availableProductId, groupId) => ({
  type: DELETE_AVAILABLE_PRODUCTS_SUCCESS,
  payload: { availableProductId, groupId }
});

export const incrementProductsSuccess = (
  availableProductId,
  sourceGroupId,
  selectedAmount
) => ({
  type: INCREMENT_PRODUCTS_SUCCESS,
  payload: {
    availableProductId, sourceGroupId, selectedAmount
  }
});

// Used also for subtracting. Negative passed in for subtracting
export const incrementAvailableProducts = (availableProductId, groupId, amount) => (dispatch) => {
  httpClient
    .post(
      `/super/availableProduct/${availableProductId}`,
      { amount }
    ).then(() => {
      dispatch(loadProductLog(groupId));
      dispatch(incrementProductsSuccess(availableProductId, groupId, amount));
      dispatch(dispatchAlert({
        message: (
          <span> Successfully updated product amount </span>
        ),
        type: 'eap-alert-success',
        time: 10000
      }));
    })
    .catch((e) => {
      console.error(e);
    });
};

export const deleteAvailableProducts = (availableProductId, groupId) => (dispatch) => {
  httpClient
    .delete(`/super/availableProduct/${availableProductId}`)
    .then(() => {
      dispatch(loadProductLog(groupId));
      dispatch(deleteAvailableProductsSuccess(availableProductId, groupId));
    })
    .catch((e) => console.error(e));
};

export const transferProductsSuccess = (
  availableProductId,
  sourceGroupId,
  selectedAmount
) => ({
  type: TRANSFER_PRODUCTS_SUCCESS,
  payload: {
    availableProductId, sourceGroupId, selectedAmount
  }
});

export const transferProductsFailure = (error) => ({
  type: TRANSFER_PRODUCTS_FAILURE,
  payload: { error }
});

export const transferAvailableProductsSuccess = (availableProductId, amount) => ({
  type: TRANSFER_AVAILABLE_PRODUCTS_SUCCESS,
  payload: { availableProductId, amount }
});

export function transferProductsAsSuper(
  availableProductId,
  sourceGroupId,
  amount,
  destinationGroupId
) {
  return (dispatch) => {
    httpClient
      .put(`/super/availableProduct/${availableProductId}/transfer`, {
        selectedAmount: amount,
        destinationGroupId
      }).then(() => {
        dispatch(loadProductLog(sourceGroupId));
        dispatch(transferProductsSuccess(availableProductId, sourceGroupId, amount));

        // TODO: Delete line after reducer refactor
        dispatch(transferAvailableProductsSuccess(availableProductId, amount));
      })
      .catch((e) => console.error('Failed to transfer: ', e));
  };
}

export function transferProducts(
  sourceAdminGroup,
  targetGroup,
  availableProduct,
  adjustedAmount
) {
  return (dispatch) => {
    httpClient.put(`/group/${sourceAdminGroup.id}/available-products/transfer`, {
      sourceGroupId: sourceAdminGroup.groupId,
      destinationGroupId: targetGroup.id,
      availableProductId: availableProduct.id,
      selectedAmount: adjustedAmount
    })
      .then(() => {
        dispatch(transferProductsSuccess(
          availableProduct.id,
          sourceAdminGroup.groupId,
          adjustedAmount
        ));
        // Our Redux Store Sucks
        dispatch(initClientUser({ requestNotification: false }));
        dispatch(dispatchAlert({
          message: (
            <ProductTransferAlert
              amount={adjustedAmount}
              productName={availableProduct.adminName}
              destinationGroupName={targetGroup.name}
            />
          ),
          type: 'eap-alert-success',
          time: 10000
        }));
      }).catch((err) => {
        console.error('ERROR TRANSFERRING');
        dispatch(transferProductsFailure(err.message));
        dispatch(dispatchAlert({
          message: (
            <span>
              Error transferring product <b>{availableProduct.adminName}</b> to
              <b> {targetGroup.name}</b>.&nbsp;
              {SERVER_ERROR}
            </span>
          ),
          type: 'eap-alert-error'
        }));
      });
  };
}

export const newPasscodeSuccess = (availableProductId, groupId, newPasscode) => ({
  type: NEW_PASSCODE_SUCCESS,
  payload: { availableProductId, groupId, newPasscode }
});

export const newPasscodeFailure = (error) => ({
  type: NEW_PASSCODE_FAILURE,
  payload: { error }
});

export function generateNewPasscodeAsSuper(availableProductId, groupId) {
  return (dispatch) => {
    httpClient.put(`/super/availableProduct/${availableProductId}/passcode/generate`)
      .then((response) => {
        dispatch(newPasscodeSuccess(availableProductId, groupId, response.data.passcode));
      })
      .catch((e) => console.error('Problem generating new passcode as super', e));
  };
}

export function generateNewPasscode(adminGroupId, groupId, availableProductId) {
  return (dispatch) => {
    httpClient.put(`/group/${adminGroupId}/available-products/passcode/generate`, { availableProductId })
      .then((response) => {
        dispatch(newPasscodeSuccess(availableProductId, groupId, response.data));
        dispatch(dispatchAlert({
          message: <span>New passcode generated. New passcode is <b>{response.data}</b></span>,
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('ERROR GENERATING NEW PASSCODE', err);
        dispatch(dispatchAlert({
          message: <span>Unable to change passcode. {SERVER_ERROR}</span>,
          type: 'eap-alert-error'
        }));
        dispatch(newPasscodeFailure(err.message));
      });
  };
}
