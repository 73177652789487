import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EapButton from '../../../components/EapButton';
import PasswordAndConfirm from '../../../formComponents/PasswordAndConfirm';

export default class AdminHandout extends Component {
  constructor() {
    super();
    this.state = {
      password: {
        value: undefined,
        isValid: false
      },
      submitting: false
    };
    this.updatePasswordInfo = this.updatePasswordInfo.bind(this);
    this.submitAdminHandout = this.submitAdminHandout.bind(this);
  }

  updatePasswordInfo(updatedPassword) {
    this.setState({
      password: {
        value: updatedPassword.value,
        isValid: updatedPassword.isValid
      }
    });
  }

  submitAdminHandout(e) {
    e.preventDefault();
    this.setState({ submitting: true });
    this.props.sendAdminHandout(this.props.signinUserId, this.state.password.value);
    this.props.closeModal();
  }

  render() {
    return (
      <div className="pure-u">
        <div className="pure-u-1-1">
          <h1>Admin Handout</h1>
        </div>
        <div>
          Please enter a temporary password to send to
          <b> {this.props.firstName} {this.props.lastName}</b> at
          <b> {this.props.email}</b>.
        </div>
        <br />
        <form
          id="admin-handout-form"
          className="pure-form pure-form-stacked"
          onSubmit={this.submitAdminHandout}
        >
          <div>
            <PasswordAndConfirm
              update={this.updatePasswordInfo}
              required
            />
            <EapButton
              id="submit-btn"
              isSubmit
              disabled={!this.state.password.isValid || this.state.submitting}
              text="SEND"
            />
            <EapButton
              isSecondary
              text="CANCEL"
              onClick={this.props.closeModal}
            />
          </div>
        </form>
      </div>
    );
  }
}

AdminHandout.propTypes = {
  closeModal: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  sendAdminHandout: PropTypes.func.isRequired,
  signinUserId: PropTypes.number.isRequired
};

AdminHandout.defaultProps = {
  email: undefined,
  firstName: '',
  lastName: '',
};
