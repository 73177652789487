import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { StaticEapTable, PermissionFilter } from '../../components/StaticEapTable';
import AdminIconList from '../../components/AdminIconList';
import AdminIcon from '../../components/AdminIcon';
import AdminInfoModal from '../../components/AdminInfoModal';
import UpdatePermissionModal from '../../components/UpdatePermissionModal';
import AdminDeleteModal from '../../components/AdminDeleteModal';
import { EMPTY } from '../../components/PermissionSelector';

export default class AdministratorsTable extends Component {
  constructor() {
    super();
    this.state = {
      adminInfoModal: undefined,
      updatePermissionModal: undefined,
      tempPermissions: EMPTY,
      deleteModal: undefined
    };
    this.update = this.update.bind(this);
    this.remove = this.remove.bind(this);
  }

  update() {
    const {
      updatePermissionModal: subjectAdmin,
      tempPermissions
    } = this.state;
    const {
      actorAdmin,
      updateAdminPermissions
    } = this.props;
    updateAdminPermissions(
      actorAdmin,
      subjectAdmin,
      tempPermissions
    );
    this.setState({
      updatePermissionModal: undefined,
      tempPermissions: EMPTY
    });
  }

  remove() {
    const {
      deleteModal: subjectAdmin
    } = this.state;
    const {
      deleteAdmin,
      actorAdmin
    } = this.props;

    deleteAdmin(
      actorAdmin,
      subjectAdmin
    );
    this.setState({
      deleteModal: undefined
    });
  }

  render() {
    const columns = [
      {
        Header: 'Username',
        accessor: 'username'
      },
      {
        Header: 'First Name',
        accessor: 'firstName'
      },
      {
        Header: 'Last Name',
        accessor: 'lastName'
      },
      {
        Header: 'Permissions',
        accessor: 'permissionLabel',
        Filter: PermissionFilter
      },
      {
        Header: 'Email',
        accessor: 'email',

      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          let updateIcon = '';
          if (
            (this.props.actorAdmin.adminGroupId !== row.original.adminGroupId)
            && (this.props.actorAdmin.per_admin_edit)
          ) {
            updateIcon = (
              <AdminIcon
                onClick={() => this.setState({
                  updatePermissionModal: row.original,
                  tempPermissions: row.original
                })}
                icon="fa-pencil-square-o"
                toolTip="Edit"
              />
            );
          }
          let deleteIcon = '';
          if (
            (this.props.actorAdmin.adminGroupId !== row.original.adminGroupId) &&
            (this.props.actorAdmin.per_admin_remove)
          ) {
            deleteIcon = (
              <AdminIcon
                onClick={() => this.setState({ deleteModal: row.original })}
                icon="fa-trash"
                toolTip="Remove"
              />
            );
          }
          return (
            <AdminIconList>
              <AdminIcon
                onClick={() => this.setState({ adminInfoModal: row.original })}
                icon="fa-info-circle"
                toolTip="Information"
              />
              {updateIcon}
              {deleteIcon}
            </AdminIconList>
          );
        }
      }
    ];
    let adminInfoTitle = 'Information for ';
    if (this.state.adminInfoModal) {
      adminInfoTitle += `${this.state.adminInfoModal.firstName} ${this.state.adminInfoModal.lastName}`;
    }

    let updateAdminTitle = 'Update Permissions for ';
    if (this.state.updatePermissionModal) {
      updateAdminTitle += `${this.state.updatePermissionModal.firstName} ${this.state.updatePermissionModal.lastName}`;
    }

    return (
      <React.Fragment>
        <StaticEapTable
          columns={columns}
          data={this.props.admins}
        />
        <AdminInfoModal
          showModal={this.state.adminInfoModal !== undefined}
          permissions={this.state.adminInfoModal}
          closeModal={() => this.setState({ adminInfoModal: undefined })}
          title={adminInfoTitle}
        />
        <UpdatePermissionModal
          title={updateAdminTitle}
          showModal={this.state.updatePermissionModal !== undefined}
          permissions={this.state.tempPermissions}
          onChange={permissions => this.setState({ tempPermissions: permissions })}
          update={this.update}
          closeModal={() => this.setState({ updatePermissionModal: undefined })}
        />
        <AdminDeleteModal
          showModal={this.state.deleteModal !== undefined}
          admin={this.state.deleteModal}
          remove={this.remove}
          closeModal={() => this.setState({ deleteModal: undefined })}
        />
      </React.Fragment>
    );
  }
}

AdministratorsTable.propTypes = {
  admins: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    permissions: PropTypes.string,
    email: PropTypes.string
  })).isRequired,
  actorAdmin: PropTypes.shape({
    adminGroupId: PropTypes.number,
    per_admin_edit: PropTypes.bool,
    per_admin_remove: PropTypes.bool
  }).isRequired,
  updateAdminPermissions: PropTypes.func.isRequired,
  deleteAdmin: PropTypes.func.isRequired
};

AdministratorsTable.defaultProps = {};
