import { connect } from 'react-redux';
import _ from 'lodash';

import Organization from './components/Organization';
import {
  loadOrganizationInfo,
  updateOrganizationInfo,
  loadGroups,
} from '../../actions/organization';

import { superAddGroup } from '../../actions/group';

const mapStateToProps = (state, ownProps) => {
  const id = parseInt(ownProps.match.params.organizationId, 10);

  let organization = _.find(state.organization.list, { id });

  if (organization === undefined) {
    organization = { id };
  }

  const theGroups = _
    .chain(state.groups.list)
    .filter({ organizationId: id })
    .map((group) => {
      if (group.availableProducts && group.availableProducts.length > 0) {
        return group;
      }
      return { ...group, availableProducts: [] };
    })
    .value();

  return {
    organization,
    groups: theGroups
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  load: () => {
    const pathId = parseInt(ownProps.match.params.organizationId, 10);
    dispatch(loadOrganizationInfo(pathId));
    dispatch(loadGroups(pathId));
  },
  updateOrganizationInfo: (updatedOrganizationInfo) => {
    const pathId = parseInt(ownProps.match.params.organizationId, 10);
    dispatch(updateOrganizationInfo(pathId, updatedOrganizationInfo));
  },
  superAddGroup: (values) => {
    const pathId = parseInt(ownProps.match.params.organizationId, 10);
    dispatch(superAddGroup(pathId, values));
  }
});

const OrganizationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Organization);

export default OrganizationContainer;
