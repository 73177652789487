import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as validator from '@edits/eap-signup-validator';
import FormInputComponent from './FormInputComponent';
import './Form.css';

import { httpClient } from '../utils/httpClient';

const EMAIL_EXISTS = 'This email is already associated to an account';

export default class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      inputValue: props.defaultValue || ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  // For nullable email
  componentWillReceiveProps(nextProps) {
    if (!this.props.disableInput && nextProps.disableInput) {
      this.setState({
        inputValue: '',
        message: ''
      });
    } else if (this.props.disableInput && !nextProps.disableInput) {
      this.setState({
        message: 'Please enter your email'
      });
    }
  }

  handleChange(e) {
    e.preventDefault();
    const message = validator.validateSingleInput('email', e.target.value);
    const isValid = message === undefined;
    const email = e.target.value;
    this.setState({
      inputValue: e.target.value
    });
    if (email !== '') {
      httpClient.get(`/user/exists/email/${email}`)
        .then((response) => {
          let emailIsValid = !response.data.exists;
          if (this.props.defaultValue !== email && response.data.exists) {
            this.setState({
              message: EMAIL_EXISTS
            });
          } else if (this.props.defaultValue === email) {
            emailIsValid = true;
            this.setState({
              message: ''
            });
          } else {
            this.setState({
              message: message ? _.first(message) : ''
            });
          }
          this.props.update({
            isValid: isValid && emailIsValid,
            keyName: this.props.keyName,
            value: email
          });
        }).catch((err) => {
          console.error(`Problem checking existing email??? email: [${email}]`, err);
        });
    } else {
      this.setState({
        message: message ? _.first(message) : ''
      });
      this.props.update({
        isValid: false,
        keyName: this.props.keyName,
        value: undefined
      });
    }
  }

  render() {
    return (
      <FormInputComponent
        required={this.props.required}
        formGroupStyle={this.props.formGroupStyle}
        keyName={this.props.keyName}
        displayLabel="EMAIL"
        handleChange={this.handleChange}
        inputValue={this.state.inputValue}
        disableInput={this.props.disableInput}
        errorMessage={this.state.message}
      />
    );
  }
}

Email.propTypes = {
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  update: PropTypes.func.isRequired,
  formGroupStyle: PropTypes.string,
  keyName: PropTypes.string,
  disableInput: PropTypes.bool
};

Email.defaultProps = {
  defaultValue: undefined,
  required: true,
  formGroupStyle: undefined,
  keyName: 'email',
  disableInput: false
};
