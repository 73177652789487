import {
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  GROUP_NAME_EXISTS_RESPONSE
} from '../actions/group';

const addGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case GROUP_NAME_EXISTS_RESPONSE: {
      return {
        ...state,
        groupNameExists: action.payload.exists
      };
    }
    case ADD_GROUP_SUCCESS: {
      // NOTE ALSO look in the userReducer!!!
      return { ...state, errorMessage: '' };
    }
    case ADD_GROUP_FAILURE: {
      return { ...state, errorMessage: action.payload.message };
    }
    default: {
      return { ...state };
    }
  }
};

export default addGroupReducer;
