import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import NavigationBar from '../../../components/NavigationBar';
import GroupContentTab from '../../../group/components/GroupContentTab';
import Products from './Products';

const ProductsRoutes = ({ baseUrl }) => (
  <div>
    <NavigationBar
      routes={[
        { path: 'info', text: 'Products', icon: 'fa-cubes' }
      ]}
      base={`${baseUrl.substring(1)}`}
    />
    <Switch>
      <Route
        path={`${baseUrl}/info`}
        render={() => <GroupContentTab><Products /></GroupContentTab>}
      />
      <Route
        exact
        path={baseUrl}
        render={() => <Redirect to={`${baseUrl}/info`} />}
      />
    </Switch>
  </div>
);

export default ProductsRoutes;

ProductsRoutes.propTypes = {
  baseUrl: PropTypes.string.isRequired
};
