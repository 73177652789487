import { connect } from 'react-redux';
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { 
  qboSaveOauth, 
  qboInit, 
  refreshQboProductData,
  refreshQboToken
} from '../../actions/quickbooks';
import EapButton from '../../components/EapButton';

const mapStateToProps = (state) => {
  return state.quickbooks;
};

const mapDispatchToProps = dispatch => ({
  init: () => {
    dispatch(qboInit());
  },
  refreshProducts: () => {
    dispatch(refreshQboProductData());
  },
  refreshQboToken: () => {
    dispatch(refreshQboToken());
  },
  pushOauth: (data) => {
    dispatch(qboSaveOauth(data));
  }
});

class Quickbooks extends Component {
  componentWillMount() {
    if (this.props.location.pathname === '/super/qbo-success') {
      this.props.pushOauth({
        location: window.location.href
      });
      return;
    }
    if (this.props.display === 'unloaded') {
      this.props.init();
    }
  }
  render() {
    let display = <div>Initalize</div>;
    if (this.props.display === 'loading') {
      display = <p>Loading...</p>;
    }
    if (this.props.display === 'oauth') {
      display = (<div>
        <p>You must authenticate the server with quickbooks.</p>
        <p>It is recommended you use the username orders@edits.net. This will
          allow system generated invoices to be attributed to this user.</p>
        <a href={this.props.uri}>
          <EapButton
            text="Quickbooks Login"
          />
        </a>
      </div>);
    }
    if (this.props.display === 'insync') {
      display = <p>Cool we are in sync.</p>;
    }
    if (this.props.display === 'error') {
      display = <p>An error occured.</p>;
    }
    return (
      <div>
        <h1>Quickbooks</h1>
        <div>
          {display}
          { this.props.display !== 'oauth' ? (
          <EapButton 
            text="Delete QBO Token"
            onClick={() => this.props.refreshQboToken()}
          /> ) : '' }
        </div>
        <div>
          <p>
            Refreshes the QBO IDs and price for refill products and the catalog.
            It only updates refill products that have a QBO ID.
            To update the squarespace go to the squarespace area in the EAP.
          </p>
          <EapButton 
            inProgress={this.props.updatingProducts}
            text="Refresh Products"
            onClick={() => this.props.refreshProducts()}
          />
        </div>
      </div>
    );
  }
}

Quickbooks.propTypes = {
  unloaded: PropTypes.bool,
  loading: PropTypes.bool,
  oauth: PropTypes.bool,
  insync: PropTypes.bool,
  error: PropTypes.bool,
  init: PropTypes.func.isRequired,
  pushOauth: PropTypes.func.isRequired,
  uri: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string
  })
};

Quickbooks.defaultProps = {
  unloaded: false,
  loading: false,
  oauth: false,
  insync: false,
  error: false,
  uri: '',
  location: {
    search: '',
    pathname: ''
  }
};

const QuickbooksContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Quickbooks));

export default QuickbooksContainer;
