import _ from 'lodash';

// TODO: Find a way to move this into the reducer.
export function getAdminGroup(groupId, state) {
  const actorAdmin = _.clone(state.user.adminGroups[
    _.findIndex(state.user.adminGroups, { groupId })
  ]);
  actorAdmin.userId = state.user.id;
  actorAdmin.adminGroupId = _.clone(actorAdmin.id);
  delete actorAdmin.id;
  return actorAdmin;
}

export default function findCopyableGroups(adminGroups) {
  return _.filter(adminGroups, adminGroup => adminGroup.per_create);
}

