import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { parseRedirectUrl } from '../../utils/url-parser';
import InviteValidator from '../../components/InviteValidator';
import SensibleContainer from '../../components/SensibleContainer';
import EapThrone from '../../components/EapThrone';
import PublicSignupForm from './PublicSignupForm';
import FormHeader from '../../components/FormHeader';
import './Signup.css';

const Signup = ({
  signUpNewUser,
  location
}) => {
  let redirectUrl;
  if (location.search) {
    redirectUrl = parseRedirectUrl(location.search);
    // if (redirectUrl.includes('join-examinee') {
    // {
  }

  return (
    <div
      className="flexcolumn sign-up-page"
      style={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
      }}
    >
      <SensibleContainer>
        <EapThrone>
          <div
            className="pure-u-3-4 center-content back-to-login-link"
          >
            <div className="pure-u-5-6" />
            <div className="pure-u-1-6">
              <Link
                to="/signin"
              >
                Go back to Sign In
              </Link>
            </div>
          </div>
          <FormHeader
            headerText="Account Sign-up"
            renderParagraph={() => (
              <>
                Please enter your information below to create
                your sign-in. Fields marked with (<span className="required">*</span>) are required.
              </>
            )}
          />
          <div className="pure-u-3-4">
            <InviteValidator location={location} />
          </div>
          <div
            className="pure-u-3-4"
            style={{ /* textAlign: 'center' */ }}
          >
            <PublicSignupForm
              onSubmit={signUpNewUser}
              redirectUrl={redirectUrl}
            />
          </div>
        </EapThrone>
      </SensibleContainer>
    </div>
  );
};

Signup.propTypes = {
  signUpNewUser: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
};

export default withRouter(Signup);
