import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DisplayPrice from '../../checkout/components/DisplayPrice';
import { DynamicEapTable } from '../../components/DynamicEapTable';
import NavigationBar from '../../components/NavigationBar';
import GroupContentTab from '../../group/components/GroupContentTab';

import './Purchases.css';

const PurchasesTable = ({ data, fetchData, baseUrl }) => (
  <div className="superPurchases">
    <NavigationBar
      routes={[
        { path: 'refills', text: 'Refills', icon: 'fa-dollar' }
      ]}
      base={`${baseUrl.substring(1)}`}
    />
    <GroupContentTab>
      <DynamicEapTable
        columns={[
          {
            Header: 'Email',
            accessor: 'email'
          },
          {
            Header: 'First',
            accessor: 'firstName'
          },
          {
            Header: 'Last',
            accessor: 'lastName'
          },
          {
            Header: 'Group',
            accessor: 'groupName',
            Cell: ({
              value,
              row: {
                original: {
                  groupId,
                },
              },
            }) => (
              <Link to={`/super/group/${groupId}/info`}>
                {value}
              </Link>
            )
          },
          {
            Header: 'Product',
            accessor: 'productName',
          },
          {
            Header: 'Quantity',
            accessor: 'quantity',
          },
          {
            Header: 'Amount',
            accessor: 'chargeAmount',
            filter: (value) => ((value * 100).toString()),
            Cell: ({ row }) => (<DisplayPrice amount={row.original.chargeAmount / 100} />)
          },
          {
            Header: 'Created',
            accessor: 'createdAt',
            disableFilters: true,
            sortType: (rowA, rowB, columnId) => (
              rowA.values[columnId].isAfter(rowB.values[columnId])
            ),
            Cell: ({ row }) => (
              <span>{row.original.createdAt.format('MM/DD/YYYY')}</span>
            )
          },
          {
            Header: 'Links',
            accessor: 'actions',
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row }) => (
              <>
                <Link to={`/super/user/${row.original.userId}/info`}>
                  User
                </Link>
                &nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://dashboard.stripe.com/payments/${row.original.stripeChargeId}`}
                >
                  Stripe
                </a>
                &nbsp;
                {
                  row.original.qboId
                    ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://app.qbo.intuit.com/app/invoice?txnId=${row.original.qboId}`}
                      >
                        QBO
                      </a>
                    )
                    : ''
                }
              </>
            )
          },
        ]}
        data={data}
        fetchData={(params) => fetchData(params)}
      />
    </GroupContentTab>
  </div>
);

PurchasesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.firstName,
    lastName: PropTypes.lastName,
    stripeId: PropTypes.string
  })).isRequired,
  fetchData: PropTypes.func.isRequired
};

export default PurchasesTable;
