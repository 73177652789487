import React from 'react';
import PropTypes from 'prop-types';

import './GroupDetailItem.css';

const GroupDetailItem = ({ headerText, faIconStr, children }) => (
  <div className="group-detail-item">
    <div>
      {faIconStr ? <span className={faIconStr} /> : ''}
      <span> {headerText}</span>
    </div>
    <div className="group-detail-item-value">
      {children}
    </div>
  </div>
);

GroupDetailItem.propTypes = {
  faIconStr: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string
  ]),

};

GroupDetailItem.defaultProps = {
  faIconStr: undefined,
  children: undefined
};

export default GroupDetailItem;
