import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './Logout.css';

const Logout = ({ logout }) => {
  logout();
  return (
    <main className="content pure-u-1-1 logout-message" role="main">
      <p>You have been logged out.</p>
      <p>To sign-in again, please <Link to="/signin">click here</Link>.</p>
    </main>);
};

Logout.propTypes = {
  logout: PropTypes.func.isRequired
};

export default Logout;
