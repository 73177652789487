import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Fulfillment.css';

import EapSelectList from '../../components/EapSelectList';
import EapButton from '../../components/EapButton';

const FulfillmentComponent = ({
  purchase,
  groups,
  onClose,
  fulfill,
}) => {
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(-1);
  const [invalid, setInvalid] = useState();
  const inChoices = groups.map((g, i) => ({
    value: i,
    label: g.name,
  }));

  inChoices.unshift({
    key: 'select',
    value: -1,
    label: 'Please select a group.',
  });

  const [choices, setChoices] = useState(inChoices);

  const handleChange = (e) => {
    if (selectedGroupIndex === -1) {
      setChoices(choices.slice(1));
    }
    setSelectedGroupIndex(e.target.value);
    setInvalid();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedGroupIndex === -1) {
      setInvalid('Please select a group');
      return;
    }
    const group = groups[selectedGroupIndex];
    if (!group) {
      setInvalid('An error occured. Please select your group again.');
      return;
    }
    fulfill({ purchase, group });
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="groupSelector">
      <form onSubmit={handleSubmit}>
        <label htmlFor="groupSelector">Please select the group to recieve your products:</label>
        <EapSelectList
          id="groupSelector"
          name="groupSelector"
          onChange={handleChange}
          value={selectedGroupIndex}
          choices={choices}
        />
        <div id="buttonContainer">
          <EapButton text="Fulfill" isSubmit />
          <EapButton
            text="Cancel"
            isSecondary
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          />
        </div>
        {invalid && <p id="error">{invalid}</p>}
      </form>
    </div>
  );
};

export default FulfillmentComponent;
