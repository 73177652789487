import _ from 'lodash';
import * as validator from '@edits/eap-signup-validator';

export function getInputTokens(rawTextInput) {
  return (rawTextInput)
    .split(',')
    .filter(token => token !== undefined && token !== '' && token !== ' ')
    .map(str => str.trim());
}

export function getValidEmails(tokens) {
  return tokens
    .filter(candidate =>
      _.isEmpty(validator.validateSingleInput('email', candidate)));
}

export function getInvalidEmails(tokens) {
  const validEmails = getValidEmails(tokens);
  return tokens
    .filter(token => !validEmails.includes(token))
    .map(invalidToken => `"${invalidToken}"`);
}

export function findDuplicates(tokens) {
  return _(tokens)
    .groupBy()
    .filter(group => group.length >= 2)
    .map(duplicateGroup => _.first(duplicateGroup))
    .map(duplicate => `"${duplicate}"`)
    .value();
}
