import { connect } from 'react-redux';
import ExamineeResults from './components/ExamineeResults';
import extractAndSortPermissions from '../utils/sortPermissions';

import { releaseResults } from '../actions/assignedProducts';
import { loadResults, clearResults } from '../actions/examinees';

const mapStateToProps = (state, ownProps) => {
  if (state.group.message) {
    return {
      group: {
        loading: false,
        message: state.group.message
      }
    };
  }

  const adminPermissions = extractAndSortPermissions(state.user.adminGroups, ownProps.groupId);

  return {
    adminGroupId: adminPermissions.id,
    groupId: ownProps.groupId,
    loadingResults: state.examinees.results.loading,
    results: state.examinees.results.list,
    user: ownProps.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  releaseResults: (params) => {
    dispatch(releaseResults(params));
  },
  loadResults: ({ adminGroupId, userId }) => {
    dispatch(loadResults({ adminGroupId, userId }));
  },
  clearResults: () => {
    dispatch(clearResults());
  }
});

const ExamineeResultsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamineeResults);

export default ExamineeResultsContainer;
