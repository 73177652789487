import { connect } from 'react-redux';
// import _ from 'lodash';

import Login from './components/Login';
import { login } from '../actions/login';

const mapStateToProps = (state) => {
  if (state.login.authenticated === undefined) {
    return { };
  } else if (state.login.authenticated === false) {
    return { loginRejected: true };
  }
  return { loginRejected: false };
};

const mapDispatchToProps = dispatch => ({
  onLoginClick: (username, password, redirectUrl) => {
    dispatch(login(username, password, redirectUrl));
  }
});

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

export default LoginContainer;
