import React from 'react';
import PropTypes from 'prop-types';
import JSONPretty from 'react-json-pretty';
import _ from 'lodash';
import './ProductLogTable.css';
import ResourceLink from '../../../components/ResourceLink';

import { StaticEapTable } from '../../../components/StaticEapTable';
import * as EapDisplayDate from '../../../components/EapDisplayDate';

const ProductLogTable = ({ productLog }) => {
  const columns = [
    {
      Header: 'Actor ID',
      accessor: 'actorId',
      Cell: ({ row }) => (
        <ResourceLink
          toRoute={`/super/user/${row.original.actorId}/info`}
          displayValue={!_.isNull(row.original.actorId) ? `${row.original.actorId}` : ''}
        />
      )
    },
    {
      Header: 'Subj ID',
      accessor: 'subjectId',
      Cell: ({ row }) => (
        <ResourceLink
          toRoute={`/super/user/${row.original.subjectId}/info`}
          displayValue={!_.isNull(row.original.subjectId) ? (`${row.original.subjectId}`) : ''}
        />
      )
    },
    {
      Header: 'Action',
      accessor: 'action'
    },
    {
      Header: 'Amount',
      accessor: 'amount'
    },
    {
      Header: 'Product',
      accessor: 'productName',
    },
    {
      Header: 'Meta',
      accessor: 'meta',
      Cell: ({ row }) => <JSONPretty json={row.original.meta} />
    },
    {
      Header: 'Date',
      accessor: 'updatedAt',
      disableFilters: true,
      Cell: ({ row }) => <EapDisplayDate.Display date={row.original.updatedAt} />,
    },
  ];

  return (
    <div className="pure-u-1">
      <StaticEapTable
        columns={columns}
        data={productLog}
      />
    </div>
  );
};

ProductLogTable.propTypes = {
  productLog: PropTypes.arrayOf(PropTypes.shape({
    updatedAt: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    productId: PropTypes.number
  }))
};

ProductLogTable.defaultProps = {
  productLog: []
};

export default ProductLogTable;
