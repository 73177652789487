import React from 'react';
import PropTypes from 'prop-types';
import './SensibleContainer.css';

// NOTE: This constrains width and centers the children
const SensibleContainer = ({ children }) => (
  <div className="pure-g eap-sensible-container">
    {children}
  </div>
);

SensibleContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

export default SensibleContainer;
