import { connect } from 'react-redux';
import AddExamineeForm from './components/AddExamineeForm';
import { addExaminee } from '../actions/examinees';
import extractAndSortPermissions from '../utils/sortPermissions';

const mapStateToProps = (state, ownProps) => {
  const newState = {};
  const adminPermissionsId = extractAndSortPermissions(state.user.adminGroups, ownProps.groupId).id;
  const selectedGroup = state.group.groupsById[ownProps.groupId];
  newState.availableProducts = selectedGroup.availableProducts || [];
  newState.adminGroupId = adminPermissionsId;
  newState.addExamineeError = state.examinees.addExamineeError;
  return newState;
};

const mapDispatchToProps = (dispatch) => ({
  addExaminee: (adminGroupId, newUserInfo, availProductInfo) => {
    dispatch(addExaminee(adminGroupId, newUserInfo, availProductInfo));
  },
});

const AddExamineeFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddExamineeForm);

export default AddExamineeFormContainer;
