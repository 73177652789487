import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import DisplayPrice from './DisplayPrice';

const RefillReceiptDetails = ({
  productName,
  quantity,
  purchaseAmount
}) => (
  <div className="receipt-details">
    <div><b>Product:</b> {productName}</div>
    <div><b>Quantity:</b>{quantity}</div>
    <div><b>Purchase Date:</b>{moment().format('MM/DD/YYYY')}</div>
    <div><b>Total Cost:</b>
      <DisplayPrice amount={purchaseAmount} />
    </div>
  </div>
);

RefillReceiptDetails.propTypes = {
  productName: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  purchaseAmount: PropTypes.number.isRequired
};

export default RefillReceiptDetails;
