/* eslint react/forbid-prop-types:0 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './AdminIconList.css';

const AdminIconList = ({ children, className, style }) => (
  <span className={classnames('admin-icon-list', className)} style={style}>
    {children}
  </span>
);

export default AdminIconList;

AdminIconList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

AdminIconList.defaultProps = {
  className: '',
  style: {}
};
