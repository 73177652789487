import React from 'react';
import PropTypes from 'prop-types';
import './Products.css';
import GenericProductLink from './GenericProductLink';

const ProductLink = ({ text, assignedProductId }) => (
  <GenericProductLink
    text={text}
    assignedProductId={assignedProductId}
    baseUrl="/api/user/assigned-product/"
  />
);

ProductLink.propTypes = {
  text: PropTypes.string.isRequired,
  assignedProductId: PropTypes.number.isRequired
};

export default ProductLink;
