import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InlineValidationMessage from '../../components/InlineValidationMessage';
import ProgressSpinner from '../../components/ProgressSpinner';
import ExamineeView from './ExamineeView';

import './EnterPasscode.css';

export default class EnterPasscode extends Component {
  constructor() {
    super();
    this.changePasscode = this.changePasscode.bind(this);
    this.submitPasscode = this.submitPasscode.bind(this);
    this.state = {
      passcode: '',
      message: '',
      joinButton: 'JOIN',
      verifyingPasscode: false
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.user.message !== '') {
      this.setState({
        message: nextProps.user.message,
        joinButton: 'JOIN',
        verifyingPasscode: false
      });
    }
  }

  changePasscode(e) {
    this.setState({
      passcode: e.target.value
    });
  }

  submitPasscode(e) {
    e.preventDefault();
    if (this.state.passcode.includes(' ')) {
      this.setState({
        message: 'Passcode cannot contain spaces',
      });
      return;
    }
    this.setState({
      joinButton: <ProgressSpinner size="small" />,
      verifyingPasscode: true
    });
    this.props.enterPasscode(this.state.passcode);
  }

  render() {
    return (
      <ExamineeView>
        <div className="enter-token-block pure-u-1">
          <form className="pure-form pure-form-stacked token-input-form">
            <div className="pure-u-1">
              { this.props.user.assignedProducts.length === 0 ?
                <p>
                  <b> There are no products in your dashboard.
                    Please enter a passcode to join a group.
                  </b> &nbsp;Please contact your administrator for a passcode.
                </p>
                : (<h3>Please enter a new passcode.</h3>)
              }
            </div>

            <div className="pure-g pure-u-1">
              <div className="pure-u-sm-1-3 pure-u-1">
                <input
                  id="passcode"
                  className=""
                  type="text"
                  placeholder="Enter passcode"
                  value={this.state.passcode}
                  onChange={this.changePasscode}
                />
                <InlineValidationMessage
                  message={this.state.message}
                />
              </div>
            </div>
            <div className="pure-g pure-u-1">
              <div className="pure-u-sm-1-3 pure-u-1">
                <button
                  id="join"
                  className="pure-button pure-button-primary pure-control"
                  onClick={this.submitPasscode}
                  disabled={this.state.verifyingPasscode}
                >
                  {this.state.joinButton}
                </button>
              </div>
            </div>
          </form>
        </div>
      </ExamineeView>
    );
  }
}

EnterPasscode.propTypes = {
  enterPasscode: PropTypes.func.isRequired,
  user: PropTypes.shape({
    message: PropTypes.string,
    assignedProducts: PropTypes.arrayOf(PropTypes.shape({
      length: PropTypes.number
    }))
  }).isRequired
};
