import { httpClient } from '../utils/httpClient';

export const CUSTOMER_DATA_REQUEST = 'CUSTOMER_DATA_REQUEST';
export const CUSTOMER_DATA_RECEIVED = 'CUSTOMER_DATA_RECEIVED';

export const customerDataRequested = () => ({
  type: CUSTOMER_DATA_REQUEST,
  payload: {}
});

export const customerDataReceived = (customer) => ({
  type: CUSTOMER_DATA_RECEIVED,
  payload: { ...customer }
});

export const getCustomerData = () => (dispatch) => (
  httpClient
    .get('/customer/')
    .then((response) => {
      dispatch(customerDataReceived(response.data.customer));
    })
    .catch((err) => console.error('ERROR loading customer data:', err))
);
