import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EapButton from '../../components/EapButton';

import './WizardButtonBar.css';

const WizardButtonBar = ({
  next,
  nextButtonDisabled,
  overrideNextText,
  previous
}) => {
  const [inProgress, setInProgress] = useState(false);
  return (
    <div className="wizard-button-bar pure-u-1">
      <div className="pure-u-1-4">
        {previous &&
        <EapButton
          onClick={previous}
          text="PREVIOUS"
        />
        }
      </div>
      <div className="pure-u-1-2" />
      { next &&
        <div className="pure-u-1-4">
          <EapButton
            onClick={() => { setInProgress(true); next(); }}
            optionalClass="next-btn"
            text={overrideNextText || 'NEXT'}
            inProgress={inProgress}
            disabled={nextButtonDisabled || inProgress}
          />
        </div>
      }
    </div>
  );
};

WizardButtonBar.propTypes = {
  nextButtonDisabled: PropTypes.bool,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func,
  overrideNextText: PropTypes.string
};

WizardButtonBar.defaultProps = {
  nextButtonDisabled: false,
  previous: undefined,
  overrideNextText: undefined
};

export default WizardButtonBar;
