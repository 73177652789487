// Ryan was here
/* eslint no-param-reassign: 0 */
import _ from 'lodash';

import {
  GET_STATUS_REQUEST,
  GET_STATUS_SUCCESS,
  GET_STATUS_FAILURE,
  RESET_SUCCESS,
  RESET_FAILURE,
  RELEASE_RESULTS_SUCCESS,
  SUPER_REVOKE_ASSIGNED_PRODUCT_SUCCESS,
  SUPER_SUPPRESS_RESULTS_SUCCESS
} from '../actions/assignedProducts';

import {
  GET_SUPER_GROUP_EXAMINEES_SUCCESS,
  SUPER_ASSIGN_PRODUCT_SUCCESS,
} from '../actions/examinees';

import {
  SUPER_LOAD_USER_SUCCESS
} from '../actions/user';

const assignedProductsReducer = (
  state = {
    list: [],
    modalId: undefined
  },
  action
) => {
  switch (action.type) {
    case GET_STATUS_REQUEST: {
      const newState = { ...state };
      newState.status = undefined;
      return newState;
    }
    case GET_STATUS_FAILURE: {
      const newState = { ...state };
      newState.status = new Error(action.payload.message);
      return newState;
    }
    case GET_STATUS_SUCCESS: {
      const newState = { ...state };
      newState.status = action.payload;
      if (_.isEmpty(action.payload)) {
        newState.status = {
          isEmpty: true
        };
      }
      newState.status.isLoaded = true;
      return newState;
    }
    case GET_SUPER_GROUP_EXAMINEES_SUCCESS: {
      const list = _.cloneDeep(state.list);
      _.each(action.payload.examinees, (ex) => {
        _.each(ex.assigned, (prod) => {
          list.push({
            ...prod,
            ...{
              userId: ex.userId,
              groupId: action.payload.groupId
            }
          });
        });
      });
      return {
        ...state,
        list: _.uniqBy(list, 'id'),
      };
    }
    case RELEASE_RESULTS_SUCCESS: {
      const newState = _.cloneDeep(state);
      const prod = _.find(newState.list, { id: action.payload.assignedProductId });
      if (prod) {
        prod.configs.suppressed = false;
        if (prod.status === 'Pending') {
          prod.status = 'Results';
        }
      }
      return newState;
    }
    case RESET_SUCCESS: {
      const newState = { ...state };
      newState.status.isFinished = false;
      newState.status.isScored = false;
      newState.status.subTests.forEach((sub) => {
        if (sub.id === action.original.subTestId) {
          sub.isFinished = false;
        }
      });
      return newState;
    }
    case RESET_FAILURE: {
      const newState = { ...state };
      newState.status = new Error(action.payload.message);
      return newState;
    }
    case SUPER_LOAD_USER_SUCCESS: {
      const list = _.cloneDeep(state.list);
      _.each(action.payload.userData.assignedProducts, (prod) => {
        list.push({
          ...prod,
          ...{
            userId: action.payload.userData.id
          }
        });
      });
      return {
        ...state,
        list: _.uniqBy(list, 'id')
      };
    }
    case SUPER_ASSIGN_PRODUCT_SUCCESS: {
      const newState = _.cloneDeep(state);
      newState.list.push({
        ...action.payload.assignedProduct,
        userId: action.payload.userId,
        groupId: action.payload.groupId
      });
      return newState;
    }
    case SUPER_REVOKE_ASSIGNED_PRODUCT_SUCCESS: {
      const newState = _.cloneDeep(state);
      _.remove(newState.list, { id: action.payload.assignedProductId });
      return newState;
    }
    case SUPER_SUPPRESS_RESULTS_SUCCESS: {
      const newState = _.cloneDeep(state);
      const prod = _.find(newState.list, { id: action.payload.assignedProductId });
      if (prod) {
        prod.configs.suppressed = true;
        if (prod && prod.status === 'Results') {
          prod.status = 'Pending';
        }
      }
      return newState;
    }
    default: {
      return { ...state };
    }
  }
};

export default assignedProductsReducer;
