import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EditProfileForm from './EditProfileForm';
import SensibleContainer from '../components/SensibleContainer';
import EapThrone from '../components/EapThrone';
import FormHeader from '../components/FormHeader';

import './EditSignin.css';

export default class EditSignin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateError: undefined,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.updateError) {
      this.setState({
      });
    }
  }

  handleSubmit(values) {
    this.props.updateSignin({
      ...values,
      currentPassword: values.password,
    });
  }

  render() {
    return (
      <SensibleContainer>
        <EapThrone className="update-signin-page">
          <div className="pure-u-3-4">
            <FormHeader
              headerText="Update Sign-in"
            />
            <div className="pure-u-1-1">
              <EditProfileForm
                initialValues={this.props.user}
                onUpdateProfile={this.handleSubmit}
              />
            </div>
          </div>
          <div className="pure-u-3-4 form-container">
            {this.state.updateError}
          </div>
        </EapThrone>
      </SensibleContainer>
    );
  }
}

EditSignin.propTypes = {
  updateSignin: PropTypes.func.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    gender: PropTypes.string,
    grade: PropTypes.number,
    dob: PropTypes.string,
    updateError: PropTypes.string
  }).isRequired
};
