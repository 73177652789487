import React from 'react';

const StagingLabel = () => (
  <div style={{
    color: 'white',
    marginLeft: '1rem',
    backgroundColor: 'red',
    padding: '.5rem',
    borderRadius: '5px'
    }}
  >
    STAGING
  </div>
);

export default StagingLabel;
