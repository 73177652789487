import React from 'react';
import PropTypes from 'prop-types';

import './EapGeneralHeader.css';

const EapGeneralHeader = ({ text }) => (
  <h2 className="eap-general-header">
    {text}
  </h2>
);

EapGeneralHeader.propTypes = {
  text: PropTypes.string.isRequired
};

export default EapGeneralHeader;
