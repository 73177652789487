/* Custom EAP Alert
 *
 * **Displaying the Alerts**
 * In order to display alerts, simply place the `<AlertContainer />` element
 * within your script.
 *
 * **Triggering Alerts***
 * You can either dispatch or directly push an alert. First you must import the
 * alert actions from the file `actions/alert.js`. The recommended method is to
 * dispatch the alerts from within your actions file. However, you can use
 * `pushAlert` anywhere.
 *
 * dispatchAlert({ message, type, time })
 *    returns dispatch function
 * pushAlert({message, type, time})
 *    void function.
 *
 * Message: <JSX> Required.
 *    Message to display.
 * Type: <string> Optional.
 *    Type of alert. It can be either `eap-alert-info` (default),
 *    `eap-alert-success`, `eap-alert-error`
 * Time: <int> Optionl
 *    Time to display in milliseconds. If <= 0, the alert will display until
 *    dismissed.
 */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Alert from './Alert';
import * as Actions from '../actions/alert';

const mapStateToProps = state => ({ alerts: state.alerts });

const mapDispatchToProps = dispatch => ({
  dismiss: (id) => {
    dispatch(Actions.dispatchDismiss(id));
  }
});

const AlertContainer = ({ alerts, dismiss }) => (
  <div className="eap-alert-area">
    { _
        .chain(alerts)
        .filter({ active: true })
        .map(a => (
          <Alert
            key={`alert_${a.id}`}
            id={a.id}
            message={a.message}
            type={a.type}
            time={a.time}
            dismiss={() => dismiss(a.id)}
          />
          ))
        .value()
      }
  </div>
);

AlertContainer.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
  dismiss: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertContainer);
