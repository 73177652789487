/* See AlertContainer for documentation */
import React from 'react';
import PropTypes from 'prop-types';
import './alert.css';

const Alert = ({
  message, type, dismiss, id
}) => {
  const newAlert = (
    <button
      className="eap-alert-cancel transparent-button"
      onClick={(e) => { e.preventDefault(); dismiss(); }}
      key={`alertBtnId_${id}`}
    >
      <span
        className={`fa ${type === 'eap-alert-success' ? 'fa-check' : 'fa-times'}`}
        style={{ marginRight: '.5em' }}
        key={`alertSpanId_${id}`}
      />
    </button>
  );
  return (
    <div className={`eap-alert-box ${type}`} key={`alertDiv_${id}`}>
      {newAlert}
      {message}
    </div>
  );
};

Alert.propTypes = {
  id: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
  message: PropTypes.element.isRequired,
  type: PropTypes.string,
};

Alert.defaultProps = {
  type: 'eap-alert-info',
};

export default Alert;
