import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// NOTE: Optional customFormat is a moment-specific string format
export const Display = ({ date, customFormat }) => (
  <span>{moment(date).format(customFormat)}</span>
);

Display.propTypes = {
  date: PropTypes.string.isRequired, // In ISO format.
  customFormat: PropTypes.string
};

Display.defaultProps = {
  customFormat: 'MM/DD/YYYY'
};

export const sort = (a, b) => (moment.utc(a).diff(moment.utc(b)));

export default Display;
