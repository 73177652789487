import { connect } from 'react-redux';
import InviteSpinner from './components/InviteSpinner';
import { acceptAdminInvite } from './actions/adminInvites';

const mapStateToProps = (state) => {
  if (!state.user) {
    return {};
  }
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => ({
  acceptInvite: (token) => {
    dispatch(acceptAdminInvite(token));
  }
});

const AdminInviteSpinnerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteSpinner);

export default AdminInviteSpinnerContainer;
