import { connect } from 'react-redux';
import Information from './components/Information';
import extractAndSortPermissions from '../utils/sortPermissions';

const mapStateToProps = (state, ownProps) => {
  const selectedGroup = state.group.groupsById[ownProps.groupId];
  const totalProductCount = selectedGroup.availableProducts
    ? selectedGroup.availableProducts.reduce((acc, cur) => cur.amount + acc, 0)
    : 0;

  return {
    group: {
      ...selectedGroup,
      permissions: extractAndSortPermissions(state.user.adminGroups, ownProps.groupId)
    },
    adminCount: (selectedGroup.admins) ? selectedGroup.admins.length : undefined,
    examineeCount: selectedGroup.examinees || undefined,
    availableProductCount: totalProductCount,
    errorMessage: state.availableProducts.errorMessage
  };
};

const mapDispatchToProps = () => ({
});

const InformationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Information);

export default InformationContainer;
