/* eslint jsx-a11y/label-has-associated-control:0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import EapButton from '../../components/EapButton';
import ProgressSpinner from '../../components/ProgressSpinner';

const Square = ({
  tokenState,
  uri,
  init,
  refresh,
  saveToken,
  loadHooks,
  hooks,
  setHooks,
  removeHook,
}) => {
  const { pathname, search } = useLocation();
  const [baseUrl, setBaseUrl] = useState('');
  const [invalidForm, setInvalidForm] = useState('');
  useEffect(() => {
    if (pathname === '/super/squarespace-success') {
      const params = new URLSearchParams(search);
      saveToken({
        code: params.get('code'),
        state: params.get('state'),
      });
    } else {
      init();
    }
  }, []);

  useEffect(() => {
    if (tokenState === 'insync') {
      loadHooks();
    }
  }, [tokenState]);

  let hooksCom;
  if (hooks === null) {
    hooksCom = (<ProgressSpinner />);
  } else if (hooks !== null && hooks.length > 0) {
    hooksCom = (
      <table>
        <tbody>
          {
            hooks.map((hook) => (
              <tr>
                <td>{hook.endpointUrl}</td>
                <td>
                  <EapButton
                    onClick={() => { removeHook({ id: hook.id }); }}
                    text="REMOVE"
                  />
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    );
  } else {
    hooksCom = (<p>No webhooks</p>);
  }
  return (
    <div>
      <h1>Squarespace</h1>
      <EapButton
        text={`${tokenState === 'oauth' ? 'Connect' : 'Refresh'} Token`}
        onClick={() => {
          if (tokenState === 'oauth') {
            window.location = uri;
          } else {
            refresh();
          }
        }}
      />
      <h2>Hooks</h2>
      { hooksCom }
      { hooks !== null ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!baseUrl) {
              setInvalidForm('Please enter a base url');
            } else {
              setHooks({ uri: baseUrl });
            }
          }}
        >
          <label htmlFor="baseUrl">Base Hooks Url:</label>
          <input
            id="baseUrl"
            type="text"
            value={baseUrl}
            onChange={(e) => {
              setInvalidForm('');
              setBaseUrl(e.target.value);
            }}
          />
          <button type="submit">Register</button>
          { invalidForm ? (
            <div>Please enter a url</div>
          ) : ''}
        </form>
      ) : '' }
    </div>
  );
};

Square.propTypes = {
  init: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default Square;
