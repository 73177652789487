import { connect } from 'react-redux';

import {
  addNewProduct,
  incrementAvailableProducts,
} from '../../actions/availableProducts';

import GroupProductsTable from './components/GroupProductsTable';

const mapStateToProps = (state) => {
  if (state.group.info === undefined) {
    return {
      group: {},
      products: [],
      relatedGroups: [],
    };
  }
  const group = state.group.info;
  const products = state.availableProducts.list.filter((prod) => (prod.groupId === group.id));
  const relatedGroups = state.groups.list.filter((g) => (
    g.organizationId === group.organizationId
  ));

  return {
    products,
    group,
    relatedGroups,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNewProduct: (
    groupId,
    productId,
    amount
  ) => dispatch(addNewProduct(groupId, productId, amount)),
  incrementAvailableProducts: (
    availableProductId,
    groupId,
    amount
  ) => dispatch(incrementAvailableProducts(availableProductId, groupId, amount))
});

const GroupProductsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupProductsTable);

export default GroupProductsTableContainer;
