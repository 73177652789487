import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StaticEapTable } from '../../../components/StaticEapTable';
import SectionHeader from '../../components/SectionHeader';
import * as EapDisplayDate from '../../../components/EapDisplayDate';
import AdminIconList from '../../../components/AdminIconList';
import calculateActionsWidth from '../../../utils/EapTableHelper';
import AddProductForm from './AddProductForm';
import AdminDeleteProductModal from './AdminDeleteProductModal';
import AdminTransferProductModalContainer from '../AdminTransferProductModalContainer';
import PasscodeRegenerationDialogContainer from '../../../components/PasscodeRegenerationDialogContainer';
import AdminIcon from '../../../components/AdminIcon';
import AddSubtractProductModal from './AddSubtractProductModal';

const GroupProductsTable = ({
  group,
  products,
  relatedGroups,
  addNewProduct,
  incrementAvailableProducts,
}) => {
  const [showAddSubModal, setShowAddSubModal] = useState();
  const columns = [
    {
      Header: 'Code',
      accessor: 'code'
    },
    {
      Header: 'Examinee Product',
      accessor: 'name'
    },
    {
      Header: 'Admin Product',
      accessor: 'adminName'
    },
    {
      Header: 'Passcode',
      accessor: 'passcode'
    },
    {
      Header: 'Amount',
      accessor: 'amount'
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      filterable: false,
      Cell: ({ row }) => <EapDisplayDate.Display date={row.original.updatedAt} />,
      sort: EapDisplayDate.sort
    },
    {
      Header: 'Actions',
      filterable: false,
      sortable: false,
      maxWidth: calculateActionsWidth({ numberOfIcons: 5 }),
      accessor: '',
      Cell: ({ row }) => (
        <AdminIconList>
          <AdminIcon
            onClick={() => setShowAddSubModal({
              type: 'add',
              product: {
                ...row.original
              },
            })}
            icon="fa-plus"
            toolTip="Add Product"
          />
          <AdminIcon
            onClick={() => setShowAddSubModal({
              type: 'subtract',
              product: {
                ...row.original
              },
            })}
            icon="fa-minus"
            toolTip="Subtract Product"
          />
          {/*
          <AdminProductsInventoryModal
            inventoryType={ProductInventoryType.ADD}
            availableProduct={row.original}
            currentAmount={row.original.amount}
            onProductsAdded={() => {}}
            groupId={group.id}
          />
          <AdminProductsInventoryModal
            inventoryType={ProductInventoryType.SUBTRACT}
            availableProduct={row.original}
            currentAmount={row.original.amount}
            onProductsAdded={() => {}}
            groupId={group.id}
          />
          */}
          <AdminDeleteProductModal
            groupName={group.name}
            availableProduct={row.original}
            onProductDeleted={() => {}}
            groupId={group.id}
          />
          <AdminTransferProductModalContainer
            organizationId={group.organizationId}
            amount={row.original.amount}
            allowedGroups={relatedGroups}
            availableProductId={row.original.id}
            onProductsTransferred={() => {}}
            groupId={group.id}
          />
          <PasscodeRegenerationDialogContainer
            availableProduct={row.original}
            groupId={group.id}
          />
        </AdminIconList>
      )
    }
  ];

  return (
    <div className="pure-u-1">
      <StaticEapTable
        columns={columns}
        data={products}
      />
      <SectionHeader title="Add Product(s)" />
      <AddProductForm
        onSubmit={({ amount, product }) => {
          addNewProduct(group.id, product.id, amount);
        }}
      />
      <AddSubtractProductModal
        type={showAddSubModal ? showAddSubModal.type : ''}
        availableProduct={showAddSubModal ? showAddSubModal.product : {}}
        onClose={({ amount } = {}) => {
          if (amount) {
            incrementAvailableProducts(
              showAddSubModal.product.id,
              showAddSubModal.product.groupId,
              amount,
            );
          }
          setShowAddSubModal(undefined);
        }}
        showModal={showAddSubModal}
      />
    </div>
  );
};

GroupProductsTable.propTypes = {
  addNewProduct: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  group: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  relatedGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
};

GroupProductsTable.defaultProps = {
  products: undefined,
};

export default GroupProductsTable;
