import React from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useSortBy,
  useFilters,
  useBlockLayout,
  useResizeColumns,
} from 'react-table';
import _ from 'lodash';

import './StaticEapTable.css';

export const DefaultFilter = ({
  column
}) => (
  <input
    placeholder="Search"
    value={column.filterValue || ''}
    onChange={(e) => column.setFilter(e.target.value || undefined)}
  />
);

DefaultFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.node,
    setFilter: PropTypes.func
  }).isRequired
};

const renderColumnSort = (column) => {
  if (!column.canSort) {
    return '';
  }
  if (!column.isSorted) {
    return (<span className="header-arrow fa fa-arrow-circle-right" />);
  }
  if (column.isSortedDesc) {
    return (<span className="header-arrow fa fa-arrow-circle-down" />);
  }
  return <span className="header-arrow fa fa-arrow-circle-up" />;
};

const renderColumnFilter = (column) => {
  if (!column.canFilter) {
    return '';
  }
  return (column.render('Filter'));
};

export const DynamicEapTable = ({
  columns,
  data,
  fetchData,
}) => {
  const [showFilter, setShowFilter] = React.useState(false);
  const [width, setWidth] = React.useState(100);
  const ref = React.useRef(null);

  // register window listener for resizing
  React.useEffect(() => {
    const handleResize = () => {
      setWidth(ref.current.getBoundingClientRect().width);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  // evenly compute the width of each column
  const cols = React.useMemo(() => {
    let w = width / columns.length;
    if (w < 100) { w = 100; }
    return columns.map((c) => ({
      width: w,
      ...c
    }));
  }, [width, columns]);


  const defaultColumn = {
    minWidth: 50,
    Filter: DefaultFilter
  };

  const instance = useTable(
    {
      columns: cols,
      data,
      manualSorting: true,
      manualFilters: true,
      defaultColumn,
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    useBlockLayout,
    useResizeColumns,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: {
      filters: filtered,
      sortBy: sorted,
    }
  } = instance;

  const [limitSize, setLimitSize] = React.useState(25);

  const pageOptions = [
    5,
    10,
    20,
    25,
    50,
    100,
    1000
  ];

  React.useEffect(() => {
    const sendFilter = [];
    _.forEach(filtered, ({ id, value }) => {
      const c = _.find(columns, { accessor: id });
      const v = (c && c.filter) ? c.filter(value) : value;
      sendFilter.push({
        id,
        value: v
      });
    });
    fetchData({
      filtered: sendFilter,
      sorted: sorted[0],
      limit: limitSize
    });
  }, [filtered, sorted, limitSize]);

  return (
    <div className="eap-table">
      <button
        className="filter-button"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setShowFilter(!showFilter);
        }}
      >
        <span className={`fa ${showFilter ? 'fa-search-minus' : 'fa-search-plus'}`} />
        Filter/Search
        <span className="screen-reader"> the following table:</span>
      </button>

      <table {...getTableProps()} ref={ref}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="text-header" {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header').toUpperCase()}
                    &nbsp;
                    {renderColumnSort(column)}
                  </div>
                  <div {...column.getResizerProps()} className="resizer" />
                </th>
              ))}
            </tr>
          ))}
          {showFilter ? headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div className="filter-container">
                    {renderColumnFilter(column)}
                  </div>
                  <div {...column.getResizerProps()} className="resizer" />
                </th>
              ))}
            </tr>
          )) : undefined}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>
                      {cell.value === null ? '' : cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            }
          )}
        </tbody>
      </table>
      <div className="eap-table-footer">
        <select
          value={limitSize}
          onChange={(e) => {
            setLimitSize(Number(e.target.value));
          }}
        >
          {pageOptions.map((pZ) => (
            <option key={pZ} value={pZ}>
              {`Show ${pZ}`}
            </option>
          ))}
        </select>
        <div className="totalRows">
          <small><em>{`(Total rows: ${rows.length})`}</em></small>
        </div>
      </div>
    </div>
  );
};

DynamicEapTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    Header: PropTypes.string,
    accessor: PropTypes.string
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  fetchData: PropTypes.func.isRequired
};

DynamicEapTable.defaultProps = {
  data: []
};

export default DynamicEapTable;
