/* eslint jsx-a11y/label-has-associated-control:0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import EapButton from '../../components/EapButton';
import EapSelectList from '../../components/EapSelectList';

const MoveExaminee = ({
  adminId,
  adminGroups,
  moving,
  moveExaminee,
  examinee,
  moveError,
  closeModal,
}) => {
  const [selectedGroupId, setSelectedGroupId] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const targetGroup = adminGroups.find((adminGroup) => adminGroup.id === selectedGroupId);
    if (!targetGroup) {
      return;
    }
    if (moving) {
      return;
    }
    /*
    if (!targetGroup) {
      this.setState({
        errorMessage: 'Please select a destination group.'
      });
      return;
    }
    */
    moveExaminee(
      adminId,
      examinee,
      targetGroup
    );
  };

  // create the choices
  const choiceObjects = adminGroups.filter((adminGroup) => (
    adminGroup.per_users_view && adminGroup.per_users_move && adminId !== adminGroup.id
  )).map((adminGroup) => ({
    value: adminGroup.id,
    label: adminGroup.name
  }));
  choiceObjects.unshift({
    label: '---Please Select---',
    value: ''
  });

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="pure-form pure-form-aligned eap-grey-form"
      >
        <div className="pure-control-group">
          <label htmlFor="destinationGroupId">
            <b> Move user to: </b>
          </label>
          <EapSelectList
            id="destinationGroupId"
            value={selectedGroupId}
            onChange={(e) => {
              const id = parseInt(e.target.value, 10);
              setSelectedGroupId(id);
            }}
            choices={choiceObjects}
            className="pure-u-1-2"
          />
        </div>
        {
          moveError && (
            <div className="pure-control-group">
              <label htmlFor="errorMessage" />
              <span className="validation-message">{moveError}</span>
            </div>
          )
        }
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EapButton
            isSubmit
            disabled={moving || selectedGroupId === ''}
            text="Move"
          />
          &nbsp;
          <EapButton
            text="Cancel"
            onClick={closeModal}
          />
        </div>
      </form>
    </div>
  );
};

MoveExaminee.propTypes = {
  adminId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  adminGroups: PropTypes.arrayOf(PropTypes.shape({
    per_products_view: PropTypes.bool,
    per_products_transfer: PropTypes.bool,
    groupId: PropTypes.number
  })).isRequired,
  moveExaminee: PropTypes.func.isRequired,
  examinee: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  moveError: PropTypes.string
};

MoveExaminee.defaultProps = {
  moveError: undefined,
};

export default MoveExaminee;
