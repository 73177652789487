import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import * as validator from '@edits/eap-signup-validator';
import {
  getInputTokens,
  findDuplicates,
  getInvalidEmails
} from '../utils/email-list-validator';

import InlineValidationMessage from './InlineValidationMessage';

import './EmailListTextArea.css';

export function getErrorString(opts) {
  if (opts.invalid.length > 0) {
    return `The following email addresses are invalid: ${_.join(opts.invalid, ', ')}`;
  }
  if (opts.duplicates.length > 0) {
    return `The following email addresses are repeated: ${_.join(opts.duplicates, ', ')}`;
  }
  return undefined;
}

class EmailListTextArea extends Component {
  constructor(props) {
    super(props);
    this.parseOnChange = this.parseOnChange.bind(this);
  }

  parseOnChange(e) {
    const tokens = getInputTokens(e.target.value);
    const invalid = getInvalidEmails(tokens);
    const duplicates = findDuplicates(tokens);

    const isValid = tokens.length > 0 && invalid.length === 0 && duplicates.length === 0;

    this.props.onChange(tokens, isValid, {
      raw: e.target.value,
      invalid,
      duplicates
    });
  }

  render() {
    return (
      <div
        style={{
          // hack: not sure why text area won't obey parent constraints
          paddingRight: '.5em'
        }}
      >
        <label htmlFor="email-list-text-area">INVITEE&#8217;S EMAIL(S)</label>
        <textarea
          id="email-list-text-area"
          value={this.props.value}
          onChange={this.parseOnChange}
          rows={this.props.rows}
          placeholder="Enter valid email addresses separated by commas."
        />
        { this.props.message ?
          <InlineValidationMessage
            message={this.props.message}
          />
          : ''
        }
      </div>
    );
  }
}

EmailListTextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  message: PropTypes.string,
  rows: PropTypes.number,
};

EmailListTextArea.defaultProps = {
  message: '',
  rows: 5,
};

export default EmailListTextArea;

