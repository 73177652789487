import _ from 'lodash';

import {
  REVOKE_ADMIN_INVITE_SUCCESS,
  SEND_ADMIN_INVITES_SUCCESS,
  SUPER_GET_ADMIN_INVITES_SUCCESS,
} from '../actions/adminInvites';

const adminInviteReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case REVOKE_ADMIN_INVITE_SUCCESS: {
      const list = _.cloneDeep(state.list);
      _.remove(list, { id: action.payload.adminInviteId });
      return {
        ...state,
        list
      };
    }
    case SEND_ADMIN_INVITES_SUCCESS: {
      const list = [
        ..._.cloneDeep(state.list),
        ...action.payload.newInvites
      ];
      return {
        ...state,
        list
      };
    }
    case SUPER_GET_ADMIN_INVITES_SUCCESS: {
      const list = _.cloneDeep(state.list);
      action.payload.invites.forEach((invite) => {
        list.push({
          ...invite,
          groupId: _.parseInt(action.payload.groupId, 10)
        });
      });
      return {
        ...state,
        list: _.uniqBy(list, 'id')
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default adminInviteReducer;
