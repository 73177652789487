import { httpClient } from '../utils/httpClient';

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';

export const getAllProductsSucceeded = (productList) => ({
  type: GET_ALL_PRODUCTS_SUCCESS,
  payload: { products: productList }
});

export function getAllProducts() {
  return (dispatch) => httpClient.get('/super/products')
    .then((response) => {
      dispatch(getAllProductsSucceeded(response.data));
    }).catch((err) => {
      console.error('Problem retrieving all EAP products.', err);
    });
}
