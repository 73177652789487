import React from 'react';
import PropTypes from 'prop-types';

import './SectionHeader.css';

const SectionHeader = ({ title }) => <h2 className="super-section-header">{title}</h2>;

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default SectionHeader;
