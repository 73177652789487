import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';
import GroupsTable from './GroupsTable';
import GroupContentTab from '../../group/components/GroupContentTab';
import AddGroupLink from './AddGroupLink';
import AddGroup from '../../components/AddGroup';
import Products from './Products';
import NavigationBar from '../../components/NavigationBar';
import ExamineeView from './ExamineeView';
import AllExaminees from './AllExamineesContainer';
import Purchases from '../PurchasesContainer';

import findCopyableGroups from '../../utils/AdminGroupHelper';

const Wrapper = ({ type, children, adminGroups }) => {
  let navBar = '';
  if (type === 'admin') {
    let routes = [
      { path: 'groups', icon: 'fa-sitemap' },
      { path: 'examinees', icon: 'fa-users', text: 'All Examinees' },
      { path: 'purchases', icon: 'fa-dollar', text: 'Your Purchases' },
      { path: 'products', icon: 'fa-cubes', text: 'Your Products & Samples' },
    ];
    if (findCopyableGroups(adminGroups).length === 0) {
      routes = _.remove(routes, (route) => route.path !== 'add-group');
    }
    navBar = (
      <NavigationBar
        routes={routes}
        base="dashboard"
      />
    );
  }
  return (
    <>
      <div className="pure-u-1">
        {navBar}
      </div>
      <div className="pure-u-1">
        <GroupContentTab>
          {children}
        </GroupContentTab>
      </div>
    </>
  );
};

const NotFound = () => (
  <div>Page not found.</div>
);

const computeType = (user) => {
  if (user.permissions.administrator) {
    return 'admin';
  }
  if (user.assignedProducts.length === 0) {
    return 'passcode';
  }
  return 'examinee';
};

const computeRedirect = (type, match) => {
  if (type === 'passcode') {
    return (<Redirect to="/passcode" />);
  }
  if (type === 'examinee') {
    return (<Redirect to={`${match.url}/products`} />);
  }
  if (type === 'admin') {
    return (<Redirect to={`${match.url}/groups`} />);
  }
  return (<Redirect to={`${match.url}/error`} />);
};

const Dashboard = ({
  user, loadUserData, match, addNewGroup
}) => {
  if (!user || !user.username) {
    loadUserData();
    return '';
  }

  const type = computeType(user);

  return (
    <Switch>
      <Route
        path={`${match.url}/examinees`}
      >
        <Wrapper
          type={type}
          adminGroups={user.adminGroups}
        >
          <AllExaminees />
        </Wrapper>
      </Route>
      <Route
        path={`${match.url}/purchases`}
        render={() => (user.permissions.administrator ? (
          <Wrapper
            type={type}
            adminGroups={user.adminGroups}
          >
            <Purchases />
          </Wrapper>
        ) : (
          <ExamineeView>
            <Products user={user} />
          </ExamineeView>
        ))}
      />
      <Route
        path={`${match.url}/products`}
        render={() => (user.permissions.administrator ? (
          <Wrapper
            type={type}
            adminGroups={user.adminGroups}
          >
            <Products user={user} />
          </Wrapper>
        ) : (
          <ExamineeView>
            <Products user={user} />
          </ExamineeView>
        ))}
      />
      <Route
        path={`${match.url}/groups`}
        render={() => (
          <Wrapper
            type={type}
            adminGroups={user.adminGroups}
          >
            <GroupsTable groups={user.adminGroups} />
            <AddGroupLink />
          </Wrapper>
        )}
      />
      <Route
        path={`${match.url}/add-group`}
        render={() => (
          <Wrapper type={type} adminGroups={user.adminGroups}>
            <AddGroup
              groups={findCopyableGroups(user.adminGroups)}
              submit={addNewGroup}
            />
          </Wrapper>
        )}
      />
      <Route
        path={`${match.url}`}
        render={() => computeRedirect(type, match)}
        exact
      />
      <Route
        path={`${match.url}/*`}
        component={NotFound}
      />
    </Switch>
  );
};

Wrapper.propTypes = {
  type: PropTypes.oneOf(['admin', 'passcode', 'examinee']).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  adminGroups: PropTypes.arrayOf(PropTypes.shape({
    per_create: PropTypes.bool
  })).isRequired
};

Dashboard.propTypes = {
  loadUserData: PropTypes.func.isRequired,
  addNewGroup: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  })
};

Dashboard.defaultProps = {
  user: {
    username: ''
  }
};

export default Dashboard;
