import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrgForm from './components/OrganizationEditor';
import { createOrganization } from '../../actions/organization';

const OrgCreate = ({ create }) => (
  <OrgForm
    buttonText="Create"
    onSubmit={create}
  />
);

OrgCreate.propTypes = {
  create: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  if (state.organization.creating) {
    return { creating: true };
  }
  return {};
};

const mapDispatchToProps = dispatch => ({
  create: (newOrgInfo) => {
    dispatch(createOrganization(newOrgInfo));
  }
});

const OrganizationsCreationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgCreate);

export default OrganizationsCreationsContainer;
