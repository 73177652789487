import { connect } from 'react-redux';
import ExamineeInvites from './components/ExamineeInvites';

import {
  revokeExamineeInvite,
  sendExamineeInvites,
  resendExamineeInvite
} from '../actions/examineeInvites';
import extractAndSortPermissions from '../utils/sortPermissions';

const mapStateToProps = (state, ownProps) => {
  if (!state.user.permissions.administrator) {
    return {
      unauthorized: true
    };
  } else if (state.group.loading) {
    return {
      unauthorized: false,
      group: { loading: true }
    };
  } else if (state.group.message) {
    return {
      unauthorized: false,
      group: {
        loading: false,
        message: state.group.message
      }
    };
  }

  const adminPermissions = extractAndSortPermissions(state.user.adminGroups, ownProps.groupId);
  const selectedGroup = state.group.groupsById[ownProps.groupId];

  return {
    unauthorized: false,
    organizationId: selectedGroup.organizationId,
    permissions: adminPermissions.admin,
    adminGroupId: adminPermissions.id,
    // treat null status as sent for legacy invites
    invites: selectedGroup.userInvites.map((invite) => ({
      ...invite,
      ...{ status: invite.status || 'Sent' }
    })),
    availableProducts: selectedGroup.availableProducts,
    sending: state.examineeInvites.sending
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  revokeInvite: (adminGroupId, inviteRowData) => {
    dispatch(revokeExamineeInvite(adminGroupId, inviteRowData));
  },
  sendInvites: (adminGroupId, emailList, availableProductId) => {
    dispatch(sendExamineeInvites(
      adminGroupId,
      ownProps.groupId,
      emailList,
      availableProductId
    ));
  },
  resendInvite: (adminGroupId, inviteId) => {
    dispatch(resendExamineeInvite(adminGroupId, inviteId));
  }
});

const ExamineeInvitesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamineeInvites);

export default ExamineeInvitesContainer;
