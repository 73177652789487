import React from 'react';

import { httpClient } from '../utils/httpClient';
import history from '../utils/history';
import { dispatchAlert } from './alert';

export const REVOKE_EXAMINEE_INVITE_SUCCESS = 'REVOKE_EXAMINEE_INVITE_SUCCESS';
export const REVOKE_EXAMINEE_INVITE_FAILURE = 'REVOKE_EXAMINEE_INVITE_FAILURE';
export const REIMBURSE_AVAILABLE_PRODUCT = 'REIMBURSE_AVAILABLE_PRODUCT';
export const SEND_EXAMINEE_INVITES_REQUEST = 'SEND_EXAMINEE_INVITES_REQUEST';
export const SEND_EXAMINEE_INVITES_SUCCESS = 'SEND_EXAMINEE_INVITES_SUCCESS';
export const SEND_EXAMINEE_INVITES_FAILURE = 'SEND_EXAMINEE_INVITES_FAILURE';
export const SUPER_GET_EXAMINEE_INVITES_SUCCESS = 'SUPER_GET_EXAMINEE_INVITES_SUCCESS';
export const SUPER_GET_EXAMINEE_INVITES_FAILURE = 'SUPER_GET_EXAMINEE_INVITES_FAILURE';
export const ACCEPT_EXAMINEE_INVITE_SUCCESS = 'ACCEPT_EXAMINEE_INVITE_SUCCESS';
export const ACCEPT_EXAMINEE_INVITE_FAILURE = 'ACCEPT_EXAMINEE_INVITE_FAILURE';
export const VALIDATE_EXAMINEE_INVITE_SUCCESS = 'VALIDATE_EXAMINEE_INVITE_SUCCESS';

function examineeJoinSuccessMessage(productName) {
  return <span> You have successfully been assigned <b>{productName}</b>. </span>;
}

export const revokeExamineeInviteSuccess = (selectedRowId, groupId) => ({
  type: REVOKE_EXAMINEE_INVITE_SUCCESS,
  payload: { selectedRowId, groupId }
});

export const revokeExamineeInviteFailure = error => ({
  type: REVOKE_EXAMINEE_INVITE_FAILURE,
  payload: { error }
});

export const reimburseAvailableProduct = (product, groupId) => ({
  type: REIMBURSE_AVAILABLE_PRODUCT,
  payload: { product, groupId }
});

export function revokeExamineeInvite(adminGroupId, examineeInviteRow) {
  return (dispatch) => {
    dispatch(revokeExamineeInviteSuccess(examineeInviteRow.id, examineeInviteRow.groupId));
    const examineeInviteId = examineeInviteRow.id;
    return httpClient.delete(`/group/${adminGroupId}/examineeInvite/${examineeInviteId}`)
      .then((product) => {
        dispatch(reimburseAvailableProduct(product.data, examineeInviteRow.groupId));
        dispatch(dispatchAlert({
          message: (
            <span>
              Examinee invite for <b>{examineeInviteRow.email}</b> has been revoked.
            </span>
          ),
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('ERROR REVOKING EXAMINEE');
        dispatch(dispatchAlert({
          message: (
            <span>
              Unable to revoke invite. {err.message}
            </span>
          ),
          type: 'eap-alert-error',
        }));
      });
  };
}

export function validateExamineeInvite(hash) {
  return new Promise((resolve, reject) =>
    httpClient
      .get(`/user/invites/examinee/${hash}/valid`)
      .then(response => resolve({ hash, valid: response.data.valid }))
      .catch(reject));
}

export const sendExamineeInvitesSuccess = (newInvites, groupId, availableProductId) => ({
  type: SEND_EXAMINEE_INVITES_SUCCESS,
  payload: { newInvites, groupId, availableProductId }
});

export const sendExamineeInvitesFailure = error => ({
  type: SEND_EXAMINEE_INVITES_FAILURE,
  payload: { error }
});

export const sendExamineeInvitesAsSuper = (groupId, emailList, availableProductId) => (
  (dispatch) => {
    dispatch({
      type: SEND_EXAMINEE_INVITES_REQUEST,
      payload: {}
    });
    return httpClient
      .post(
        `/super/group/${groupId}/examineeInvites`,
        { emailList, availableProductId }
      )
      .then((response) => {
        dispatch(sendExamineeInvitesSuccess(response.data, groupId, availableProductId));
      }).catch((err) => {
        console.error('ERROR SENDING EXAMINEE INVITES', err);
      });
  }
);

export const sendExamineeInvites = (adminGroupId, groupId, emailList, availableProductId) => (
  (dispatch) => {
    dispatch({
      type: SEND_EXAMINEE_INVITES_REQUEST,
      payload: {}
    });
    return httpClient
      .post(
        `/group/${adminGroupId}/examineeInvites`,
        { groupId, emailList, availableProductId }
      )
      .then((response) => {
        if (response.data.length === 0) {
          dispatch(dispatchAlert({
            message: (
              <span>
                There is no product left for these invites.
                Check your product levels or call us if there is a problem.
              </span>
            ),
            type: 'eap-alert-error',
            time: 10000
          }));
        } else {
          dispatch(dispatchAlert({
            message: (
              <span>
              Invites have successfully been sent.
              </span>
            ),
            type: 'eap-alert-success',
            time: 10000
          }));
          dispatch(sendExamineeInvitesSuccess(response.data, groupId, availableProductId));
        }
      })
      .catch((err) => {
        console.error('ERROR SENDING EXAMINEE INVITES');
        dispatch(dispatchAlert({
          message: (
            <span> Unable to email examinee invites. {err.message}.</span>
          ),
          type: 'eap-alert-error'
        }));
      });
  }
);

export const superGetExamineeInvitesSuccess = (invites, groupId) => ({
  type: SUPER_GET_EXAMINEE_INVITES_SUCCESS,
  payload: { invites, groupId }
});

export const superGetExamineeInvitesFailure = error => ({
  type: SUPER_GET_EXAMINEE_INVITES_FAILURE,
  payload: { error }
});

export function superGetExamineeInvites(groupId) {
  return dispatch =>
    httpClient.get(`/super/group/${groupId}/examineeInvites`)
      .then((response) => {
        dispatch(superGetExamineeInvitesSuccess(response.data, groupId));
      }).catch((err) => {
        console.error('ERROR LOADING EXAMINEE INVITES');
        dispatch(superGetExamineeInvitesFailure(err.message));
      });
}

export function superRevokeExamineeInvite(examineeInviteRow, groupId) {
  return (dispatch) => {
    dispatch(revokeExamineeInviteSuccess(examineeInviteRow.id, groupId));
    const examineeInviteId = examineeInviteRow.id;
    return httpClient.delete(`/super/examineeInvite/${examineeInviteId}`)
      .then((product) => {
        dispatch(reimburseAvailableProduct(product.data, groupId));
        dispatch(dispatchAlert({
          message: (
            <span>
              Examinee invite for <b>{examineeInviteRow.email}</b> has been revoked.
            </span>
          ),
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('ERROR REVOKING EXAMINEE');
        dispatch(dispatchAlert({
          message: (
            <span>
              Unable to revoke invite. {err.message}
            </span>
          ),
          type: 'eap-alert-error',
        }));
      });
  };
}

export const acceptExamineeInviteSuccess = assignedProduct => ({
  type: ACCEPT_EXAMINEE_INVITE_SUCCESS,
  payload: { assignedProduct }
});

export const acceptExamineeInviteFailure = error => ({
  type: ACCEPT_EXAMINEE_INVITE_FAILURE,
  payload: { error }
});

export function acceptExamineeInvite(hash) {
  return dispatch =>
    httpClient.post('/user/invites/examinee/accept', { hash })
      .then((response) => {
        dispatch(acceptExamineeInviteSuccess(response.data));
        dispatch(dispatchAlert({
          message: examineeJoinSuccessMessage(response.data.product.name),
          type: 'eap-alert-success',
          time: 5000
        }));
        history.push('/dashboard');
      }).catch((err) => {
        console.error('ERROR ACCEPTING EXAMINEE INVITE', err.message);
        dispatch(dispatchAlert({
          message: (
            <span>
              Invite could not be found or is invalid.
              Please contact the administrator who invited you or contact EdITS support.
            </span>
          ),
          type: 'eap-alert-error',
          time: 5000
        }));
        history.push('/dashboard');
      });
}

export function resendExamineeInvite(adminGroupId, inviteId) {
  return dispatch =>
    httpClient.put(`/group/${adminGroupId}/examineeInvite/${inviteId}`)
      .then(() => {
        dispatch(dispatchAlert({
          message: (
            <span>
              Invite has successfully been resent.
            </span>
          ),
          type: 'eap-alert-success',
          time: 10000
        }));
      }).catch((err) => {
        console.error(err);
        dispatch(dispatchAlert({
          message: (
            <span> Unable to resend examinee invite. {err.message}.</span>
          ),
          type: 'eap-alert-error'
        }));
      });
}


export function superResendExamineeInvite(inviteId) {
  return dispatch =>
    httpClient.put(`/super/examineeInvite/${inviteId}`)
      .then(() => {
        dispatch(dispatchAlert({
          message: (
            <span>
              Invite has successfully been resent.
            </span>
          ),
          type: 'eap-alert-success',
          time: 10000
        }));
      }).catch((err) => {
        console.error(err);
        dispatch(dispatchAlert({
          message: (
            <span> Unable to resend examinee invite. {err.message}.</span>
          ),
          type: 'eap-alert-error'
        }));
      });
}
