import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';

import { scrubUserData } from './utils/FormsHelper';
import InlineValidationMessage from './components/InlineValidationMessage';
import FormInputGroup from './components/FormInputGroup';
import EapDatePicker from './components/EapDatePicker';
import GradeLevelSelectList from './components/GradeLevelSelectList';
import GenderSelectList from './components/GenderSelectList';
import {
  CoreUserProfileSchema,
  NewPasswordSchema,
  ConfirmPasswordSchema,
  UniqueUsernameSchema,
  UsernameSchema,
  UniqueEmailSchema,
  TermsAndConditionsSchema,
} from './signup/components/UserSchema';
import EapButton from './components/EapButton';
import StyledForm from './components/StyledForm';

class SelfActivationForm extends React.Component {
  constructor(props) {
    super(props);

    const { initialValues } = props;
    this.state = {
      userDoesNotHaveEmail: isEmpty(initialValues.email),
      initialValues: {
        ...initialValues,
        email: initialValues.email || '',
        dob: initialValues.dob,
        gender: initialValues.gender || '',
        grade: props.initialValues.grade || '',
        password: '',
        confirmPassword: '',
        termsAndConditions: false
      }
    };
    this.submitRegistration = this.submitRegistration.bind(this);
  }

  submitRegistration(values) {
    const scrubbed = scrubUserData(values);
    this.props.onSubmit(scrubbed);
  }

  render() {
    const { userDoesNotHaveEmail, initialValues } = this.state;
    return (
      <Formik
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validationSchema={
          CoreUserProfileSchema.concat(yup.object().shape({
              email: !userDoesNotHaveEmail ? UniqueEmailSchema(initialValues.email) : undefined,
              username: UsernameSchema.concat(UniqueUsernameSchema(initialValues.username)),
              password: NewPasswordSchema,
              confirmPassword: ConfirmPasswordSchema,
              termsAndConditions: TermsAndConditionsSchema
            }))
        }
        onSubmit={values => this.submitRegistration(values)}
      >
        {({
 errors, touched, setFieldValue, setFieldTouched
 }) =>
  (
    <StyledForm>
      <FormInputGroup required>
        <label htmlFor="username">
         USERNAME
        </label>
        <Field
          name="username"
          type="text"
        />
        {
       touched.username &&
       errors.username &&
       <InlineValidationMessage message={errors.username} />
      }
      </FormInputGroup>
      <FormInputGroup required={!userDoesNotHaveEmail}>
        <label htmlFor="email">EMAIL</label>
        <Field
          disabled={userDoesNotHaveEmail}
          type="text"
          name="email"
        />
        {touched.email && errors.email &&
        <InlineValidationMessage message={errors.email} />}
        <div>
          <label htmlFor="userDoesNotHaveEmail">
            <input
              name="userDoesNotHaveEmail"
              type="checkbox"
              checked={userDoesNotHaveEmail}
              onChange={() => {
            this.setState({ userDoesNotHaveEmail: !userDoesNotHaveEmail });
            setFieldValue('email', '');
            setFieldTouched('email', false);
            }}
            />
              I do not have an email
          </label>
        </div>
      </FormInputGroup>
      <FormInputGroup required>
        <label htmlFor="password">PASSWORD</label>
        <Field
          type="password"
          name="password"
        />
        {touched.password && errors.password &&
        <InlineValidationMessage message={errors.password} />
       }
      </FormInputGroup>
      <FormInputGroup required>
        <label htmlFor="confirmPassword">CONFIRM PASSWORD</label>
        <Field
          type="password"
          name="confirmPassword"
        />
        {touched.confirmPassword && errors.confirmPassword &&
        <InlineValidationMessage message={errors.confirmPassword} />
            }
      </FormInputGroup>
      <FormInputGroup required>
        <label htmlFor="firstName">FIRST NAME</label>
        <Field
          type="text"
          name="firstName"
        />
        {touched.firstName && errors.firstName &&
        <InlineValidationMessage message={errors.firstName} />
            }
      </FormInputGroup>
      <FormInputGroup required>
        <label htmlFor="lastName">LAST NAME</label>
        <Field
          type="text"
          name="lastName"
        />
        {touched.lastName && errors.lastName &&
        <InlineValidationMessage message={errors.lastName} />}
      </FormInputGroup>
      <FormInputGroup>
        <label htmlFor="dob">
        DATE OF BIRTH (MM/DD/YYYY)
        </label>
        <EapDatePicker
          className=""
          initialDate={initialValues.dob || ''}
          onDateSelected={dateVal => setFieldValue('dob', dateVal)}
          maxDate={new Date()}
        />
      </FormInputGroup>
      <FormInputGroup>
        <label htmlFor="gender">
        GENDER
        </label>
        <GenderSelectList />
      </FormInputGroup>
      <FormInputGroup>
        <label htmlFor="grade">GRADE LEVEL</label>
        <GradeLevelSelectList />
      </FormInputGroup>
      <FormInputGroup>
        <label htmlFor="termsAndConditions">
          <Field
            type="checkbox"
            name="termsAndConditions"
            className="eap-form-checkbox"
          />
          I agree to&nbsp;
          <a href="https://www.edits.net/legal/terms-of-use/" target="_blank" rel="noopener noreferrer">
            terms and conditions
          </a>
          &nbsp;for this site <span className="required">*</span>
        </label>
        {touched.termsAndConditions && errors.termsAndConditions &&
        <InlineValidationMessage message={errors.termsAndConditions} />}
      </FormInputGroup>
      <FormInputGroup>
        <EapButton
          isSubmit
          text="SIGN UP"
        />
      </FormInputGroup>
    </StyledForm>
  )
  }
      </Formik>
    );
  }
}

SelfActivationForm.propTypes = {
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    username: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    gender: PropTypes.string,
    grade: PropTypes.string
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default SelfActivationForm;
