import { connect } from 'react-redux';
import _ from 'lodash';
import User from './components/User';
import {
  superLoadUser,
  superUpdateUser
} from '../../actions/user';
import { sendAdminHandout } from '../../actions/adminHandout';
import { requestPasswordReset } from '../../actions/password';

import {
  superRevokeAssignedProduct,
  superReleaseResults,
  superSuppressResults
} from '../../actions/assignedProducts';

const mapStateToProps = (state, ownProps) => {
  const userId = _.parseInt(ownProps.match.params.userId, 10);
  const user = _.find(state.user.list, { id: userId });
  const examineeGroups = _
    .chain(state.examinees.list)
    .filter({ userId })
    .map((g) => {
      const group = _.find(state.groups.list, { id: g.groupId }) || {};
      const org = _.find(state.organization.list, { id: group.organizationId }) || {};
      return {
        id: g.id,
        groupId: g.groupId,
        organizationId: group.organizationId,
        customId: org.customId,
        description: group.description,
        name: group.name,
      };
    })
    .value();

  const adminGroups = _
    .chain(state.admins.list)
    .filter({ userId })
    .map((g) => {
      const group = _.find(state.groups.list, { id: g.groupId }) || {};
      const org = _.find(state.organization.list, { id: group.organizationId }) || {};
      return {
        id: g.id,
        groupId: g.groupId,
        organizationId: group.organizationId,
        customId: org.customId,
        description: group.description,
        name: group.name,
      };
    })
    .value();

  const assignedProducts = _
    .chain(state.assignedProducts.list)
    .filter({ userId })
    .map((prod) => {
      const productDef = _.find(state.products.list, { id: prod.productId });
      let productName = '';
      if (productDef !== undefined) {
        productName = productDef.adminName;
      }
      return {
        ...user,
        ...{ productName },
        ...prod,
      };
    })
    .value();

  return {
    signin: user || {},
    examineeGroups,
    adminGroups,
    assignedProducts
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadUserInfo: (sourceUserId) => (dispatch(superLoadUser(sourceUserId))),
  updateUserInfo: (sourceUserId, updatedUserInfo) => {
    dispatch(superUpdateUser(sourceUserId, updatedUserInfo));
  },
  resetPassword: (email) => {
    dispatch(requestPasswordReset(email, true));
  },
  sendAdminHandout: (signinUserId, password) => {
    dispatch(sendAdminHandout(signinUserId, password));
  },
  revokeAssignedProduct: (product) => {
    dispatch(superRevokeAssignedProduct(product.id, product.groupId));
  },
  releaseResults: (product) => {
    dispatch(superReleaseResults(product, product.groupId));
  },
  suppressResults: (product) => {
    dispatch(superSuppressResults(product, product.groupId));
  }
});

const UserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(User);

export default UserContainer;
