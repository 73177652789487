import { connect } from 'react-redux';

import PasswordReset from './components/PasswordReset';
import { requestPasswordReset } from '../actions/password';

const mapStateToProps = state => ({
  password: { ...state.password }
});

const mapDispatchToProps = dispatch => ({
  resetPassword: (email) => {
    dispatch(requestPasswordReset(email));
  }
});

const PasswordResetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReset);

export default PasswordResetContainer;
