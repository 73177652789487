import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AdminIconList from '../../../components/AdminIconList';
import { StaticEapTable } from '../../../components/StaticEapTable';
import EapButton from '../../../components/EapButton';
import * as EapDisplayDate from '../../../components/EapDisplayDate';
import ExamineeInviteDialog from '../../../group/components/ExamineeInviteDialog';
import ExamineeInviteRevokeModal from '../../../group/components/ExamineeInviteRevokeModal';
import ExamineeInviteResendModal from '../../../group/components/ExamineeInviteResendModal';

class GroupExamineeInvitesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendingInvite: false
    };
  }

  render() {
    const columns = [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Sender ID',
        accessor: 'senderUserId'
      },
      {
        Header: 'Product Assigned',
        accessor: 'productName'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Date Sent',
        accessor: 'createdAt',
        Cell: ({ row }) => <EapDisplayDate.Display date={row.original.createdAt} />,
        sort: EapDisplayDate.sort
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => (
          <AdminIconList>
            <ExamineeInviteResendModal
              selectedExamineeInvite={row.original}
              resendInvite={this.props.resendExamineeInvite}
            />
            <ExamineeInviteRevokeModal
              selectedExamineeInvite={row.original}
              revokeInvite={
                (invite) =>
                  this.props.revokeExamineeInvite(invite, this.props.match.params.groupId)
              }
            />
          </AdminIconList>
        )
      }
    ];
    return (
      <div>
        <EapButton
          text="SEND INVITE"
          onClick={() => this.setState({ sendingInvite: true })}
        />
        <ExamineeInviteDialog
          availableProducts={this.props.products || []}
          showModal={this.state.sendingInvite}
          sendInvites={(emails, selectedProductId) => {
            this.props.sendInvites(
              emails,
              selectedProductId
            );
            this.setState({ sendingInvite: false });
          }}
          closeModal={() => this.setState({ sendingInvite: false })}
        />
        <StaticEapTable
          columns={columns}
          data={this.props.examineeInvites}
        />
      </div>
    );
  }
}

GroupExamineeInvitesTable.propTypes = {
  sendInvites: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number
  })),
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string.isRequired
    })
  }).isRequired,
  examineeInvites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    senderUserId: PropTypes.number,
    productName: PropTypes.string,
    productAdminName: PropTypes.string,
    createdAt: PropTypes.string
  })).isRequired,
  resendExamineeInvite: PropTypes.func.isRequired,
  revokeExamineeInvite: PropTypes.func.isRequired
};

GroupExamineeInvitesTable.defaultProps = {
  products: undefined
};

export default GroupExamineeInvitesTable;
