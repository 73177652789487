import { cloneDeep, toNumber, isFinite } from 'lodash';
import moment from 'moment';

import {
  FILTER_PURCHASES_SUCCESS,
  FULFILLMENT_REQUEST,
  FULFILLMENT_SUCCESS,
  REFILL_SUCCESS,
} from '../actions/purchase';

import {
  USER_DATA_RECEIVED
} from '../actions/user';

const purchasesReducer = (
  state = {
    serverFiltered: [],
  },
  action
) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case FILTER_PURCHASES_SUCCESS: {
      newState.serverFiltered = action.payload.map((row) => ({
        ...row,
        createdAt: moment(row.createdAt),
        updatedAt: moment(row.updatedAt)
      }));

      return newState;
    }
    case FULFILLMENT_REQUEST: {
      const {
        purchase: {
          uuid,
        },
      } = action.payload;
      if (newState.purchases && newState.purchases[uuid]) {
        newState.purchases[uuid].fulfillmentStatus = 'Fulfilling';
      }
      return newState;
    }
    case FULFILLMENT_SUCCESS: {
      const {
        purchase: {
          uuid,
        },
        group,
      } = action.payload;
      if (newState.purchases && newState.purchases[uuid]) {
        newState.purchases[uuid].fulfillmentStatus = 'Fulfilled';
        newState.purchases[uuid].groupId = group.groupId;
        newState.purchases[uuid].groupName = group.name;
      }
      return newState;
    }
    case USER_DATA_RECEIVED: {
      newState.purchases = {};
      action.payload.orders.forEach((row) => {
        const uuid = `o${row.id}`;
        newState.purchases[uuid] = {
          type: 'Order',
          uuid,
          id: row.id,
          groupId: row.groupId,
          groupName: row.group ? row.group.name : '',
          amount: row.grandTotal,
          fulfillmentStatus: row.fulfillmentStatus || 'Unfulfilled',
          lineItems: row.order_lineItems.map((line) => {
            let amount;
            try {
              amount = toNumber(line.quantity) * toNumber(line.unitPrice);
              if (!isFinite(amount)) {
                amount = null;
              }
              amount = amount.toFixed(2);
            } catch (e) {
              amount = null;
            }
            return {
              productId: line.productId,
              quantity: line.quantity,
              unitPrice: line.unitPrice,
              name: line.product ? line.product.adminName : '',
              amount,
            };
          }),
          createdAt: moment(row.createdAt),
          updatedAt: moment(row.updatedAt),
        };
      });
      action.payload.refills.forEach((row) => {
        const uuid = `r${row.id}`;
        newState.purchases[uuid] = {
          type: 'Refill',
          uuid,
          id: row.id,
          groupId: row.groupId,
          groupName: row.group ? row.group.name : '',
          amount: row.chargeAmount,
          fulfillmentStatus: 'Fulfilled',
          lineItems: [{
            productId: row.productId,
            name: row.product ? row.product.adminName : row.stripeChargeDescription,
            unitPrice: row.unitPrice,
            quantity: row.quantity,
            amount: row.chargeAmount,
          }],
          createdAt: moment(row.createdAt),
          updatedAt: moment(row.updatedAt),
        };
      });
      return newState;
    }
    case REFILL_SUCCESS: {
      const {
        refill: {
          id,
          groupId,
          group,
          chargeAmount: amount,
          productId,
          product,
          unitPrice,
          quantity,
          createdAt,
          updatedAt,
        },
      } = action.payload;
      const uuid = `r${id}`;
      newState.purchases[uuid] = {
        type: 'Refill',
        uuid,
        id,
        groupId,
        groupName: group ? group.name : '',
        amount,
        fulfillmentStatus: 'Fulfilled',
        lineItems: [{
          productId,
          name: product ? product.adminName : '',
          unitPrice,
          quantity,
          amount,
        }],
        createdAt: moment(createdAt),
        updatedAt: moment(updatedAt),
      };
      return newState;
    }
    default: {
      return newState;
    }
  }
};

export default purchasesReducer;
