import history from '../utils/history';

export const GENERAL_ERROR = 'GENERAL_ERROR';

export const sendGeneralError = (error) => ({
  type: GENERAL_ERROR,
  payload: { error }
});

export const publishServerError = (error) => (dispatch) => {
  dispatch(sendGeneralError(error));
  history.push('/server-error');
};
