import React from 'react';
import PropTypes from 'prop-types';
import EapModal from './EapModal';
import EAPButton from './EapButton';
import { EMPTY, PermissionSelector } from './PermissionSelector';

const AdminInfoModal = ({
  showModal, closeModal, title, permissions
}) => (
  <EapModal
    modalTitle={title}
    showModal={showModal}
  >
    <React.Fragment>
      <PermissionSelector
        permissions={permissions}
        onChange={() => {}}
        disabled
      />
      <EAPButton
        onClick={() => closeModal()}
        text="Close"
        type="button"
      />
    </React.Fragment>
  </EapModal>
);

AdminInfoModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    per_users_view: PropTypes.bool,
    per_users_invite: PropTypes.bool,
    per_users_move: PropTypes.bool,
    per_products_view: PropTypes.bool,
    per_products_assign: PropTypes.bool,
    per_products_transfer: PropTypes.bool,
    per_admin_view: PropTypes.bool,
    per_admin_invite: PropTypes.bool,
    per_admin_remove: PropTypes.bool,
    per_admin_edit: PropTypes.bool,
    per_create: PropTypes.bool,
    permissions: PropTypes.string
  }),
  title: PropTypes.string
};

AdminInfoModal.defaultProps = {
  title: 'Information',
  permissions: EMPTY,
  showModal: false
};

export default AdminInfoModal;
