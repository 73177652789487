import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import History from '../utils/history';
import './TabMenuItem.css';

const TabMenuItem = ({
  link, text, icon, size
}) => {
  const isCurrent = History.location.pathname.indexOf(link) > -1;
  return (
    <li className={`pure-menu-item ${size} group-tab-list-item`}>
      <Link to={`${link}`} className={`pure-menu-link ${isCurrent && 'eap-menu-current'} group-tab`}>
        <span className={`fa ${icon} eap-menu-icon`} />
        <strong>
          {text}
        </strong>
      </Link>
    </li>
  );
};

TabMenuItem.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string
};

TabMenuItem.defaultProps = {
  size: 'pure-u-1-5',
};

export default TabMenuItem;
