import React from 'react';
import PropTypes from 'prop-types';

import EapModal from '../../../components/EapModal';
import DeleteExamineeHasProducts from './DeleteExamineeHasProducts';
import DeleteExamineeNoProducts from './DeleteExamineeNoProducts';

const DeleteExamineeModal = ({
  selectedExaminee,
  closeModal,
  deleteExaminee,
}) => (
  <EapModal
    modalTitle="Delete Examinee"
    showModal
  >
    {
      (selectedExaminee.assigned && selectedExaminee.assigned.length > 0)
        ? (
          <DeleteExamineeHasProducts
            closeModal={closeModal}
            firstName={selectedExaminee.firstName}
            lastName={selectedExaminee.lastName}
          />
        ) : (
          <DeleteExamineeNoProducts
            selectedExaminee={selectedExaminee}
            closeModal={closeModal}
            deleteExaminee={deleteExaminee}
          />
        )
    }
  </EapModal>
);

export default DeleteExamineeModal;

DeleteExamineeModal.propTypes = {
  selectedExaminee: PropTypes.shape({
    assigned: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number
    })),
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }).isRequired,
  deleteExaminee: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
