import React from 'react';
import PropTypes from 'prop-types';

import EapButton from '../../../components/EapButton';

import './DeleteProductForm.css';

const DeleteProductForm = ({
  availableProductId,
  groupId,
  deleteProducts,
  productName,
  groupName,
  onCancel,
  onProductDeleted
}) => (
  <div className="delete-products-form">
    <p>
      Are you sure you want to remove <span>{productName}</span> from the [{groupName}] group?
    </p>
    <form className="pure-form">
      <EapButton
        text="YES"
        onClick={() => {
            deleteProducts(availableProductId, groupId);
            onProductDeleted();
            }
          }
      />
      <EapButton
        isSecondary
        text="NO"
        onClick={() => onCancel()}
      />
    </form>
  </div>
);

DeleteProductForm.propTypes = {
  availableProductId: PropTypes.number.isRequired,
  groupId: PropTypes.number.isRequired,
  groupName: PropTypes.string.isRequired,
  deleteProducts: PropTypes.func.isRequired,
  productName: PropTypes.string.isRequired,
  onProductDeleted: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
export default DeleteProductForm;
