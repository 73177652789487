import React from 'react';

import { httpClient } from '../utils/httpClient';
import { initClientUser } from './user';
import { dispatchAlert } from './alert';

export const ACTIVATE_SUCCESS = 'ACTIVATE_SUCCESS';
export const ACTIVATE_FAILURE = 'ACTIVATE_FAILURE';

export const activateSuccess = () => ({
  type: ACTIVATE_SUCCESS
});

export const activateFailure = (error) => ({
  type: ACTIVATE_FAILURE,
  payload: { message: error }
});

export function activateSignin(updatedUserInfo) {
  return (dispatch) => {
    const {
      username,
      password,
      email,
      firstName,
      lastName,
      gender,
      dob,
      grade: inGrade,
    } = updatedUserInfo;

    const payload = {
      username,
      password,
      email,
      firstName,
      lastName,
      gender,
      dob,
      grade: inGrade ? inGrade.toString() : '',
    };
    httpClient
      .put('/user/activate', { ...payload })
      .then(() => {
        dispatch(activateSuccess());
        dispatch(initClientUser());
        dispatch(dispatchAlert({
          message: <span> Account is now active. </span>,
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch(() => {
        console.error('ERROR ACTIVATING SIGNIN');
        dispatch(activateFailure('A server error occured.'));
      });
  };
}
