import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import EapButton from '../../components/EapButton';
import { StaticEapTable } from '../../components/StaticEapTable';

const ExamineeUploadStep2 = ({
  validating,
  preview,
  errors,
  next,
  previous
}) => {
  if (validating) {
    return (
      <div>
        <h2>Please wait.</h2>
        <p>Validating file</p>
      </div>
    );
  }
  if (!_.isEmpty(errors)) {
    const rowErrors = _.map(errors.rowErrors, (val, key) => (
      <li key={uuid()}>
        Row {key} has errors.
        <ul>
          {_.map(val, (v) => (<li key={uuid()}>{v}</li>))}
        </ul>
      </li>
    ));
    return (
      <div>
        <h2>Validation Error</h2>
        <p>You cannot upload this file due to the following error(s):</p>
        <ul>
          {errors['insufficient-products'] ? <li key={uuid()}>{errors['insufficent-products']}</li> : ''}
          {rowErrors}
        </ul>
        <EapButton
          text="Back"
          onClick={() => previous()}
        />
      </div>
    );
  }
  return (
    <div>
      <h2>Confirm Upload</h2>
      <p>Below is a preview of the data. Are you sure you want to upload? This action cannot be undone.</p>
      <EapButton
        text="Upload"
        onClick={next}
      />
      <StaticEapTable
        columns={[
          {
            Header: 'User Name',
            accessor: 'username'
          },
          {
            Header: 'Password',
            accessor: 'password'
          },
          {
            Header: 'Email',
            accessor: 'email'
          },
          {
            Header: 'First',
            accessor: 'first'
          },
          {
            Header: 'Last',
            accessor: 'last'
          },
          {
            Header: 'Date of Birth',
            accessor: 'dob',
            Cell: ({ cell: { value } }) => {
              if (value) {
                return value.format('MM/DD/YYYY');
              }
              return '';
            }
          },
          {
            Header: 'Grade',
            accessor: 'grade',
          },
          {
            Header: 'Gender',
            accessor: 'gender'
          }
        ]}
        data={preview}
      />
    </div>
  );
};

ExamineeUploadStep2.propTypes = {
  validating: PropTypes.bool,
  preview: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    email: PropTypes.string,
    first: PropTypes.string,
    last: PropTypes.string
  })),
  errors: PropTypes.shape({
    'insufficient-products': PropTypes.string,
  })
};

ExamineeUploadStep2.defaultProps = {
  validating: true,
  preview: [],
  errors: {}
};

export default ExamineeUploadStep2;
