import React from 'react';
import { httpClient } from '../utils/httpClient';
import {
  PasswordResetSuccessMessage,
  PasswordResetMultipleAccountMessage
} from '../password-reset/components/PasswordResetFeedback';

import { dispatchAlert } from './alert';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_REQUEST_FAILED = 'PASSWORD_RESET_REQUEST_FAILED';

export const PASSWORD_RESET_REQUEST_SUCCESS = 'PASSWORD_RESET_REQUEST_SUCCESS';
export const PASSWORD_RESET_NO_ACCOUNT_FOUND = 'PASSWORD_RESET_NO_ACCOUNT_FOUND';
export const PASSWORD_RESET_MULTIPLE_ACCOUNTS = 'PASSWORD_RESET_MULTIPLE_ACCOUNTS';

export const requestPasswordReset = (emailAddress, triggerAlert) => (dispatch) => {
  httpClient.post('/user/password-reset', { emailAddress })
    .then(() => {
      if (triggerAlert) {
        dispatchAlert({
          message: <PasswordResetSuccessMessage emailAddress={emailAddress} />,
          type: 'eap-alert-success',
          time: 5000
        });
      } else {
        dispatch({
          type: PASSWORD_RESET_REQUEST_SUCCESS,
          payload: { emailAddress }
        });
      }
    }).catch((err) => {
      if (err.response.status === 422) {
        if (triggerAlert) {
          dispatchAlert({
            message: <PasswordResetMultipleAccountMessage emailAddress={emailAddress} />,
            type: 'eap-alert-error'
          });
        } else {
          dispatch({
            type: PASSWORD_RESET_MULTIPLE_ACCOUNTS,
            payload: { emailAddress }
          });
        }
      } else if (err.response.status === 404) {
        dispatch({
          type: PASSWORD_RESET_NO_ACCOUNT_FOUND,
          payload: { emailAddress }
        });
      } else {
        // Will the following ever happen? 🤔
        dispatch({
          type: PASSWORD_RESET_REQUEST_FAILED,
          payload: {
            emailAddress
            // message: 'Could not reset account for that email'
          }
        });
      }
    });
};
