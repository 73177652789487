import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { v4 as Uuid } from 'uuid';

import EapButton from '../../components/EapButton';
import EapModal from '../../components/EapModal';

import Fulfillment from '../containers/FulfillmentContainer';

import './Purchase.css';

const PurchaseComponent = ({
  purchases,
}) => {
  const { uuid } = useParams();
  const purchase = purchases[uuid];
  const [showFulfillment, setShowFulfillment] = useState(false);
  const {
    type,
    amount,
    fulfillmentStatus,
    groupId,
    groupName,
    updatedAt,
    lineItems,
    createdAt,
  } = purchase;

  const MappedLineItems = lineItems.map(({
    name,
    quantity,
    unitPrice,
    amount: lineTotal,
  }) => (
    <tr key={Uuid()}>
      <td>{name}</td>
      <td className="right">{quantity}</td>
      <td className="right">{unitPrice ? `$${unitPrice.toFixed(2)}` : ''}</td>
      <td className="right">${lineTotal}</td>
    </tr>
  ));

  let displayGroupName = '';
  if (groupName) {
    displayGroupName = (<span>into <Link to={`/group/${groupId}/information`}>{groupName}</Link> </span>);
  }
  return (
    <div className="purchase" id="purchaseContainer">
      <div className="purchaseContent">
        <h1>Purchase</h1>
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Quantity</th>
              <th>Unit<br />Price</th>
              <th>Line<br />Total</th>
            </tr>
          </thead>
          <tbody>
            {MappedLineItems}
            <tr>
              <td />
              <td />
              <td><b>Total:</b></td>
              <td>${amount}</td>
            </tr>
          </tbody>
        </table>
        <p>
          Purchased on {createdAt.format('MM/DD/YYYY')} from
          {
          type === 'Refill'
            ? ' the EAP'
            : (<span> <a href="https://www.edits.net" target="blank">EdITS.net</a></span>)
        }
        </p>
        {
        fulfillmentStatus === 'Fulfilled'
          ? (<p>Products fulfilled {displayGroupName} on {updatedAt.format('MM/DD/YYYY')}</p>)
          : (
            <EapButton
              text={
                fulfillmentStatus === 'Fulfilling'
                  ? 'Please Wait'
                  : 'Fulfill your order'
              }
              disabled={fulfillmentStatus === 'Fulfilling'}
              inProgress={fulfillmentStatus === 'Fulfilling'}
              onClick={() => setShowFulfillment(true)}
            />
          )
      }
        <EapModal
          modalTitle="Fulfill Order"
          showModal={showFulfillment}
        >
          <Fulfillment
            purchase={purchase}
            onClose={() => setShowFulfillment(false)}
          />
        </EapModal>
      </div>
    </div>
  );
};

export default PurchaseComponent;
