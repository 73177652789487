import {
  CUSTOMER_DATA_REQUEST,
  CUSTOMER_DATA_RECEIVED,
} from '../actions/customer';

const customerReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_DATA_RECEIVED: {
      return { ...state, ...(action.payload.customer) };
    }
    case CUSTOMER_DATA_REQUEST: {
      return { ...state };
    }
    default: {
      return { ...state };
    }
  }
};

export default customerReducer;
