import {
  // SIGNUP_REQUEST,
  SIGNUP_FAILED,
  SIGNUP_SUCCEEDED
} from '../actions/signup';

const signupReducer = (state = {}, action) => {
  switch (action.type) {
    case SIGNUP_SUCCEEDED: {
      return { ...state, signupSuccess: true };
    }
    case SIGNUP_FAILED: {
      return { ...state, signupErr: action.payload.err };
    }
    default: {
      return state;
    }
  }
};

export default signupReducer;
