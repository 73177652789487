import { connect } from 'react-redux';
import { toArray } from 'lodash';

import Purchases from './components/Purchases';

const mapStateToProps = ({
  purchases: { purchases = [] }
}) => ({
  purchases: toArray(purchases, (val) => val).sort((a, b) => (
    b.createdAt.diff(a.createdAt)
  ))
});

const mapDispatchToProps = () => ({});

const PurchasesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Purchases);

export default PurchasesContainer;
