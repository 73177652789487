/* eslint jsx-a11y/label-has-associated-control:0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import EapButton from '../../components/EapButton';
import './ExamineePasswordOverrideForm.css';

const ExamineePasswordOverrideForm = ({ name, onOverride, onCancel }) => {
  const [newPassword, setNewPassword] = useState('');
  const validPassword = (newPassword.length >= 4);
  const elementId = `${name.replace(' ', '-')}-password-input`;
  return (
    <div>
      <p>
        Set a new password for examinee: <b>{name}</b>
      </p>
      <form
        className="pure-form"
        onSubmit={() => onOverride(newPassword)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingBottom: '1em',
          }}
        >
          <label
            htmlFor={elementId}
            style={{
              fontWeight: 'bold',
            }}
          >
            NEW PASSWORD
          </label>
          &nbsp;
          <div
            style={{
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <input
              style={{
                width: '100%',
              }}
              id={elementId}
              autoComplete="password"
              type="text"
              className="pure-input"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <tiny>
              (Must have a minimum of 4 characters)
            </tiny>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EapButton
            id="override-examinee-password"
            onClick={() => onOverride(newPassword)}
            disabled={!validPassword}
            text="CHANGE PASSWORD"
          />
          &nbsp;
          <EapButton
            isSecondary
            id="cancel-override-password"
            text="Cancel"
            onClick={() => onCancel()}
          />
        </div>
      </form>
    </div>
  );
};

ExamineePasswordOverrideForm.propTypes = {
  onOverride: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,

};

export default ExamineePasswordOverrideForm;
