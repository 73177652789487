import React from 'react';
import PropTypes from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';

import InputMask from 'react-input-mask';

import CheckoutInputElement from './CheckoutInputElement';
import InlineValidationMessage from '../../components/InlineValidationMessage';
import DisplayPrice from './DisplayPrice';
import WizardButtonBar from './WizardButtonBar';
import InjectedStripeForm from './InjectedStripeForm';
import StripeLoadingInProgress from './StripeLoadingInProgress';

import './OrderDetailsForm.css';

class OrderDetailsForm extends React.Component {
  constructor() {
    super();
    this.onQuantityChange = this.onQuantityChange.bind(this);
    this.state = {
      paymentInfoIsValid: false,
      stripeToken: undefined,
      quantity: ''
    };
  }

  onQuantityChange(e) {
    const rawValue = e.target.value;
    this.setState({
      quantity: rawValue,
    });
    if (e.target.value !== '') {
      const num = parseInt(rawValue, 0);
      this.props.onSetQuantity(num);
    }
  }

  render() {
    const { next, stripePublicKey } = this.props;
    const { stripeToken, quantity } = this.state;
    const quantityParsed = parseInt(quantity, 0);

    const orderDetailsValid = quantityParsed > 0 && stripeToken !== undefined;
    return (
      <>
        <div className="pure-u-1">
          <CheckoutInputElement gridClass="pure-u-1-8">
            <InputMask
              className="custom-checkout-input"
              name="quantity"
              mask="999"
              maskChar=" "
              placeholder="QTY."
              value={quantity}
              onChange={(e) => this.onQuantityChange(e)}
            />
          </CheckoutInputElement>
          <CheckoutInputElement gridClass="pure-u-7-8">
            <div className="checkout-product-label">
              <div>
                {this.props.product.name}
              </div>
              <div className="product-price-message">
                Regular price for {this.props.product.name} is&nbsp;
                <DisplayPrice amount={this.props.product.price} /> per
                test
              </div>
            </div>
          </CheckoutInputElement>
          <div className="bulk-quantity-message-wrapper">
            {
              this.state.quantity > 100
              && (
                <InlineValidationMessage
                  message="Call us for volume discounts for orders over 100, or you're free to continue checking out!"
                />
              )
            }
          </div>
        </div>
        {
          this.props.stripeScriptError
          && (
            <div>
              Stripe ERR: {JSON.stringify(this.props.stripeScriptError)}
            </div>
          )
        }
        {
          this.props.stripeScriptLoaded && stripePublicKey ? (
            <StripeProvider apiKey={stripePublicKey}>
              <Elements>
                <InjectedStripeForm
                  onStripeTokenDestroyed={() => this.setState({ stripeToken: undefined })}
                  onValidation={(valid) => this.setState({ paymentInfoIsValid: valid })}
                  onCardTokenCreated={(token) => {
                    this.setState({ stripeToken: token });
                    this.props.onStripeTokenCreated(token);
                  }}
                />
              </Elements>
            </StripeProvider>
          ) : (<StripeLoadingInProgress />)
        }
        <WizardButtonBar
          nextButtonDisabled={!orderDetailsValid}
          next={() => {
            const ready = this.state.stripeToken !== undefined && this.state.paymentInfoIsValid;
            if (ready) {
              next();
            }
          }}
        />
      </>
    );
  }
}

OrderDetailsForm.propTypes = {
  stripePublicKey: PropTypes.string,
  stripeScriptLoaded: PropTypes.bool.isRequired,
  stripeScriptError: PropTypes.string,
  product: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number
  }).isRequired,
  onSetQuantity: PropTypes.func.isRequired,
  onStripeTokenCreated: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired
};

OrderDetailsForm.defaultProps = {
  stripeScriptError: undefined,
  stripePublicKey: undefined
};

export default OrderDetailsForm;
