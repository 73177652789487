import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import { scrubUserData } from '../../../utils/FormsHelper';

import {
  CoreUserProfileSchema,
  NewPasswordSchema,
  RequiredEmailSchema,
  ConfirmPasswordSchema,
  UsernameSchema,
  UniqueUsernameSchema
} from '../../../signup/components/UserSchema';

import InlineValidationMessage from '../../../components/InlineValidationMessage';
import EapButton from '../../../components/EapButton';
import EapDatePicker from '../../../components/EapDatePicker';
import FormInputGroup from '../../../components/FormInputGroup';
import StyledForm from '../../../components/StyledForm';
import GradeLevelSelectList from '../../../components/GradeLevelSelectList';
import GenderSelectList from '../../../components/GenderSelectList';

class CreateSigninForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requireEmail: false
    };
  }

  render() {
    const { requireEmail } = this.state;
    const initialValues = {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      dob: '',
      gender: '',
      grade: '',
      currentPassword: '',
      isActive: false
    };
    return (
      <div className="pure-u-1">
        <Formik
          validateOnChange
          validateOnBlur
          initialValues={initialValues}
          validationSchema={
              CoreUserProfileSchema.concat(yup.object().shape({
              username: UsernameSchema.concat(UniqueUsernameSchema()),
              email: requireEmail ? RequiredEmailSchema : undefined,
                password: NewPasswordSchema,
                confirmPassword: ConfirmPasswordSchema
                }))}
          onSubmit={(values) => {
        const scrubbed = scrubUserData(values);
        this.props.superCreateSignin(scrubbed);
        }}
        >
          {({
 errors, touched, setFieldValue, setFieldTouched
}) => (
  <StyledForm>
    <FormInputGroup required>
      <label htmlFor="username">USERNAME</label>
      <Field
        type="text"
        name="username"
      />
      {touched.username && errors.username &&
      <InlineValidationMessage message={errors.username} />}
    </FormInputGroup>
    <FormInputGroup required={requireEmail}>
      <label htmlFor="email">EMAIL</label>
      <Field
        disabled={!requireEmail}
        type="text"
        name="email"
      />
      {touched.email && errors.email &&
      <InlineValidationMessage message={errors.email} />}
      <div>
        <input
          type="checkbox"
          checked={!requireEmail}
          onChange={() => {
          this.setState({ requireEmail: !requireEmail });
          setFieldValue('email', '');
          setFieldTouched('email', false);
          }}
        />
        User does not have email
      </div>
    </FormInputGroup>
    <FormInputGroup required>
      <label htmlFor="password">PASSWORD</label>
      <Field
        type="password"
        name="password"
      />
      {touched.password && errors.password &&
      <InlineValidationMessage message={errors.password} />
       }
    </FormInputGroup>
    <FormInputGroup required>
      <label htmlFor="confirmPassword">CONFIRM PASSWORD</label>
      <Field
        type="password"
        name="confirmPassword"
      />
      {touched.confirmPassword && errors.confirmPassword &&
      <InlineValidationMessage message={errors.confirmPassword} />
            }
    </FormInputGroup>
    <FormInputGroup required>
      <label htmlFor="firstName">FIRST NAME</label>
      <Field
        type="text"
        name="firstName"
      />
      {touched.firstName && errors.firstName &&
      <InlineValidationMessage message={errors.firstName} />
            }
    </FormInputGroup>
    <FormInputGroup required>
      <label htmlFor="lastName">LAST NAME</label>
      <Field
        type="text"
        name="lastName"
      />
      {touched.lastName && errors.lastName &&
      <InlineValidationMessage message={errors.lastName} />}
    </FormInputGroup>
    <FormInputGroup>
      <label htmlFor="dob">
        DATE OF BIRTH (MM/DD/YYYY)
      </label>
      <EapDatePicker
        onDateSelected={dateVal => setFieldValue('dob', dateVal)}
      />
    </FormInputGroup>
    <FormInputGroup >
      <label htmlFor="gender">GENDER</label>
      <GenderSelectList />
    </FormInputGroup>
    <FormInputGroup>
      <label htmlFor="grade">
        GRADE LEVEL
      </label>
      <GradeLevelSelectList />
    </FormInputGroup>
    <div />
    <FormInputGroup>
      <label htmlFor="isActive">ACTIVE USER</label>
      <Field
        name="isActive"
        component="select"
      >
        <option value>True</option>
        <option value={false}>False</option>
      </Field>
    </FormInputGroup>
    <div />
    <FormInputGroup>
      <EapButton
        text="CREATE SIGNIN"
        isSubmit
      />
    </FormInputGroup>
  </StyledForm>
  )}
        </Formik>
      </div>
    );
  }
}

export default CreateSigninForm;

CreateSigninForm.propTypes = {
  superCreateSignin: PropTypes.func.isRequired,
};
