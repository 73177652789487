import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import History from '../utils/history';

import './SideMenuItem.css';

const SideMenuItem = ({
  text, icon, link
}) => {
  const isCurrent = History.location.pathname.indexOf(link) > -1;
  return (
    <li className="pure-menu-item admin-menu-list-item">
      <Link to={`${link}`} className={`pure-menu-link ${isCurrent ? 'eap-menu-current' : ''}`}>
        <span className={`fa ${icon} eap-menu-icon`} />
        <strong>
          {text}
        </strong>
      </Link>
    </li>
  );
};

SideMenuItem.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};
export default SideMenuItem;
