const qs = require('query-string');

module.exports = {
  parseRedirectUrl(queryString) {
    const decoded = qs.parse(queryString);
    return decoded.redirectUrl;
  },

  isWhitelistedRedirectUrl: (redirectUrl) => {
    // TODO: Learn Regex so I can write *.edits.net or *.staging.edits.net or *.edits.dev
    // --Ryan Lee 2018 (I have no shame in my Regex illiteracy
    const dev = /^https:\/\/eap.edits.dev.*/;
    const stage = /^https:\/\/eap.staging.edits.dev.*/;
    const net = /^https:\/\/eap.edits.net.*/;
    const relative = /^\/.*/;
    const briefs = {
      dev: /^https:\/\/briefs.edits.dev.*/,
      stage: /^https:\/\/briefs.staging.edits.net.*/,
      net: /^https:\/\/briefs.edits.net.*/
    };
    return dev.test(redirectUrl)
      || net.test(redirectUrl)
      || stage.test(redirectUrl)
      || briefs.dev.test(redirectUrl)
      || briefs.stage.test(redirectUrl)
      || briefs.net.test(redirectUrl)
      || relative.test(redirectUrl);
  },

  isRelativeUrl: (redirectUrl) => {
    const dev = /^https:\/\/eap.edits.dev.*/;
    const net = /^https:\/\/eap.edits.net.*/;
    const relative = /^\/.*/;
    return dev.test(redirectUrl)
      || net.test(redirectUrl)
      || relative.test(redirectUrl);
  },

  parseCartItems: (queryString) => {
    const queryObj = qs.parse(queryString);
    return JSON.parse(queryObj.items);
  }
};

