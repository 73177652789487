import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const MenuItem = ({
  to, text, click, external, icon
}) => {
  if (external) {
    return (
      <li className="pure-menu-item">
        <a
          className="pure-menu-link"
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={`fa fa-${icon}`} />
          {text}
        </a>
      </li>
    );
  }
  return (
    <li className="pure-menu-item">
      <Link
        className="pure-menu-link"
        to={to}
        onClick={click}
      >
        <span className={`fa fa-${icon}`} />
        {text}
      </Link>
    </li>
  );
};

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
  click: PropTypes.func,
  icon: PropTypes.string,
  external: PropTypes.bool
};

MenuItem.defaultProps = {
  click() {},
  text: '',
  icon: '',
  external: false
};

export default MenuItem;
