import React from 'react';
import PropTypes from 'prop-types';

import EapButton from '../../../components/EapButton';

const DeleteExamineeHasProducts = ({
  closeModal,
  firstName,
  lastName
}) => (
  <div>
    <p>
      Unable to remove examinee <b>{firstName} {lastName}</b> from group.
    </p>
    <p className="validation-message">
      Error: Examinee still has assigned products
    </p>
    <p>
      To remove user as an examinee, please go to the assigned products
      table and revoke all assigned products asssociated to the examinee.
    </p>
    <p>
      First Name: <b>{firstName}</b> Last Name: <b>{lastName}</b>
    </p>
    <EapButton
      text="Close"
      isSecondary
      onClick={closeModal}
    />
  </div>
);

export default DeleteExamineeHasProducts;

DeleteExamineeHasProducts.propTypes = {
  closeModal: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired
};
