import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import PrintableReceipt from './PrintableReceipt';
import RefillReceiptDetails from './RefillReceiptDetails';

import EapButton from '../../components/EapButton';

import './PurchaseCompletedFeedback.css';

const PurchaseCompletedFeedback = ({
  groupId,
  quantity,
  productName,
  purchaseAmount,
  email,
  optionalEmail,
  onClickedDone
}) => {
  const componentRef = useRef();
  return (
    <div className="pure-u-1">
      <p>
        <b>
          Thank you for your purchase! A receipt has been
          emailed to <span className="email">{email}</span>. You
          may also <ReactToPrint
            trigger={() => <button className="receipt-link">print a receipt</button>}
            content={() => componentRef.current}
          />, or use the DONE button below to access
          your new products.
        </b>
      </p>
      <RefillReceiptDetails
        productName={productName}
        quantity={quantity}
        purchaseAmount={purchaseAmount}
      />
      <PrintableReceipt
        ref={componentRef}
        purchaseAmount={purchaseAmount}
        quantity={quantity}
        productName={productName}
        email={email}
        optionalEmail={optionalEmail}
        groupId={groupId}
      />
      <div className="pure-u-1" />
      <div className="pure-u-2-3" />
      <div className="pure-u-1-3">
        <div style={{ float: 'right' }}>
          <EapButton text="DONE" onClick={onClickedDone} />
        </div>
      </div>
    </div>
  );
};

PurchaseCompletedFeedback.propTypes = {
  groupId: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  purchaseAmount: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  optionalEmail: PropTypes.string,
  onClickedDone: PropTypes.func.isRequired
};

PurchaseCompletedFeedback.defaultProps = {
  optionalEmail: undefined
};

export default PurchaseCompletedFeedback;
