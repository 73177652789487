import React from 'react';

import { httpClient } from '../utils/httpClient';
import { dispatchAlert } from './alert';
import SERVER_ERROR from './serverErrorMessage';

export const RELEASE_RESULTS_SUCCESS = 'RELEASE_RESULTS_SUCCESS';
export const RELEASE_RESULTS_FAILURE = 'RELEASE_RESULTS_FAILURE';

export const GET_STATUS_REQUEST = 'GET_ASSIGNED_STATUS_REQUEST';
export const GET_STATUS_SUCCESS = 'GET_ASSIGNED_STATUS_SUCCESS';
export const GET_STATUS_FAILURE = 'GET_ASSIGNED_SATUS_FAILURE';

export const RESET_REQUEST = 'ASSIGNED_RESET_REQUEST';
export const RESET_SUCCESS = 'ASSIGNED_RESET_SUCCESS';
export const RESET_FAILURE = 'ASSIGNED_RESET_FAILURE';

export const RESCORE_REQUEST = 'ASSIGNED_RESCORE_REQUEST';
export const RESCORE_SUCCESS = 'ASSIGNED_RESCORE_SUCCESS';
export const RESCORE_FAILURE = 'ASSIGNED_RESCORE_FAILURE';

export const SUPER_REVOKE_ASSIGNED_PRODUCT_SUCCESS = 'SUPER_REVOKE_ASSIGNED_PRODUCT_SUCCESS';
export const SUPER_REVOKE_ASSIGNED_PRODUCT_FAILURE = 'SUPER_REVOKE_ASSIGNED_PRODUCT_FAILURE';

export const SUPER_SUPPRESS_RESULTS_SUCCESS = 'SUPER_SUPPRESS_RESULTS_SUCCESS';
export const SUPER_SUPPRESS_RESULTS_FAILURE = 'SUPER_SUPPRESS_RESULTS_FAILURE';

export const ENTER_MSD_SUCCESS = 'ENTER_MSD_SUCCESS';

function releaseResultsSuccessAlert(nameObject) {
  return (
    <span>Results released to
      <b> {nameObject.firstName} {nameObject.lastName}</b>.
    </span>
  );
}

function releaseResultsFailureAlert(product) {
  return (
    <span>Unable to release results released to
      <b> {product.firstName} {product.lastName}</b>.&nbsp;
      {SERVER_ERROR}
    </span>
  );
}

function releaseSuppressSuccessAlert(product) {
  return (
    <span>Results suppressed for
      <b> {product.firstName} {product.lastName}</b> for
      <b> {product.productName}</b>
    </span>
  );
}

function releaseSuppressFailureAlert(product) {
  return (
    <span>Unable to release results suppress for
      <b> {product.firstName} {product.lastName}</b> for
      <b> {product.productName}</b>.&nbsp;
      {SERVER_ERROR}
    </span>
  );
}

export const releaseResultsSuccess = (groupId, assignedProductId) => ({
  type: RELEASE_RESULTS_SUCCESS,
  payload: { groupId, assignedProductId }
});

export const releaseResultsFailure = (error) => ({
  type: RELEASE_RESULTS_FAILURE,
  payload: { error }
});

export function releaseResults({
  adminGroupId,
  groupId,
  assignedProductId,
  firstName,
  lastName
}) {
  return (dispatch) => {
    dispatch(releaseResultsSuccess(groupId, assignedProductId));
    httpClient.put(`/group/${adminGroupId}/assigned-products/${assignedProductId}/release`).then(() => {
      dispatch(dispatchAlert({
        message: releaseResultsSuccessAlert({ firstName, lastName }),
        type: 'eap-alert-success',
        time: 5000
      }));
    }).catch((err) => {
      console.error('ERROR releasing results');
      dispatch(releaseResultsFailure(err.message));
      dispatch(dispatchAlert({
        message: releaseResultsFailureAlert({ firstName, lastName }),
        type: 'eap-alert-error'
      }));
    });
  };
}

export function getStatus(id) {
  return (dispatch) => {
    dispatch({
      type: GET_STATUS_REQUEST,
      payload: {}
    });
    httpClient
      .get(`/super/assigned/${id}/status`)
      .then((vals) => {
        dispatch({
          type: GET_STATUS_SUCCESS,
          payload: vals.data
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch(dispatchAlert({
          message: <span>Unable to get status. A server error occured.</span>,
          type: 'eap-alert-error'
        }));
        dispatch({
          type: GET_STATUS_FAILURE,
          payload: { message: err.message }
        });
      });
  };
}

export function resetTest(id, subTestId) {
  return (dispatch) => {
    dispatch({
      type: RESET_REQUEST,
      payload: {}
    });
    httpClient
      .put(`/super/assigned/${id}/reset`, { subTestId })
      .then((vals) => {
        dispatch({
          type: RESET_SUCCESS,
          payload: vals.data,
          original: { id, subTestId }
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch(dispatchAlert({
          message: <span>Unable to reset test. A server error occured.</span>,
          type: 'eap-alert-error'
        }));
        dispatch({
          type: RESET_FAILURE,
          payload: { message: err.message }
        });
      });
  };
}

export function rescoreTest(id) {
  return (dispatch) => {
    dispatch({
      type: RESCORE_REQUEST,
      payload: {}
    });
    httpClient
      .put(`/super/assigned/${id}/rescore`)
      .then((vals) => {
        dispatch({
          type: RESCORE_SUCCESS,
          payload: vals.data,
          original: { id }
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch(dispatchAlert({
          message: <span>Unable to reset test. A server error occured.</span>,
          type: 'eap-alert-error'
        }));
        dispatch({
          type: RESCORE_FAILURE,
          payload: { message: err.message }
        });
      });
  };
}

export function enterMSD({
  testId,
  groupId,
  adminGroupId,
  score,
  gender,
  grade
}) {
  return (dispatch) => {
    let superAdmin = false;
    if (adminGroupId === undefined) {
      superAdmin = true;
    }

    let url = `/group/${adminGroupId}/assigned-products/entermsd`;
    if (superAdmin) {
      url = `/super/assigned/${testId}/entermsd`;
    }
    httpClient
      .put(url, {
        testId,
        score,
        gender,
        grade
      })
      .then((response) => {
        dispatch(dispatchAlert({
          message: <span>MSD score entered.</span>,
          type: 'eap-alert-success',
          time: 10000
        }));
        if (!superAdmin) {
          dispatch({
            type: ENTER_MSD_SUCCESS,
            payload: {
              groupId,
              testId,
              incomplete: response.data.payload.incomplete
            }
          });
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(dispatchAlert({
          message: <span>Unable to reset test. A server error occured.</span>,
          type: 'eap-alert-error'
        }));
      });
  };
}

export const superRevokeAssignedProductSuccess = (assignedProductId, groupId) => ({
  type: SUPER_REVOKE_ASSIGNED_PRODUCT_SUCCESS,
  payload: { assignedProductId, groupId }
});

export const superRevokeAssignedProductFailure = (error) => ({
  type: SUPER_REVOKE_ASSIGNED_PRODUCT_FAILURE,
  payload: { error }
});

export function superRevokeAssignedProduct(productId, groupId) {
  return (dispatch) => {
    dispatch(superRevokeAssignedProductSuccess(productId, groupId));
    return httpClient.delete(`/super/assigned/${productId}`)
      .then(() => {
        dispatch(dispatchAlert({
          message: <span> Assigned product has been revoked.</span>,
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('ERROR revoking assigned products');
        dispatch(superRevokeAssignedProductFailure(err.message));
        dispatch(dispatchAlert({
          message: <span>Unable to revoke assigned product. {err.message}.</span>,
          type: 'eap-alert-error'
        }));
      });
  };
}

export function superReleaseResults(selectedProduct) {
  return (dispatch) => {
    const productId = selectedProduct.id;
    dispatch(releaseResultsSuccess(false, productId));
    return httpClient.put(`/super/assigned/${productId}/release`)
      .then(() => {
        dispatch(dispatchAlert({
          message: releaseResultsSuccessAlert(selectedProduct),
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('ERROR releasing results');
        dispatch(releaseResultsFailure(err.message));
        dispatch(dispatchAlert({
          message: releaseResultsFailureAlert(selectedProduct),
          type: 'eap-alert-error'
        }));
      });
  };
}

export const superSuppressResultsSuccess = (assignedProductId, groupId) => ({
  type: SUPER_SUPPRESS_RESULTS_SUCCESS,
  payload: { assignedProductId, groupId }
});

export const superSuppressResultsFailure = (error) => ({
  type: SUPER_SUPPRESS_RESULTS_FAILURE,
  payload: { error }
});

export function superSuppressResults(selectedProduct, groupId) {
  return (dispatch) => {
    const productId = selectedProduct.id;
    dispatch(superSuppressResultsSuccess(productId, groupId));
    return httpClient.put(`/super/assigned/${productId}/suppress`)
      .then(() => {
        dispatch(dispatchAlert({
          message: releaseSuppressSuccessAlert(selectedProduct),
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('ERROR suppressing results');
        dispatch(superSuppressResultsFailure(err.message));
        dispatch(dispatchAlert({
          message: releaseSuppressFailureAlert(selectedProduct),
          type: 'eap-alert-error'
        }));
      });
  };
}
