import { connect } from 'react-redux';
import OrganizationsTable from './components/OrganizationsTable';
import { updateOrganizationInfo, filter } from '../../actions/organization';

const mapStateToProps = (state) => {
  if (!state.user.permissions.super) {
    return {
      unauthorized: true
    };
  }
  return {
    orginzations: state.organization.serverFiltered,
    unauthorized: false,
    superAdmin: state.super
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchData: (params = {}) => {
    dispatch(filter(params));
  },
  updateOrganizationInfo: (sourceOrganizationId, updatedOrganizationInfo) => {
    dispatch(updateOrganizationInfo(sourceOrganizationId, updatedOrganizationInfo));
  }
});

const OrganizationsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationsTable);

export default OrganizationsTableContainer;
