import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as validator from '@edits/eap-signup-validator';
import FormInputComponent from './FormInputComponent';
import './Form.css';

export default class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordValue: '',
      passwordError: props.showErrorOnInit ? 'Please enter your current password' : undefined,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    e.preventDefault();
    const password = e.target.value;
    let message = validator.validateSingleInput('password', password);
    let isValid = message === undefined;
    if (message !== undefined) {
      message = _.first(message);
    }
    if (!this.props.showError) {
      message = 'Please enter your current password';
      if (e.target.value !== '') {
        isValid = true;
      } else {
        isValid = false;
      }
    }
    if (!this.props.required) {
      message = undefined;
      isValid = true;
    }
    this.setState({
      passwordValue: password,
      passwordError: (isValid) ? '' : message
    });
    this.props.update({
      isValid,
      keyName: this.props.keyName,
      value: password
    });
  }

  render() {
    return (
      <FormInputComponent
        required={this.props.required}
        formGroupStyle={this.props.formGroupStyle}
        keyName={this.props.keyName}
        displayLabel={this.props.displayLabel}
        inputType="password"
        handleChange={this.handleChange}
        inputValue={this.state.passwordValue}
        errorMessage={this.state.passwordError}
      />
    );
  }
}

Password.propTypes = {
  update: PropTypes.func.isRequired,
  keyName: PropTypes.string,
  required: PropTypes.bool,
  formGroupStyle: PropTypes.string,
  displayLabel: PropTypes.string,
  showError: PropTypes.bool,
  showErrorOnInit: PropTypes.bool
};

Password.defaultProps = {
  keyName: 'password',
  required: true,
  formGroupStyle: undefined,
  displayLabel: 'PASSWORD',
  showError: true,
  showErrorOnInit: false
};
