import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SideMenuItem from './SideMenuItem';
import './AdminMenu.css';

const AdminMenu = ({ routes, base, transparent }) =>
  (
    <div className={`pure-menu ${transparent ? 'eap-admin-menu-transparent' : 'eap-admin-menu'}`}>
      <ul className="pure-menu-list">
        {
        _.map(routes, route =>
          (<SideMenuItem
            key={`${base}-${route.path}`}
            link={`/${base}/${route.path}`}
            text={(route.text !== undefined) ? route.text :
              _.upperFirst(route.path.replace(/-/g, ' ')
                .replace(/\w\S*/g, foundString =>
                  foundString.charAt(0).toUpperCase() + foundString.substr(1).toLowerCase()))}
            icon={route.icon}
          />))
      }
      </ul>
    </div>
  );

AdminMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    text: PropTypes.string,
    icon: PropTypes.string.isRequired
  })).isRequired,
  base: PropTypes.string,
  transparent: PropTypes.bool
};

AdminMenu.defaultProps = {
  base: '',
  transparent: false
};

export default AdminMenu;
