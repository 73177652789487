// ReactToPrint requires this be a class component
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';

import RefillReceiptDetails from './RefillReceiptDetails';

import './PrintableReceipt.css';

class PrintableReceipt extends React.Component {
  render() {
    return (
      <div className="printed">
        <h3>EdITS</h3>
        <RefillReceiptDetails
          email={this.props.email}
          productName={this.props.productName}
          quantity={this.props.quantity}
          purchaseAmount={this.props.purchaseAmount}
        />
      </div>
    );
  }
}

PrintableReceipt.propTypes = {
  email: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  purchaseAmount: PropTypes.number.isRequired
};

export default PrintableReceipt;
