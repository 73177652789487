import { connect } from 'react-redux';
import _ from 'lodash';
import GroupExamineeInvitesTable from './components/GroupExamineeInvitesTable';
import {
  sendExamineeInvitesAsSuper,
  superRevokeExamineeInvite,
  superResendExamineeInvite
} from '../../actions/examineeInvites';

const mapStateToProps = (state) => {
  if (state.group.info === undefined) {
    return {
      examineeInvites: [],
      products: []
    };
  }

  const groupId = state.group.info.id;

  const examineeInvites = _.chain(state.examineeInvites.list)
    .filter({ groupId })
    .map((invite) => ({
      ...invite,
      ...{ status: invite.status || 'Sent' }
    }))
    .value();

  return {
    examineeInvites,
    products: state.availableProducts.list
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  sendInvites: (emailList, availableProductId) => {
    dispatch(sendExamineeInvitesAsSuper(
      ownProps.match.params.groupId,
      emailList,
      availableProductId
    ));
  },
  revokeExamineeInvite: (invite, groupId) => {
    dispatch(superRevokeExamineeInvite(invite, groupId));
  },
  resendExamineeInvite: (inviteId) => {
    dispatch(superResendExamineeInvite(inviteId));
  }
});

const GroupExamineeInvitesTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupExamineeInvitesTable);

export default GroupExamineeInvitesTableContainer;
