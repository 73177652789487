import { connect } from 'react-redux';
import SuperHome from './components/Super';

import {
  getAllProducts
} from '../actions/products';

const mapStateToProps = (state) => {
  if (!state.user.permissions.super) {
    return {
      unauthorized: true
    };
  }
  return {
    unauthorized: false
  };
};

const mapDispatchToProps = (dispatch) => ({
  load: () => {
    dispatch(getAllProducts());
  }
});

const SuperContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperHome);

export default SuperContainer;
