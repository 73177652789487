import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import _ from 'lodash';

import EapButton from './EapButton';
// import EapStaticAlert from './EapStaticAlert';
import InlineValidationMessage from './InlineValidationMessage';
import GroupSelector from './GroupSelector';

const INVALID_AMOUNT = 'The amount must be a valid positive number';
const GREATER_AMOUNT = 'The amount is greater than what is available for transfer';

export default class ProductTransferForm extends Component {
  static isValidAmount(amountInput) {
    return !_.isEmpty(amountInput) &&
      !Number.isNaN(amountInput) &&
      parseInt(amountInput, 10) !== 0;
  }

  constructor(props) {
    super(props);
    this.transferProducts = this.transferProducts.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onGroupSelected = this.onGroupSelected.bind(this);
    this.amountIsGreaterThanLimit = this.amountIsGreaterThanLimit.bind(this);
    this.displayError = this.displayError.bind(this);

    const firstGroup = _.first(this.props.groups);
    this.state = {
      amountMessage: '',
      selectedGroupId: firstGroup.id,
      transferEnabled: false,
      amountInput: ''
    };
  }

  onChangeAmount(e) {
    this.setState({
      amountInput: e.target.value
    });

    if (!ProductTransferForm.isValidAmount(e.target.value)) {
      this.setState({
        amountMessage: INVALID_AMOUNT,
        transferEnabled: false
      });
    } else if (this.amountIsGreaterThanLimit(e.target.value)) {
      this.setState({
        amountMessage: GREATER_AMOUNT,
        transferEnabled: false
      });
    } else {
      this.setState({
        amountMessage: '',
        transferEnabled: true
      });
    }
  }

  onGroupSelected(groupId) {
    this.setState({
      selectedGroupId: groupId,
      transferEnabled: ProductTransferForm.isValidAmount(this.state.amountInput) &&
        !this.amountIsGreaterThanLimit(this.state.amountInput)
    });
  }

  transferProducts(e) {
    e.preventDefault();
    if (this.state.selectedGroupId !== undefined &&
      ProductTransferForm.isValidAmount(this.state.amountInput) &&
      !this.amountIsGreaterThanLimit(this.state.amountInput)
    ) {
      this.props.transferProducts(
        parseInt(this.state.amountInput, 10),
        parseInt(this.state.selectedGroupId, 10)
      );
    }
  }

  amountIsGreaterThanLimit(amountInput) {
    return amountInput > this.props.availableAmount;
  }

  displayError() {
    if (this.state.amountMessage) {
      return this.state.amountMessage;
    } else if (this.props.availableAmount === 0) {
      return 'WARNING: You are out of product!';
    }
    return '';
  }

  render() {
    return (
      <div>
        <p>
          {this.props.isPurchaseTransfer ?
          `You have ${this.props.availableAmount} purchase to make available to a group. Please select the amount you'd like to make available to a group.` :
          `You have ${this.props.availableAmount} products available for transfer. Please select the amount from this group to transfer into the destination group.`
          }
        </p>
        <form className="pure-form pure-form-aligned eap-grey-form">
          <div className="pure-control-group">
            <label htmlFor="transferAmount">Transfer Amount:</label>
            <InputMask
              disabled={this.props.availableAmount === 0}
              id="transferAmount"
              onChange={this.onChangeAmount}
              type="text"
              alwaysShowMask={false}
              mask={_.times(this.props.availableAmount.toString().length, () => '9').join('')}
              maskChar=""
              placeholder={this.props.availableAmount === 0 ?
              'N/A' : `1-${this.props.availableAmount.toString()}`
              }
            />
            <div style={{ marginLeft: '5em' }}>
              <InlineValidationMessage message={this.displayError()} />
            </div>
          </div>
          <div className="pure-control-group">
            <label htmlFor="destinationGroupId">Destination Group:</label>
            <GroupSelector
              selectedGroupId={this.state.selectedGroupId}
              onGroupSelected={this.onGroupSelected}
              groups={this.props.groups}
            />
          </div>
          <div className="pure-controls">
            <EapButton
              onClick={this.transferProducts}
              text={this.props.isPurchaseTransfer ? 'MAKE AVAILABLE' : 'TRANSFER'}
              disabled={!this.state.transferEnabled}
            />
            &nbsp;
            <EapButton
              isSecondary
              onClick={() => this.props.closeModal()}
              text="CANCEL"
            />
          </div>
        </form>
      </div>
    );
  }
}

ProductTransferForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  availableAmount: PropTypes.number.isRequired,
  transferProducts: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.name,
    groupId: PropTypes.number
  })).isRequired,
  isPurchaseTransfer: PropTypes.bool
};

ProductTransferForm.defaultProps = {
  isPurchaseTransfer: false
};
