import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProgressSpinner from './ProgressSpinner';

class InviteSpinner extends Component {
  componentWillMount() {
    this.props.acceptInvite(this.props.match.params.hash);
  }

  render() {
    return <ProgressSpinner />;
  }
}

export default InviteSpinner;

InviteSpinner.propTypes = {
  acceptInvite: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      hash: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
