import { connect } from 'react-redux';

import PasswordUpdate from './components/PasswordUpdate';
import { updatePassword } from '../actions/user';

const mapStateToProps = state => ({
  success: state.user.passwordUpdateSucceeded,
  message: !state.user.passwordUpdateSucceeded ? state.user.updateFailedMessage : undefined,
  expired: state.user.passwordResetExpired,
});

const mapDispatchToProps = dispatch => ({
  updatePassword: (password, resetToken) => {
    dispatch(updatePassword(password, resetToken));
  }
});

const PasswordUpdateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordUpdate);

export default PasswordUpdateContainer;
