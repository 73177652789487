/**
 * !!!WARNING!!!
 * Mounting this component will trigger http request.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import {
  getStatus,
  resetTest,
  rescoreTest
} from '../../actions/assignedProducts';

import EapModal from '../../components/EapModal';
import EapButton from '../../components/EapButton';
import Spinner from '../../components/ProgressSpinner';

class AssignedProductStatusModal extends React.Component {
  constructor() {
    super();
    this.state = {
      showConfirm: undefined
    };
  }
  componentWillMount() {
    this.props.getStatus(this.props.testId);
  }
  resetSubTest(sub) {
    this.props.resetTest(sub.productId, sub.id);
    this.closeConfirm();
  }
  closeConfirm() {
    this.setState({
      showConfirm: undefined
    });
  }
  rescore() {
    this.props.rescore(this.props.testId);
    this.closeRescore();
  }
  closeRescore() {
    this.setState({
      showRescore: false
    });
  }
  render() {
    let display;

    if (this.props.error) {
      display = <p>A server error occured!</p>;
    } else if (!this.props.status.isLoaded) {
      display = <Spinner />;
    } else if (this.props.status.isEmpty) {
      display = <p>This product has not been started.</p>;
    } else {
      const showRescore = (
        <div>
          Are you sure you want to rescore?
          <EapButton
            text="Yes"
            onClick={() => this.rescore()}
          />
          <EapButton
            text="No"
            onClick={() => this.closeRescore()}
          />
        </div>
      );
      const normalProduct = (
        <ul className="no-bullets">
          <li>
            <span className="pure-u-1-3">Finished:</span>
            <span className="pure-u-1-3">{this.props.status.isFinished.toString()}</span>
          </li>
          <li>
            <span className="pure-u-1-3">Scored:</span>
            <span className="pure-u-1-3">{this.props.status.isScored.toString()}</span>
            <EapButton
              text="Rescore"
              onClick={() => this.setState({ showRescore: true })}
            />
          </li>
          {this.state.showRescore ? showRescore : ''}
        </ul>
      );
      const subTests = [];
      this.props.status.subTests.forEach((sub) => {
        const confirmJSX = (
          <div>
            Are you sure you want to reset?
            <EapButton
              text="Yes"
              onClick={() => this.resetSubTest(sub)}
            />
            <EapButton
              text="No"
              isSecondary
              onClick={() => this.closeConfirm()}
            />
          </div>
        );
        subTests.push((
          <li key={uuid()}>
            <div>
              <span className="pure-u-1-3">{sub.subTest || sub.testType}</span>
              <span className="pure-u-1-3">{sub.isFinished.toString()}</span>
              <span className="pure-u-1-3">
                <EapButton
                  text="Reset"
                  onClick={() => { this.setState({ showConfirm: sub.id }); }}
                />
              </span>
            </div>
            {this.state.showConfirm === sub.id ? confirmJSX : ''}
          </li>
        ));
      });
      display = (
        <div>
          {normalProduct}
          {subTests.length > 0 ? <hr /> : ''}
          <ul className="no-bullets">
            {subTests}
          </ul>
        </div>
      );
    }

    return (
      <EapModal
        showModal={this.props.isOpen}
        modalTitle="Show Status"
      >
        <div>
          {display}
          <div className="modal-center">
            <EapButton
              text="Close"
              isSecondary
              onClick={() => this.props.closeModal()}
            />
          </div>
        </div>
      </EapModal>
    );
  }
}

AssignedProductStatusModal.propTypes = {
  testId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  resetTest: PropTypes.func.isRequired,
  rescore: PropTypes.func.isRequired,
  error: PropTypes.bool,
  status: PropTypes.shape({
    isLoaded: PropTypes.bool,
    isEmpty: PropTypes.bool,
    isFinished: PropTypes.bool,
    isScored: PropTypes.bool,
    subTests: PropTypes.array
  })
};

AssignedProductStatusModal.defaultProps = {
  error: undefined,
  status: {
    isLoaded: false,
    isEmpty: false,
    isFinished: false,
    isScored: false,
    subTests: []
  }
};

export default connect(
  (state) => {
    if (state.assignedProducts.status instanceof Error) {
      return { error: true };
    }
    if (state.assignedProducts.status) {
      return { status: { ...state.assignedProducts.status } };
    }
    return { status: undefined };
  },
  dispatch => ({
    getStatus: (testId) => {
      dispatch(getStatus(testId));
    },
    resetTest: (testId, subTestId) => {
      dispatch(resetTest(testId, subTestId));
    },
    rescore: (testId) => {
      dispatch(rescoreTest(testId));
    }
  })
)(AssignedProductStatusModal);
