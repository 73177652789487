/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AdminIcon from '../../../components/AdminIcon';
import AdminIconList from '../../../components/AdminIconList';
import { StaticEapTable } from '../../../components/StaticEapTable';
import EapButton from '../../../components/EapButton';
import ResourceLink from '../../../components/ResourceLink';
import * as EapDisplayDate from '../../../components/EapDisplayDate';
import ProductAssignmentModal from './ProductAssignmentModal';
import DeleteExamineeModal from './DeleteExamineeModal';
import AddExamineeModal from './AddExamineeModal';

class GroupExamineesTable extends Component {
  constructor() {
    super();
    this.state = {
      showAddExamineeModal: false,
      showModal: {
        type: '',
        data: {},
      },
    };
    this.assignProduct = this.assignProduct.bind(this);
    this.deleteExaminee = this.deleteExaminee.bind(this);
    this.setShowModal = this.setShowModal.bind(this);
  }

  setShowModal(params) {
    this.setState({ showModal: params });
  }

  assignProduct(examinee, product) {
    this.props.superAssignProduct(examinee, this.props.groupId, product);
    this.setShowModal({ type: '', data: {} });
  }

  deleteExaminee(examinee) {
    this.props.superDeleteExaminee(examinee, this.props.groupId);
  }

  render() {
    const {
      state: {
        showModal,
      }
    } = this;
    const columns = [
      {
        Header: 'ID',
        accessor: 'userId',
        Cell: ({ row }) => (
          <ResourceLink
            displayValue={row.original.userId.toString()}
            toRoute={`/super/user/${row.original.userId}/info`}
          />
        )
      },
      {
        Header: 'User Name',
        accessor: 'username'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'First',
        accessor: 'firstName',
      },
      {
        Header: 'Last',
        accessor: 'lastName',
      },
      {
        Header: 'Join Date',
        accessor: 'createdAt',
        Cell: ({ row }) => <EapDisplayDate.Display date={row.original.createdAt} />,
        sort: EapDisplayDate.sort
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => (
          <AdminIconList>
            <AdminIcon
              url={`/super/user/${row.original.id}/info`}
              icon="fa-info-circle"
              toolTip="Information"
            />
            <AdminIcon
              onClick={() => this.setShowModal({ type: 'assign', data: row.original })}
              icon="fa-file"
              toolTip="Assign"
            />
            <AdminIcon
              onClick={() => this.setShowModal({ type: 'delete', data: row.original })}
              icon="fa-trash"
              toolTip="Delete"
            />
          </AdminIconList>
        )
      }
    ];
    return (
      <div>
        <EapButton
          text="Add Examinee"
          onClick={() => this.setState({ showAddExamineeModal: true })}
        />
        <StaticEapTable
          columns={columns}
          data={this.props.examinees}
        />
        <AddExamineeModal
          addExaminee={(user) => this.props.addExaminee(user, this.props.groupId)}
          showModal={this.state.showAddExamineeModal}
          closeModal={() => this.setState({ showAddExamineeModal: false })}
        />
        {
          showModal.type === 'assign'
            ? (
              <ProductAssignmentModal
                selectedExaminee={showModal.data}
                assignProduct={this.assignProduct}
                closeModal={() => this.setShowModal({ type: '', data: {} })}
              />
            ) : ''
        }
        {
          showModal.type === 'delete'
            ? (
              <DeleteExamineeModal
                selectedExaminee={showModal.data}
                deleteExaminee={this.deleteExaminee}
                closeModal={() => this.setShowModal({ type: '', data: {} })}
              />
            ) : ''
        }
      </div>
    );
  }
}

GroupExamineesTable.propTypes = {
  examinees: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    userGroupId: PropTypes.number,
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    createdAt: PropTypes.string
  })),
  groupId: PropTypes.number.isRequired,
  superDeleteExaminee: PropTypes.func.isRequired,
  superAssignProduct: PropTypes.func.isRequired,
  addExaminee: PropTypes.func.isRequired
};

GroupExamineesTable.defaultProps = {
  examinees: []
};

export default GroupExamineesTable;
