import { connect } from 'react-redux';
import _ from 'lodash';

import GroupExamineesTable from './components/GroupExamineesTable';
import {
  getSuperGroupExaminees,
  superDeleteExaminee,
  superAssignProduct,
  superAddExaminee,
} from '../../actions/examinees';

const mapStateToProps = (state) => {
  if (state.group.info === undefined) {
    return {
      groupId: 0,
      examinees: []
    };
  }
  const examinees = _
    .chain(state.examinees.list)
    .filter({ groupId: state.group.info.id })
    .map((ex) => ({
      ...ex,
      ..._.find(state.user.list, { userId: ex.userId }),
      assigned: _.filter(state.assignedProducts.list, { userId: ex.userId })
    }))
    .value();
  return {
    groupId: state.group.info.id,
    examinees
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSuperGroupExaminees: (groupId) => {
    dispatch(getSuperGroupExaminees(groupId));
  },
  superDeleteExaminee: (examinee, groupId) => {
    dispatch(superDeleteExaminee(examinee, groupId));
  },
  superAssignProduct: (examinee, groupId, product) => {
    dispatch(superAssignProduct(examinee, groupId, product));
  },
  addExaminee: (user, groupId) => {
    dispatch(superAddExaminee(user, groupId));
  }
});

const GroupExamineesTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupExamineesTable);

export default GroupExamineesTableContainer;
