import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EapModal from '../../../components/EapModal';
import { DynamicEapTable } from '../../../components/DynamicEapTable';
import AdminIcon from '../../../components/AdminIcon';
import AdminIconList from '../../../components/AdminIconList';
import UserEditor from './UserEditor';
import calculateActionsWidth from '../../../utils/EapTableHelper';

export default class SigninsTable extends Component {
  constructor() {
    super();
    this.state = {
      selectedSigninId: undefined,
    };
  }

  render() {
    const {
      users,
      filterUsers,
      updateUserInfo
    } = this.props;

    const {
      selectedSigninId
    } = this.state;

    if (!users) {
      filterUsers();
    }

    const columns = [
      {
        Header: 'ID',
        accessor: 'id',
        width: 70,
        Cell: ({ row }) => (
          <div className="actions">
            <Link
              to={`/super/user/${row.original.id}/info`}
              style={{ textDecoration: 'none' }}
            >
              {row.original.id}
            </Link>
          </div>
        )
      },
      {
        Header: 'Username',
        accessor: 'username',
        width: 136
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 136
      },
      {
        Header: 'First',
        accessor: 'firstName',
      },
      {
        Header: 'Last',
        accessor: 'lastName'
      },
      {
        Header: 'Admin',
        accessor: 'adminGroups',
        width: 80,
        Cell: ({ row }) => (
          <div className="centerTable">
            { (row.original.adminGroups.length > 0) ? 'Yes' : 'No' }
          </div>
        )
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: ({ cell: { value } }) => (
          <div className="centerTable">
            {value.format('MM/DD/YYYY')}
          </div>
        ),
        sortType: ({ values: { createdAt: a } }, { values: { createdAt: b } }) => (
          a.isAfter(b)
        ),
        disableFilters: true
      },
      {
        Header: 'Updated',
        accessor: 'updatedAt',
        Cell: ({ cell: { value } }) => (
          <div className="centerTable">
            {value.format('MM/DD/YYYY')}
          </div>
        ),
        sortType: ({ values: { createdAt: a } }, { values: { createdAt: b } }) => (
          a.isAfter(b)
        ),
        disableFilters: true
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="centerTable">
            <AdminIconList style={{ width: calculateActionsWidth({ numberOfIcons: 2 }) }}>
              <AdminIcon
                url={`/super/user/${row.original.id}/info`}
                icon="fa-info-circle"
                toolTip="View"
              />
              <AdminIcon
                onClick={() => this.setState({ selectedSigninId: row.original.id })}
                icon="fa-pencil"
                toolTip="Edit"
              />
              <EapModal
                modalTitle="Edit Signin Information"
                showModal={selectedSigninId === row.original.id}
              >
                <UserEditor
                  userInfo={row.original}
                  userId={parseInt(row.original.id, 10)}
                  closeModal={() => this.setState({ selectedSigninId: undefined })}
                  updateUserInfo={updateUserInfo}
                  fromTable
                />
              </EapModal>
            </AdminIconList>
          </div>
        )
      }
    ];
    return (
      <div>
        <DynamicEapTable
          columns={columns}
          data={users}
          fetchData={({ filtered, sorted, limit }) => filterUsers(filtered, sorted, limit)}
        />
      </div>
    );
  }
}

SigninsTable.propTypes = {
  updateUserInfo: PropTypes.func.isRequired
};
