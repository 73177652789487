import { httpClient } from '../utils/httpClient';

export const QBO_ERROR = 'QBO_ERROR';

export const QBO_INIT_START = 'QBO_INIT';
export const QBO_INIT_INSYNC = 'QBO_INIT_INSYNC';
export const QBO_INIT_OAUTH = 'QBO_OAUTH';

export const QBO_OAUTH_START = 'QBO_OAUTH_START';
export const QBO_OAUTH_SUCCESS = 'QBO_OAUTH_SUCCESS';

export const QBO_REFRESH_REQUEST = 'QBO_REFRESH_REQUEST';
export const QBO_REFRESH_SUCCESS = 'QBO_REFRESH_SUCCESS';

export const refreshQboProductData = () => ((dispatch) => {
  dispatch({ type: QBO_REFRESH_REQUEST });
  httpClient
    .post('/qbo/refresh-products')
    .then(() => {
      dispatch({ type: QBO_REFRESH_SUCCESS });
    })
    .catch((err) => console.error('Error on QBO ProductRefresh', err));
});

export const refreshQboToken = () => ((dispatch) => {
  dispatch({ type: QBO_INIT_START });
  httpClient
    .delete('/qbo')
    .then(({ data }) => {
      dispatch({
        type: QBO_INIT_OAUTH,
        payload: {
          uri: data.redirect
        }
      });
    }).catch((err) => console.error('Error on QBO token delete', err));
});

export const qboInit = () => ((dispatch) => {
  dispatch({ type: QBO_INIT_START });
  httpClient
    .get('/qbo')
    .then((res) => {
      switch (res.data.state) {
        case 'insync':
          dispatch({ type: QBO_INIT_INSYNC });
          break;
        case 'oauth':
          dispatch({
            type: QBO_INIT_OAUTH,
            payload: {
              uri: res.data.redirect
            }
          });
          break;
        default:
          throw new Error('Error');
      }
    })
    .catch(() => {
      console.error('Error on QBO Init');
    });
});

export function qboSaveOauth(data) {
  return (dispatch) => {
    dispatch({ type: QBO_OAUTH_START });
    httpClient
      .put('/qbo', data)
      .then(() => {
        dispatch({
          type: QBO_OAUTH_SUCCESS
        });
      }).catch(() => {
        console.error('ERROR on QBO save auth');
        dispatch({
          type: QBO_ERROR
        });
      });
  };
}
