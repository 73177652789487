import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AdminIcon from '../../components/AdminIcon';
import { DynamicEapTable } from '../../components/DynamicEapTable';
import NavigationBar from '../../components/NavigationBar';
import GroupContentTab from '../../group/components/GroupContentTab';

import './Orders.css';

const OrdersTable = ({ data, fetchData, baseUrl }) => (
  <div className="superOrdersTable">
    <NavigationBar
      routes={[
        { path: 'orders', text: 'Orders', icon: 'fa-dollar' }
      ]}
      base={`${baseUrl.substring(1)}`}
    />
    <GroupContentTab>
      <DynamicEapTable
        columns={[
          {
            Header: 'Email',
            accessor: 'email'
          },
          {
            Header: 'First',
            accessor: 'firstName'
          },
          {
            Header: 'Last',
            accessor: 'lastName'
          },
          {
            Header: 'Group',
            accessor: 'groupName',
            disableSortBy: true,
            Cell: ({
              value,
              row: {
                original: {
                  groupId,
                },
              },
            }) => (
              <Link to={`/super/group/${groupId}/info`}>
                {value}
              </Link>
            )
          },
          {
            Header: 'Fulfillment',
            accessor: 'fulfillmentStatus',
          },
          {
            Header: 'Amount',
            accessor: 'grandTotal',
            Cell: ({ value }) => (`$${value}`),
          },
          {
            Header: 'Created',
            accessor: 'createdAt',
            disableFilters: true,
            sortType: (rowA, rowB, columnId) => (
              rowA.values[columnId].isAfter(rowB.values[columnId])
            ),
            Cell: ({ row }) => (
              <span>{row.original.createdAt.format('MM/DD/YYYY')}</span>
            )
          },
          {
            Header: 'Links',
            accessor: 'actions',
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row }) => (
              <>
                <AdminIcon
                  url={`${baseUrl}/information`}
                  icon="fa-info-circle"
                  toolTip="Information"
                />
                &nbsp;
                <AdminIcon
                  url={`/super/user/${row.original.userId}/info`}
                  icon="fa-user"
                  toolTip="User"
                />
                {
                  row.original.qboId
                    ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://app.qbo.intuit.com/app/invoice?txnId=${row.original.qboId}`}
                      >
                        QBO
                      </a>
                    )
                    : ''
                }
              </>
            )
          },
        ]}
        data={data}
        fetchData={(params) => fetchData(params)}
      />
    </GroupContentTab>
  </div>
);

OrdersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.firstName,
    lastName: PropTypes.lastName,
    stripeId: PropTypes.string
  })).isRequired,
  fetchData: PropTypes.func.isRequired
};

export default OrdersTable;
