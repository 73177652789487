import React from 'react';
import './PureSpinner.css';

// currently white or grey
const PureSpinner = ({ color = 'white' }) => (
  <div className={`lds-ring lds-ring-${color}`}>
    <div />
    <div />
    <div />
    <div />
  </div>
);


export default PureSpinner;
