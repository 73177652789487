import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Provider } from 'react-redux';
import 'purecss/build/pure-min.css';
import 'purecss/build/grids-responsive-min.css';
import 'font-awesome/css/font-awesome.css';
import App from './App';
import store from './store';
import './index.css';

import { httpClient } from './utils/httpClient';
import { clearState } from './actions/root';
import { publishServerError } from './actions/error';
import history from './utils/history';

if (_.includes(window.location.host, 'localhost')) {
  console.error(`
    !!!!!! WARNING !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

      Are you SURE you want to use 'localhost'???
      - Client-side security depends on correct domain: eap.edits.dev

    !!!!!! WARNING !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  `);
}

// This the default error handler.
// All errors are pass on the promise chain via a rejection.
// 401 and >=500 errors have convenience functions so the reducers do not
// have to explicitly handled those cases
httpClient.interceptors.response.use(
  (response) => response,
  (err) => {
    // This should never happen?
    if (!err.response) {
      const wrappedE = new Error('Error interceptor: Undefined exception');
      wrappedE.response = {
        status: 0,
      };
      return Promise.reject(wrappedE);
    }

    // Convenience function for dealing with unauthenticated responses
    if (err.response.status === 401) {
      store.dispatch(clearState());
      const nonAuthPaths = ['/signin', '/signup'];
      if (nonAuthPaths.indexOf(history.location.pathname) === -1) {
        const redirectUrl = encodeURIComponent(window.location);
        history.push(`/signin?redirectUrl=${redirectUrl}?`);
      }
    }

    // Convenience function for dealing with server errors
    if (err.response.status >= 500) {
      store.dispatch(publishServerError(err));
    }

    // All errors are passed through
    return Promise.reject(err);
  }
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
