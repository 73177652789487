import React from 'react';
import PropTypes from 'prop-types';

import WizardStepIcon from './WizardStepIcon';

import './WizardStepIndicator.css';

const WizardStepIndicator = ({
  number, text, current, completed, paymentFailed
}) => {
  let referenceStr;
  referenceStr = (completed) ? 'COMPLETED' : (`TODO${number}`);
  if (current) {
    referenceStr = `CURRENT${number}`;
  }
  if (paymentFailed) {
    referenceStr = 'PAYMENT_FAILED';
  }

  const isTodo = !current && !completed && !paymentFailed;
  // TODO: ERGGGHh this is awful
  const additionalClassName = `${completed && !paymentFailed && 'display-text-completed'} ${isTodo && 'display-text-todo'} ${paymentFailed && 'display-text-payment-failed'}`;

  return (
    <span className="wizard-step-indicator">
      <WizardStepIcon reference={referenceStr} />
      <div className={`display-text ${additionalClassName}`}>
        {text}
      </div>
    </span>
  );
};

WizardStepIndicator.propTypes = {
  current: PropTypes.bool.isRequired,
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  paymentFailed: PropTypes.bool
};

WizardStepIndicator.defaultProps = {
  paymentFailed: false
};

export default WizardStepIndicator;
