import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import { httpClient } from '../utils/httpClient';
import history from '../utils/history';
import { dispatchAlert } from './alert';
import { initClientUser } from './user';

export const REVOKE_ADMIN_INVITE_SUCCESS = 'REVOKE_ADMIN_INVITE_SUCCESS';
export const REVOKE_ADMIN_INVITE_FAILURE = 'REVOKE_ADMIN_INVITE_FAILURE';
export const SEND_ADMIN_INVITES_SUCCESS = 'SEND_ADMIN_INVITES_SUCCESS';
export const SEND_ADMIN_INVITES_FAILURE = 'SEND_ADMIN_INVITES_FAILURE';
export const ACCEPT_ADMIN_INVITE_SUCCESS = 'ACCEPT_ADMIN_INVITE_SUCCESS';
export const ACCEPT_ADMIN_INVITE_FAILURE = 'ACCEPT_ADMIN_INVITE_FAILURE';
export const SUPER_GET_ADMIN_INVITES_SUCCESS = 'SUPER_GET_ADMIN_INVITES_SUCCESS';
export const SUPER_GET_ADMIN_INVITES_FAILURE = 'SUPER_GET_ADMIN_INVITES_FAILURE';

function adminJoinSuccessMessage(groupName) {
  return <span> You are now an administrator for <b>{groupName}</b>. </span>;
}

export const revokeAdminInviteSuccess = (adminInviteId, groupId) => ({
  type: REVOKE_ADMIN_INVITE_SUCCESS,
  payload: { adminInviteId, groupId }
});

export const revokeAdminInviteFailure = error => ({
  type: REVOKE_ADMIN_INVITE_FAILURE,
  payload: { error }
});

export function revokeAdminInvite(adminGroupId, groupId, adminInviteRow) {
  return (dispatch) => {
    dispatch(revokeAdminInviteSuccess(adminInviteRow.id, groupId));
    const adminInviteId = adminInviteRow.id;
    return httpClient.delete(`/group/${adminGroupId}/adminInvite/${adminInviteId}`)
      .then(() => {
        dispatch(dispatchAlert({
          message: (
            <span>
              Admin invite for <b>{adminInviteRow.email}</b> has been revoked.
            </span>
          ),
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('ERROR REVOKING ADMIN');
        dispatch(dispatchAlert({
          message: (
            <span>
              Unable to revoke invite. {err.message}
            </span>
          ),
          type: 'eap-alert-error',
        }));
      });
  };
}

export function validateAdminInvite(hash) {
  return new Promise((resolve, reject) =>
    httpClient
      .get(`/user/invites/admin/${hash}/valid`)
      .then(response => resolve({ hash, valid: response.data.valid }))
      .catch(reject));
}

export const acceptAdminInviteSuccess = adminGroup => ({
  type: ACCEPT_ADMIN_INVITE_SUCCESS,
  payload: { adminGroup }
});

export const acceptAdminInviteFailure = error => ({
  type: ACCEPT_ADMIN_INVITE_FAILURE,
  payload: { error }
});

export function acceptAdminInvite(hash) {
  return dispatch =>
    httpClient.post('/user/invites/admin/accept', { hash })
      .then((response) => {
        dispatch(acceptAdminInviteSuccess(response.data));
        dispatch(initClientUser());
        dispatch(dispatchAlert({
          message: adminJoinSuccessMessage(response.data.name),
          type: 'eap-alert-success',
          time: 5000
        }));
        history.push('/dashboard/groups');
      }).catch((err) => {
        console.error('ERROR ACCEPTING ADMIN INVITE', err.message);
        if (err.response.status === 409) {
          dispatch(dispatchAlert({
            message: adminJoinSuccessMessage(err.response.data.groupName),
            type: 'eap-alert-success',
            time: 5000
          }));
        } else {
          dispatch(dispatchAlert({
            message: (
              <span>
                Invite could not be found or is invalid.
                Please contact the administrator who invited you or contact EdITS support.
              </span>
            ),
            type: 'eap-alert-error',
            time: 5000
          }));
        }
        history.push('/dashboard');
      });
}

export const sendAdminInvitesSuccess = (newInvites, groupId) => ({
  type: SEND_ADMIN_INVITES_SUCCESS,
  payload: { newInvites, groupId }
});

export const sendAdminInvitesFailure = error => ({
  type: SEND_ADMIN_INVITES_FAILURE,
  payload: { error }
});

export function sendAdminInvites(adminGroupId, groupId, emailList, permissions) {
  return dispatch =>
    httpClient.post(`/group/${adminGroupId}/adminInvites`, {
      groupId,
      emailList,
      permissions
    })
      .then((response) => {
        const newInvites = _.map(response.data, invite =>
          Object.assign({}, invite, { createdAt: moment(invite.createdAt).format('MMMM Do YYYY') }));
        dispatch(dispatchAlert({
          message: (
            <span>
              Invites have successfully been sent.
            </span>
          ),
          type: 'eap-alert-success',
          time: 10000
        }));
        dispatch(sendAdminInvitesSuccess(newInvites, groupId));
      }).catch((err) => {
        console.error('ERROR SENDING ADMIN INVITES');
        dispatch(dispatchAlert({
          message: (
            <span> Unable to email admin invites. {err.message}.</span>
          ),
          type: 'eap-alert-error'
        }));
      });
}

export function resendAdminInvite(adminGroupId, adminInviteId) {
  return dispatch =>
    httpClient.put(`/group/${adminGroupId}/adminInvite/${adminInviteId}`)
      .then(() => {
        dispatch(dispatchAlert({
          message: (
            <span>
              Invite has successfully been resent.
            </span>
          ),
          type: 'eap-alert-success',
          time: 10000
        }));
      }).catch((err) => {
        console.error(err);
        dispatch(dispatchAlert({
          message: (
            <span> Unable to resend admin invite. {err.message}.</span>
          ),
          type: 'eap-alert-error'
        }));
      });
}


export const superResendAdminInvite = (adminInviteId) => (
  (dispatch) => (
    httpClient
      .put(`/super/adminInvite/${adminInviteId}`)
      .then(() => {
        dispatch(dispatchAlert({
          message: (
            <span>
              Invite has successfully been resent.
            </span>
          ),
          type: 'eap-alert-success',
          time: 10000
        }));
      }).catch((err) => {
        console.error(err);
        dispatch(dispatchAlert({
          message: (
            <span> Unable to resend admin invite. {err.message}.</span>
          ),
          type: 'eap-alert-error'
        }));
      })
  )
);

export function superSendAdminInvites(groupId, emailList, permissions) {
  return dispatch =>
    httpClient.post(`super/group/${groupId}/adminInvites`, { emailList, permissions })
      .then((response) => {
        dispatch(dispatchAlert({
          message: (
            <span>
              Invites have successfully been sent.
            </span>
          ),
          type: 'eap-alert-success',
          time: 5000
        }));
        dispatch(sendAdminInvitesSuccess(response.data, groupId));
      }).catch((err) => {
        console.error('ERROR SENDING INVITES');
        dispatch(dispatchAlert({
          message: (
            <span> Unable to email admin invites. {err.message}.</span>
          ),
          type: 'eap-alert-error'
        }));
        dispatch(sendAdminInvitesFailure(err.message));
      });
}

export const superGetAdminInvitesSuccess = (invites, groupId) => ({
  type: SUPER_GET_ADMIN_INVITES_SUCCESS,
  payload: { invites, groupId }
});

export const superGetAdminInvitesFailure = error => ({
  type: SUPER_GET_ADMIN_INVITES_FAILURE,
  payload: { error }
});

export const superGetAdminInvites = (groupId) => (
  (dispatch) => (
    httpClient
      .get(`/super/group/${groupId}/adminInvites`)
      .then((response) => {
        dispatch(superGetAdminInvitesSuccess(response.data, groupId));
      })
      .catch((err) => {
        console.error('ERROR RETRIEVING ADMIN INVITES', err.message);
        dispatch(superGetAdminInvitesFailure(err.message));
      })
  )
);

export const superRevokeAdminInvite = (selectedAdminInvite, groupId) => (
  (dispatch) => {
    dispatch(revokeAdminInviteSuccess(selectedAdminInvite.id, groupId));
    const inviteId = selectedAdminInvite.id;
    return httpClient.delete(`/super/adminInvite/${inviteId}`)
      .then(() => {
        dispatch(dispatchAlert({
          message: (
            <span>
              Admin invite for <b>{selectedAdminInvite.email}</b> has been revoked.
            </span>
          ),
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('ERROR REVOKING ADMIN', err.message);
        dispatch(revokeAdminInviteFailure(err.message));
        dispatch(dispatchAlert({
          message: (
            <span>
              Unable to revoke invite. {err.message}
            </span>
          ),
          type: 'eap-alert-error',
        }));
      });
  }
);
