import React from 'react';
import PropTypes from 'prop-types';

import current1 from './1 - 273043.svg';
import todo1 from './1 - BCC2CB.svg';
import current2 from './2 - 273043.svg';
import todo2 from './2 - BCC2CB.svg';
import current3 from './3 - 273043.svg';
import todo3 from './3 - BCC2CB.svg';
import completed from './385CAD - Check.svg';
import paymentFailed from './CF3339 - X.svg';

const Icon = {
  CURRENT1: current1,
  CURRENT2: current2,
  CURRENT3: current3,
  TODO1: todo1,
  TODO2: todo2,
  TODO3: todo3,
  COMPLETED: completed,
  PAYMENT_FAILED: paymentFailed
};

// CURRENT or TODO followed by 1, 2 or 3
const WizardStepIcon = ({ reference }) => (
  <img
    style={{ backgroundColor: 'transparent' }}
    alt={`Checkout Step ${Icon[reference].toString()}`}
    src={Icon[reference]}
  />
);

WizardStepIcon.propTypes = {
  reference: PropTypes.string.isRequired
};

export default WizardStepIcon;
