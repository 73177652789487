import React from 'react';
import PropTypes from 'prop-types';

const EapOption = ({ choice }) => (
  <option value={choice.value.toString()} hidden={choice.hidden}>
    {choice.label}
  </option>
);

EapOption.propTypes = {
  choice: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

export default EapOption;
