import _ from 'lodash';
import * as Actions from '../actions/alert';
import { LOGOUT_REQUEST } from '../actions/logout';

function alertReducer(state = [], action) {
  const newState = _.cloneDeep(state);
  switch (action.type) {
    case Actions.PUSH_ALERT: {
      newState.push(_.merge({}, action.payload, { active: true }));
      return newState;
    }
    case Actions.DISMISS_ALERT: {
      return _.map(newState, (a) => {
        const b = a; // Shut eslint up. I'll mutate that param if I want!
        if (b.id === action.payload.id) {
          b.active = false;
          clearTimeout(b.timer);
        }
        return b;
      });
    }
    case Actions.DISMISS_ALL: {
      return _.map(newState, (a) => {
        clearTimeout(a.timer);
        return {
          active: false,
        };
      });
    }
    case LOGOUT_REQUEST: {
      // clean up any of the timers;
      return _.map(newState, (a) => {
        clearTimeout(a.timer);
        return a;
      });
    }
    default: {
      return state;
    }
  }
}

export default alertReducer;
