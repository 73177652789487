/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import EapSelectList from '../../components/EapSelectList';

import './AvailableProductSelector.css';

export default class AvailableProductSelector extends Component {
  constructor(props) {
    super(props);
    this.selectProduct = this.selectProduct.bind(this);
  }

  selectProduct(e) {
    const {
      availableProducts,
      onSelectProduct
    } = this.props;
    const id = _.parseInt(e.target.value);
    const selectedProduct = _.find(availableProducts, { id });
    if (selectedProduct) {
      onSelectProduct(selectedProduct);
    }
  }

  render() {
    const choices = _
      .chain(this.props.availableProducts)
      .filter((prod) => (prod.amount >= 0))
      .map((product) => ({
        value: product.id,
        label: `${product.name} (${product.amount})`
      }))
      .value();
    return (
      <EapSelectList
        style={this.props.style}
        id="availableProducts"
        name="available-product-selector"
        value={this.props.selectedProduct.id}
        onChange={this.selectProduct}
        className={this.props.className}
        choices={choices}
      />
    );
  }
}

AvailableProductSelector.propTypes = {
  availableProducts: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    amount: PropTypes.number,
    id: PropTypes.number
  })),
  selectedProduct: PropTypes.shape({
    name: PropTypes.string,
    amount: PropTypes.number,
    id: PropTypes.number
  }).isRequired,
  className: PropTypes.string,
  onSelectProduct: PropTypes.func.isRequired
};

AvailableProductSelector.defaultProps = {
  availableProducts: undefined,
  className: 'pure-input-3'
};
