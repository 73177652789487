import React from 'react';

import { httpClient } from '../utils/httpClient';
import { dispatchAlert } from './alert';
import SERVER_ERROR from './serverErrorMessage';

export const SEND_ADMIN_HANDOUT_SUCCESS = 'SEND_ADMIN_HANDOUT_SUCCESS';
export const SEND_ADMIN_HANDOUT_FAILURE = 'SEND_ADMIN_HANDOUT_FAILURE';

export const sendAdminHandoutSuccess = () => ({
  type: SEND_ADMIN_HANDOUT_SUCCESS
});

export const sendAdminHandoutFailure = () => ({
  type: SEND_ADMIN_HANDOUT_FAILURE
});

export function sendAdminHandout(signinUserId, password) {
  return (dispatch) => {
    dispatch(sendAdminHandoutSuccess());
    return httpClient.put(`/super/user/adminHandout/${signinUserId}`, {
      password
    }).then(() => {
      dispatch(dispatchAlert({
        message: <span>Admin handout sent.</span>,
        type: 'eap-alert-success',
        time: 5000
      }));
    }).catch(() => {
      console.error('ERROR SENDING HANDOUT');
      dispatch(dispatchAlert({
        message: <span>Unable to send admin handout. {SERVER_ERROR}.</span>,
        type: 'eap-alert-error'
      }));
    });
  };
}
