import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import GroupContentTab from '../../../group/components/GroupContentTab';
import NavigationBar from '../../../components/NavigationBar';
import OrganizationsTableContainer from '../OrganizationsTableContainer';
import OrganizationsCreationContainer from '../OrganizationsCreationContainer';

const OrganizationsRoutes = ({ baseUrl }) => (
  <div>
    <NavigationBar
      routes={[
        { path: 'find', text: 'Find', icon: 'fa-search' },
        { path: 'create', text: 'Create', icon: 'fa-users' },
      ]}
      base={`${baseUrl.substring(1)}`}
    />
    <Switch>
      <Route
        path={`${baseUrl}/find`}
        render={() => <GroupContentTab><OrganizationsTableContainer /></GroupContentTab>}
      />
      <Route
        path={`${baseUrl}/create`}
        render={() => <GroupContentTab><OrganizationsCreationContainer /></GroupContentTab>}
      />
      <Route
        exact
        path={baseUrl}
        render={() => <Redirect to={`${baseUrl}/find`} />}
      />
    </Switch>
  </div>
);

export default OrganizationsRoutes;

OrganizationsRoutes.propTypes = {
  baseUrl: PropTypes.string.isRequired
};
