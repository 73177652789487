import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './AdminIcon.css';

/*
 * Paramaters:
 *   url: String to a url.
 *   onClick: Click handler. Turns icon into button. Takes presidence over url
 *   icon: font awesome icon.
 *   toolTip: Text to be displayed in the toolTip
 *   permission: String of the permission to check.
 *   permissions: Object of permissions to check against.
 */
const AdminIcon = ({
  url, onClick, icon, toolTip, permission, permissions
}) => {
  const isNotPermitted = permission && permissions && !permissions[permission];
  const isPurchase = permission === 'purchase';
  if (!isPurchase && isNotPermitted) {
    return <span />;
  }
  if (onClick) {
    return (
      <span>
        <button
          className={`fa ${icon} admin-icon-color admin-transparent-button`}
          title={toolTip}
          onClick={(e) => { e.preventDefault(); onClick(e); }}
        />
      </span>
    );
  }
  return (
    <span>
      <Link
        to={url}
        className={`admin-icon-color fa ${icon} admin-transparent-button`}
        title={toolTip}
      />
    </span>
  );
};

AdminIcon.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  toolTip: PropTypes.string,
  permission: PropTypes.string,
  permissions: PropTypes.shape({
    per_products_view: PropTypes.bool,
    per_users_view: PropTypes.bool,
    per_admin_view: PropTypes.bool
  })
};

AdminIcon.defaultProps = {
  url: '',
  onClick: undefined,
  toolTip: undefined,
  permission: undefined,
  permissions: undefined
};

export default AdminIcon;
