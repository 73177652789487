import React from 'react';
import PropTypes from 'prop-types';
import ResourceLink from '../../components/ResourceLink';

import AdminIcon from '../../components/AdminIcon';
import AdminIconList from '../../components/AdminIconList';
import { StaticEapTable } from '../../components/StaticEapTable';

const OrganizationGroups = ({ tableData }) => {
  const columns = [
    {
      Header: 'Group Id',
      accessor: 'id',
      Cell: ({ row }) => (
        <ResourceLink
          toRoute={`/super/group/${row.original.id}/info`}
          displayValue={row.original.id.toString()}
        />
      )
    },
    {
      Header: 'Group Name',
      accessor: 'name',
    },
    {
      Header: 'Group Description',
      accessor: 'description',
    },
    {
      Header: 'Available Products',
      accessor: 'availableProducts',
      width: 300,
      disableFitlers: true,
      Cell: ({ row }) => (
        <div>
          {
            row.original.availableProducts.map((ap) => (
              <div key={`${ap.name}${row.original.id}`}>{ap.name} ({ap.amount})</div>
            ))
          }
        </div>
      )
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      Cell: ({ row }) => (
        <AdminIconList>
          <AdminIcon
            url={`/super/group/${row.original.id}/info`}
            icon="fa-info-circle"
            toolTip="View"
          />
        </AdminIconList>
      )
    }
  ];
  return (
    <div className="pure-u-1-1">
      <h1>Groups</h1>
      <StaticEapTable
        columns={columns}
        data={tableData}
      />
    </div>
  );
};

OrganizationGroups.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    groupId: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    availableProducts: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      name: PropTypes.string
    }))
  }))
};

OrganizationGroups.defaultProps = {
  tableData: [],
};

export default OrganizationGroups;
