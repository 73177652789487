import React from 'react';

import { httpClient } from '../utils/httpClient';
import history from '../utils/history';
import { dispatchAlert } from './alert';

export const FILTER_USERS_REQUEST = 'FILTER_USERS_REQUEST';
export const FILTER_USERS_SUCCESS = 'FILTER_USERS_SUCCESS';
export const FILTER_USERS_FAILURE = 'FILTER_USERS_FAILURE';

export const USER_DATA_REQUEST = 'USER_DATA_REQUEST';
export const USER_DATA_RECEIVED = 'USER_DATA_RECEIVED';
export const USER_DATA_ERROR = 'USER_DATA_ERROR';
export const PASSCODE_SUCCESS = 'PASSCODE_SUCCESS';
export const PASSCODE_FAILURE = 'PASSCODE_FAILURE';
export const SIGNIN_UPDATE_REQUEST = 'SIGNIN_UPDATE_REQUEST';
export const SIGNIN_UPDATE_SUCCESS = 'SIGNIN_UPDATE_SUCCESS';
export const SIGNIN_UPDATE_FAILURE = 'SIGNIN_UPDATE_FAILURE';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_REQUEST_SENT = 'PASSWORD_RESET_REQUEST_SENT';
export const PASSWORD_RESET_REQUEST_FAILED = 'PASSWORD_RESET_REQUEST_FAILED';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';

export const SUPER_CREATE_SIGNIN_SUCCESS = 'SUPER_CREATE_SIGNIN_SUCCESS';
export const SUPER_CREATE_SIGNIN_FAILURE = 'SUPER_CREATE_SIGNIN_FAILURE';
export const SUPER_LOAD_USER_SUCCESS = 'SUPER_LOAD_USER_SUCCESS';
export const SUPER_UPDATE_USER_SUCCESS = 'SUPER_UPDATE_USER_SUCCESS';

export const userDataRequested = () => ({
  type: USER_DATA_REQUEST,
  payload: {}
});

export const userDataReceived = (userObj) => ({
  type: USER_DATA_RECEIVED,
  payload: { ...userObj }
});

export const initClientUser = (
  params = { requestNotification: true }
) => function initClientUserAsync(dispatch) {
  if (params.requestNotification) {
    dispatch(userDataRequested());
  }
  return httpClient
    .get('/user/')
    .then((response) => {
      dispatch(userDataReceived(response.data.user));
    })
    .catch((err) => {
      if (err.status === 401) {
        return;
      }
      dispatch({
        type: USER_DATA_ERROR,
        payload: {}
      });
      console.error('ERROR loading user data');
    });
};

export const passcodeSuccess = (product) => ({
  type: PASSCODE_SUCCESS,
  payload: { product }
});

export const passcodeFailure = (message) => ({
  type: PASSCODE_FAILURE,
  payload: { message }
});

export function enterPasscode(passcode) {
  return (dispatch) => {
    httpClient.post('/passcode', { passcode })
      .then((response) => {
        dispatch(passcodeSuccess(response.data));
        dispatch(dispatchAlert({
          message: <span>Passcode was accepted</span>,
          type: 'eap-alert-success',
          time: 5000
        }));
        history.push('./dashboard');
      }).catch((err) => {
        console.error({ err });
        const notFound = err.response.data.statusCode === 404;
        const isConflict = err.response.data.statusCode === 409;
        let displayMessage = err.response.data.message;
        if (isConflict) {
          displayMessage = 'You have already attempted to use this passcode';
        } else if (notFound) {
          displayMessage = 'Invalid passcode entered';
        }
        dispatch(passcodeFailure(displayMessage));
      });
  };
}

export const updatePassword = (newPassword, resetToken) => (dispatch) => {
  dispatch({ type: UPDATE_PASSWORD, payload: {} });
  httpClient
    .put('/user/password', { resetToken, newPassword })
    .then(() => history.push('/'))
    .catch((e) => {
      dispatch({
        type: UPDATE_PASSWORD_FAILED,
        payload: {
          expired: e.response.status === 410,
          message: e.message
        }
      });
    });
};

export const signinUpdateSuccess = () => ({
  type: SIGNIN_UPDATE_SUCCESS
});

export function updateSignin(updatedUserInfo) {
  return (dispatch) => {
    dispatch({
      type: SIGNIN_UPDATE_REQUEST,
      payload: {}
    });
    const {
      username,
      currentPassword,
      email,
      firstName,
      lastName,
      gender,
      dob,
      grade: inGrade,
      password,
    } = updatedUserInfo;

    const payload = {
      username,
      currentPassword,
      email,
      firstName,
      lastName,
      gender,
      dob,
      password,
      grade: inGrade ? inGrade.toString() : '',
    };

    httpClient
      .put('/user', payload)
      .then(() => {
        dispatch(signinUpdateSuccess());
        dispatch(initClientUser());
        dispatch(dispatchAlert({
          message: <span> Update successful. </span>,
          type: 'eap-alert-success',
          time: 5000
        }));
        history.push('/dashboard');
      }).catch((err) => {
        dispatch({
          type: SIGNIN_UPDATE_FAILURE,
          payload: { ...err.response.data }
        });

        if (err.response.data.message === 'Password did not match') {
          dispatch(dispatchAlert({
            message: <span>Could not update profile, because password did not match.</span>,
            type: 'eap-alert-error',
            time: 5000
          }));
        } else {
          console.error('ERROR UPDATING SIGNIN', err.response.data);
          dispatch(dispatchAlert({
            message: <span> {err.response.data.message} </span>,
            type: 'eap-alert-error',
            time: 5000
          }));
        }
      });
  };
}

export const superCreateSigninSuccess = () => ({
  type: SUPER_CREATE_SIGNIN_SUCCESS
});

export const superCreateSigninFailure = (error) => ({
  type: SUPER_CREATE_SIGNIN_FAILURE,
  payload: { error }
});

export const superCreateSignin = (newSignin) => (dispatch) => {
  httpClient.post('/super/user', { ...newSignin, grade: newSignin.grade ? newSignin.grade.toString() : '' })
    .then(() => {
      dispatch(superCreateSigninSuccess());
      dispatch(dispatchAlert({
        message: <span> User <b>{newSignin.name}</b> successfully created. </span>,
        type: 'eap-alert-success',
        time: 5000
      }));
      history.push('/super/signins');
    }).catch((err) => {
      let errorMessage = err.message;
      if (!errorMessage) {
        errorMessage = 'Error';
      }
      dispatch(superCreateSigninFailure(errorMessage));
      dispatch(dispatchAlert({
        message: (
          <span>
            Could not create signin.
            Please refresh and try again.
            {err.message}
          </span>
        ),
        type: 'eap-alert-error'
      }));
    });
};

export function filter(filters, sorted, limit) {
  return (dispatch) => httpClient
    .get('/users', { params: { filter: filters, sorted, limit } })
    .then((response) => {
      dispatch({
        type: FILTER_USERS_SUCCESS,
        payload: response.data
      });
    })
    .catch((error) => {
      console.error('ERROR Filtering Examinees', error.response.data.message);
    });
}

export const superLoadUser = (sourceUserId) => (
  (dispatch) => (
    httpClient
      .get(`/super/user/${sourceUserId}`)
      .then((response) => {
        dispatch({
          type: SUPER_LOAD_USER_SUCCESS,
          payload: { userData: response.data }
        });
        return response.data;
      }).catch((err) => {
        console.error(err);
      })
  )
);

export const superUpdateUser = (sourceUserId, updatedUserInfo) => (
  (dispatch) => (
    httpClient
      .put(`/super/user/${sourceUserId}`, { ...updatedUserInfo, grade: updatedUserInfo.grade ? updatedUserInfo.grade.toString() : '' })
      .then(() => {
        dispatch({
          type: SUPER_UPDATE_USER_SUCCESS,
          payload: { updatedUserInfo, userId: sourceUserId }
        });
        dispatch(dispatchAlert({
          message: <span> Update successful. </span>,
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('ERROR UPDATING SIGNIN', err);
        let errorMessage = 'Error updating user';
        if (err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        dispatch(dispatchAlert({
          message: <span> {errorMessage}. Please refresh page </span>,
          type: 'eap-alert-error'
        }));
      })
  )
);
