import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import ExamineesContainer from '../ExamineesContainer';
import ExamineeInvitesContainer from '../ExamineeInvitesContainer';
import AddExamineeFormContainer from '../AddExamineeFormContainer';
import NavigationBar from '../../components/NavigationBar';
import GroupContentTab from './GroupContentTab';

const Examinees = ({
  baseUrl,
  group,
  location
}) => (
  <div>
    {
      (group.permissions.users.invite)
        ? (
          <NavigationBar
            routes={[
              { path: 'active', text: 'Examinees', icon: 'fa-users' },
              { path: 'invite', text: 'Invite Examinees', icon: 'fa-envelope' },
              { path: 'add', text: 'Add Examinees', icon: 'fa-plus' },
              // { path: 'import', text: 'Import from CSV', icon: 'fa-file-text-o' }
            ]}
            base={`${baseUrl.substring(1)}/examinees`}
          />
        ) : <span />
    }
    <GroupContentTab>
      <Switch>
        <Route
          path={`${baseUrl}/examinees/active`}
          render={() => (
            <ExamineesContainer
              baseUrl={baseUrl}
              location={location.pathname}
              groupId={group.id}
            />
          )}
        />
        <Route
          path={`${baseUrl}/examinees/invite`}
          render={() => <ExamineeInvitesContainer groupId={group.id} />}
        />
        <Route
          path={`${baseUrl}/examinees/add`}
          render={() => <AddExamineeFormContainer groupId={group.id} />}
        />
        { /* <Route
        path={`${baseUrl}/examinees/import`}
        render={() => <ImportExaminees />}
      /> */ }
        <Route
          exact
          path={`${baseUrl}/examinees`}
          render={() => <Redirect to={`${baseUrl}/examinees/active`} />}
        />
      </Switch>
    </GroupContentTab>
  </div>
);

export default Examinees;

Examinees.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  group: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    permissions: PropTypes.shape({
      users: PropTypes.shape({
        view: PropTypes.bool,
        invite: PropTypes.bool,
        move: PropTypes.bool
      })
    })
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};
