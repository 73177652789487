import React from 'react';
import { Field } from 'formik';

const GenderSelectList = () => (
  <Field
    component="select"
    name="gender"
  >
    <option value="" />
    <option value="M">Male</option>
    <option value="F">Female</option>
  </Field>
);

export default GenderSelectList;
