import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AdministratorsContainer from '../AdministratorsContainer';
import AdminInvitesContainer from '../AdminInvitesContainer';
import NavigationBar from '../../components/NavigationBar';
import GroupContentTab from './GroupContentTab';

const AdministratorsView = ({ baseUrl, group }) => (
  <div>
    { (group.permissions.admin.invite) ?
      <NavigationBar
        routes={[
          { path: 'active', text: 'Administrators', icon: 'fa-bank' },
          { path: 'invite', text: 'Invite Administrators', icon: 'fa-envelope' },
        ]}
        base={`${baseUrl.substring(1)}/administrators`}
      /> : <span />
    }
    <GroupContentTab>
      <Switch>
        <Route
          path={`${baseUrl}/administrators/active`}
          render={() => <AdministratorsContainer groupId={group.id} />}
        />
        <Route
          path={`${baseUrl}/administrators/invite`}
          render={() => (
            <AdminInvitesContainer groupId={group.id} />
          )}
        />
        <Route
          exact
          path={`${baseUrl}/administrators/`}
          render={() => <Redirect to={`${baseUrl}/administrators/active`} />}
        />
      </Switch>
    </GroupContentTab>
  </div>
);

export default AdministratorsView;

AdministratorsView.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    permissions: PropTypes.shape({
      admin: PropTypes.shape({
        view: PropTypes.bool,
        invite: PropTypes.bool,
        remove: PropTypes.bool,
        edit: PropTypes.bool
      })
    })
  }).isRequired,
  baseUrl: PropTypes.string.isRequired,
};
