import React from 'react';
import PropTypes from 'prop-types';

import './FormHeader.css';

const FormHeader = ({ headerText, renderParagraph }) => (
  <div className="pure-u-1-1 eap-form-header">
    <h1>{headerText}</h1>
    <p className="pure-u-3-4">
      {renderParagraph && renderParagraph()}
    </p>
  </div>
);

FormHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  renderParagraph: PropTypes.func
};

FormHeader.defaultProps = {
  renderParagraph: undefined
};

export default FormHeader;
