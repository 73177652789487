/* eslint react/destructuring-assignment:0 jsx-a11y/label-has-associated-control:0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';

import InlineValidationMessage from '../../components/InlineValidationMessage';
import EapButton from '../../components/EapButton';
import AvailableProductSelector from './AvailableProductSelector';
import './ExamineeInviteForm.css';

// TODO: Break this up and modulize it
const emailsValidation = yup
  .mixed()
  .test(
    'emails',
    ({ value }) => {
      if (!value) {
        return 'Please enter at least one email.';
      }
      const emails = value.split(',').map((em) => em.trim()).filter((em) => em);
      const schema = yup.string().email();
      const dups = _.uniq(emails);
      const invalid = emails.filter((em) => !schema.isValidSync(em));
      if (invalid.length > 0) {
        return `The following email(s) are invalid:${invalid.join(', ')}`;
      }
      if (dups.length !== emails.length) {
        const repeats = _
          .chain(emails)
          .groupBy()
          .toArray()
          .filter((val) => val.length > 1)
          .map((val) => val[0])
          .value();
        return `The following emails(s) are repeated:${repeats.join(', ')}`;
      }
      // Catchall for sanity
      return 'There are problems in your form';
    },
    (value) => {
      if (!value) {
        return false;
      }
      const emails = value.split(',').map((em) => em.trim()).filter((em) => em);
      const schema = yup
        .array()
        .of(yup.string().email('why not'))
        .test('duplicates', 'Duplicate emails', (dps) => {
          const dups = _.uniq(dps);
          return (dps.length === dups.length);
        });
      return schema.isValid(emails);
    }
  );

const ExamineeInviteForm = ({
  availableProducts,
  sendInvites,
  onCloseDialog,
  sending
}) => (
  <div className="pure-u-1">
    <Formik
      validateOnChange
      validateOnBlur
      initialValues={{
        emails: '',
        availableProduct: availableProducts[0] || { id: -1, amount: -1 }
      }}
      validationSchema={yup.object().shape({
        emails: emailsValidation,
        availableProduct: yup.object().shape({
          amount: yup.number().min(1, 'You are out of this product')
        }).required()
      })}
      onSubmit={(values, { resetForm }) => {
        const emails = values.emails.split(',').map((em) => em.trim()).filter((em) => em);
        sendInvites(
          emails,
          values.availableProduct.id
        );
        resetForm();
      }}
    >
      {({
        errors,
        touched,
      }) => (
        <Form
          className="pure-form"
        >
          <div className="pure-u-1-2">
            <label htmlFor="email-list-text-area">INVITEE&#8217;S EMAIL(S)</label>
            <Field
              placeholder="Enter email addresses separated by commas."
              style={{ marginTop: '.5rem' }}
              rows="5"
              name="emails"
              as="textarea"
            />
            {
              touched.emails
                && errors.emails
                && <InlineValidationMessage message={errors.emails} />
            }
          </div>
          <div className="pure-u-1-2">
            <div style={{ marginLeft: '.5em' }}>
              <label htmlFor="availableProduct">ASSIGN PRODUCT *</label>
              <Field name="availableProduct">
                {({ field, form }) => (
                  <AvailableProductSelector
                    style={{ width: '100%' }}
                    selectedProduct={field.value}
                    availableProducts={availableProducts}
                    onSelectProduct={(p) => form.setFieldValue(field.name, p)}
                  />
                )}
              </Field>
              <EapButton
                style={{ marginTop: '.5em' }}
                text="SEND"
                faIcon="fa-envelope"
                isSubmit
                disabled={sending}
                inProgress={sending}
              />
              { onCloseDialog && (
                <EapButton
                  style={{ marginTop: '.5em' }}
                  isSecondary
                  onClick={onCloseDialog}
                  text="CANCEL"
                />
              )}
              {
                touched.availableProduct
                  && errors.availableProduct
                  && errors.availableProduct.amount
                  && <InlineValidationMessage message={errors.availableProduct.amount} />
              }
            </div>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

ExamineeInviteForm.propTypes = {
  availableProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    adminName: PropTypes.string
  })).isRequired,
  sendInvites: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func
};

ExamineeInviteForm.defaultProps = {
  onCloseDialog: undefined
};

export default ExamineeInviteForm;
