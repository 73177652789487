import React from 'react';
import PropTypes from 'prop-types';

import { StaticEapTable } from '../../components/StaticEapTable';
import { Display as EapDisplayDate, sort as SortDate } from '../../components/EapDisplayDate';
import AdminIconList from '../../components/AdminIconList';
import ExamineeInviteRevokeModal from './ExamineeInviteRevokeModal';
import ExamineeInviteResendModal from './ExamineeInviteResendModal';

const PendingInvitesTable = ({
  resendInvite,
  revokeInvite,
  adminGroupId,
  pendingInvites
}) => {
  const columns = [
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Date Sent',
      accessor: 'createdAt',
      filterable: false,
      Cell: ({ row }) => <EapDisplayDate date={row.original.createdAt} />,
      sort: SortDate
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Product assigned',
      accessor: 'productAdminName'
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => (
        <AdminIconList>
          <ExamineeInviteResendModal
            selectedExamineeInvite={row.original}
            resendInvite={(id) => resendInvite(adminGroupId, id)}
          />
          <ExamineeInviteRevokeModal
            selectedExamineeInvite={row.original}
            revokeInvite={(id) => revokeInvite(adminGroupId, id)}
          />
        </AdminIconList>
      )
    }
  ];
  return (
    <StaticEapTable
      columns={columns}
      data={pendingInvites}
    />
  );
};

PendingInvitesTable.propTypes = {
  adminGroupId: PropTypes.number.isRequired,
  pendingInvites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    createdAt: PropTypes.string
  })).isRequired,
  resendInvite: PropTypes.func.isRequired,
  revokeInvite: PropTypes.func.isRequired
};

export default PendingInvitesTable;
