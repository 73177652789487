import React from 'react';
import PropTypes from 'prop-types';

const PasswordResetShell = ({ children }) => (
  <div className="flex-column">
    <div className="pure-g pure-u-1">
      <div className="pure-u-1-24 pure-u-md-1-5" />
      <div className="pure-u-22-24 pure-u-md-1-2">
        {children}
      </div>
    </div>
  </div>
);

PasswordResetShell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

export default PasswordResetShell;
