import url from 'url';
import _ from 'lodash';
import history from './history';

export default function redirect(urlToPassOn, redirectUrl) {
  if (urlToPassOn) {
    const redirectObj = url.parse(urlToPassOn);
    if (redirectObj.host === window.location.host || _.isEmpty(redirectObj.host)) {
      const goTo = `${redirectObj.pathname}${redirectObj.search || ''}`;
      history.push(goTo);
    } else {
      window.location = redirectUrl;
    }
  } else {
    history.push('/dashboard');
  }
}
