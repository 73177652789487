import React from 'react';
import PropTypes from 'prop-types';

import './EapModalDialog.css';

// This is a simple modal with styled header and a title
const EapModalDialog = ({ modalTitle, children }) => (
  <div className="modal-body pure-g">
    <div className="pure-u-1 modal-banner">
      <h3 className="modal-title">{modalTitle}</h3>
    </div>
    <div className="pure-u-1 modal-content-block">
      { children || 'No Children? We were expecting children... :('}
    </div>
  </div>
);


EapModalDialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  modalTitle: PropTypes.string.isRequired
};

export default EapModalDialog;
