import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from './ConfirmationModal';
import AdminIcon from './AdminIcon';

class SuppressResultsModal extends Component {
  constructor() {
    super();
    this.state = {
      openModal: false
    };
  }

  render() {
    return (
      <span>
        <AdminIcon
          onClick={
            () => this.setState({ openModal: true })
          }
          icon="fa-lock"
          toolTip="Suppress Results"
          permission="suppressed"
          permissions={
            {
              ...this.props.selectedProduct.configs,
              suppressed: !this.props.selectedProduct.configs.suppressed
            }
          }
        />
        <ConfirmationModal
          modalTitle="SUPPRESS RESULTS"
          showModal={this.state.openModal}
          messagePrompt={
            <p>
              Are you sure you want to suppress results for&nbsp;
              <b>
                {this.props.selectedProduct.firstName}&nbsp;
                {this.props.selectedProduct.lastName}
              </b> for
              <b> {this.props.selectedProduct.productName}</b>?
            </p>
        }
          closeModal={() => this.setState({ openModal: false })}
          confirmAction={this.props.suppressResults}
        />
      </span>
    );
  }
}

export default SuppressResultsModal;

SuppressResultsModal.propTypes = {
  selectedProduct: PropTypes.shape({
    configs: PropTypes.shape({
      type: PropTypes.string,
      caps: PropTypes.bool,
      cops: PropTypes.bool,
      copes: PropTypes.bool,
      suppressed: PropTypes.bool
    }),
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired
  }).isRequired,
  suppressResults: PropTypes.func.isRequired,
};
