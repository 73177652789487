import React from 'react';
import PropTypes from 'prop-types';

import GroupTabHeader from './GroupTabHeader';

const GroupWrapper = ({ groupName, headingText, children }) => (
  <div>
    <GroupTabHeader
      groupName={groupName}
      headingText={headingText}
    />
    <div>
      {children}
    </div>
  </div>
);

GroupWrapper.propTypes = {
  groupName: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

export default GroupWrapper;
