import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EapModal from '../../../components/EapModal';
import AdminIcon from '../../../components/AdminIcon';
import DeleteProductFormContainer from '../DeleteProductsFormContainer';

class AdminDeleteProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onProductDeleted(availableProductId) {
    this.setState({ productId: undefined });
    this.props.onProductDeleted(availableProductId);
  }

  render() {
    return (
      <span>
        <AdminIcon
          onClick={() => this.setState({ productId: this.props.availableProduct.id })}
          icon="fa-trash-o"
          toolTip="Delete Product"
        />
        <EapModal
          modalTitle="Delete Products for ..."
          showModal={this.state.productId === this.props.availableProduct.id}
        >
          <DeleteProductFormContainer
            groupName={this.props.groupName}
            productName={this.props.availableProduct.adminName}
            availableProductId={this.props.availableProduct.id}
            onProductDeleted={() => this.onProductDeleted(this.props.availableProduct.id)}
            onCancel={() => this.setState({ productId: undefined })}
            groupId={this.props.groupId}
          />
        </EapModal>
      </span>
    );
  }
}

AdminDeleteProductModal.propTypes = {
  groupName: PropTypes.string.isRequired,
  onProductDeleted: PropTypes.func.isRequired,
  availableProduct: PropTypes.shape({
    id: PropTypes.number,
    adminName: PropTypes.string
  }).isRequired,
  groupId: PropTypes.number.isRequired
};

export default AdminDeleteProductModal;

