import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import EapSelectList from './EapSelectList';

class GroupSelector extends Component {
  constructor(props) {
    super(props);
    this.onGroupSelected = this.onGroupSelected.bind(this);
  }

  onGroupSelected(e) {
    const value = _.parseInt(e.currentTarget.value);
    if (_.isNumber(value)) {
      this.props.onGroupSelected(value);
    }
    // TODO: else what?
  }

  render() {
    return (
      <EapSelectList
        id="destinationGroupId"
        name="destination-group-selector"
        value={this.props.selectedGroupId}
        onChange={this.onGroupSelected}
        className="pure-u-1-5"
        choices={_.map(this.props.groups, group =>
        ({ value: group.id, label: group.name }))
        }
      />
    );
  }
}

GroupSelector.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })),
  selectedGroupId: PropTypes.number.isRequired,
  onGroupSelected: PropTypes.func.isRequired
};

GroupSelector.defaultProps = {
  groups: []
};

export default GroupSelector;
