import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProductTransferDialog from '../../components/ProductTransferDialog';
import { StaticEapTable, DefaultFilter } from '../../components/StaticEapTable';
import ConfirmationModal from '../../components/ConfirmationModal';
import AdminIcon from '../../components/AdminIcon';
import AdminIconList from '../../components/AdminIconList';

export default class AvailableProducts extends Component {
  constructor(props) {
    super(props);
    // TODO: Generalize the below filter?
    const allowedGroups =
      props.user.adminGroups.filter(group =>
        group.per_products_view &&
        group.per_products_transfer &&
        group.groupId !== this.props.group.id).map(adminGroup => ({
        id: adminGroup.groupId,
        name: adminGroup.name
      }));

    this.state = {
      allowedGroups,
      transferForGroup: undefined,
      generatePasscodeModal: undefined
    };
  }

  componentDidMount() {
    this.props.loadGroupInfo();
  }


  render() {
    if (!this.props.group.permissions.products.view) {
      return (
        <h2> You do not have permissions to view products of this group </h2>
      );
    }
    const columns = [
      {
        Header: 'Product',
        accessor: 'adminName',
        Filter: DefaultFilter
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Filter: DefaultFilter
      },
      {
        Header: 'Passcode',
        accessor: 'passcode',
        Filter: DefaultFilter
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          const allowPurchase = row.original.price !== null;
          return (
            <AdminIconList>
              { this.state.allowedGroups.length > 0 ? (
                <React.Fragment>
                  <AdminIcon
                    onClick={() => this.setState({ transferForGroup: row.original.id })}
                    icon="fa-random"
                    toolTip="Transfer Products"
                    permission="transfer"
                    permissions={this.props.group.permissions.products}
                  />
                  <ProductTransferDialog
                    dialogTitle="TRANSFER PRODUCTS"
                    groups={this.state.allowedGroups}
                    availableAmount={row.original.amount}
                    showDialog={this.state.transferForGroup === row.original.id}
                    handleClose={() => this.setState({ transferForGroup: undefined })}
                    transferProducts={(amountAdded, targetGroupId) => {
                    const sourceAdminGroupId = this.props.group.permissions.id;
                    const availableProduct = row.original;
                    const targetGroup = this.state.allowedGroups.find(g =>
                      g.id.toString() === targetGroupId.toString());
                    this.props.transferProducts(
                      {
                        id: sourceAdminGroupId,
                        groupId: availableProduct.groupId
                      },
                      targetGroup,
                      availableProduct,
                      amountAdded
                    );
                    this.setState({ transferForGroup: undefined });
                  }
                  }
                  />
                </React.Fragment>) : ''
            }
              <AdminIcon
                onClick={() => this.setState({ generatePasscodeModal: row.original.id })}
                icon="fa-rotate-right"
                toolTip="Generate New Passcode"
                permission="assign"
                permissions={this.props.group.permissions.products}
              />
              <ConfirmationModal
                modalTitle="GENERATE NEW PASSCODE"
                showModal={this.state.generatePasscodeModal === row.original.id}
                messagePrompt={
                  <p>
                  Are you sure you want to generate a new passcode for
                    <b> { row.original.adminName }</b>
                  </p>
              }
                closeModal={() => this.setState({ generatePasscodeModal: undefined })}
                confirmAction={() =>
                this.props.generateNewPasscode(this.props.group.permissions.id, row.original.id)
              }
                errorMessage={this.props.errorMessage}
              />
              { allowPurchase &&
              <AdminIcon
                icon="fa-dollar"
                toolTip="Buy More Products"
                url={`/group/${this.props.group.id}/available-product/${row.original.id}/purchase`}
                permission="purchase"
                permissions={this.props.group.permissions.products}
              />
            }
            </AdminIconList>
          );
        }
      }
    ];
    return (
      <StaticEapTable
        columns={columns}
        data={this.props.availableProducts}
      />
    );
  }
}

AvailableProducts.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.number,
    organization: PropTypes.shape({
      id: PropTypes.number.isRequired
    }),
    permissions: PropTypes.shape({
      id: PropTypes.number,
      products: PropTypes.shape({
        assign: PropTypes.bool,
        transfer: PropTypes.bool,
        view: PropTypes.bool
      })
    }).isRequired,
  }).isRequired,
  availableProducts: PropTypes.arrayOf(PropTypes.shape({
    adminName: PropTypes.string,
    amount: PropTypes.number,
    passcode: PropTypes.string
  })).isRequired,
  errorMessage: PropTypes.string,
  user: PropTypes.shape({
    adminGroups: PropTypes.array
  }).isRequired,
  transferProducts: PropTypes.func.isRequired,
  generateNewPasscode: PropTypes.func.isRequired,
  loadGroupInfo: PropTypes.func.isRequired
};

AvailableProducts.defaultProps = {
  errorMessage: undefined
};
