import React, { useState } from 'react';
import PropTypes from 'prop-types';

import EapModal from '../../components/EapModal';
import EapButton from '../../components/EapButton';
import InlineValidationMessage from '../../components/InlineValidationMessage';
import AvailableProductSelector from './AvailableProductSelector';

const AssignedProductModal = ({
  showModal,
  availableProducts,
  closeModal,
  assignProduct,
}) => {
  const [product, setProduct] = useState(availableProducts[0]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      id,
      productId,
      amount
    } = product;

    if (productId !== undefined || amount !== undefined || amount > 0) {
      assignProduct(id);
    }
  };
  return (
    <EapModal
      modalTitle="ASSIGN PRODUCT"
      showModal={showModal}
    >
      <form
        onSubmit={handleSubmit}
        className="pure-form pure-form-stacked"
      >
        <div
          style={{
            paddingBottom: '1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <AvailableProductSelector
            selectedProduct={product}
            availableProducts={availableProducts}
            onSelectProduct={(p) => setProduct(p)}
          />
          {
            product.amount < 1
            && (<InlineValidationMessage message="You are out of this product" />)
          }
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EapButton
            optionalClass="confirmation-button"
            isSubmit
            disabled={product.amount < 1}
            text="Assign"
          />
          &nbsp;
          <EapButton
            isSecondary
            text="Close"
            onClick={closeModal}
          />
        </div>
      </form>
    </EapModal>
  );
};

AssignedProductModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  availableProducts: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired
  })),
  assignProduct: PropTypes.func.isRequired,
};

AssignedProductModal.defaultProps = {
  availableProducts: undefined
};

export default AssignedProductModal;
