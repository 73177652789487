import { v4 as uuid } from 'uuid';

export const PUSH_ALERT = 'PUSH_ALERT';
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const DISMISS_ALL = 'DISMISS_ALL';

export function dispatchDismissAll() {
  return (dispatch) => {
    dispatch({ type: DISMISS_ALL, payload: {} });
  };
}

export function dispatchDismiss(id) {
  return (dispatch) => {
    dispatch({
      type: DISMISS_ALERT,
      payload: {
        id
      }
    });
  };
}

export function dispatchAlert({ message, type, time }) {
  return (dispatch) => {
    const payload = {
      id: uuid(),
      message,
      type,
      time,
      active: true
    };
    if (time > 0) {
      payload.timer = setTimeout(() => dispatchDismiss(payload.id)(dispatch), time);
    }
    dispatch({
      type: PUSH_ALERT,
      payload
    });
  };
}
