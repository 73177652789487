import React from 'react';
import PropTypes from 'prop-types';
import * as validator from '@edits/eap-signup-validator';

import InlineValidationMessage from '../../components/InlineValidationMessage';
import WizardButtonBar from './WizardButtonBar';

import './EmailForm.css';

class EmailForm extends React.Component {
  constructor() {
    super();
    this.state = {
      alternateEmail: '',
      validateOnChange: false
    };
  }

  render() {
    const { next, previous } = this.props;
    return (
      <div className="pure-u-1">
        <p>
          <b>A receipt for this purchase will be sent to this email address:</b>
          <br />
          {this.props.email}
        </p>
        <div>
          <p>
            <b>
              Would you like a copy of this receipt sent to
              someone else? If so, enter their email address below.
            </b>
          </p>
          <form
            className="pure-form email-form"
            onSubmit={(e) => {
              e.preventDefault();
              next();
            }}
          >
            <input
              placeholder="EMAIL@EMAIL.COM"
              onBlur={(e) => {
                this.setState({
                  validateOnChange: true
                });
                const message = validator.validateSingleInput('email', e.target.value);
                if (message && message.length > 0) {
                  this.setState({ error: message[0] });
                } else {
                  this.setState({ error: undefined });
                  this.props.onOptionalEmailEntered(e.target.value);
                }
              }}
              onChange={(e) => {
                if (this.state.validateOnChange) {
                  const message = validator.validateSingleInput('email', e.target.value);
                  if (message && message.length > 0) {
                    this.setState({ error: message[0] });
                  } else {
                    this.setState({ error: undefined });
                    this.props.onOptionalEmailEntered(e.target.value);
                  }
                }
                this.setState({ alternateEmail: e.target.value });
              }}
              name="alternate-email"
              type="text"
              value={this.state.alternateEmail}
            />
            { false && <InlineValidationMessage message={this.state.error} /> }
          </form>
        </div>
        <WizardButtonBar
          next={next}
          previous={previous}
        />
      </div>
    );
  }
}

EmailForm.propTypes = {
  email: PropTypes.string.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  onOptionalEmailEntered: PropTypes.func.isRequired
};

export default EmailForm;
