import { connect } from 'react-redux';
import _ from 'lodash';

import ExamineeUploadForm from './components/ExamineeUploadForm';
import {
  parseBulkExamineesFile,
  bulkCreateExaminees
} from '../actions/examinees';

import { loadGroupViaSuper } from '../actions/group';

import { getAllAvailableProductsByGroup } from '../actions/availableProducts';

const mapStateToProps = (state, ownProps) => {
  const groupId = _.parseInt(ownProps.match.params.groupId, 10);
  const thisGroup = _.find(state.groups.list, { id: groupId });
  const availableProducts = _.filter(state.availableProducts.list, { groupId });
  return {
    preview: state.examinees.upload.examinees,
    validationErrors: state.examinees.upload.errors,
    availableProducts,
    validating: state.examinees.upload.inProgress,
    groupName: thisGroup && thisGroup.name,
    groupId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  load: () => {
    const groupId = _.parseInt(ownProps.match.params.groupId, 10);
    dispatch(loadGroupViaSuper(groupId));
    dispatch(getAllAvailableProductsByGroup(groupId));
  },
  bulkCreateExaminees: (examineeFile, availableProductId) => (
    dispatch(bulkCreateExaminees(
      ownProps.match.params.groupId,
      examineeFile,
      availableProductId
    ))
  ),
  parseBulkExamineesFile: (examineeFile, availableProductId) => (
    dispatch(parseBulkExamineesFile(
      ownProps.match.params.groupId,
      examineeFile,
      availableProductId
    ))
  ),
});

const ExamineeUploadFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamineeUploadForm);

export default ExamineeUploadFormContainer;
