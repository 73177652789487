import React from 'react';
import PropTypes from 'prop-types';

import './ProductTransferAlert.css';

const ProductTransferAlert = ({ amount, destinationGroupName, productName }) => (
  <span>
    <span className="transfer-alert-emphasis">{amount}</span> products of
    <span className="transfer-alert-emphasis">{productName}</span> transferred to group
    <span className="transfer-alert-emphasis">{destinationGroupName}</span>
  </span>
);

ProductTransferAlert.propTypes = {
  amount: PropTypes.number.isRequired,
  destinationGroupName: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired
};

export default ProductTransferAlert;
