import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import EapSelectList from './EapSelectList';

import './ProductStatusFilter.css';

export const Method = (filter, row) => {
  if (_.isEmpty(filter.value)) {
    return true;
  }
  return row.status === filter.value;
};

export const Filter = ({
  filter,
  onChange,
  classname,
  column,
  filterValue,
}) => {
  let id = '';
  let value = filterValue;
  let change = onChange;
  let label = '';
  let defaultClassName = '';
  if (column) {
    id = column.id;
    change = (e) => column.setFilter(e.target.value);
    defaultClassName = 'new-status-selector';
  } else {
    id = filter.id;
    value = filter.value;
    label = (<label htmlFor={id}>Status</label>);
  }
  return (
    <span className={classnames(classname, defaultClassName)}>
      {label}
      <EapSelectList
        id={id}
        onChange={change}
        value={value}
        choices={[
          { value: '', label: '' },
          { value: 'New', label: 'New' },
          { value: 'Launch', label: 'Launch' },
          { value: 'Resume', label: 'Resume' },
          { value: 'Results', label: 'Results' },
          { value: 'Pending', label: 'Pending' }
        ]}
      />
    </span>
  );
};

Filter.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  classname: PropTypes.string,
  column: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string
  }),
  filterValue: PropTypes.string,
};

Filter.defaultProps = {
  classname: '',
  column: undefined,
  filterValue: undefined
};
