import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { validateExamineeInvite } from '../actions/examineeInvites';
import { validateAdminInvite } from '../actions/adminInvites';
import EapStaticAlert from './EapStaticAlert';

class InviteValidator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErr: ''
    };
  }

  componentDidMount() {
    // distinguish for admin vs examinee
    const queryString = this.props.location.search;
    if (queryString.includes('join-examinee')) {
      const inviteToken = this.props.location.search
        .replace('?redirectUrl=%2Fjoin-examinee%2F', '');
      validateExamineeInvite(inviteToken)
        .then(result => this.setState({ showErr: !result.valid }))
        .catch(e => console.log('Problem parsing token :(', e));
    } else if (queryString.includes('join-admin')) {
      const inviteToken = this.props.location.search
        .replace('?redirectUrl=%2Fjoin-admin%2F', '');
      validateAdminInvite(inviteToken)
        .then(result => this.setState({ showErr: !result.valid }))
        .catch(e => console.log('Problem parsing token :(', e));
    }
  }

  render() {
    const isSignin = this.props.location &&
      this.props.location.pathname === '/signin';
    const errMessage = `This invite is not valid, but you may still continue 
      to sign ${isSignin ? 'into your' : 'up for an'} account!`;
    return (
      <div style={{ width: '85%', margin: 'auto' }}>
        <EapStaticAlert warning>
          {this.state.showErr && errMessage}
        </EapStaticAlert>
      </div>
    );
  }
}

InviteValidator.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired
};

export default InviteValidator;
