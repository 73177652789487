/* eslint import/no-cycle:0 */
import { combineReducers } from 'redux';
import alertReducer from './alert';
import loginReducer from './loginReducer';
import userReducer from './userReducer';
import passwordReducer from './passwordReducer';
import customerReducer from './customerReducer';
import stripeReducer from './stripeReducer';
import signupReducer from './signupReducer';
import activateReducer from './activateReducer';
import addGroupReducer from './addGroupReducer';
import quickbooksReducer from './quickbooks';
import squarespaceReducer from './squarespace';
import groupReducer from './groupReducer';
import groupsReducer from './groupsReducer';
import availableProductsReducer from './availableProductsReducer';
import productReducer from './productReducer';
import productLogReducer from './productLogReducer';
import assignedProductsReducer from './assignedProductsReducer';
import adminsReducer from './adminsReducer';
import examineesReducer from './examineesReducer';
import organizationReducer from './organizationReducer';
import errorReducer from './errorReducer';
import adminInviteReducer from './adminInviteReducer';
import examineeInviteReducer from './examineeInviteReducer';
import purchasesReducer from './purchasesReducer';
import resultsReducer from './resultsReducer';

import { ROOT_NUKE } from '../actions/root';

const reducers = combineReducers({
  alerts: alertReducer,
  login: loginReducer,
  user: userReducer,
  password: passwordReducer,
  customer: customerReducer,
  signup: signupReducer,
  activator: activateReducer,
  addGroup: addGroupReducer,
  quickbooks: quickbooksReducer,
  squarespace: squarespaceReducer,
  group: groupReducer,
  groups: groupsReducer,
  availableProducts: availableProductsReducer,
  assignedProducts: assignedProductsReducer,
  products: productReducer,
  productLog: productLogReducer,
  admins: adminsReducer,
  examinees: examineesReducer,
  organization: organizationReducer,
  stripe: stripeReducer,
  error: errorReducer,
  adminInvites: adminInviteReducer,
  examineeInvites: examineeInviteReducer,
  purchases: purchasesReducer,
  results: resultsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === ROOT_NUKE) {
    return reducers({}, action);
  }
  return reducers(state, action);
};

export default rootReducer;
