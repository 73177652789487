import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import AdminMenu from '../../components/AdminMenu';
import SuperHome from './Home';
import Quickbooks from './Quickbooks';
import Square from '../squarespace/SquarespaceContainer';
import OrganizationsRoutes from '../organizations/components/OrganizationsRoutes';
import GroupsTable from '../group/components/GroupsTable';
import ExamineeUploadFormContainer from '../../group/ExamineeUploadFormContainer';
import GroupContentTab from '../../group/components/GroupContentTab';
import SigninsRoutes from '../signins/components/SigninsRoutes';
import ProductsRoutes from '../products/components/ProductsRoutes';
import UserContainer from '../signins/UserContainer';
import OrganizationContainer from '../organizations/OrganizationContainer';
import GroupViewContainer from '../group/GroupViewContainer';
import AddGroupAdministratorFormContainer from '../group/AddGroupAdministratorFormContainer';
import PurchasesTableContainer from '../purchases/PurchasesTableContainer';
import OrdersTableContainer from '../orders/OrdersTableContainer';
import './Super.css';

const NotFound = () => (
  <div>Page not found.</div>
);

// NOTE: I'm not sure we need the ${match.url} in the NotFound component
class Super extends Component {
  componentDidMount() {
    /* eslint-disable-next-line react/destructuring-assignment */
    this.props.load();
  }

  render() {
    const {
      unauthorized,
      match: {
        url
      }
    } = this.props;

    if (unauthorized) {
      return (
        <div>
          <h1>Forbidden</h1>
          <p>You do not have permission of view this page.</p>
        </div>
      );
    }
    return (
      <div>
        <div className="pure-u-1 pure-u-md-1-6 side-menu-wrapper">
          <AdminMenu
            routes={[
              { path: 'home', icon: 'fa-home' },
              { path: 'qbo', icon: 'fa-book' },
              { path: 'organizations', icon: 'fa-bank' },
              { path: 'groups', icon: 'fa-sitemap' },
              { path: 'signins', icon: 'fa-user' },
              { path: 'products', icon: 'fa-cubes' },
              { path: 'refills', icon: 'fa-dollar' },
              { path: 'orders', icon: 'fa-dollar' },
              { path: 'square', icon: 'fa-share' },
            ]}
            base="super"
          />
        </div>
        <div className="pure-u-1 pure-u-md-19-24">
          <Switch>
            <Route
              path={`${url}/home`}
              render={() => <SuperHome />}
            />
            <Route
              isExact
              path={`${url}/qbo`}
              render={() => <Quickbooks />}
            />
            <Route
              isExact
              path={`${url}/square`}
              render={() => <Square />}
            />
            <Route
              isExact
              path={`${url}/qbo-success`}
              render={() => <Quickbooks />}
            />
            <Route
              isExact
              path={`${url}/squarespace-success`}
              render={() => <Square />}
            />
            <Route
              isExact
              path={`${url}/organizations`}
              render={() => (<OrganizationsRoutes baseUrl={`${url}/organizations`} />)}
            />
            <Route
              isExact
              path={`${url}/groups`}
              render={() => <GroupsTable baseUrl={url} />}
            />
            <Route
              isExact
              path={`${url}/signins`}
              render={() => (<SigninsRoutes baseUrl={`${url}/signins`} />)}
            />
            <Route
              isExact
              path={`${url}/products`}
              render={() => (<ProductsRoutes baseUrl={`${url}/products`} />)}
            />
            <Route
              isExact
              path={`${url}/user/:userId/info`}
              render={(props) => <GroupContentTab><UserContainer {...props} /></GroupContentTab>}
            />
            <Route
              isExact
              path={`${url}/group/:groupId/info`}
              render={(props) => (
                <GroupContentTab><GroupViewContainer {...props} /></GroupContentTab>
              )}
            />
            <Route
              isExact
              path={`${url}/group/:groupId/administrator/new`}
              render={(props) => (
                <GroupContentTab><AddGroupAdministratorFormContainer {...props} /></GroupContentTab>
              )}
            />
            <Route
              isExact
              path={`${url}/group/:groupId/examinees`}
              render={(props) => (
                <GroupContentTab><ExamineeUploadFormContainer {...props} /></GroupContentTab>
              )}
            />
            <Route
              isExact
              path={`${url}/organization/:organizationId/info`}
              render={(props) => (
                <GroupContentTab><OrganizationContainer {...props} /></GroupContentTab>
              )}
            />
            <Route
              isExact
              path={`${url}/refills`}
              render={(props) => (
                <PurchasesTableContainer
                  baseUrl={`${url}`}
                  {...props}
                />
              )}
            />
            <Route
              isExact
              path={`${url}/orders`}
              render={(props) => (
                <OrdersTableContainer
                  baseUrl={url}
                  {...props}
                />
              )}
            />
            <Route
              path={`${url}/*`}
              component={NotFound}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

Super.propTypes = {
  unauthorized: PropTypes.bool,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

Super.defaultProps = {
  unauthorized: false
};

export default Super;
