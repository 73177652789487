import { httpClient } from '../utils/httpClient';

export const SUPER_FILTER_GROUPS_SUCCESS = 'SUPER_FILTER_GROUPS_SUCCESS';

export const superFilter = ({ filtered, sorted, limit }) => ((dispatch) => (
  httpClient
    .get('/super/groups', { params: { filter: filtered, sorted, limit } })
    .then((response) => {
      dispatch({
        type: SUPER_FILTER_GROUPS_SUCCESS,
        payload: response.data
      });
    })
    .catch((error) => {
      console.error('ERROR Filtering Groups', error.response.data.message);
    })
));
