import React from 'react';
import PropTypes from 'prop-types';
import { DynamicEapTable } from '../../../components/DynamicEapTable';

import './UserTableSelector.css';

const UserTableSelector = ({ onSelected, filterUsers, users }) => (
  <DynamicEapTable
    columns={[
      { Header: 'ID', accessor: 'id', width: 70 },
      { Header: 'Username', accessor: 'username' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'First', accessor: 'firstName' },
      { Header: 'Last', accessor: 'lastName' },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => (
          <button
            type="button"
            onClick={() => onSelected(row.original)}
          >
            Add
          </button>
        )
      }
    ]}
    data={users}
    fetchData={({ filtered, sorted, limit }) => filterUsers(filtered, sorted, limit)}
  />
);

UserTableSelector.propTypes = {
  onSelected: PropTypes.func.isRequired
};

export default UserTableSelector;
