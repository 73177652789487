import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import EapModalDialog from './EapModalDialog';
import './EapModal.css';

const EapModal = ({
  showModal, modalTitle, children
}) => (

  <Modal
    isOpen={showModal}
    contentLabel={`EapModal-${modalTitle}`}
    ariaHideApp={false}
    style={{
      // FYI: styles are additive while className replaces default modal base style
      overlay: {
        zIndex: 20
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        paddingTop: '0',
        paddingRight: '0',
        paddingBottom: '0',
        paddingLeft: '0',
        borderStyle: 'none',
        transform: 'translate(-50%, -50%)',
        overflow: 'hidden'
      }
    }}
  >
    <EapModalDialog
      modalTitle={modalTitle}
    >
      { children || 'This component needs some kids...'}
    </EapModalDialog>
  </Modal>
);

EapModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  modalTitle: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired
};

EapModal.defaultProps = {};

export default EapModal;
