import React from 'react';
import _ from 'lodash';

import { httpClient } from '../utils/httpClient';
import { dispatchAlert } from './alert';
import history from '../utils/history';

export const UPDATE_ADMIN_PERMISSIONS_SUCCESS = 'UPDATE_ADMIN_PERMISSIONS_SUCCESS';
export const UPDATE_ADMIN_PERMISSIONS_FAILURE = 'UPDATE_ADMIN_PERMISSIONS_FAILURE';

export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE';
export const SUPER_LOAD_GROUP_ADMINS_SUCCESS = 'SUPER_LOAD_GROUP_ADMINS_SUCCESS';
export const SUPER_LOAD_GROUP_ADMINS_FAILURE = 'SUPER_LOAD_GROUP_ADMINS_FAILURE';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_FAILURE = 'ADD_ADMIN_FAILURE';

const updateAdminSuccess = (groupId, admin, newPermissions) => ({
  type: UPDATE_ADMIN_PERMISSIONS_SUCCESS,
  payload: {
    groupId,
    admin,
    newPermissions
  }
});

export function updateAdminPermissions(groupId, actorAdmin, subjectAdmin, newPermissions) {
  return (dispatch) => {
    httpClient.put(`/group/${actorAdmin.adminGroupId}/admin/${subjectAdmin.adminGroupId}`, {
      newPermissions: _.pick(newPermissions, [
        'per_admin_edit',
        'per_admin_invite',
        'per_admin_remove',
        'per_admin_view',
        'per_create',
        'per_products_assign',
        'per_products_transfer',
        'per_products_view',
        'per_users_invite',
        'per_users_move',
        'per_users_view'
      ])
    })
      .then(() => {
        dispatch(dispatchAlert({
          message: (
            <span>
              Update permissions for <b>{subjectAdmin.firstName} {subjectAdmin.lastName}</b>
            </span>
          ),
          type: 'eap-alert-success',
          time: 5000
        }));
      });
    dispatch(updateAdminSuccess(groupId, subjectAdmin, newPermissions));
  };
}

export const deleteAdminFailure = error => ({
  type: DELETE_ADMIN_FAILURE,
  payload: { error }
});

export function deleteAdmin(groupId, actorAdmin, subjectAdmin) {
  let url;
  if (actorAdmin === undefined) {
    url = `/super/admin/${subjectAdmin.adminGroupId}`;
  } else {
    url = `/group/${actorAdmin.adminGroupId}/admin/${subjectAdmin.adminGroupId}`;
  }
  return dispatch => (
    httpClient
      .delete(url)
      .then(() => {
        dispatch({
          type: DELETE_ADMIN_SUCCESS,
          payload: { groupId, subjectAdmin }
        });
        dispatch(dispatchAlert({
          message: (
            <span>
              <b>{subjectAdmin.firstName} {subjectAdmin.lastName}</b>&nbsp;
              has been removed as an administrator.
            </span>
          ),
          type: 'eap-alert-success',
          time: 5000
        }));
      }).catch((err) => {
        console.error('ERROR DELETING ADMIN', err);
        dispatch(deleteAdminFailure(err.message));
        dispatch(dispatchAlert({
          message: (
            <span>
              Unable to delete admin. {err.message}
            </span>
          ),
          type: 'eap-alert-error',
          time: 5000
        }));
      })
  );
}

export function superUpdateAdminPermissions(
  groupId,
  subjectAdmin,
  newPermissions
) {
  return (dispatch) => {
    dispatch(updateAdminSuccess(groupId, subjectAdmin, newPermissions));
    httpClient.put(`/super/admin/${subjectAdmin.adminGroupId}`, { newPermissions })
      .then(() => {
        dispatch(dispatchAlert({
          message: (
            <span>
              Admin permissions for&nbsp;
              <b>{subjectAdmin.firstName} {subjectAdmin.lastName}</b>&nbsp;
              updated.
            </span>
          ),
          type: 'eap-alert-success',
          time: 5000
        }));
      });
  };
}

export const loadSuperGroupAdminsSuccess = (groupAdmins, groupId) => ({
  type: SUPER_LOAD_GROUP_ADMINS_SUCCESS,
  payload: { admins: groupAdmins, groupId }
});

export const loadSuperGroupAdminsFailure = error => ({
  type: SUPER_LOAD_GROUP_ADMINS_FAILURE,
  payload: { error }
});

export function loadSuperGroupAdmins(groupId) {
  return dispatch =>
    httpClient.get(`/super/group/${groupId}/admins`)
      .then((response) => {
        dispatch(loadSuperGroupAdminsSuccess(response.data, groupId));
      }).catch(err => dispatch(loadSuperGroupAdminsFailure(err)));
}

export function sendAlreadyAdminAlert(userData) {
  return dispatch =>
    dispatch(dispatchAlert({
      message: (
        <span>
          <b>{userData.firstName} {userData.lastName}</b> is already an admin in this group.
        </span>
      ),
      type: 'eap-alert-error',
    }));
}

export const addAdminSuccess = (groupId, newAdmin) => ({
  type: ADD_ADMIN_SUCCESS,
  payload: { groupId, newAdmin }
});

export function addAdmin(groupId, user, adminPermissions) {
  return dispatch =>
    httpClient.post(`/super/group/${groupId}/admin`, {
      userId: user.id,
      email: user.email,
      permissions: adminPermissions
    })
      .then((response) => {
        history.push(`/super/group/${groupId}/info`);
        dispatch(dispatchAlert({
          message: (
            <span> User has been added as an admin. </span>
          ),
          type: 'eap-alert-success',
          time: 5000
        }));
        dispatch(addAdminSuccess(groupId, response.data));
      }).catch((err) => {
        if (err && err.response && err.response.data) {
          dispatch(dispatchAlert({
            message: (<span>{err.response.data.message}</span>),
            type: 'eap-alert-error',
            time: 5000
          }));
        }
      });
}
