import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InlineValidationMessage from '../../components/InlineValidationMessage';
import EapButton from '../../components/EapButton';

import './GroupDetailEditor.css';

const GROUP_NAME_MIN_CHAR = 1;
const GROUP_NAME_MIN_LENGTH_MESSAGE = 'Group name must contain at least 1 character';

class GroupDetailEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: { ...props.group },
      nameMessage: undefined,
    };

    this.handleChange = this.handleChange.bind(this);
    this.validateAndSave = this.validateAndSave.bind(this);
    this.validateGroupName = this.validateGroupName.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      nameMessage: nextProps.groupNameExists ? 'The group name already exists: ' : undefined
    });
  }

  cancelEdit() {
    this.setState({
      group: { ...this.props.group },
      nameMessage: undefined
    });
    this.props.closeModal();
  }

  handleEnter(e) {
    if (e.key === 'Enter') {
      this.validateAndSave();
    }
  }

  validateGroupName(e) {
    if (e.target.value === this.props.group.name) {
      this.setState({ nameMessage: undefined });
    } else if (!this.hasMinimumNameLength()) {
      this.setState({
        nameMessage: GROUP_NAME_MIN_LENGTH_MESSAGE
      });
    } else if (e.target.value !== this.props.group.name) {
      this.props.checkUniqueGroupName(e.target.value);
    }
  }

  hasMinimumNameLength() {
    return this.state.group.name.length >= GROUP_NAME_MIN_CHAR;
  }

  formHasChanged() {
    return this.props.group.name !== this.state.group.name ||
      this.props.group.description !== this.state.group.description;
  }

  validateAndSave() {
    if (!this.hasMinimumNameLength()) {
      this.setState({
        nameMessage: GROUP_NAME_MIN_LENGTH_MESSAGE
      });
    } else if (this.formHasChanged() && !this.props.groupNameExists) {
      this.props.updateGroup({
        id: this.props.group.id,
        name: this.state.group.name,
        description: this.state.group.description || undefined,
      });
      this.props.closeModal();
    }
  }

  handleChange(e) {
    if (e.target.id === 'groupName') {
      this.setState({
        group: { ...this.state.group, name: e.target.value },
      });
    } else if (e.target.id === 'groupDescription') {
      this.setState({
        group: { ...this.state.group, description: e.target.value },
      });
    }
  }

  render() {
    return (
      <div className="group-editor-box">
        <form className="pure-form pure-form-stacked eap-grey-form">
          <label htmlFor="groupName">GROUP NAME</label>
          <input
            id="groupName"
            type="text"
            value={this.state.group.name}
            onChange={this.handleChange}
            onKeyPress={this.handleEnter}
            onBlur={this.validateGroupName}
          />
          <InlineValidationMessage message={this.state.nameMessage} />
          <label htmlFor="groupDescription">DESCRIPTION</label>
          <input
            id="groupDescription"
            type="text"
            value={this.state.group.description || ''}
            onChange={this.handleChange}
            onKeyPress={this.handleEnter}
          />
        </form>
        <EapButton
          onClick={() => { this.validateAndSave(); }}
          disabled={
            this.props.groupNameExists ||
            !this.formHasChanged() ||
            !this.hasMinimumNameLength()
          }
          text="SAVE"
        />
        <EapButton
          isSecondary
          onClick={this.cancelEdit}
          text="CANCEL"
        />
      </div>
    );
  }
}

GroupDetailEditor.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    nameMessage: PropTypes.string,
  }).isRequired,
  updateGroup: PropTypes.func.isRequired,
  checkUniqueGroupName: PropTypes.func.isRequired,
  groupNameExists: PropTypes.bool,
  closeModal: PropTypes.func.isRequired
};

GroupDetailEditor.defaultProps = {
  groupNameExists: false
};

export default GroupDetailEditor;
