import { connect } from 'react-redux';
import AddGroupAdministratorForm from './components/AddGroupAdministratorForm';
import { addAdmin } from '../../actions/admins';
import {
  filter,
} from '../../actions/user';


const mapStateToProps = (state, ownProps) => {
  const { groupId } = ownProps.match.params;
  return {
    groupId,
    users: state.user ? (state.user.serverFiltered) : undefined
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { groupId } = ownProps.match.params;
  return {
    addAdmin: (user, adminPermissions) => {
      dispatch(addAdmin(groupId, user, adminPermissions));
    },
    filterUsers: (filters, sorted, limit) => {
      dispatch(filter(filters, sorted, limit));
    }
  };
};

const AddGroupAdministratorFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddGroupAdministratorForm);

export default AddGroupAdministratorFormContainer;
