import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';

import { scrubUserData } from '../../utils/FormsHelper';
import InlineValidationMessage from '../../components/InlineValidationMessage';
import FormInputGroup from '../../components/FormInputGroup';
import EapDatePicker from '../../components/EapDatePicker';
import GradeLevelSelectList from '../../components/GradeLevelSelectList';
import GenderSelectList from '../../components/GenderSelectList';
import {
  CoreUserProfileSchema,
  RequiredEmailSchema,
  UniqueEmailSchema,
  UsernameSchema,
  NewPasswordSchema,
  ConfirmPasswordSchema,
  TermsAndConditionsSchema,
  UniqueUsernameSchema,
} from './UserSchema';
import EapButton from '../../components/EapButton';
import StyledForm from '../../components/StyledForm';

import './PublicSignupForm.css';

class PublicSignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usernameOnly: false,
      initialValues: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        grade: '',
        termsAndConditions: false
      }
    };
    this.submitRegistration = this.submitRegistration.bind(this);
  }


  submitRegistration(values) {
    const scrubbed = scrubUserData(values);
    this.props.onSubmit(
      {
        ...scrubbed,
        username: !this.state.usernameOnly ? scrubbed.email : scrubbed.username
      },
      this.props.redirectUrl
    );
  }

  render() {
    const { usernameOnly, initialValues } = this.state;
    return (
      <Formik
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validationSchema={
          CoreUserProfileSchema.concat(yup.object().shape({
              email: usernameOnly ? undefined : RequiredEmailSchema.concat(UniqueEmailSchema()),
              username: usernameOnly ?
                UsernameSchema.concat(UniqueUsernameSchema()) :
                undefined,
              password: NewPasswordSchema,
              confirmPassword: ConfirmPasswordSchema,
              termsAndConditions: TermsAndConditionsSchema
            }))
        }
        onSubmit={values => this.submitRegistration(values)}
      >
        {({
 values, errors, touched, setFieldValue
 }) => {
   const showEmailError = !usernameOnly && touched.email && errors.email;
   const showUsernameError = usernameOnly && touched.username && errors.username;

 return (
   <StyledForm>
     <div className="public-email-username-form-group">
       <label
         htmlFor={this.state.usernameOnly ? 'username' : 'email'}
       >
         {usernameOnly ? 'Username *' : 'Email *'}
       </label>
       <button
         type="button"
         className="email-username-toggle"
         onClick={(e) => {
        if (!usernameOnly) {
          setFieldValue('email', '');
          } else {
          setFieldValue('username', '');
         }
         this.setState({ usernameOnly: !usernameOnly });
        }}
       >{usernameOnly ?
        'I have an email address' :
        'I have no email and would like to choose my own username.'
        }
       </button>
       <Field
         name={this.state.usernameOnly ? 'username' : 'email'}
         type="text"
       />
       { showUsernameError &&
       <InlineValidationMessage message={errors.username} />
      }
       { showEmailError &&
       <InlineValidationMessage message={errors.email} />
      }
     </div>
     <FormInputGroup required>
       <label htmlFor="password">PASSWORD</label>
       <Field
         type="password"
         name="password"
       />
       {touched.password && errors.password &&
       <InlineValidationMessage message={errors.password} />
       }
     </FormInputGroup>
     <FormInputGroup required>
       <label htmlFor="confirmPassword">CONFIRM PASSWORD</label>
       <Field
         type="password"
         name="confirmPassword"
       />
       {touched.confirmPassword && errors.confirmPassword &&
       <InlineValidationMessage message={errors.confirmPassword} />
            }
     </FormInputGroup>
     <FormInputGroup required>
       <label htmlFor="firstName">First Name</label>
       <Field
         type="text"
         name="firstName"
       />
       {touched.firstName && errors.firstName &&
       <InlineValidationMessage message={errors.firstName} />
            }
     </FormInputGroup>
     <FormInputGroup required>
       <label htmlFor="lastName">Last Name</label>
       <Field
         type="text"
         name="lastName"
       />
       {touched.lastName && errors.lastName &&
       <InlineValidationMessage message={errors.lastName} />}
     </FormInputGroup>
     <FormInputGroup>
       <label htmlFor="dob">
        DATE OF BIRTH (MM/DD/YYYY)
       </label>
       <EapDatePicker
         initialDate={values.dob}
         onDateSelected={dateVal => setFieldValue('dob', dateVal)}
       />
     </FormInputGroup>
     <FormInputGroup>
       <label htmlFor="gender">
        GENDER
       </label>
       <GenderSelectList />
     </FormInputGroup>
     <FormInputGroup>
       <label htmlFor="grade">GRADE LEVEL</label>
       <GradeLevelSelectList />
     </FormInputGroup>
     <FormInputGroup>
       <label
         id="public-terms-and-conditions"
         htmlFor="termsAndConditions"
       >
         <Field
           type="checkbox"
           name="termsAndConditions"
           className="eap-form-checkbox"
         />
          I agree to&nbsp;
         <a href="https://www.edits.net/legal/terms-of-use/" target="_blank" rel="noopener noreferrer">
            terms and conditions
         </a>
          &nbsp;for this site <span className="required">*</span>
       </label>
       {touched.termsAndConditions && errors.termsAndConditions &&
       <InlineValidationMessage message={errors.termsAndConditions} />}
     </FormInputGroup>
     <FormInputGroup>
       <EapButton
         isSubmit
         text="SIGN UP"
       />
     </FormInputGroup>
   </StyledForm>
  );
  }}
      </Formik>
    );
  }
}

PublicSignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string
};

PublicSignupForm.defaultProps = {
  redirectUrl: undefined
};

export default PublicSignupForm;
