import React from 'react';
import { Link } from 'react-router-dom';
import './AddGroupLink.css';

const AddGroupLink = () => (
  <div className="pure-u-1 add-group-link">
    <Link
      className="add-group-link"
      to="/dashboard/add-group"
    >
    Add new group
    </Link>
  </div>
);

export default AddGroupLink;
