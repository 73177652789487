import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Wizard, Steps, Step } from 'react-albus';

import ExamineeUploadStep1 from './ExamineeUploadStep1';
import ExamineeUploadStep2 from './ExamineeUploadStep2';
import EapGeneralHeader from '../../components/EapGeneralHeader';
import EapButton from '../../components/EapButton';

import './ExamineeUploadForm.css';

class ExamineeUploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: undefined,
    };
  }

  componentDidMount() {
    const {
      groupName,
      load
    } = this.props;

    if (!groupName) {
      load();
    }
  }

  render() {
    const {
      groupId,
      groupName,
      availableProducts,
      validating,
      preview,
      validationErrors,
      parseBulkExamineesFile: validateFile,
      bulkCreateExaminees: upload,
    } = this.props;

    return (
      <Route
        render={({ history, match: { url } }) => (
          <div className="pure-u-1 examinee-upload-box">
            <EapGeneralHeader
              text={`Examinee Bulk Upload - Group: ${groupName}`}
            />
            <Wizard history={history} basename={url}>
              <Steps>
                <Step
                  id="selectUpload"
                  render={({ next }) => (
                    <ExamineeUploadStep1
                      availableProducts={availableProducts}
                      next={({ selectedProduct, file }) => {
                        validateFile(file, selectedProduct.id);
                        this.setState({ selectedProduct });
                        next();
                      }}
                    />
                  )}
                />
                <Step
                  id="confirmUpload"
                  render={({ next, previous }) => (
                    <ExamineeUploadStep2
                      validating={validating}
                      preview={preview}
                      errors={validationErrors}
                      previous={() => {
                        previous();
                      }}
                      next={() => {
                        /* eslint-disable-next-line react/destructuring-assignment */
                        upload(preview, this.state.selectedProduct.id);
                        next();
                      }}
                    />
                  )}
                />
                <Step
                  id="success"
                  render={() => (
                    <div>
                      <h1>{validating ? 'Please Wait' : 'Success!'}</h1>
                      <EapButton
                        disabled={validating}
                        text="Next"
                        onClick={() => history.push(`/super/group/${groupId}/info`)}
                      />
                    </div>
                  )}
                />
              </Steps>
            </Wizard>
          </div>
        )}
      />
    );
  }
}

ExamineeUploadForm.propTypes = {
  groupId: PropTypes.number.isRequired,
  groupName: PropTypes.string,
  preview: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string.isRequired,
    first: PropTypes.string.isRequired,
    last: PropTypes.string.isRequired
  })),

  validationErrors: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired
  })),

  availableProducts: PropTypes.arrayOf(PropTypes.shape({
    adminName: PropTypes.string.isRequired
  })),

  load: PropTypes.func.isRequired,
  parseBulkExamineesFile: PropTypes.func.isRequired,
  bulkCreateExaminees: PropTypes.func.isRequired,
};

ExamineeUploadForm.defaultProps = {
  groupName: undefined,
  validationErrors: undefined,
  preview: [],
  availableProducts: [],
};

export default ExamineeUploadForm;
