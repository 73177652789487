/* eslint react/destructuring-assignment:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EapButton from '../../../components/EapButton';
import GradeDropdown from '../../../formComponents/GradeDropdown';
import GenderDropdown from '../../../formComponents/GenderDropdown';
import EapModal from '../../../components/EapModal';
import SuperGroups from '../../commonTables/SuperGroups';
import SectionHeader from '../../components/SectionHeader';
import AssignedProductsTable from '../../commonTables/AssignedProducts';
import ConfirmationModal from '../../../components/ConfirmationModal';
import UserEditor from './UserEditor';
import AdminHandout from './AdminHandout';

import './User.scss';

export default class User extends Component {
  constructor() {
    super();
    this.state = {
      selectedUserId: undefined,
      resetPasswordModal: false,
      openAdminHandoutModal: false
    };
  }

  componentDidMount() {
    this.props.loadUserInfo(parseInt(this.props.match.params.userId, 10));
  }

  render() {
    return (
      <div>
        <h1> Signin Information </h1>
        <EapModal
          modalTitle="Edit Signin Information"
          showModal={
            this.state.selectedUserId === parseInt(this.props.match.params.userId, 10)
          }
        >
          <UserEditor
            userInfo={this.props.signin}
            userId={parseInt(this.props.match.params.userId, 10)}
            closeModal={() => this.setState({ selectedUserId: undefined })}
            updateUserInfo={this.props.updateUserInfo}
          />
        </EapModal>
        <div className="user-display-form">
          <div className="pure-control-group">
            <label htmlFor="username">Username:</label>
            <span>{this.props.signin.username}</span>
          </div>
          <div className="pure-control-group">
            <label htmlFor="email">Email:</label>
            <span>{this.props.signin.email}</span>
          </div>
          <div className="pure-control-group">
            <EapButton
              optionalClass="super-reset-password"
              onClick={() => this.setState({ resetPasswordModal: true })}
              disabled={!this.props.signin.email}
              text="Reset Password"
            />
            <ConfirmationModal
              modalTitle="RESET PASSWORD"
              showModal={this.state.resetPasswordModal}
              messagePrompt={
                <div>
                  <span>
                          Please confirm that you would like to reset
                          the password for the follow signin: &nbsp;
                  </span>
                  <br />
                  <b>
                          Name: {this.props.signin.firstName} {this.props.signin.lastName}
                  </b>
                  <br />
                  <b> Username: {this.props.signin.username} </b>
                  <br />
                  <span>
                          Would you like to send a password reset request email to
                    <b> {this.props.signin.email}</b>?
                  </span>
                </div>
                    }
              closeModal={() => this.setState({ resetPasswordModal: false })}
              confirmAction={() => this.props.resetPassword(this.props.signin.email)}
            />
          </div>
          <div className="pure-control-group">
            <label htmlFor="firstName">First:</label>
            <span>{this.props.signin.firstName}</span>
          </div>
          <div className="pure-control-group">
            <label htmlFor="lastName">Last:</label>
            <span>{this.props.signin.lastName}</span>
          </div>
          <div className="pure-control-group user-status-display">
            <label>STATUS:</label>
            <div>
              <label htmlFor="isActive">Is Active</label>
              <input
                name="isActive"
                type="checkbox"
                checked={this.props.signin.isActive}
                disabled
              />
              <label htmlFor="isSuperAdmin">Super Admin</label>
              <input
                name="isSuperAdmin"
                type="checkbox"
                checked={this.props.signin.isSuperAdmin}
                disabled
              />
            </div>
          </div>
          <div className="pure-control-group">
            <label htmlFor="dob">DOB:</label>
            <span>{this.props.signin.dob === null ? 'None' : this.props.signin.dob}</span>
          </div>
          <div className="pure-control-group">
            <label htmlFor="grade">Grade:</label>
            <GradeDropdown
              formGroupStyle="pure-u-3-5"
              update={() => {}}
              value={this.props.signin.grade}
              disabled
            />
          </div>
          <div className="pure-control-group">
            <label htmlFor="gender">Gender:</label>
            <GenderDropdown
              formGroupStyle="pure-u-3-5"
              update={() => {}}
              value={this.props.signin.gender}
              disabled
            />
          </div>
        </div>
        <div className="super-user-edit-button-bar">
          <EapButton
            text="Edit"
            onClick={() =>
              this.setState({ selectedUserId: parseInt(this.props.match.params.userId, 10) })
            }
          />

          <EapButton
            text="Send Admin Handout"
            disabled={!this.props.signin.email}
            onClick={() => this.setState({ openAdminHandoutModal: true })}
          />
        </div>

        <EapModal
          modalTitle="Send Admin Handout"
          showModal={this.state.openAdminHandoutModal}
        >
          <AdminHandout
            closeModal={() => this.setState({ openAdminHandoutModal: false })}
            email={this.props.signin.email}
            firstName={this.props.signin.firstName}
            lastName={this.props.signin.lastName}
            sendAdminHandout={this.props.sendAdminHandout}
            signinUserId={parseInt(this.props.match.params.userId, 10)}
          />
        </EapModal>
        <SuperGroups
          type="examinee"
          tableData={this.props.examineeGroups}
        />
        <SuperGroups
          type="admin"
          tableData={this.props.adminGroups}
        />
        <SectionHeader title="Assigned Products" />
        <AssignedProductsTable
          data={this.props.assignedProducts}
          revoke={this.props.revokeAssignedProduct}
          releaseResults={this.props.releaseResults}
          suppressResults={this.props.suppressResults}
        />
      </div>
    );
  }
}

User.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string
    })
  }).isRequired,
  loadUserInfo: PropTypes.func.isRequired,
  signin: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dob: PropTypes.string,
    grade: PropTypes.number,
    gender: PropTypes.string,
    isActive: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
  }),
  adminGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    groupId: PropTypes.number,
    organizationId: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string
  })),
  examineeGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    groupId: PropTypes.number,
    organizationId: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string
  })),
  assignedProducts: PropTypes.arrayOf(PropTypes.shape({
    testId: PropTypes.number,
    groupId: PropTypes.number,
    organizationId: PropTypes.string,
    groupName: PropTypes.string,
    productName: PropTypes.string,
    status: PropTypes.string
  })),
  updateUserInfo: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  sendAdminHandout: PropTypes.func.isRequired
};

User.defaultProps = {
  signin: undefined,
  adminGroups: [],
  examineeGroups: [],
  assignedProducts: []
};
