import { connect } from 'react-redux';
import AllExaminees from './AllExaminees';

import {
  moveExaminee,
  overrideExamineePassword
} from '../../actions/examinees';

import {
  filterResults,
} from '../../actions/results';

import {
  releaseResults,
} from '../../actions/assignedProducts';

import { requestPasswordReset } from '../../actions/password';

const mapStateToProps = (state) => ({
  examinees: state.results.serverFiltered,
  examineeCount: state.results.serverTotal,
  adminGroups: state.user.adminGroups,
  moveError: state.examinees.moveError,
});

const mapDispatchToProps = (dispatch) => ({
  search: (params) => {
    dispatch(filterResults(params));
  },
  moveExaminee: (adminId, examinee, sourceGroupId, newGroup) => {
    dispatch(moveExaminee(adminId, examinee, sourceGroupId, newGroup));
  },
  overrideExamineePassword: (examineeId, newPassword, username) => {
    dispatch(overrideExamineePassword(examineeId, newPassword, username));
  },
  resetPassword: (email) => {
    dispatch(requestPasswordReset(email, true));
  },
  releaseResults: (params) => {
    dispatch(releaseResults(params));
  }
});

const AllExamineesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AllExaminees);

export default AllExamineesContainer;
