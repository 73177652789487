import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AdminInvitesForm from '../../components/AdminInvitesForm';
import { StaticEapTable, PermissionFilter } from '../../components/StaticEapTable';
import AdminIconList from '../../components/AdminIconList';
import AdminIcon from '../../components/AdminIcon';
import AdminInfoModal from '../../components/AdminInfoModal';
import AdminInviteRevokeModal from './AdminInviteRevokeModal';
import AdminInviteResendModal from './AdminInviteResendModal';

export default class Invites extends Component {
  constructor() {
    super();
    this.state = {
      adminInfoModal: undefined
    };
    this.submitInvites = this.submitInvites.bind(this);
    this.revokeInvite = this.revokeInvite.bind(this);
    this.resendInvite = this.resendInvite.bind(this);
  }

  submitInvites(emailList, inviteData) {
    this.props.sendInvites(
      this.props.actorAdmin.adminGroupId,
      emailList,
      inviteData
    );
  }

  revokeInvite(selectedInvite) {
    this.props.revokeInvite(this.props.actorAdmin.adminGroupId, selectedInvite);
  }

  resendInvite(inviteId) {
    this.props.resendInvite(this.props.actorAdmin.adminGroupId, inviteId);
  }

  render() {
    const columns = [
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Date Sent',
        accessor: 'createdAt',
        filterable: false,
        sortable: false
      },
      {
        Header: 'Permission Level',
        accessor: 'permissionString',
        Filter: PermissionFilter
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => (
          <AdminIconList>
            <AdminIcon
              onClick={() => this.setState({ adminInfoModal: row.original })}
              icon="fa-info-circle"
              toolTip="Information"
            />
            <AdminInviteResendModal
              selectedAdminInvite={row.original}
              resendInvite={this.resendInvite}
            />
            <AdminInviteRevokeModal
              selectedAdminInvite={row.original}
              revokeInvite={this.revokeInvite}
            />
          </AdminIconList>
        )
      }
    ];
    return (
      <div>
        <AdminInvitesForm
          submitInvites={this.submitInvites}
        />
        <StaticEapTable
          columns={columns}
          data={this.props.invites}
        />
        <AdminInfoModal
          showModal={this.state.adminInfoModal !== undefined}
          permissions={this.state.adminInfoModal || {}}
          closeModal={() => this.setState({ adminInfoModal: undefined })}
        />
      </div>
    );
  }
}

Invites.propTypes = {
  actorAdmin: PropTypes.shape({
    adminGroupId: PropTypes.number.isRequired,
  }).isRequired,
  invites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    createdAt: PropTypes.string
  })).isRequired,
  revokeInvite: PropTypes.func.isRequired,
  sendInvites: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired
};
