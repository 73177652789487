import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { PaginationEapTable } from '../../components/PaginationEapTable';
import { sortPermissions } from '../../utils/sortPermissions';

import AdminIcon from '../../components/AdminIcon';
import AdminIconList from '../../components/AdminIconList';
import EapModal from '../../components/EapModal';
import ExamineePasswordOverrideForm from '../../group/components/ExamineePasswordOverrideForm';
import EnterMSD from '../../components/EnterMSDModal';
import MoveExaminee from '../../group/components/MoveExaminee';
import ConfirmationModal from '../../components/ConfirmationModal';
import AdminProductLink from '../../components/AdminProductLink';
import { Display as EapDisplayDate, sort as dateSort } from '../../components/EapDisplayDate';
import * as ProductStatus from '../../components/ProductStatusFilter';
import ReleaseResultsModal from '../../components/ReleaseResultsModal';

const AllExaminees = ({
  search,
  examinees,
  examineeCount,
  adminGroups,
  overrideExamineePassword,
  resetPassword,
  moveExaminee,
  moveError,
  releaseResults,
}) => {
  const [showModal, setShowModal] = useState({ type: '', data: {} });
  useEffect(() => {
    if (examinees === undefined) {
      search();
    }
  }, [examinees, search]);
  const columns = [
    {
      Header: 'Username',
      accessor: 'username',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
    },
    {
      Header: 'Group',
      accessor: 'groupName',
      Cell: ({ row }) => (
        <Link to={`/group/${row.original.groupId}/information`}>
          {row.original.groupName}
        </Link>
      ),
    },
    {
      Header: 'Product',
      accessor: 'productName',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => (
        <AdminProductLink
          text={row.original.status}
          assignedProductId={row.original.assignedProductId}
        />
      ),
      Filter: ProductStatus.Filter,
      filterMethod: ProductStatus.Method,
    },
    {
      Header: 'Date',
      accessor: 'assignedProductUpdated',
      Cell: ({ row: { original: { assignedProductUpdated: updatedAt } } }) => (
        <EapDisplayDate date={updatedAt} />
      ),
      sortMethod: dateSort,
      disableFilters: true,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => {
        const usersPermission = sortPermissions(row.original);
        usersPermission.id = row.original.adminGroupId;
        return (
          <AdminIconList>
            <AdminIcon
              onClick={() => { setShowModal({ type: 'move', data: row.original }); }}
              icon="fa-random"
              toolTip="Move"
              permission="move"
              permissions={usersPermission.user}
            />
            <AdminIcon
              onClick={() => { setShowModal({ type: 'setPassword', data: row.original }); }}
              icon="fa-key"
              toolTip="Set Password"
              permission="invite"
              permissions={usersPermission.user}
            />
            {
              row.original.email
                ? (
                  <AdminIcon
                    onClick={() => {
                      setShowModal({ type: 'resetPassword', data: row.original });
                    }}
                    icon="fa-rotate-left"
                    toolTip="Reset Password"
                  />
                ) : ''
            }
            {
              row.original.configs
                && (
                  row.original.configs.caps
                  || row.original.configs.spac
                  || row.original.configs.capsp
                )
                ? (
                  <AdminIcon
                    onClick={() => {
                      setShowModal({ type: 'enterMsd', data: row.original });
                    }}
                    icon="fa-plus"
                    toolTip="Enter MSD"
                  />
                ) : ''
            }
            {
              row.original.configs && row.original.configs.suppressed
                ? (
                  <AdminIcon
                    onClick={() => {
                      setShowModal({ type: 'release', data: row.original });
                    }}
                    icon="fa-share-square-o"
                    toolTip="Release Results"
                  />
                ) : ''
            }

          </AdminIconList>
        );
      },
    }
  ];
  return (
    <>
      <PaginationEapTable
        columns={columns}
        data={examinees}
        fetchData={(params) => search(params)}
        total={examineeCount}
      />
      {
        showModal.type === 'setPassword'
          ? (
            <EapModal
              modalTitle="OVERRIDE PASSWORD"
              showModal
            >
              <ExamineePasswordOverrideForm
                name={`${showModal.data.firstName} ${showModal.data.lastName}`}
                onCancel={() => setShowModal({ type: '', data: {} })}
                onOverride={(newPassword) => {
                  const {
                    data: {
                      username,
                      userId,
                    },
                  } = showModal;
                  overrideExamineePassword(userId, newPassword, username);
                  setShowModal({ type: '', data: {} });
                }}
              />
            </EapModal>
          ) : ''
      }
      {
        showModal.type === 'resetPassword'
          ? (
            <ConfirmationModal
              modalTitle="RESET PASSWORD"
              showModal
              messagePrompt={(
                <div>
                  <span>
                    Please confirm that you would like to reset
                    the password for the following signin:
                  </span>
                  <br />
                  <b> Name: {showModal.data.firstName} {showModal.data.lastName} </b>
                  <br />
                  <span>
                    Would you like to send a password reset request email to
                    <b> {showModal.data.email}</b>?
                  </span>
                </div>
              )}
              closeModal={() => setShowModal({ type: '', data: {} })}
              confirmAction={() => {
                resetPassword(showModal.data.email);
                setShowModal({ type: '', data: {} });
              }}
            />
          ) : ''
      }
      {
        showModal.type === 'move'
          ? (
            <EapModal
              modalTitle="MOVE USER"
              showModal
            >
              <MoveExaminee
                adminId={showModal.data.adminGroupId}
                adminGroups={adminGroups}
                examinee={showModal.data}
                moveExaminee={(adminId, examinee, newGroup) => {
                  const {
                    adminGroupId,
                    groupId,
                    userId,
                    userGroupId,
                    firstName,
                    lastName,
                  } = showModal.data;
                  moveExaminee(
                    adminGroupId,
                    {
                      id: userId,
                      userGroupId,
                      firstName,
                      lastName,
                    },
                    groupId,
                    newGroup
                  );
                  setShowModal({ type: '', data: {} });
                }}
                moveError={moveError}
                closeModal={() => {
                  setShowModal({ type: '', data: {} });
                }}
              />
            </EapModal>
          ) : ''
      }
      {
        showModal.type === 'enterMsd'
          ? (
            <EnterMSD
              showModal
              closeModal={() => setShowModal({ type: '', data: {} })}
              testId={showModal.data.assignedProductId}
              groupId={showModal.data.groupId}
              adminGroupId={showModal.data.adminGroupId}
              firstName={showModal.data.firstName}
              lastName={showModal.data.lastName}
              gender={showModal.data.gender}
              grade={showModal.data.grade}
            />
          ) : ''
      }
      {
        showModal.type === 'release'
          ? (
            <ReleaseResultsModal
              firstName={showModal.data.firstName}
              lastName={showModal.data.lastName}
              productName={showModal.data.productName}
              releaseResults={() => releaseResults(showModal.data)}
              closeModal={() => setShowModal({ type: '', data: {} })}
            />
          ) : ''
      }
    </>
  );
};

export default AllExaminees;
