import React from 'react';
import PropTypes from 'prop-types';

import EapModal from '../../components/EapModal';

import ExamineeInviteForm from './ExamineeInviteForm';

const ExamineeInviteDialog = ({
  showModal, availableProducts, sendInvites, closeModal
}) => (
  <EapModal
    modalTitle="Invite New Examinee"
    showModal={showModal}
  >
    <ExamineeInviteForm
      availableProducts={availableProducts}
      onCloseDialog={closeModal}
      sendInvites={(validEmailList, selectedProductId) => {
          sendInvites(validEmailList, selectedProductId);
          }}
    />
  </EapModal>
);

ExamineeInviteDialog.propTypes = {
  availableProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
  showModal: PropTypes.bool.isRequired,
  sendInvites: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ExamineeInviteDialog;
