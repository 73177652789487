import React from 'react';
import PropTypes from 'prop-types';

import EapModal from '../components/EapModal';
import ProductTransferForm from '../components/ProductTransferForm';

const ProductTransferDialog = ({
  dialogTitle,
  showDialog,
  isPurchaseTransfer,
  availableAmount,
  groups,
  handleClose,
  transferProducts
}) => (
  <EapModal
    modalTitle={dialogTitle}
    showModal={showDialog}
  >
    <ProductTransferForm
      isPurchaseTransfer={isPurchaseTransfer}
      availableAmount={availableAmount}
      groups={groups}
      closeModal={handleClose}
      transferProducts={transferProducts}
    />
  </EapModal>
);

ProductTransferDialog.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  showDialog: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  availableAmount: PropTypes.number.isRequired,
  transferProducts: PropTypes.func.isRequired,
  isPurchaseTransfer: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

ProductTransferDialog.defaultProps = {
  isPurchaseTransfer: false
};

export default ProductTransferDialog;
