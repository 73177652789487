import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  init,
  refreshToken,
  saveToken,
  getHooks,
  setHooks,
  removeHook,
} from '../../actions/squarespace';

import SquarespaceComponent from './SquarespaceComponent';

const mapStateToProps = (state) => {
  const {
    squarespace: {
      tokenState,
      hooks,
      uri,
    },
  } = state;
  return {
    tokenState,
    uri,
    hooks,
  };
};

const mapDispatchToProps = (dispatch) => ({
  init: () => {
    dispatch(init());
  },
  saveToken: (data) => {
    dispatch(saveToken(data));
  },
  refresh: () => {
    dispatch(refreshToken());
  },
  loadHooks: () => {
    dispatch(getHooks());
  },
  setHooks: (data) => {
    dispatch(setHooks(data));
  },
  removeHook: ({ id }) => {
    dispatch(removeHook({ id }));
  },
});

const SquarespaceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SquarespaceComponent));

export default SquarespaceContainer;
