import _ from 'lodash';

import {
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,
  CREATE_ORGANIZATION_REQUEST,
  LOAD_ORGANIZATION_SUPER_SUCCESS,
  LOAD_ORGANIZATION_SUPER_FAILURE,
  UPDATE_ORGANIZATION_SUPER_SUCCESS,
  UPDATE_ORGANIZATION_SUPER_FAILURE,
  ORG_FILTER_SUCCESS,
} from '../actions/organization';

import {
  LOAD_GROUP_SUPER_SUCCESS
} from '../actions/group';

import {
  SUPER_LOAD_USER_SUCCESS
} from '../actions/user';

const organizationReducer = (
  state = {
    createError: undefined,
    list: [],
    serverFiltered: []
  },
  action
) => {
  switch (action.type) {
    case CREATE_ORGANIZATION_REQUEST: {
      return {
        ...state,
        creating: true
      };
    }
    case CREATE_ORGANIZATION_SUCCESS: {
      const list = _.cloneDeep(state.list);
      list.push(action.payload);
      return {
        ...state,
        createError: undefined,
        creating: false,
        list: _.uniqBy(list, 'id')
      };
    }
    case CREATE_ORGANIZATION_FAILURE: {
      return {
        ...state,
        createError: action.payload.error,
        creating: false
      };
    }
    case LOAD_ORGANIZATION_SUPER_SUCCESS: {
      const list = _.cloneDeep(state.list);
      list.push({
        ...action.payload.organizationData
      });
      return {
        ...state,
        list: _.uniqBy(list, 'id')
      };
    }
    case LOAD_ORGANIZATION_SUPER_FAILURE: {
      return { ...state, organizationError: action.payload.error };
    }
    case UPDATE_ORGANIZATION_SUPER_SUCCESS: {
      const {
        updatedOrganizationInfo: newInfo
      } = action.payload;
      const list = _.cloneDeep(state.list);
      const toUpdatePos = _.findIndex(list, { id: newInfo.id });
      list[toUpdatePos] = {
        ...list[toUpdatePos],
        ...newInfo
      };
      return {
        ...state,
        list,
        organizationEditError: undefined
      };
    }
    case UPDATE_ORGANIZATION_SUPER_FAILURE: {
      return { ...state, organizationEditError: action.payload.error };
    }
    case ORG_FILTER_SUCCESS: {
      return { ...state, serverFiltered: action.payload };
    }
    case LOAD_GROUP_SUPER_SUCCESS: {
      const list = _.cloneDeep(state.list);
      list.push(action.payload.organization);
      return { ...state, list: _.uniqBy(list, 'id') };
    }
    case SUPER_LOAD_USER_SUCCESS: {
      const list = _.cloneDeep(state.list);
      _.each(action.payload.userData.examineeGroups, (g) => {
        list.push({
          ...g.organization
        });
      });
      return { ...state, list: _.uniqBy(list, 'id') };
    }
    default: {
      return { ...state };
    }
  }
};

export default organizationReducer;
