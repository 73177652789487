import { connect } from 'react-redux';

import PasscodeRegenerationDialog from './PasscodeRegenerationDialog';
import { generateNewPasscodeAsSuper } from '../actions/availableProducts';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = dispatch => ({
  generateNewPasscode: (availableProductId, groupId) => {
    dispatch(generateNewPasscodeAsSuper(availableProductId, groupId));
  }
});

const PasscodeRegenerationDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasscodeRegenerationDialog);

export default PasscodeRegenerationDialogContainer;
