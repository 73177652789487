import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { DynamicEapTable } from '../../../components/DynamicEapTable';
import EapDisplayDate from '../../../components/EapDisplayDate';
import EapModal from '../../../components/EapModal';
import AdminIcon from '../../../components/AdminIcon';
import AdminIconList from '../../../components/AdminIconList';
import OrganizationEditor from './OrganizationEditor';
import calculateActionsWidth from '../../../utils/EapTableHelper';

export default class OrganizationsTable extends Component {
  constructor() {
    super();
    this.state = {
      selectedOrgId: undefined
    };
  }

  render() {
    const {
      orginzations,
      fetchData
    } = this.props;

    if (!orginzations) {
      fetchData();
    }

    const columns = [
      {
        Header: 'ID',
        accessor: 'customId',
        Cell: ({ row }) => (
          <div className="actions">
            <Link
              to={`/super/organization/${row.original.id}/info`}
              style={{ textDecoration: 'none' }}
            >
              {row.original.customId}
            </Link>
          </div>
        )
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Has Subscription',
        accessor: 'hasSubscription',
        Cell: ({ row }) => (
          <span> {(row.original.hasSubscription) ? 'True' : 'False' } </span>
        )
      },
      {
        Header: 'Renewal Date',
        accessor: 'renewalDate',
        disableFilters: true,
        Cell: ({ row }) => (row.original.renewalDate ? (
          <EapDisplayDate date={row.original.renewalDate} />) : '')
      },
      {
        Header: 'Subscription Start Date',
        accessor: 'subscriptionStartDate',
        disableFilters: true,
        Cell: ({ row }) => (row.original.subscriptionStartDate ? (
          <EapDisplayDate date={row.original.subscriptionStartDate} />) : '')
      },
      {
        Header: 'Date Created',
        accessor: 'createdAt',
        disableFilters: true,
        Cell: ({ row }) => (row.original.createdAt ? (
          <EapDisplayDate date={row.original.createdAt} />) : '')
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableFilters: true,
        disableSortBy: true,
        maxWidth: calculateActionsWidth({ numberOfIcons: 2 }),
        Cell: ({ row }) => (
          <AdminIconList>
            <AdminIcon
              url={`/super/organization/${row.original.id}/info`}
              icon="fa-info-circle"
              toolTip="View"
            />
            <AdminIcon
              onClick={() => this.setState({ selectedOrgId: row.original.id })}
              icon="fa-pencil"
              toolTip="Edit"
            />
            <EapModal
              modalTitle="Edit Organization Information"
              showModal={this.state.selectedOrgId === row.original.id}
            >
              <OrganizationEditor
                organization={row.original}
                closeModal={() => this.setState({ selectedOrgId: undefined })}
                updateOrganizationInfo={this.props.updateOrganizationInfo}
                fromTable
              />
            </EapModal>
          </AdminIconList>
        )
      }
    ];
    return (
      <DynamicEapTable
        columns={columns}
        data={orginzations}
        fetchData={this.props.fetchData}
      />
    );
  }
}

OrganizationsTable.propTypes = {
  updateOrganizationInfo: PropTypes.func.isRequired
};
