import React from 'react';
import PropTypes from 'prop-types';
import EapStaticAlert from './EapStaticAlert';

const GeneralError = ({ reloadAllTheThings }) => (
  <div>
    <EapStaticAlert>
      <div>
        <h4>A general error occurred.</h4>
        <div>
          Contact EdITS support at this email support@edits.net or&nbsp;
          <a
            href="/"
            onClick={() => reloadAllTheThings()}
          >
            return to the home page.
          </a>
        </div>
      </div>
    </EapStaticAlert>
  </div>
);

GeneralError.propTypes = {
  reloadAllTheThings: PropTypes.func.isRequired
};
export default GeneralError;
