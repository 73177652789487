import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import PureSpinner from './PureSpinner';

import './EapButton.css';

const EapButton = ({
  text,
  onClick,
  isSecondary,
  isSubmit,
  disabled,
  optionalClass,
  faIcon,
  id,
  inProgress,
  style
}) => {
  const sizeClass = text.length > 10 ? 'eap-btn-primary--large' : 'eap-btn-primary--normal';
  const primaryOrSecondary = isSecondary ? 'eap-button-secondary' : 'eap-button-primary';
  return (
    <button
      style={style}
      className={`pure-button ${optionalClass} ${primaryOrSecondary} ${sizeClass}`}
      disabled={disabled}
      onClick={onClick}
      type={isSubmit ? 'submit' : 'button'}
      id={id}
    >
      <div className="pure-g">
        <span
          className={inProgress ? 'pure-u-3-4' : 'pure-u-1'}
          style={{ marginRight: '.2em' }}
        >
          {faIcon && <span className={`fa ${faIcon}`} style={{ marginRight: '.2em' }} /> }
          {text.toUpperCase()}
        </span>
        <span className="pure-u-1-6">
          { inProgress && <PureSpinner /> }
        </span>
      </div>
    </button>
  );
};

EapButton.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSubmit: PropTypes.bool,
  onClick: PropTypes.func,
  optionalClass: PropTypes.string,
  faIcon: PropTypes.string,
  inProgress: PropTypes.bool,
};

EapButton.defaultProps = {
  onClick: undefined,
  optionalClass: '',
  faIcon: undefined,
  disabled: false,
  isSecondary: false,
  isSubmit: false,
  inProgress: false,
  id: `button.${uuid()}`
};

export default EapButton;
