import React from 'react';
import PropTypes from 'prop-types';
import './EapThrone.css';

const EapThrone = ({ children, className }) => (<div className={`eap-throne ${className}`}>{children}</div>);

EapThrone.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

export default EapThrone;
