import { connect } from 'react-redux';

import Logout from './components/Logout';
import { logout } from '../actions/logout';

const mapStateToProps = () => ({ });

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

const LogoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);

export default LogoutContainer;
