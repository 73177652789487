import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { StaticEapTable, DefaultFilter } from '../../components/StaticEapTable';
import AdminIcon from '../../components/AdminIcon';
import AdminIconList from '../../components/AdminIconList';
import EapModal from '../../components/EapModal';
import EapButton from '../../components/EapButton';
import PureSpinner from '../../components/PureSpinner';
import { PurchaseFulfillment as Fulfillment } from '../../purchase';

const Purchases = ({
  purchases
}) => {
  const [purchase, setPurchase] = useState();

  const columns = [
    {
      Header: 'Type',
      accessor: 'type',
      Filter: DefaultFilter,
    },
    {
      Header: 'Group',
      accessor: 'groupName',
      Cell: ({ row, value }) => {
        if (value) {
          return (
            <Link to={`/group/${row.original.groupId}/information`}>
              {value}
            </Link>
          );
        }
        return '';
      }
    },
    {
      Header: 'Fulfillment',
      accessor: 'fulfillmentStatus',
      Cell: ({ row, value }) => {
        switch (value) {
          case 'Unfulfilled': {
            return (
              <EapButton
                type="button"
                optionalClass="link-button"
                onClick={() => setPurchase(row.original)}
                text="Fulfill Purchase"
              />
            );
          }
          case 'Fulfilling': {
            return (<span>Fulfilling <PureSpinner color="grey" /></span>);
          }
          default: {
            return 'Fulfilled';
          }
        }
      }
    },
    {
      Header: 'Date',
      accessor: 'updatedAt',
      Cell: ({ row }) => (
        row.original.updatedAt.format('MM/DD/YYYY')
      )
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => (
        <AdminIconList>
          <AdminIcon
            url={`/purchase/${row.original.uuid}`}
            icon="fa-info-circle"
            toolTip="Information"
          />
          {
            row.original.fulfillmentStatus === 'Fulfilled'
              ? ''
              : (
                <AdminIcon
                  onClick={() => {
                    setPurchase(row.original);
                  }}
                  icon="fa-share-square-o"
                  toolTip="FulFill"
                />
              )
          }
        </AdminIconList>
      ),
    }
  ];

  if (!purchases || purchases.length === 0) {
    return (
      <p>
        You have not refilled any products. Click on the dollar
        sign symbol next to a product in a group to refill your
        products.
      </p>
    );
  }
  return (
    <>
      <StaticEapTable
        columns={columns}
        data={purchases}
      />
      <EapModal
        modalTitle="Fulfill Order"
        showModal={purchase !== undefined}
      >
        <Fulfillment
          purchase={purchase}
          onClose={() => setPurchase(undefined)}
        />
      </EapModal>
    </>
  );
};

export default Purchases;
