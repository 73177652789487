import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TabMenuItem from './TabMenuItem';

// Currently only works with 5 items or less
const NavigationBar = ({ routes, base }) => (
  <div className="pure-menu pure-menu-horizontal eap-admin-menu">
    <ul className="pure-menu-list pure-u-1-1">
      {
        _.map(routes, (route) => (
          <TabMenuItem
            key={`${base}-${route.path}`}
            link={`/${base}/${route.path}`}
            text={route.text !== undefined ? (
              route.text
            ) : (
              _.upperFirst(route.path.replace(/-/g, ' ').replace(/\w\S*/g, (foundString) => (
                foundString.charAt(0).toUpperCase() + foundString.substr(1).toLowerCase()
              )))
            )}
            icon={route.icon}
            size={`pure-u-1-${routes.length}`}
          />
        ))
    }
    </ul>
  </div>
);

NavigationBar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
  })).isRequired,
  base: PropTypes.string
};

NavigationBar.defaultProps = {
  base: ''
};

export default NavigationBar;
