import React from 'react';
import PropTypes from 'prop-types';
import url from 'url';
import './Products.css';

const GenericProductLink = ({
  text, assignedProductId, baseUrl, clickHandler, isAdmin
}) => {
  let icon = 'fa-play-circle';
  if (text === 'New') {
    icon = 'fa-asterisk';
  }
  if (text === 'Resume') {
    icon = 'fa-play-circle';
  }
  if (text === 'Results') {
    icon = 'fa-bar-chart';
  }
  if (text === 'Pending') {
    icon = 'fa-line-chart';
  }
  let link = url.resolve(baseUrl, `${assignedProductId}`);
  let trgt = '';
  if (isAdmin) {
    link += '?admin=true';
    trgt = '__blank';
  }
  if (clickHandler) {
    return (
      <a
        href="#getInfo"
        onClick={(e) => { e.preventDefault(); clickHandler(e); }}
      >
        <span className={`product-icon fa ${icon}`} />
        {text}
      </a>
    );
  }
  return (<a href={link} target={trgt}><span className={`product-icon fa ${icon}`} /> {text}</a>);
};

GenericProductLink.propTypes = {
  text: PropTypes.string.isRequired,
  assignedProductId: PropTypes.number.isRequired,
  baseUrl: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
  isAdmin: PropTypes.bool,
};

GenericProductLink.defaultProps = {
  isAdmin: false,
  clickHandler: undefined
};

export default GenericProductLink;
