import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './ResourceLink.css';

const ResourceLink = ({ toRoute, displayValue }) => (
  <div className="actions">
    <Link
      to={toRoute}
      style={{ textDecoration: 'none' }}
    >
      <strong>{displayValue}</strong>
    </Link>
  </div>
);

ResourceLink.propTypes = {
  toRoute: PropTypes.string.isRequired,
  displayValue: PropTypes.string.isRequired
};

export default ResourceLink;
