/* eslint react/destructuring-assignment:0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import EapModal from '../../../components/EapModal';
import EapButton from '../../../components/EapButton';
import SectionHeader from '../../components/SectionHeader';
import GroupDetailItem from '../../../group/components/GroupDetailItem';
import GroupDetailEditorContainer from '../../../group/GroupDetailEditorContainer';
import GroupProductsTableContainer from '../GroupProductsTableContainer';
import ProductLogTable from './ProductLogTable';
import GroupAdministratorsTableContainer from '../GroupAdministratorsTableContainer';
import GroupExamineesTableContainer from '../GroupExamineesTableContainer';
import GroupAdminInvitesTableContainer from '../GroupAdminInvitesTableContainer';
import GroupExamineeInvitesTableContainer from '../GroupExamineeInvitesTableContainer';
import AssignedProductsTable from '../../commonTables/AssignedProducts';

import './GroupView.css';

class GroupView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false
    };
  }

  componentDidMount() {
    this.props.load();
  }

  render() {
    const {
      group,
      organization,
    } = this.props;

    const {
      editing
    } = this.state;

    return (
      <div>
        <div className="pure-u-1">
          <div className="pure-u-2-3">
            <SectionHeader title="Group Information" />
          </div>
          <div className="pure-u-1-3 bulk-examinee-link">
            <span className="fa fa-users" />
            <Link to={`/super/group/${group.id}/examinees`}>
              Bulk upload examinees
            </Link>
          </div>
        </div>
        <GroupDetailItem headerText="GROUP NAME">
          {group.name}
        </GroupDetailItem>
        <GroupDetailItem headerText="DESCRIPTION">
          {group.description}
        </GroupDetailItem>
        <GroupDetailItem headerText="ORGANIZATION">
          <Link to={`/super/organization/${organization.id}/info`}>
            Organization: {organization.name} ({organization.customId})
          </Link>
        </GroupDetailItem>

        <EapButton
          text="EDIT"
          onClick={() => this.setState({ editing: true })}
        />
        <EapModal
          modalTitle="EDIT GROUP"
          showModal={editing}
        >
          <GroupDetailEditorContainer
            group={group}
            closeModal={() => this.setState({ editing: false })}
          />
        </EapModal>

        <SectionHeader title="Products" />
        <GroupProductsTableContainer />

        <SectionHeader title="Administrators" />
        <GroupAdministratorsTableContainer match={this.props.match} />

        <SectionHeader title="Examinees" />
        <GroupExamineesTableContainer match={this.props.match} />

        <SectionHeader title="Assigned Products" />
        <AssignedProductsTable
          data={this.props.assignedProducts}
          revoke={this.props.revokeAssignedProduct}
          releaseResults={this.props.releaseResults}
          suppressResults={this.props.suppressResults}
        />

        <SectionHeader title="Admin Invites" />
        <GroupAdminInvitesTableContainer match={this.props.match} />

        <SectionHeader title="Examinee Invites" />
        <GroupExamineeInvitesTableContainer match={this.props.match} />

        <SectionHeader title="Product (Audit) Log" />
        <ProductLogTable
          productLog={this.props.productLog}
        />
      </div>
    );
  }
}

GroupView.propTypes = {
  load: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number
  }),
  group: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number
  }).isRequired,
  assignedProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    groupId: PropTypes.number,
    organizationId: PropTypes.string,
    groupName: PropTypes.string,
    productName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    status: PropTypes.string
  })),
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string.isRequired
    })
  }).isRequired,
};

GroupView.defaultProps = {
  organization: {},
  assignedProducts: []
};

export default GroupView;
