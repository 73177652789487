import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

import EapButton from './EapButton';
import EapSelectList from './EapSelectList';
import InlineValidationMessage from './InlineValidationMessage';

import { httpClient } from '../utils/httpClient';

import './AddGroup.css';

const AddGroup = ({ requireAdmins, groups, submit }) => {
  const validate = (values) => {
    if (_.isEmpty(values.name.trim())) {
      return {
        name: 'Please enter a name.'
      };
    }
    return httpClient
      .get(`/group/exists/${values.name}`)
      .then((response) => {
        const errors = {};
        if (response.data.exists) {
          errors.name = 'This name is taken.';
        }
        return errors;
      });
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      admins: 0,
      copyAdmins: true
    },
    validate,
    onSubmit: (values) => {
      const submitValues = {
        name: values.name,
        description: values.description,
        sourceGroup: values.copyAdmins ? groups[values.admins] : undefined
      };
      submit(submitValues);
      formik.resetForm();
    }
  });

  return (
    <div className="add-group pure-u-sm-1-2 pure-u-md-1-3 pure-u-1">
      <p>
        Please enter information below to create a group. Marked fields (
        <span className="required">*</span>) are required.
      </p>
      <form className="pure-form pure-form-stacked eap-grey-form" onSubmit={formik.handleSubmit}>
        <fieldset>
          <label htmlFor="name"> NAME <span className="required"> *</span></label>
          <input
            type="text"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {formik.errors.name ? <InlineValidationMessage message={formik.errors.name} /> : null}
          <label htmlFor="description">
           DESCRIPTION
          </label>
          <input
            id="description"
            name="description"
            type="text"
            value={formik.values.description}
            onChange={formik.handleChange}
          />
          <label htmlFor="groupAdmins">
            COPY ADMINISTRATORS FROM
            { (requireAdmins) ? <span className="required"> *</span> : '' }
          </label>
          <EapSelectList
            id="groupAdmins"
            name="admins"
            value={formik.values.admins}
            onChange={formik.handleChange}
            choices={
              groups.map((group, index) => ({
                value: index, label: group.name
              }))
            }
          />
          {
            (!requireAdmins) ? (
              <label htmlFor="copyAdmins" className="pure-checkbox">
                <input
                  id="copyAdmins"
                  name="copyAdmins"
                  type="checkbox"
                  value={formik.values.copyAdmins}
                  checked={formik.values.copyAdmins}
                  onChange={formik.handleChange}
                  style={{ width: 'auto' }}
                /> Copy admins
              </label>
            ) : ''
          }
          <div className="pure-u-1-2">
            <EapButton
              optionalClass="group-button"
              text="ADD GROUP"
              isSubmit
            />
          </div>
          {
            (requireAdmins) ? (
              <div className="pure-u-1-2">
                <Link
                  className="add-grp-cancel-link"
                  to="/dashboard/groups"
                >
                  <EapButton
                    optionalClass="group-button"
                    onClick={() => {}}
                    text="CANCEL"
                  />
                </Link>
              </div>
            ) : ''
          }
        </fieldset>
      </form>
    </div>
  );
};

AddGroup.propTypes = {
  submit: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
  requireAdmins: PropTypes.bool,
};

AddGroup.defaultProps = {
  requireAdmins: true,
};

export default AddGroup;
