import { connect } from 'react-redux';
import { activateSignin } from './actions/activate';
import Activator from './Activator';

const mapStateToProps = (state) => {
  if (!state.user) {
    return {};
  }
  return {
    user: state.user,
    error: state.activator.error
  };
};
const mapDispatchToProps = dispatch => ({
  activateUser: (userInfo) => {
    dispatch(activateSignin(userInfo));
  }
});

const ActivatorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Activator);

export default ActivatorContainer;
