import React from 'react';
import PropTypes from 'prop-types';

import './InlineValidationMessage.css';

const InlineValidationMessage = ({ message, ...props }) =>
  <div className="eap-validation-message" {...props} >{message}</div>;

InlineValidationMessage.propTypes = {
  message: PropTypes.string
};

InlineValidationMessage.defaultProps = {
  message: undefined
};

export default InlineValidationMessage;
