import { httpClient } from '../utils/httpClient';

export const PRODUCT_LOG_RECEIVED = 'PRODUCT_LOG_RECIEVED';

export const productLogReceived = (groupId, productLogEntries) => ({
  type: PRODUCT_LOG_RECEIVED,
  payload: {
    groupId,
    productLogEntries
  }
});

export function loadProductLog(groupId) {
  return (dispatch) => {
    httpClient
      .get(`/super/group/${groupId}/productLog`)
      .then((response) => {
        dispatch(productLogReceived(groupId, response.data.productLog));
      }).catch((err) => console.error('ERR on get product log!', err));
  };
}
