import _ from 'lodash';

import {
  ADD_ADMIN_SUCCESS,
  DELETE_ADMIN_SUCCESS,
  UPDATE_ADMIN_PERMISSIONS_SUCCESS,
  SUPER_LOAD_GROUP_ADMINS_SUCCESS,
} from '../actions/admins';

import {
  SUPER_LOAD_USER_SUCCESS
} from '../actions/user';

const adminsReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case SUPER_LOAD_GROUP_ADMINS_SUCCESS: {
      const list = _.cloneDeep(state.list);
      action.payload.admins.forEach((admin) => {
        list.push({
          id: admin.adminGroupId,
          adminGroupId: admin.adminGroupId,
          userId: admin.id,
          groupId: action.payload.groupId,
          per_admin_edit: admin.per_admin_edit,
          per_admin_invite: admin.per_admin_invite,
          per_admin_remove: admin.per_admin_remove,
          per_admin_view: admin.per_admin_view,
          per_create: admin.per_create,
          per_products_assign: admin.per_products_assign,
          per_products_transfer: admin.per_products_transfer,
          per_products_view: admin.per_products_view,
          per_users_invite: admin.per_users_invite,
          per_users_move: admin.per_users_move,
          per_users_view: admin.per_users_view
        });
      });
      return {
        ...state,
        list: _.uniqBy(list, 'adminGroupId')
      };
    }
    case UPDATE_ADMIN_PERMISSIONS_SUCCESS: {
      const list = _.cloneDeep(state.list);
      const updated = _.find(list, { adminGroupId: action.payload.admin.adminGroupId });
      _.merge(
        updated,
        _.pick(action.payload.newPermissions, [
          'per_admin_edit',
          'per_admin_invite',
          'per_admin_remove',
          'per_admin_view',
          'per_create',
          'per_products_assign',
          'per_products_transfer',
          'per_products_view',
          'per_users_invite',
          'per_users_move',
          'per_users_view'
        ])
      );
      return {
        ...state,
        list
      };
    }
    case ADD_ADMIN_SUCCESS: {
      const list = _.cloneDeep(state.list);
      const added = _.pick(action.payload.newAdmin, [
        'id',
        'adminGroupId',
        'userId',
        'per_admin_edit',
        'per_admin_invite',
        'per_admin_remove',
        'per_admin_view',
        'per_create',
        'per_products_assign',
        'per_products_transfer',
        'per_products_view',
        'per_users_invite',
        'per_users_move',
        'per_users_view'
      ]);
      added.id = action.payload.newAdmin.adminGroupId;
      added.groupId = _.parseInt(action.payload.groupId);
      list.push(added);
      return {
        ...state,
        list,
        errorMessage: undefined
      };
    }
    case DELETE_ADMIN_SUCCESS: {
      const list = _.cloneDeep(state.list);
      const { adminGroupId } = action.payload.subjectAdmin;
      _.remove(list, { adminGroupId });
      return {
        ...state,
        list
      };
    }
    case SUPER_LOAD_USER_SUCCESS: {
      const list = _.cloneDeep(state.list);
      _.each(action.payload.userData.adminGroups, (group) => {
        const rtn = { ...group, adminGroupId: group.id };
        delete rtn.group;
        list.push(rtn);
      });
      return {
        ...state,
        list: _.uniqBy(list, 'id')
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default adminsReducer;
