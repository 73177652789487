import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import EapGeneralHeader from '../../components/EapGeneralHeader';

const GroupTabHeader = ({ headingText, groupName }) => (
  <EapGeneralHeader
    text={`${_.toUpper(headingText)}: ${groupName}`}
  />
);

GroupTabHeader.propTypes = {
  headingText: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired
};

export default GroupTabHeader;
