import { connect } from 'react-redux';

import AdminInvites from './components/AdminInvites';
import { getAdminGroup } from '../utils/AdminGroupHelper';
import { getPermissionString } from '../components/PermissionSelector';

import {
  revokeAdminInvite,
  sendAdminInvites,
  resendAdminInvite
} from '../actions/adminInvites';

const mapStateToProps = (state, ownProps) => {
  if (!state.user.permissions.administrator) {
    return {
      unauthorized: true
    };
  }
  if (state.group.loading) {
    return {
      unauthorized: false,
      group: { loading: true }
    };
  }
  if (state.group.message) {
    return {
      unauthorized: false,
      group: {
        loading: false,
        message: state.group.message
      }
    };
  }

  const actorAdmin = getAdminGroup(ownProps.groupId, state);
  const selectedGroup = state.group.groupsById[ownProps.groupId];


  return {
    actorAdmin,
    invites: selectedGroup.adminInvites.map((invite) => ({
      ...invite,
      permissionString: getPermissionString(invite),
      ...{ status: invite.status || 'Sent' }
    })),

  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  revokeInvite: (adminGroupId, inviteRowData) => {
    dispatch(revokeAdminInvite(adminGroupId, ownProps.groupId, inviteRowData));
  },
  sendInvites: (adminGroupId, emailList, inviteData) => {
    dispatch(sendAdminInvites(adminGroupId, ownProps.groupId, emailList, inviteData));
  },
  resendInvite: (adminGroupId, inviteId) => {
    dispatch(resendAdminInvite(adminGroupId, inviteId));
  }
});

const AdminInvitesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminInvites);

export default AdminInvitesContainer;
