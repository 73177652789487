import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './Form.css';
import EapSelectList from '../components/EapSelectList';

export default class Dropdown extends Component {
  constructor() {
    super();
    this.state = {
      isValid: true
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();
    let { value } = e.target;
    if (value !== '' && this.props.isNumber) {
      value = parseInt(value, 10);
    }
    this.setState({
      isValid: (this.props.required) ? !(value === '') : true
    });
    this.props.update({
      isValid: (this.props.required) ? !(value === '') : true,
      keyName: this.props.keyName,
      value: (value === '') ? undefined : value
    });
  }

  render() {
    let asterisk = '';
    if (this.props.required) {
      asterisk = (<span className="required"> *</span>);
    }
    return (
      <div>
        { this.props.addLabel ? (
          <label htmlFor={this.props.keyName}>
            {this.props.boldLabel ?
              <b>{this.props.labelName}</b>
              : this.props.labelName
            }
            {asterisk}
          </label>
          ) : ''
        }
        <EapSelectList
          id={this.props.keyName}
          name={this.props.keyName}
          value={this.props.value || ''}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          choices={this.props.choices}
          className={this.props.selectStyle}
        />
        {
          (this.state.isValid) ? '' :
          <div className="validation-message">
            Please select a {_.toLower(this.props.labelName)}
          </div>
        }
      </div>
    );
  }
}

Dropdown.propTypes = {
  boldLabel: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  addLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  update: PropTypes.func.isRequired,
  keyName: PropTypes.string,
  isNumber: PropTypes.bool,
  labelName: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })),
  selectStyle: PropTypes.string
};

Dropdown.defaultProps = {
  selectStyle: undefined,
  boldLabel: false,
  value: undefined,
  addLabel: false,
  disabled: false,
  required: false,
  keyName: 'grade',
  isNumber: false,
  labelName: undefined,
  choices: []
};
