import React from 'react';
import { Link } from 'react-router-dom';
import EditsLogo from './EditsLogo';
import './Banner.css';

const PublicBanner = () => (
  <div className="eap-public-banner" role="banner">
    <Link to="/signin" className="">
      <EditsLogo />
    </Link>
    <h1 className=""><span className="screen-reader">Edits</span> Assessment Platform</h1>
  </div>
);

export default PublicBanner;
