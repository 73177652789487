import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from './Dropdown';
import './Form.css';

const GenderDropdown = ({
  formGroupStyle,
  keyName,
  selectStyle,
  value,
  disabled,
  update,
  addLabel,
  boldLabel,
  required
}) => (
  <div className={formGroupStyle}>
    <Dropdown
      keyName={keyName}
      selectStyle={selectStyle}
      value={value}
      disabled={disabled}
      update={update}
      addLabel={addLabel}
      boldLabel={boldLabel}
      labelName="GENDER"
      required={required}
      choices={[
        { value: '', label: '' },
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' }
      ]}
    />
  </div>
);

export default GenderDropdown;

GenderDropdown.propTypes = {
  boldLabel: PropTypes.bool,
  value: PropTypes.string,
  addLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  update: PropTypes.func.isRequired,
  keyName: PropTypes.string,
  formGroupStyle: PropTypes.string,
  selectStyle: PropTypes.string
};

GenderDropdown.defaultProps = {
  boldLabel: false,
  value: undefined,
  addLabel: false,
  disabled: false,
  required: false,
  keyName: 'gender',
  formGroupStyle: 'pure-u-lg-1-2 pure-u-md-1-2 pure-u-1',
  selectStyle: 'pure-u-11-12'
};
