import { connect } from 'react-redux';

import Purchase from '../components/PurchaseComponent';

const mapStateToProps = ({
  purchases: {
    purchases,
  },
}) => ({
  purchases,
});

const mapDispatchToProps = () => ({});

const PurchaseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Purchase);

export default PurchaseContainer;
