import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import Banner from './components/Banner';
import MainMenuBar from './MainMenuBar';
import ActivatorContainer from './ActivatorContainer';
import { initClientUser } from './actions/user';
import { checkHeartbeat } from './actions/heartbeat';
import ProgressSpinner from './components/ProgressSpinner';
import Footer from './Footer';
import AlertContainer from './components/AlertContainer';
import SensibleContainer from './components/SensibleContainer';

const heartbeatIntervalInSeconds = 60 * 1000;

class Authorized extends Component {
  constructor() {
    super();
    this.topRef = React.createRef();
    this.contentRef = React.createRef();
    this.state = {
      heartbeatTimerId: undefined,
      isStagingEnv: false,
    };
  }

  componentDidMount() {
    if (!this.props.user.loaded) {
      this.props.loadUserData();
    }
    const id = setInterval(() => this.props.checkHeartbeat(), heartbeatIntervalInSeconds);
    this.setState({
      heartbeatTimerId: id,
      isStagingEnv: window.location.host.includes('staging')
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.topRef.current.focus();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.heartbeatTimerId);
  }

  render() {
    const hasSuperPriviliges = this.props.user.permissions && this.props.user.permissions.super;
    let componentToLoad;
    if (this.props.user.loaded === 'complete' && this.props.user.isActive) {
      componentToLoad = this.props.children;
    } else if (this.props.user.loaded === 'complete') {
      componentToLoad = <ActivatorContainer />;
    } else {
      componentToLoad = <ProgressSpinner />;
    }
    return (
      // You have to double wrap due to an IE 11 bug
      <div className="flex-column">
        <div className="wrapper">
          <span className="screen-reader" tabIndex="-1" ref={this.topRef}>
            Page loaded.
            <button onClick={() => this.contentRef.current.focus()} >
              Jump to content.
            </button>
          </span>
          <Banner isStagingEnv={this.state.isStagingEnv} />
          <MainMenuBar isSuper={hasSuperPriviliges} />
          <div className="flex-column">
            <div className="pure-u-1">
              <SensibleContainer>
                <div className="pure-u-1">
                  <div className="pure-u-1" style={{ marginTop: '.25em' }}>
                    <AlertContainer />
                  </div>
                  <div className="pure-u-1">
                    <span className="screen-reader" ref={this.contentRef} tabIndex="-1">
                      <h1>Content</h1>
                    </span>
                    { componentToLoad }
                  </div>
                </div>
              </SensibleContainer>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

Authorized.propTypes = {
  children: PropTypes.element.isRequired,
  user: PropTypes.shape({
    loaded: PropTypes.string,
    username: PropTypes.string,
    isActive: PropTypes.bool,
    permissions: PropTypes.shape({ super: PropTypes.bool })
  }).isRequired,
  loadUserData: PropTypes.func.isRequired,
  checkHeartbeat: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-typos
  location: ReactRouterPropTypes.location.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  loadUserData: () => {
    dispatch(initClientUser());
  },
  checkHeartbeat: () => {
    checkHeartbeat();
  }
});

const AuthorizedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Authorized));

export default AuthorizedContainer;
