import React from 'react';
import Script from 'react-load-script';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import CheckoutWizard from './CheckoutWizard';
import FailedPaymentDisplay from './FailedPaymentDisplay';
import CheckoutPanel from './CheckoutPanel';
import WizardProgressBar from './WizardProgressBar';

import './Checkout.css';

class Checkout extends React.Component {
  constructor() {
    super();
    this.handleScriptError = this.handleScriptError.bind(this);
    this.handleScriptLoad = this.handleScriptLoad.bind(this);

    this.state = {
      scriptLoaded: false,
      step: 1,
      paymentFailedMessage: undefined,
      // paymentFailedMessage: 'BRO',
    };
  }

  componentDidMount() {
    const { stripePublicKey } = this.props;
    if (!stripePublicKey) {
      this.props.loadStripePublicKey();
    }
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }

  render() {
    const { scriptLoaded, paymentFailedMessage } = this.state;
    const { stripePublicKey } = this.props;

    return (
      <main className="flex-column " role="main">
        { !scriptLoaded && <span>Loading Stripe for payment processing</span>}
        <Script
          url="https://js.stripe.com/v3/"
          onCreate={this.handleScriptCreate}
          onError={this.handleScriptError}
          onLoad={this.handleScriptLoad}
        />
        <WizardProgressBar
          currentStep={this.state.step}
          paymentFailed={paymentFailedMessage !== undefined}
        />
        <div className="pure-u-11-12 checkout-container">
          <div>
            { this.state.scriptError && `STRIPE ERROR: ${this.state.scriptError}` }
          </div>
          <Route
            render={({ history, match: { url } }) => {
              if (paymentFailedMessage) {
                return (
                  <CheckoutPanel title="CARD NOT PROCESSED">
                    <FailedPaymentDisplay
                      message={paymentFailedMessage}
                      groupId={this.props.groupId}
                    />
                  </CheckoutPanel>
                );
              }
              return (
                <CheckoutWizard
                  history={history}
                  url={url}
                  groupId={this.props.groupId}
                  purchaserEmail={this.props.email}
                  availableProduct={this.props.availableProduct}
                  stripePublicKey={stripePublicKey}
                  stripeScriptLoaded={scriptLoaded}
                  onStepChange={(nextStep) => this.setState({ step: nextStep })}
                  onPaymentFailed={(errMessage) => {
                    this.setState({ paymentFailedMessage: errMessage });
                    const paymentFailureUrl = url.concat('/payment-failed');
                    history.push(paymentFailureUrl);
                  }}
                  refill={this.props.refill}
                  price={11}
                />
              );
            }}
          />
        </div>
      </main>
    );
  }
}

Checkout.propTypes = {
  groupId: PropTypes.number.isRequired,
  availableProduct: PropTypes.shape({
    adminName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  }).isRequired,
  email: PropTypes.string.isRequired,
  stripePublicKey: PropTypes.string,
  loadStripePublicKey: PropTypes.func.isRequired
};

Checkout.defaultProps = {
  stripePublicKey: null
};

export default Checkout;
