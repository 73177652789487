import { connect } from 'react-redux';
import GroupResultsTable from './components/GroupResultsTable';
import * as AssignedProductsActions from '../actions/assignedProducts';
import extractAndSortPermissions from '../utils/sortPermissions';

import {
  filterResults,
} from '../actions/results';

const mapStateToProps = (state, ownProps) => {
  if (!state.user.permissions.administrator) {
    return {};
  }
  if (state.group.loading) {
    return {
      group: { loading: true }
    };
  }
  if (state.group.message) {
    return {
      group: {
        loading: false,
        message: state.group.message
      }
    };
  }

  const selectedGroup = state.group.groupsById[ownProps.groupId];

  const adminPermissions = extractAndSortPermissions(state.user.adminGroups, ownProps.groupId);

  return {
    assignedProducts: state.assignedProducts,
    results: state.results.serverFiltered,
    totalResults: state.results.serverTotal,
    group: selectedGroup,
    permissions: {
      id: adminPermissions.id,
      ...adminPermissions.users
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadResults: (groupId, params) => {
    params.filtered.push({
      id: 'groupId',
      value: groupId,
    });
    dispatch(filterResults(params));
  },
  releaseResults: (params) => {
    dispatch(AssignedProductsActions.releaseResults(params));
  }
});

const GroupResultsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupResultsTable);

export default GroupResultsContainer;
