import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import WizardButtonBar from './WizardButtonBar';
import DisplayPrice from './DisplayPrice';
import { RefillError } from '../../actions/purchase';

import './PurchaseReviewForm.css';

class PurchaseReviewForm extends React.Component {
  constructor() {
    super();
    this.state = {
      purchaseError: undefined
    };
  }

  render() {
    const brand = get(this.props.stripeToken, 'card.brand');
    const last4 = get(this.props.stripeToken, 'card.last4');
    const purchaseTotal = this.props.quantity * this.props.product.price;
    if (!this.props.stripeToken) {
      this.props.onBackButtonClicked();
    }
    return (
      <div className="pure-u-1">
        <div>
          { this.state.purchaseError &&
          <div>
            ERROR : {this.state.purchaseError}
          </div>
          }
          <div><b>REVIEW YOUR PURCHASE</b></div>
          <div>
            <b>Product:</b> { this.props.product.name}
          </div>
          <div>
            <b>Quantity:</b> { this.props.quantity }
          </div>
          <div>
            <b>Payment Method:</b> { `${brand} Last 4 digits ...${last4}` }
          </div>
          <div>
            <b>Receipt email:</b> { this.props.email }
            { this.props.optionalEmail && ` and ${this.props.optionalEmail}` }
          </div>
          <div className="payment-summary-line-item">
            <b>Total cost:</b> <DisplayPrice amount={purchaseTotal} />
          </div>
        </div>
        <WizardButtonBar
          overrideNextText="CONFIRM"
          next={() => {
            const {
              quantity,
              product,
              groupId,
              stripeToken,
              optionalEmail,
              next,
              refill,
              onPaymentFailure,
            } = this.props;
            const amount = quantity * product.price;
            refill({
              product,
              groupId,
              quantity,
              amount,
              stripeToken: stripeToken.id,
              optionalEmail,
            })
              .then(() => { next(); })
              .catch((err) => {
                if (err instanceof RefillError) {
                  onPaymentFailure(err.message);
                }
              });
          }}
          previous={this.props.previous}
        />
      </div>
    );
  }
}

PurchaseReviewForm.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number
  }).isRequired,
  groupId: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  optionalEmail: PropTypes.string,
  stripeToken: PropTypes.shape({
    id: PropTypes.string.isRequired,
    card: PropTypes.shape({
      brand: PropTypes.string.isRequired
    })
  }).isRequired,
  onPaymentFailure: PropTypes.func.isRequired,
  onBackButtonClicked: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired
};

PurchaseReviewForm.defaultProps = {
  optionalEmail: undefined
};

export default PurchaseReviewForm;
