import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './PermissionSelector.css';
import EapSelectList from './EapSelectList';

import InlineValidationMessage from './InlineValidationMessage';

export const RECOMMENDED = {
  per_users_view: true,
  per_users_invite: true,
  per_users_move: true,
  per_products_view: true,
  per_products_transfer: false,
  per_products_assign: true,
  per_admin_view: true,
  per_admin_invite: false,
  per_admin_remove: false,
  per_admin_edit: false,
  per_create: false
};

export const FULL = {
  per_users_view: true,
  per_users_invite: true,
  per_users_move: true,
  per_products_view: true,
  per_products_transfer: true,
  per_products_assign: true,
  per_admin_view: true,
  per_admin_invite: true,
  per_admin_remove: true,
  per_admin_edit: true,
  per_create: true
};

export const VIEW = {
  per_users_view: true,
  per_users_invite: false,
  per_users_move: false,
  per_products_view: true,
  per_products_transfer: false,
  per_products_assign: false,
  per_admin_view: true,
  per_admin_invite: false,
  per_admin_remove: false,
  per_admin_edit: false,
  per_create: false
};

export const EMPTY = {
  per_users_view: false,
  per_users_invite: false,
  per_users_move: false,
  per_products_view: false,
  per_products_transfer: false,
  per_products_assign: false,
  per_admin_view: false,
  per_admin_invite: false,
  per_admin_remove: false,
  per_admin_edit: false,
  per_create: false
};

export const VIEW_STRING = 'View';
export const RECOMMENDED_STRING = 'Recommended';
export const FULL_STRING = 'Full';
export const CUSTOM_STRING = 'Custom';

export function cleanPermissions(dirtyPer) {
  return _.pickBy(dirtyPer, (val, key) => (
    _.startsWith(key, 'per_')
  ));
}

export function getPermissionString(dirtyPer) {
  const p = cleanPermissions(dirtyPer);
  if (_.isEqual(p, RECOMMENDED)) {
    return RECOMMENDED_STRING;
  }
  if (_.isEqual(p, FULL)) {
    return FULL_STRING;
  }
  if (_.isEqual(p, VIEW)) {
    return VIEW_STRING;
  }
  return CUSTOM_STRING;
}

export function forceValid(permissions) {
  const p = { ...permissions };
  if (_.isEqual(p, EMPTY)) {
    throw new Error('Cannot force empty permission to be valid.');
  }
  if (!p.per_users_view && (p.per_users_invite || p.per_users_move || p.per_products_assign)) {
    p.per_users_view = true;
  }
  if (!p.per_products_view && (p.per_products_assign || p.per_products_transfer)) {
    p.per_products_view = true;
  }
  if (!p.per_admin_view && (p.per_admin_invite || p.per_admin_edit || p.per_admin_remove)) {
    p.per_admin_view = true;
  }

  return p;
}

export function validate(dirtyP) {
  const p = cleanPermissions(dirtyP);
  if (!p.per_users_view && (p.per_users_invite || p.per_users_move)) {
    return false;
  }
  if (!p.per_products_view && (p.per_products_assign || p.per_products_transfer)) {
    return false;
  }
  if (!p.per_admin_view && (p.per_admin_invite || p.per_admin_edit || p.per_admin_remove)) {
    return false;
  }
  if (_.isEqual(p, EMPTY)) {
    return false;
  }
  return true;
}

export class PermissionSelector extends Component {
  constructor(props) {
    super(props);
    this.dropDownChange = this.dropDownChange.bind(this);
    this.checkboxChange = this.checkboxChange.bind(this);
  }

  dropDownChange(e) {
    switch (e.target.value) {
      case FULL_STRING:
        this.props.onChange({ ...FULL });
        break;
      case VIEW_STRING:
        this.props.onChange({ ...VIEW });
        break;
      case RECOMMENDED_STRING:
        this.props.onChange({ ...RECOMMENDED });
        break;
      case CUSTOM_STRING:
        this.props.onChange({ ...EMPTY });
        break;
      default:
        break;
    }
  }

  checkboxChange(e) {
    let p = { ...this.props.permissions };
    const { name } = e.target;
    p[name] = e.target.checked;

    if (!e.target.checked) {
      switch (name) {
        case 'per_users_view':
          p.per_users_invite = false;
          p.per_users_move = false;
          p.per_products_assign = false;
          break;
        case 'per_admin_view':
          p.per_admin_invite = false;
          p.per_admin_edit = false;
          p.per_admin_remove = false;
          break;
        case 'per_products_view':
          p.per_products_assign = false;
          p.per_products_transfer = false;
          break;
        default:
          break;
      }
    }

    if (_.isEqual(p, EMPTY)) {
      this.props.onChange(EMPTY, false);
      return false;
    }
    p = forceValid(p);
    this.props.onChange(p, true);
    return true;
  }

  render() {
    const permissionString = getPermissionString(this.props.permissions);
    const PermissionCheckbox = ({ permission, label }) => (
      <div className="pure-control-group">
        <input
          className="pure-input"
          type="checkbox"
          name={permission}
          checked={this.props.permissions[permission]}
          onChange={this.checkboxChange}
          disabled={this.props.disabled}
        />
        <label
          htmlFor={permission}
          style={{ marginLeft: '.3em' }}
        >{label}
        </label>
      </div>
    );
    const isEmpty = _.isEqual(cleanPermissions(this.props.permissions), EMPTY);
    return (
      <div className="permission-selector">
        <div className="pure-u-1 permission-level-label-wrapper">
          <label className="" htmlFor="permission-level-selector">
            <b>
              { (this.props.disabled) ? 'Select permissions level:' : 'Permissions level:' }
            </b>
          </label>
        </div>
        <div className="pure-u-1">
          <EapSelectList
            id="permission-level-selector"
            name="permission-level-selector"
            value={permissionString}
            onChange={this.dropDownChange}
            disabled={this.props.disabled}
            choices={[
            { value: RECOMMENDED_STRING, label: RECOMMENDED_STRING },
            { value: FULL_STRING, label: FULL_STRING },
            { value: VIEW_STRING, label: VIEW_STRING },
            { value: CUSTOM_STRING, label: CUSTOM_STRING }
            ]}
            className="pure-u-2-3"
          />
        </div>
        <div>
          <h4>Examinees</h4>
          <PermissionCheckbox
            label="View Examinees"
            permission="per_users_view"
          />
          <PermissionCheckbox
            label="Invite Examinees"
            permission="per_users_invite"
          />
          <PermissionCheckbox
            label="Move Examinees"
            permission="per_users_move"
          />
        </div>
        <div>
          <h4>Products</h4>
          <PermissionCheckbox
            label="View Products"
            permission="per_products_view"
          />
          <PermissionCheckbox
            label="Assign Products"
            permission="per_products_assign"
          />
          <PermissionCheckbox
            label="Transfer Products"
            permission="per_products_transfer"
          />
        </div>
        <div>
          <h4>Administrators</h4>
          <PermissionCheckbox
            label="View Administrators"
            permission="per_admin_view"
          />
          <PermissionCheckbox
            label="Invite Administrators"
            permission="per_admin_invite"
          />
          <PermissionCheckbox
            label="Edit Administrators"
            permission="per_admin_edit"
          />
          <PermissionCheckbox
            label="Remove Administrators"
            permission="per_admin_remove"
          />
        </div>
        <div>
          <h4>Groups</h4>
          <PermissionCheckbox
            label="Create New Group"
            permission="per_create"
          />
        </div>
        { isEmpty ? (
          <InlineValidationMessage
            message="You must select at least one permission level."
          />) : ''
        }
      </div>
    );
  }
}
PermissionSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    per_users_view: PropTypes.bool,
    per_users_invite: PropTypes.bool,
    per_users_move: PropTypes.bool,
    per_products_view: PropTypes.bool,
    per_products_transfer: PropTypes.bool,
    per_products_assign: PropTypes.bool,
    per_admin_view: PropTypes.bool,
    per_admin_invite: PropTypes.bool,
    per_admin_remove: PropTypes.bool,
    per_admin_edit: PropTypes.bool,
    per_create: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool
};

PermissionSelector.defaultProps = {
  disabled: false
};
