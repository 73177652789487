import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import GroupDetailEditor from './components/GroupDetailEditor';

import {
  updateGroup,
  checkUniqueGroupName,
} from '../actions/group';

const mapStateToProps = (state, ownProps) => {
  if (state.group.saved) {
    return {
      saved: { ...state.group.saved }
    };
  } else if (state.addGroup.groupNameExists !== undefined) {
    return {
      groupNameExists: state.addGroup.groupNameExists,
      group: { ...ownProps.group },
      saved: undefined
    };
  }

  // Default Initial
  return {
    group: { ...ownProps.group }
  };
};

const mapDispatchToProps = dispatch => ({
  updateGroup: (group) => {
    dispatch(updateGroup(group));
  },
  checkUniqueGroupName: (groupName) => {
    dispatch(checkUniqueGroupName(groupName));
  }
});

const GroupDetailEditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupDetailEditor);

export default withRouter(GroupDetailEditorContainer);
