import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import PasswordResetShell from './components/PasswordResetShell';
import PasswordUpdateContainer from './PasswordUpdateContainer';
import PasswordResetContainer from './PasswordResetContainer';

class PasswordResetRouter extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <PasswordResetShell>
        <Switch>
          <Route
            path={`${this.props.match.url}`}
            exact
            render={
            () => (<PasswordResetContainer />)
            }
          />
          <Route
            path={`${this.props.match.url}/update/:resetToken`}
            render={props => <PasswordUpdateContainer {...props} />}
          />
        </Switch>
      </PasswordResetShell>
    );
  }
}

PasswordResetRouter.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired
};

export default PasswordResetRouter;
