import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from './ConfirmationModal';

const AdminDeleteModal = ({
  showModal,
  remove,
  closeModal,
  admin
}) => (
  <ConfirmationModal
    modalTitle="DELETE ADMINISTRATOR"
    showModal={showModal}
    messagePrompt={
      <p>
        Are you sure you want to remove&nbsp;
        <b>
          {admin.firstName} {admin.lastName}
        </b>
        &nbsp;as an administrator?
      </p>
    }
    closeModal={closeModal}
    confirmAction={remove}
  />
);

AdminDeleteModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  admin: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
};

AdminDeleteModal.defaultProps = {
  showModal: false,
  admin: {
    first: '',
    last: ''
  }
};

export default AdminDeleteModal;
