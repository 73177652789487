import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import _ from 'lodash';

import EapModal from '../../../components/EapModal';
import EapStaticAlert from '../../../components/EapStaticAlert';
import EapButton from '../../../components/EapButton';

import './ProductsInventoryForm.css';

export const SUBTRACT = 'subtract';
export const ADD = 'add';

const AddSubtractProductModal = ({
  availableProduct,
  onClose,
  type,
  showModal,
}) => {
  const [validationMessage, setValidationMessage] = useState();
  const [amount, setAmount] = useState(0);
  const onChange = (e) => {
    const {
      target: {
        value,
      }
    } = e;
    const parsedValue = parseInt(value, 10);
    if (Number.isNaN(parsedValue)) {
      setValidationMessage('Please enter a valid amount.');
      return false;
    }
    if (parsedValue === 0) {
      setValidationMessage('Please enter a valid amount.');
      return false;
    }
    if (type === 'subtract' && parsedValue > availableProduct.amount) {
      setValidationMessage('Insufficent Products');
      return false;
    }

    setValidationMessage(undefined);

    if (type === 'subtract') {
      setAmount(parsedValue * -1);
    } else {
      setAmount(parsedValue);
    }
    return true;
  };

  return (
    <EapModal
      modalTitle={type === 'subtract' ? 'Remove Products' : 'Add Products'}
      showModal={showModal}
    >
      <div className="products-inventory-form">
        <p>
          {`Group currently has ${availableProduct.amount} of `}
          <b>{availableProduct.adminName}</b>
        </p>
        <form className="pure-form pure-form-aligned eap-grey-form">
          <div className="pure-control-group">
            <InputMask
              onChange={onChange}
              type="text"
              alwaysShowMask={false}
              mask="9999"
              maskChar=""
              placeholder={`1-${type === 'subtract' ? availableProduct.amount : 1000}`}
            />
          </div>

          {
            validationMessage
            && (
              <EapStaticAlert>
                {validationMessage}
              </EapStaticAlert>
            )
          }

          <EapButton
            text={type === 'subtract' ? 'SUBTRACT' : 'ADD'}
            onClick={() => {
              onClose({ amount });
            }}
            disabled={validationMessage !== undefined}
          />
          &nbsp;
          <EapButton
            isSecondary
            text="CLOSE"
            onClick={() => onClose()}
          />
        </form>
      </div>
    </EapModal>
  );
};

AddSubtractProductModal.propTypes = {
  availableProduct: PropTypes.shape({
    amount: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  /** Either add or subtract */
  type: PropTypes.string.isRequired,
  showModal: PropTypes.bool
};

AddSubtractProductModal.defaultProps = {
  showModal: false,
};

export default AddSubtractProductModal;
