import {
  STRIPE_PUBLIC_KEY_LOADED
} from '../actions/stripe';

const stripeReducer = (state = { scriptLoaded: false }, action) => {
  switch (action.type) {
    case STRIPE_PUBLIC_KEY_LOADED: {
      return {
        publicKey: action.payload.key
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default stripeReducer;
