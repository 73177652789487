import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import { isString, isEmpty } from 'lodash';
import { parseISO, isFuture, isValid } from 'date-fns';

// initialDate (optional) must be a string in simple ISO string format
const EapDatePicker = ({ initialDate, onDateSelected }) => {
  if (!isEmpty(initialDate) && !isString(initialDate)) {
    throw new TypeError('initialDate MUST be a string in format of yyyy-MM-dd');
  }
  let parsedDate = parseISO(initialDate);
  if (!isValid(parsedDate)) {
    parsedDate = undefined;
  }
  const [date, setDate] = useState(parsedDate);
  return (
    <DatePicker
      selected={date}
      onChange={(selectedDate) => {
        setDate(selectedDate);
        if (onDateSelected && !isFuture(selectedDate)) {
          onDateSelected(selectedDate);
        } else {
          setDate(undefined);
          onDateSelected(undefined);
        }
      }}
      maxDate={new Date()}
    />
  );
};

EapDatePicker.propTypes = {
  initialDate: PropTypes.string,
  onDateSelected: PropTypes.func.isRequired
};

EapDatePicker.defaultProps = {
  initialDate: undefined
};

export default EapDatePicker;
