import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AdminIcon from './AdminIcon';
import ConfirmationModal from './ConfirmationModal';

class PasscodeRegenerationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  render() {
    return (
      <span>
        <AdminIcon
          onClick={() => this.setState({ showModal: true })}
          icon="fa-rotate-right"
          toolTip="Generate New Passcode"
        />
        <ConfirmationModal
          modalTitle="GENERATE NEW PASSCODE"
          showModal={this.state.showModal}
          messagePrompt={
            <p>
              Are you sure you want to generate a new passcode for&nbsp;
              <b>{this.props.availableProduct.adminName}</b>
            </p>
          }
          closeModal={() => this.setState({ showModal: false })}
          confirmAction={() =>
            this.props.generateNewPasscode(this.props.availableProduct.id, this.props.groupId)
          }
          errorMessage={this.props.availableProduct.errorMessage}
        />
      </span>
    );
  }
}

PasscodeRegenerationDialog.propTypes = {
  availableProduct: PropTypes.shape({
    id: PropTypes.number,
    adminName: PropTypes.string,
    errorMessage: PropTypes.string,
  }).isRequired,
  generateNewPasscode: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired
};

export default PasscodeRegenerationDialog;
