import React from 'react';
import { Field } from 'formik';

const GradeLevelSelectList = () => (
  <Field
    component="select"
    name="grade"
  >
    <option value="" />
    <option value="5">Elementary (Grade 1-5)</option>
    <option value="6">Middle School (Grade 6)</option>
    <option value="7">Middle School (Grade 7)</option>
    <option value="8">Middle School (Grade 8)</option>
    <option value="9">High School (Grade 9)</option>
    <option value="10">High School (Grade 10)</option>
    <option value="11">High School (Grade 11)</option>
    <option value="12">High School (Grade 12)</option>
    <option value="14">High School Graduate</option>
    <option value="15">Some College</option>
    <option value="16">2-year College Degree (AA)</option>
    <option value="17">4-year College Degree (BA,BS)</option>
    <option value="18">{'Master\'s Degree (MA, MS)'}</option>
    <option value="19">Professional Degree (MD, JD, PhD)</option>
  </Field>
);

export default GradeLevelSelectList;
