import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as validator from '@edits/eap-signup-validator';
import FormInputComponent from './FormInputComponent';
import './Form.css';

export default class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPasswordValue: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    e.preventDefault();
    const confirmPassword = e.target.value;
    let errorMessage = _.first(validator.validateSingleInput('password', confirmPassword));
    this.setState({
      confirmPasswordValue: confirmPassword,
    });
    if (!this.props.required) {
      errorMessage = undefined;
    }
    this.props.update({
      isValid: errorMessage === undefined,
      keyName: this.props.keyName,
      value: confirmPassword
    });
  }

  render() {
    return (
      <FormInputComponent
        required={this.props.required}
        formGroupStyle={this.props.formGroupStyle}
        keyName={this.props.keyName}
        displayLabel="CONFIRM PASSWORD"
        inputType="password"
        handleChange={this.handleChange}
        inputValue={this.state.confirmPasswordValue}
        errorMessage={(this.props.passwordMatch) ? '' :
          'Passwords do not match'
        }
      />
    );
  }
}

Password.propTypes = {
  passwordMatch: PropTypes.bool,
  update: PropTypes.func.isRequired,
  keyName: PropTypes.string,
  required: PropTypes.bool,
  formGroupStyle: PropTypes.string,
};

Password.defaultProps = {
  passwordMatch: true,
  keyName: 'confirmPassword',
  required: true,
  formGroupStyle: undefined
};
