import { connect } from 'react-redux';
import InviteSpinner from './components/InviteSpinner';
import { acceptExamineeInvite } from './actions/examineeInvites';

const mapStateToProps = (state) => {
  if (!state.user) {
    return {};
  }
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => ({
  acceptInvite: (token) => {
    dispatch(acceptExamineeInvite(token));
  }
});

const ExamineeInviteSpinnerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteSpinner);

export default ExamineeInviteSpinnerContainer;
