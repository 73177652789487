import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from './Dropdown';
import './Form.css';

const GradeDropdown = ({
  formGroupStyle,
  keyName,
  selectStyle,
  value,
  disabled,
  update,
  addLabel,
  boldLabel,
  required
}) => (
  <div className={formGroupStyle}>
    <Dropdown
      keyName={keyName}
      selectStyle={selectStyle}
      value={value}
      disabled={disabled}
      update={update}
      isNumber
      addLabel={addLabel}
      boldLabel={boldLabel}
      labelName="GRADE LEVEL"
      required={required}
      choices={[
      { value: '', label: '' },
      { value: '5', label: 'Elementary (Grade 1-5)' },
      { value: '6', label: 'Middle School (Grade 6)' },
      { value: '7', label: 'Middle School (Grade 7)' },
      { value: '8', label: 'Middle School (Grade 8)' },
      { value: '9', label: 'High School (Grade 9)' },
      { value: '10', label: 'High School (Grade 10)' },
      { value: '11', label: 'High School (Grade 11)' },
      { value: '12', label: 'High School (Grade 12)' },
      { value: '14', label: 'High School Graduate' },
      { value: '15', label: 'Some College' },
      { value: '16', label: '2-year College Degree (AA)' },
      { value: '17', label: '4-year College Degree (BA,BS)' },
      { value: '18', label: 'Master\'s Degree (MA, MS)' },
      { value: '19', label: 'Professional Degree (MD, JD, PhD)' },
      ]}
    />
  </div>
);

export default GradeDropdown;

GradeDropdown.propTypes = {
  boldLabel: PropTypes.bool,
  value: PropTypes.number,
  addLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  update: PropTypes.func.isRequired,
  keyName: PropTypes.string,
  formGroupStyle: PropTypes.string,
  selectStyle: PropTypes.string
};

GradeDropdown.defaultProps = {
  boldLabel: false,
  value: undefined,
  addLabel: false,
  disabled: false,
  required: false,
  keyName: 'grade',
  formGroupStyle: 'pure-u-lg-1-2 pure-u-md-1-2 pure-u-1',
  selectStyle: 'pure-u-11-12'
};
