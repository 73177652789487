import { connect } from 'react-redux';
import _ from 'lodash';

import PurchasesTable from './PurchasesTable';
import {
  filterPurchases
} from '../../actions/purchase';

const mapStateToProps = (state, inProps) => {
  const data = state.purchases.serverFiltered.map((row) => ({
    id: row.id,
    userId: row.userId,
    firstName: row.user.firstName,
    lastName: row.user.lastName,
    email: row.user.email,
    productName: row.product ? row.product.name : '',
    groupName: row.group ? row.group.name : '',
    groupId: row.groupId ? row.groupId : '',
    quantity: row.quantity,
    unitPrice: row.unitPrice,
    chargeAmount: row.chargeAmount,
    stripeChargeId: row.stripeChargeId,
    qboId: row.qboInvoiceId,
    createdAt: row.createdAt,
    updatedAt: row.updatedAt,
  }));
  return {
    data,
    baseUrl: inProps.baseUrl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchData: ({ filtered: unparsed, ...params }) => {
    const filtered = {
      purchases: _.filter(unparsed, ({ id }) => _.includes(['id', 'stripeChargeId', 'chargeAmount'], id)),
      users: _.filter(unparsed, ({ id }) => _.includes(['email', 'firstName', 'lastName', 'value'], id)),
      groups: _(unparsed)
        .filter(({ id }) => _.includes(['groupName'], id))
        .map(({ id, value }) => {
          if (id === 'groupName') {
            return {
              id: 'name',
              value,
            };
          }
          return {
            id,
            value,
          };
        })
        .value(),
      products: _(unparsed)
        .filter(({ id }) => _.includes(['productName'], id))
        .map(({ id, value }) => {
          if (id === 'productName') {
            return {
              id: 'name',
              value,
            };
          }
          return {
            id,
            value,
          };
        })
        .value()
    };
    dispatch(filterPurchases({ type: 'refills', filtered, ...params }));
  }
});

const PurchasesTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchasesTable);

export default PurchasesTableContainer;
