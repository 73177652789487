import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../components/ConfirmationModal';
import AdminIcon from '../../components/AdminIcon';

class ExamineeInviteRevokeModal extends Component {
  constructor() {
    super();
    this.state = {
      openModal: false
    };
  }
  render() {
    return (
      <span>
        <AdminIcon
          onClick={() => this.setState({ openModal: true })}
          icon="fa-times"
          toolTip="Revoke"
        />
        <ConfirmationModal
          modalTitle="REVOKE EXAMINEE INVITE"
          showModal={this.state.openModal}
          messagePrompt={
            <p>
              Are you sure you want to revoke
              <b> {this.props.selectedExamineeInvite.email} </b>
              examinee invite?
            </p>
          }
          closeModal={() => this.setState({ openModal: false })}
          confirmAction={
            () => this.props.revokeInvite(this.props.selectedExamineeInvite)
          }
        />
      </span>
    );
  }
}

export default ExamineeInviteRevokeModal;

ExamineeInviteRevokeModal.propTypes = {
  selectedExamineeInvite: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    senderUserId: PropTypes.number,
    productName: PropTypes.string,
    productAdminName: PropTypes.string,
    createdAt: PropTypes.string
  }).isRequired,
  revokeInvite: PropTypes.func.isRequired
};
