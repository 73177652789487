import React from 'react';
import { Wizard, Steps, Step } from 'react-albus';
import PropTypes from 'prop-types';

import OrderDetailsFormContainer from '../OrderDetailsFormContainer';
import PurchaseReviewForm from './PurchaseReviewForm';
import PurchaseCompletedFeedback from './PurchaseCompletedFeedback';
import EmailForm from './EmailForm';
import CheckoutPanel from './CheckoutPanel';

class CheckoutWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeToken: undefined,
      quantity: 25,
    };
  }

  render() {
    const {
      history, url, stripePublicKey, stripeScriptLoaded
    } = this.props;

    const simpleProduct = {
      id: this.props.availableProduct.id,
      name: this.props.availableProduct.adminName,
      price: this.props.availableProduct.price || 11
    };
    const purchaseAmount = simpleProduct.price * this.state.quantity;
    return (
      <Wizard history={history} basename={url}>
        <Steps>
          <Step
            id="credit-card-form"
            render={({ next }) => (
              <CheckoutPanel title="ORDER DETAILS">
                <OrderDetailsFormContainer
                  next={() => {
                    this.props.onStepChange(2);
                    next();
                  }}
                  product={simpleProduct}
                  stripePublicKey={stripePublicKey}
                  onStripeTokenCreated={stripeToken => this.setState({ stripeToken })}
                  onSetQuantity={(qty) => {
                    this.setState({ quantity: qty });
                  }}
                  stripeScriptLoaded={stripeScriptLoaded}
                />
              </CheckoutPanel>
              )
            }
          />
          <Step
            id="confirm-email"
            render={({ next, previous }) => (
              <CheckoutPanel title="CONTACT INFO">
                <EmailForm
                  onOptionalEmailEntered={optionalEmail => this.setState({ optionalEmail })}
                  email={this.props.purchaserEmail}
                  next={() => {
                    next();
                    this.props.onStepChange(3);
                  }}
                  previous={() => {
                  previous();
                  this.props.onStepChange(1);
                  }}
                />
              </CheckoutPanel>
              )
              }
          />
          <Step
            id="review-purchase"
            render={({ next, previous }) => (
              <CheckoutPanel title="CONFIRMATION">
                <PurchaseReviewForm
                  quantity={this.state.quantity}
                  stripeToken={this.state.stripeToken}
                  groupId={this.props.groupId}
                  product={simpleProduct}
                  email={this.props.purchaserEmail}
                  optionalEmail={this.state.optionalEmail}
                  refill={this.props.refill}
                  next={() => {
                  next();
                  this.props.onStepChange(4);
                  this.setState({ stripeToken: undefined });
                  }}
                  previous={previous}
                  onBackButtonClicked={() => history.go(-3)}
                  onPaymentFailure={stripeErr => this.props.onPaymentFailed(stripeErr)}
                />
              </CheckoutPanel>)
              }
          />
          <Step
            id="complete"
            render={() => (
              <CheckoutPanel title="RECEIPT OF PURCHASE">
                <PurchaseCompletedFeedback
                  productName={simpleProduct.name}
                  groupId={this.props.groupId}
                  quantity={this.state.quantity}
                  email={this.props.purchaserEmail}
                  purchaseAmount={purchaseAmount}
                  optionalEmail={this.state.optionalEmail}
                  onClickedDone={() => history.push(`/group/${this.props.groupId}/products`)}
                />
              </CheckoutPanel>
              )
}
          />
        </Steps>
      </Wizard>
    );
  }
}

CheckoutWizard.propTypes = {
  availableProduct: PropTypes.shape({
    id: PropTypes.number.isRequired,
    adminName: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired
  }).isRequired,
  onStepChange: PropTypes.func.isRequired,
  onPaymentFailed: PropTypes.func.isRequired,
  purchaserEmail: PropTypes.string.isRequired,
  price: PropTypes.number,
  groupId: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  url: PropTypes.string.isRequired,
  stripePublicKey: PropTypes.string,
  stripeScriptLoaded: PropTypes.bool.isRequired
};

CheckoutWizard.defaultProps = {
  price: undefined,
  stripePublicKey: null
};

export default CheckoutWizard;
