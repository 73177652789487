import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { superFilter } from '../../../actions/groups';
import { DynamicEapTable } from '../../../components/DynamicEapTable';
import AdminIcon from '../../../components/AdminIcon';
import AdminIconList from '../../../components/AdminIconList';
import NavigationBar from '../../../components/NavigationBar';
import GroupContentTab from '../../../group/components/GroupContentTab';


const mapStateToProps = (state, inProps) => ({
  groups: state.groups.serverFiltered,
  baseUrl: inProps.baseUrl,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (params) => {
    dispatch(superFilter(params));
  }
});

export const SuperGroupsTable = ({ fetch, groups, baseUrl }) => {
  if (groups === undefined) {
    fetch();
  }

  const columns = [
    {
      Header: 'Group Id',
      accessor: 'id',
      Cell: ({ row }) => (
        <div className="actions">
          <Link
            to={`/super/group/${row.original.id}/info`}
            style={{ textDecoration: 'none' }}
          >
            {row.original.id}
          </Link>
        </div>
      )
    },
    {
      Header: 'Organization Id',
      accessor: 'customId',
      Cell: ({ row }) => (
        <div className="actions">
          <Link
            to={`/super/organization/${row.original.organizationId}/info`}
            style={{ textDecoration: 'none' }}
          >
            {row.original.customId}
          </Link>
        </div>
      )
    },
    {
      Header: 'Group Name',
      accessor: 'name'
    },
    {
      Header: 'Group Description',
      accessor: 'description'
    },
    {
      Header: 'Actions',
      accessor: '',
      disableSortBy: true,
      disableFiltering: true,
      Cell: ({ row }) => (
        <AdminIconList>
          <AdminIcon
            url={`/super/group/${row.original.id}/info`}
            icon="fa-info-circle"
            toolTip="View"
          />
          <AdminIcon
            url={`/super/group/${row.original.id}/examinees`}
            icon="fa-users"
            toolTip="Bulk Upload Examinees"
          />
        </AdminIconList>
      )
    }
  ];

  return (
    <>
      <NavigationBar
        routes={[
          { path: 'groups', text: 'Groups', icon: 'fa-sitemap' }
        ]}
        base={`${baseUrl.substring(1)}`}
      />
      <GroupContentTab>
        <DynamicEapTable
          columns={columns}
          data={groups}
          fetchData={fetch}
        />
      </GroupContentTab>
    </>
  );
};

const GroupsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperGroupsTable);

export default GroupsTableContainer;
