import { connect } from 'react-redux';
import CreateSigninForm from './components/CreateSigninForm';
import { superCreateSignin } from '../../actions/user';

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  superCreateSignin: (newUserInfo) => {
    dispatch(superCreateSignin(newUserInfo));
  }
});

const CreateSigninFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSigninForm);

export default CreateSigninFormContainer;
